import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { Footer } from '../../components/Footer/Footer';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { ScrollableList } from '../../components/ScrollableList/ScrollableList';
import { Topbar } from '../../components/Topbar/Topbar';
import './Browse.scss';
import { Badge } from 'react-bootstrap';
import {
  faCertificate,
  faEllipsisH,
  faExclamation,
  faFilm,
  faLightbulb,
  faMedal,
  faPlay,
  faTags,
  faTrophy,
  faUserCircle,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMobileDetect } from '../../../hooks/useMobileDetect';
import { MaskedIcon } from '../../components/MaskedIcon/MaskedIcon';
import { DeferredRender } from '../../components/DeferredRender/DeferredRender';
import { clamp, map } from '../../utils/utils';
import { useCapacitor } from '../../../hooks/useCapacitor';
import { useTranslation } from 'react-i18next';
import { Title } from '../../components/Title/Title';
import { OOLogo } from '../../components/OOLogo/OOLogo';
import { useHistory } from 'react-router-dom';
import { Billboard } from '../../components/Billboard/Billboard';
import { useGaming } from '../../../contexts/GamingContext';

const BrowseComponent = () => {
  const {
    userDataFmt,
    comingSoonVideos,
    mostViewsVideos,
    freeVideos,
    keepWatching,
    newVideos,
    updateUserData,
    billboard,
    series,
    seriesAuthors,
    seriesTags,
    staticData,
    isTrial,
    recommendations,
    bonusVideos,
  } = useFirebase();
  const { isMobile } = useMobileDetect();
  const { platform } = useCapacitor();
  const { t } = useTranslation();
  const [isAso, setIsAso] = useState(false);
  const history = useHistory();
  const { isEnabled: isGamingEnabled } = useGaming();

  const [seriesConfig, setSeriesConfig] = useState<{ showSeriesInBrowse: boolean; showAuthorSeriesInBrowse: boolean; showTagSeriesInBrowse: boolean }>({
    showSeriesInBrowse: false,
    showAuthorSeriesInBrowse: false,
    showTagSeriesInBrowse: false,
  });

  const billboardWrapperRef = useRef<HTMLDivElement>();
  const [hasBillboard, setHasBillboard] = useState(false);
  const [billboardPlay, setBillboardPlay] = useState(false);

  useEffect(() => {
    setSeriesConfig({
      showSeriesInBrowse: false,
      showAuthorSeriesInBrowse: false,
      showTagSeriesInBrowse: true,
    });
  }, []);

  useEffect(() => {
    return () => {
      updateUserData(null, { skipListUpdate: true }).catch(console.error);
    };
  }, []);

  useEffect(() => {
    if (!userDataFmt) return;
    const myCategory = userDataFmt.profile?.formData?.categoriaProfessionale;
    setIsAso(myCategory == 'aso');
  }, [userDataFmt]);

  useEffect(() => {
    setHasBillboard(!!billboard);
  }, [billboard, isMobile]);

  useLayoutEffect(() => {
    const scrollCallback = () => {
      const topbarBaseColor = 20;
      const topbarOpacityBreakpoint = 512;
      const valueToMap = clamp(window.scrollY, 0, topbarOpacityBreakpoint);
      const opacity = map(valueToMap, 0, topbarOpacityBreakpoint, 0, 1);
      const topbarElement = document.querySelector<HTMLDivElement>('.billboard-topbar');
      if (topbarElement) {
        topbarElement.style.backgroundColor = `rgba(${topbarBaseColor}, ${topbarBaseColor}, ${topbarBaseColor}, ${opacity})`;
      }
    };

    document.addEventListener('scroll', scrollCallback);
    return () => {
      document.removeEventListener('scroll', scrollCallback);
    };
  }, []);

  if (!userDataFmt || !seriesTags || !seriesTags.length || (hasBillboard && !billboard)) return <FullscreenLoader />;

  return (
    <>
      <Title>{t('labels.home')}</Title>

      <div className={'billboard-video'} style={{ marginBottom: '-20vmax' }} ref={billboardWrapperRef}>
        <div className={(isMobile ? 'billboard-inset-shadow ' : '') + (platform == 'ios' ? 'billboard-topbar-fixed-bg ' : '') + 'billboard-topbar'}>
          <Topbar />
        </div>
        {hasBillboard && !isMobile && <Billboard video={billboard} play={billboardPlay} setPlay={setBillboardPlay} wrapper={billboardWrapperRef} />}
        {isMobile && (
          <>
            <div
              className={'billboard-mobile-image'}
              onClick={() => {
                if (!(staticData.SHOW_ADVENT_CALENDAR_2022 && staticData.ADVENT_BACKGROUND && !isAso)) return;
                history.push('/advent');
              }}
              style={{
                backgroundImage: `url(${
                  staticData.SHOW_ADVENT_CALENDAR_2022 && staticData.ADVENT_BACKGROUND && !isAso ? staticData.ADVENT_BACKGROUND : staticData.BACKGROUND
                })`,
              }}
            />
          </>
        )}
      </div>

      {/*{!!tagCards.length && (
        <div className={'d-flex justify-content-center mt-5 mb-2'}>
          {tagCards.map((tag) => (
            <div
              key={tag.id}
              className={'tag-card'}
              onClick={async () => {
                const query = tag.id;
                const code = await hash(query);
                history.push(`/tag?q=${query}&c=${code}`);
              }}
            >
              {tag.label}
            </div>
          ))}
        </div>
      )}*/}

      <div style={{ zIndex: 2, position: 'relative' }}>
        {isGamingEnabled && !!bonusVideos.length && (
          <ScrollableList
            title={t('labels.bonusVideos')}
            elements={bonusVideos}
            onDetailsOpen={() => {
              if (billboardPlay) setBillboardPlay(false);
            }}
          >
            <FontAwesomeIcon color={'#b7cce0'} icon={faMedal} />
          </ScrollableList>
        )}

        {!!staticData.SHOW_COMING_SOON && !!comingSoonVideos.length && (
          <ScrollableList
            title={
              <span>
                Coming s<OOLogo />n
              </span>
            }
            elements={comingSoonVideos}
            onDetailsOpen={() => {
              if (billboardPlay) setBillboardPlay(false);
            }}
          >
            <FontAwesomeIcon color={'#b7cce0'} icon={faFilm} />
          </ScrollableList>
        )}

        {!!newVideos.length && (
          <ScrollableList
            title={t('labels.nuoveUscite')}
            forceVertical={true}
            elements={newVideos}
            onDetailsOpen={() => {
              if (billboardPlay) setBillboardPlay(false);
            }}
          >
            <MaskedIcon bg={faCertificate} bgColor={'#b7cce0'} fg={faExclamation} fgColor={'#141414'} foregroundStyles={{ transform: 'translateX(0.5px)' }} />
          </ScrollableList>
        )}

        {!!keepWatching.length && (
          <ScrollableList
            title={t('labels.continuaAGuardare')}
            elements={keepWatching}
            keepWatching={true}
            onDetailsOpen={() => {
              if (billboardPlay) setBillboardPlay(false);
            }}
            // onKeepWatchingRemove={(videoId) => {
            //   setKeepWatching((current) => current.filter((video) => video.id != videoId));
            // }}
          >
            <MaskedIcon bg={faPlay} bgColor={'#b7cce0'} fg={faEllipsisH} fgColor={'#141414'} foregroundStyles={{ transform: 'translateX(-1px)' }} />
          </ScrollableList>
        )}

        {!isTrial && !!recommendations && !!recommendations.length && (
          <ScrollableList
            title={t('labels.videoConsigliati')}
            elements={recommendations}
            onDetailsOpen={() => {
              if (billboardPlay) setBillboardPlay(false);
            }}
          >
            <FontAwesomeIcon color={'#b7cce0'} icon={faLightbulb} fixedWidth={true} />
          </ScrollableList>
        )}

        {isTrial && userDataFmt.isNewTrial && !!freeVideos.length && (
          <ScrollableList
            title={t('labels.videoGratuiti')}
            elements={freeVideos}
            onDetailsOpen={() => {
              if (billboardPlay) setBillboardPlay(false);
            }}
          >
            <FontAwesomeIcon color={'#b7cce0'} icon={faVideo} />
          </ScrollableList>
        )}

        {!!staticData.SHOW_MOST_VIEWS && !!mostViewsVideos.length && (
          <ScrollableList
            title={t('labels.topWeek')}
            forceVertical={true}
            showPosition={true}
            elements={mostViewsVideos}
            onDetailsOpen={() => {
              if (billboardPlay) setBillboardPlay(false);
            }}
          >
            <FontAwesomeIcon color={'#b7cce0'} icon={faTrophy} />
          </ScrollableList>
        )}

        {seriesConfig.showSeriesInBrowse &&
          !!series?.length &&
          series.map((serie, index) => (
            <DeferredRender key={serie.id} timeout={index * 500}>
              <ScrollableList
                title={serie.name}
                description={serie.description}
                elements={serie.seasons[0].episodes.map((ep) => ep.video)}
                onDetailsOpen={() => {
                  if (billboardPlay) setBillboardPlay(false);
                }}
              >
                {!isMobile && <Badge variant={'dark'}>Serie</Badge>}
              </ScrollableList>
            </DeferredRender>
          ))}

        {seriesConfig.showTagSeriesInBrowse &&
          !!seriesTags?.length &&
          seriesTags.map((serie, index) => (
            <DeferredRender key={serie.tag.id} timeout={index + 1}>
              <ScrollableList
                tagId={serie.tag.id}
                title={serie.tag.label}
                elements={serie.tagVideos}
                onDetailsOpen={() => {
                  if (billboardPlay) setBillboardPlay(false);
                }}
              >
                <FontAwesomeIcon color={'#b7cce0'} icon={faTags} />
              </ScrollableList>
            </DeferredRender>
          ))}

        {seriesConfig.showAuthorSeriesInBrowse &&
          !!seriesAuthors?.length &&
          seriesAuthors.map((serie, index) => (
            <DeferredRender key={serie.author.id} timeout={index * 500}>
              <ScrollableList
                title={serie.author.name}
                elements={serie.authorVideos}
                onDetailsOpen={() => {
                  if (billboardPlay) setBillboardPlay(false);
                }}
              >
                {!!serie.author.photo ? (
                  <img src={serie.author.photo} alt={serie.author.name} style={{ width: '25px', borderRadius: '50%' }} />
                ) : (
                  <MaskedIcon bg={faUserCircle} bgColor={'#fafafa'} />
                )}
              </ScrollableList>
            </DeferredRender>
          ))}
      </div>

      <Footer />
    </>
  );
};

export default BrowseComponent;
