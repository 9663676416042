import { useCallback, useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { CountryFlag } from '../../components/CountryFlag/CountryFlag';
import { Footer } from '../../components/Footer/Footer';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { Title } from '../../components/Title/Title';
import { Topbar } from '../../components/Topbar/Topbar';
import './YoutubeLive.scss';
import { useCurrentLanguage } from '../../../hooks/useCurrentLanguage';
import { Redirect } from 'react-router-dom';
import { canAccessFeature } from '../../utils/utils';

const YoutubeLiveComponent = () => {
  const { getStreamingData, staticData, userDataFmt } = useFirebase();
  const { currentLanguage } = useCurrentLanguage();
  const [mainLoading, setMainLoading] = useState(false);
  const [loading, setLoading] = useState<string>(null);
  const [streamingData, setStreamingData] = useState([]);

  useEffect(() => {
    setMainLoading(true);
    getStreamingData()
      .then(setStreamingData)
      .catch(console.error)
      .finally(() => setMainLoading(false));
  }, []);

  const fetchData = useCallback((channelId) => {
    setLoading(channelId);
    getStreamingData(channelId)
      .then(({ streaming }) => {
        setStreamingData((current) => {
          const copy = current.slice();
          const toUpdate = copy.find((channel) => channel.channelId == channelId);
          toUpdate.streaming = streaming;
          return copy;
        });
      })
      .catch(console.error)
      .finally(() => {
        setLoading(null);
      });
  }, []);

  if (!staticData.LOADED || !userDataFmt) return <FullscreenLoader />;
  if (!canAccessFeature('LIVE', currentLanguage, staticData, userDataFmt)) {
    return <Redirect to={'/browse'} />;
  }

  if (mainLoading) return <FullscreenLoader />;

  return (
    <>
      <Title>Tootor Live</Title>

      <div style={{ marginBottom: '72px' }}>
        <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
          <Topbar />
        </div>
      </div>

      <Container className={'my-5'}>
        <div className={'d-flex align-items-center justify-content-center mb-4 w-100 mx-auto'}>
          <img className={'w-100'} src="https://d260e0gu5nbefz.cloudfront.net/gaming/banners/banner_live.jpg" />
        </div>
        <div className={'grid-live'}>
          {streamingData.map((channelData) => (
            <div className={'thumbnail-video rounded overflow-hidden'} key={channelData.channelId}>
              <div className={'yt-outer-shell'}>
                <div
                  className={'yt-inner-shell rounded ' + (channelData.streaming ? '' : 'static')}
                  style={{ '--static-bg-image-url': `url(${process.env.PUBLIC_URL}/noise.png)` } as any}
                >
                  {channelData.streaming && (
                    <iframe
                      src={'https://www.youtube.com/embed/live_stream?channel=' + channelData.channelId}
                      title={channelData.name}
                      allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen'}
                    ></iframe>
                  )}
                  {!channelData.streaming && (
                    <div className={'d-flex flex-column align-items-center justify-content-center yt-content'}>
                      <span className={'font-weight-bold'}>
                        {channelData.name}
                        {channelData.languages ? channelData.languages.map((l) => <CountryFlag key={l} language={l} className={'ml-1'} />) : ''}
                      </span>
                      <span className={'small'}>Nessuno streaming in corso</span>
                      <Button
                        className={'mt-2 cursor-pointer'}
                        disabled={loading == channelData.channelId}
                        size={'sm'}
                        variant={'primary'}
                        onClick={() => fetchData(channelData.channelId)}
                      >
                        {loading == channelData.channelId ? 'Caricamento...' : 'Ricarica'}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default YoutubeLiveComponent;
