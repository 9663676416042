import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Container, Jumbotron, Row } from 'react-bootstrap';
import { Logo } from '../Logo/Logo';
import './Footer.scss';
import { AppDownload } from '../AppDownload/AppDownload';
import { useEffect, useState } from 'react';
import { useCapacitor } from '../../../hooks/useCapacitor';
import { useMobileDetect } from '../../../hooks/useMobileDetect';
import { Legalese } from '../Legalese/Legalese';
import { faLifeRing } from '@fortawesome/free-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { faCodeBranch, faGift } from '@fortawesome/free-solid-svg-icons';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { LanguageSelector } from '../LanguageSelector/LanguageSelector';
import { isItalian } from '../../utils/utils';
import { useGeolocation } from '../../../contexts/GeolocationContext';

const FooterComponent = () => {
  const [suggestApp, setSuggestApp] = useState(false);
  const { isNative } = useCapacitor();
  const { isMobile, isTV } = useMobileDetect();
  const { t } = useTranslation();
  const { staticData } = useFirebase();
  const { country_code } = useGeolocation();
  const { userDataFmt } = useFirebase();

  useEffect(() => {
    setSuggestApp(isMobile && !isNative);
  }, [isMobile, isNative]);

  if (isNative) return null;

  return (
    <Jumbotron className={`mb-0 bottom-jumbotron pb-8rem ${suggestApp ? '' : 'jumbotron-pb'}`}>
      <Container className={'p-0'}>
        <Row>
          <Col className={'d-flex justify-content-start justify-content-sm-center align-items-start align-items-sm-center flex-column flex-md-row'}>
            <Logo className={'logo-tootor mr-0 mr-sm-3'} variant={'light'} />
            <div className={'d-flex mb-2 mt-2 flex-wrap'}>
              {!isTV && userDataFmt?.profile?.formData?.categoriaProfessionale !== 'aso' && <LanguageSelector className={'mr-2 mb-2'} />}
              <Button variant={'primary'} size={'sm'} className={'mr-2 mb-2'} href={'/help'}>
                <div className={'d-flex align-items-center'}>
                  <FontAwesomeIcon icon={faLifeRing} className={'mr-2'} />
                  <span>{t('labels.helpCenter')}</span>
                </div>
              </Button>
              {staticData.SHOW_COLLABORATE && (
                <Button variant={'dark'} size={'sm'} className={'mr-2 mb-2'} href={'/collaborate'}>
                  <div className={'d-flex align-items-center'}>
                    <FontAwesomeIcon icon={faCodeBranch} className={'mr-2'} />
                    <span>{t('labels.lavoraConNoi')}</span>
                  </div>
                </Button>
              )}
              {!isNative && staticData.ENABLE_GIFTS && isItalian(country_code) && (
                <Button variant={'dark'} size={'sm'} className={'mb-2'} href={'/gift'}>
                  <div className={'d-flex align-items-center'}>
                    <FontAwesomeIcon icon={faGift} className={'mr-2'} />
                    <span>{t('labels.regalaAbbonamento')}</span>
                  </div>
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <small className={'text-muted d-flex justify-content-flex-start justify-content-sm-center flex-wrap'}>
              <Legalese />
            </small>
          </Col>
        </Row>
        {isMobile && !isNative && (
          <Row>
            <Col>
              <AppDownload />
            </Col>
          </Row>
        )}
      </Container>
    </Jumbotron>
  );
};

export { FooterComponent as Footer };
