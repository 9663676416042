import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const TrackingEventsComponent = () => {
  const history = useHistory();

  useEffect(() => {
    history.listen(() => {
      const page = window.location.pathname;
      const _hsq = ((window as any)._hsq = (window as any)._hsq || []);
      _hsq.push(['setPath', page]);
      _hsq.push(['trackPageView']);
      (window as any).fbq?.('track', 'PageView');
    });
  }, []);

  return null;
};

export { TrackingEventsComponent as TrackingEvents };
