import { Title } from '../../components/Title/Title';
import { Topbar } from '../../components/Topbar/Topbar';
import { useEffect, useState } from 'react';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { Alert, Button, Container, Form, Modal } from 'react-bootstrap';
import AdminCompany from './AdminCompany';
import { set } from 'lodash';

const Companies = () => {
  const { getCompanies, updateCompany } = useFirebase();
  const [companies, setCompanies] = useState(null);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [companiesFilter, setCompaniesFilter] = useState('');
  const [companyName, setCompanyName] = useState<string>('');
  const [createMode, setCreateMode] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    getCompanies().then(setCompanies);
  }, []);

  useEffect(() => {
    filterCompanies();
  }, [companiesFilter, companies]);

  const createCompanyInfo = async () => {
    const data = { name: companyName };
    updateCompany({ companyData: data, enabled: false })
      .then(() => {
        setCreateMode(false);
        setCompanyName('');
        setShowSuccess(true);
        getCompanies().then(setCompanies);
      })
      .catch((err) => {
        console.error(err);
        setShowError(true);
      });
  };

  const filterCompanies = () => {
    const filteredCompanies = (() => {
      if (!companies) return [];
      return companies.filter(
        (company: { name: any; id: string }) =>
          (company.name || '').toLowerCase().includes(companiesFilter) || company.id.toLowerCase().includes(companiesFilter)
      );
    })();

    setFilteredCompanies(filteredCompanies);
  };

  if (!companies) return <FullscreenLoader />;

  return (
    <>
      <Title>Aziende</Title>

      <div style={{ marginBottom: '72px' }}>
        <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
          <Topbar />
        </div>
      </div>

      <Container className={'my-5'}>
        {showSuccess && (
          <Alert variant={'success'} onClose={() => setShowSuccess(false)} dismissible>
            Nuova azienda creata con successo
          </Alert>
        )}

        <div className={'d-flex align-items-baseline justify-content-between'}>
          <div className={'display-4 mb-5'}>Gestione aziende</div>
          <Button variant={'primary'} onClick={() => setCreateMode(true)}>
            Crea
          </Button>
        </div>

        {filteredCompanies.length != companies.length && (
          <Alert style={{ position: 'fixed', bottom: '1rem', right: '2rem', zIndex: 10 }} variant={'danger'} className={'d-flex align-items-center'}>
            <strong>Filtri di ricerca applicati</strong>
            <Button size={'sm'} variant={'secondary'} className={'ml-3'} onClick={() => setCompaniesFilter('')}>
              Ripristina
            </Button>
          </Alert>
        )}

        <div>
          <Form.Control
            className={'mb-5'}
            placeholder={'Cerca Azienda per Id o Nome'}
            value={companiesFilter}
            onInput={(e) => {
              const filter = (e.target.value || '').toLowerCase();
              setCompaniesFilter(filter);
            }}
          />
        </div>

        <div className={'d-flex flex-column'} style={{ gap: '2rem' }}>
          {!filteredCompanies.length && <div>Nessuna azienda trovata</div>}
          {filteredCompanies.map((company) => (
            <AdminCompany company={company} key={company.id} reload={() => getCompanies().then(setCompanies)} />
          ))}
        </div>
      </Container>

      {createMode && (
        <Modal size={'lg'} show={createMode} onHide={() => setCreateMode(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Pannello di creazione azienda</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showError && (
              <Alert variant={'danger'} onClose={() => setShowError(false)} dismissible>
                Si è verificato un errore durante la creazione azienda. Riprova.
              </Alert>
            )}
            <div className={'d-flex align-items-start'} style={{ gap: '2rem' }}>
              <Form.Group style={{ flex: 1 }}>
                <Form.Label>Nome azienda</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Inserisci nome azienda"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  isValid={!!companyName}
                  required
                />
              </Form.Group>
            </div>
            <div className={'mb-5 w-100 d-flex justify-content-end'}>
              <Button size={'sm'} disabled={!companyName} onClick={createCompanyInfo}>
                Crea
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default Companies;
