import { FunctionComponent } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useGeolocation } from '../../../contexts/GeolocationContext';
import { shouldCompleteFirstLogin } from '../../utils/utils';
import { FullscreenLoader } from '../FullscreenLoader/FullscreenLoader';

interface PrivateRoutePropTypes {
  [key: string]: any;
  component?: FunctionComponent<RouteComponentProps>;
  path: string;
  children?: JSX.Element;
  countryRestrictions?: string[];
}

const PrivateRouteComponent = ({ component: Component, path, children, countryRestrictions, ...rest }: PrivateRoutePropTypes) => {
  const { currentUser, userDataFmt, staticData } = useFirebase();
  const { country_code } = useGeolocation();
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        if (countryRestrictions && !countryRestrictions.includes(country_code)) return <Redirect to={`/`} />;
        if (!currentUser) return <Redirect to={`/?redirectTo=${path}`} />;
        if (!userDataFmt || !Object.keys(staticData).length) return <FullscreenLoader />;
        if (!path.startsWith('/first-login') && shouldCompleteFirstLogin(userDataFmt)) return <Redirect to={'/first-login'} />;
        if (!shouldCompleteFirstLogin(userDataFmt) && (path.startsWith('/platform-coming-soon') || path.startsWith('/ticket'))) return <Component {...props} />;
        if (!path.startsWith('/first-login') && !staticData.PLATFORM_AVAILABLE && !userDataFmt.admin && !userDataFmt.earlyAccess)
          return <Redirect to={'/platform-coming-soon'} />;
        if (Component) return <Component {...props} />;
        return children;
      }}
    />
  );
};

export { PrivateRouteComponent as PrivateRoute };
