import { Browser } from '@capacitor/browser';
import { faApple, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import {
  faAngleDoubleUp,
  faCertificate,
  faCheck,
  faCheckCircle,
  faChevronRight,
  faCircleNotch,
  faCoins,
  faEnvelope,
  faShieldAlt,
  faUndoAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormEvent, useEffect, useRef, useState } from 'react';
import Gravatar from 'react-awesome-gravatar';
import { Alert, Badge, Button, ButtonGroup, Card, Container, Dropdown, DropdownButton, Form, Image, InputGroup, ListGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Else, If, Then, When } from 'react-if';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useCapacitor } from '../../../hooks/useCapacitor';
import { useCurrentLanguage } from '../../../hooks/useCurrentLanguage';
import { ErrorModal } from '../../components/ErrorModal/ErrorModal';
import { Footer } from '../../components/Footer/Footer';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { LoginWithGoogle } from '../../components/LoginWithGoogle/LoginWithGoogle';
import { MaskedIcon } from '../../components/MaskedIcon/MaskedIcon';
import { Title } from '../../components/Title/Title';
import { Topbar } from '../../components/Topbar/Topbar';
import { formatSecondsTimestamp, generateColorFromArbitraryString, getPriceInterval, isValidPhoneNumber } from '../../utils/utils';
import './Profile.scss';
import { useStripe } from '@stripe/react-stripe-js';
import { Legalese } from '../../components/Legalese/Legalese';

const ProfileComponent = () => {
  const {
    signupData,
    userDataFmt,
    currentUser,
    subscription,
    getDeviceIdentifier,
    getCustomerPortalUrl,
    getRegisteredDevices,
    removeDevice,
    logoutAll,
    checkEmail,
    changePassword,
    reauthenticate,
    deleteAccount,
    sendEmailVerification,
    database,
    updateCustomerSubscription,
    getCustomerData,
    staticData,
    earlyRenew,
  } = useFirebase();
  const history = useHistory();
  const { isNative } = useCapacitor();
  const stripe = useStripe();

  const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = useState(false);
  const [isLogoutAllModalOpen, setIsLogoutAllModalOpen] = useState(false);
  const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false);
  const [isPasswordChangeModalOpen, setIsPasswordChangeModalOpen] = useState(false);
  const [isPhoneNumberChangeModalOpen, setIsPhoneNumberChangeModalOpen] = useState(false);
  const [isPhoneNumberChangedModalOpen, setIsPhoneNumberChangedModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isReauthenticateModalOpen, setIsReauthenticateModalOpen] = useState(false);
  const [isAccountDeletionModalOpen, setIsAccountDeletionModalOpen] = useState(false);

  const [portalUrlLoading, setPortalUrlLoading] = useState(false);
  const [registeredDevices, setRegisteredDevices] = useState<any[]>(null);
  const [currentDeviceId, setCurrentDeviceId] = useState<string>(null);
  const [signInMethods, setSignInMethods] = useState<string[]>([]);
  const [newPassword, setNewPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmationPasswordVisible, setConfirmationPasswordVisible] = useState(false);
  const [errorCode, setErrorCode] = useState('');
  const reauthenticationCallback = useRef(null);

  const [earlyRenewLoading, setEarlyRenewLoading] = useState(false);

  const [committedPlanUpgrade, setCommittedPlanUpgrade] = useState<any>(null);
  const [isPlanUpgradeModalOpen, setIsPlanUpgradeModalOpen] = useState(false);
  const [planUpgradeLoading, setPlanUpgradeLoading] = useState(false);
  const [planUpgradeData, setPlanUpgradeData] = useState<any>(null);
  useEffect(() => {
    if (isPlanUpgradeModalOpen) return;
    setPlanUpgradeData(null);
  }, [isPlanUpgradeModalOpen]);
  useEffect(() => {
    if (!!subscription && subscription.items[0].plan.interval == 'month') {
      database
        .collection(`userData/${currentUser.uid}/subscription_schedules`)
        .where('status', '==', 'commit')
        .orderBy('created', 'desc')
        .limit(1)
        .get()
        .then((schedulesCollection) => {
          const [doc] = schedulesCollection.docs;
          if (doc) setCommittedPlanUpgrade({ id: doc.id, ...doc.data() });
          else setCommittedPlanUpgrade({});
        })
        .catch(console.error);
    }
  }, [subscription]);

  useEffect(() => {
    if (!isPasswordChangeModalOpen) setNewPassword('');
  }, [isPasswordChangeModalOpen]);

  const { t } = useTranslation();
  const { currentLocale, currentLanguage } = useCurrentLanguage();

  const [customerData, setCustomerData] = useState<any>({});

  const [upgradePrices, setUpgradePrices] = useState<any[]>([]);
  const [upgradeLoading, setUpgradeLoading] = useState(false);
  const [selectedUpgradeLoading, setSelectedUpgradeLoading] = useState<string>(null);
  const selectUpgradedPrice = (priceId: string) => {
    setUpgradeLoading(true);
    setSelectedUpgradeLoading(priceId);
    updateCustomerSubscription(priceId)
      .then(() => window.location.reload())
      .catch(console.error);
  };

  useEffect(() => {
    getRegisteredDevices().then(setRegisteredDevices);
    getDeviceIdentifier().then(setCurrentDeviceId);
    getCustomerData().then(setCustomerData);
    if (currentUser) checkEmail(currentUser.email).then(setSignInMethods);
  }, []);

  useEffect(() => {
    if (!subscription) return;
    database
      .collection(`${subscription.product.path}/prices`)
      .where('active', '==', true)
      .orderBy('unit_amount', 'asc')
      .get()
      .then((results) => results.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
      .then(setUpgradePrices);
  }, [subscription]);

  const verifyEmail = () => {
    sendEmailVerification('/profile').then(() => {
      setIsVerifyEmailModalOpen(true);
    });
  };

  const handleStripeCredit = async () => {
    if (earlyRenewLoading) return;
    setEarlyRenewLoading(true);
    const { id, url } = await earlyRenew();
    if (isNative) {
      await Browser.open({ url });
    } else await stripe.redirectToCheckout({ sessionId: id });
    setEarlyRenewLoading(false);
  };

  const runChangePassword = () => {
    changePassword(newPassword)
      .then(() => {
        setIsPasswordChangeModalOpen(false);
        setIsSuccessModalOpen(true);
      })
      .catch((error) => {
        if (error.code == 'auth/requires-recent-login') {
          reauthenticationCallback.current = runChangePassword;
          setIsReauthenticateModalOpen(true);
          return;
        }

        setErrorCode(error.code);
        setIsErrorModalOpen(true);
      })
      .finally(() => {
        reauthenticationCallback.current = null;
      });
  };

  const runDeleteAccount = () => {
    setIsWaitingModalOpen(true);
    deleteAccount()
      .then(() => {
        history.push('/');
        window.location.reload();
      })
      .catch((error) => {
        if (error.code == 'auth/requires-recent-login') {
          reauthenticationCallback.current = runDeleteAccount;
          setIsReauthenticateModalOpen(true);
          return;
        }

        setErrorCode(error.code);
        setIsErrorModalOpen(true);
      })
      .finally(() => {
        reauthenticationCallback.current = null;
        setIsWaitingModalOpen(false);
      });
  };

  const onPasswordChangeSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    runChangePassword();
  };

  const onPhoneNumberChangeSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (phoneError) {
      return;
    }

    setIsPhoneNumberChangeModalOpen(false);
    setIsWaitingModalOpen(true);
    database
      .doc(`userData/${currentUser.uid}`)
      .set({ profile: { formData: { phoneNumber: newPhoneNumber } } }, { merge: true })
      .then(() => {
        setPhoneNumber(newPhoneNumber);
        setIsPhoneNumberChangedModalOpen(true);
        setIsWaitingModalOpen(false);
      })
      .catch((error) => {
        setErrorCode(error.code);
        setIsErrorModalOpen(true);
        setIsWaitingModalOpen(false);
      });
  };

  const runReauthenticate = () => {
    reauthenticate(confirmationPassword)
      .then(() => {
        setIsReauthenticateModalOpen(false);
        if (reauthenticationCallback.current) {
          reauthenticationCallback.current();
        } else {
          // const url = new URL(window.location.href);
          // url.searchParams.set('mfaEnroll', '1');
          // window.location.assign(url.search);
        }
      })
      .catch((error) => {
        setErrorCode(error.code);
        setIsReauthenticateModalOpen(false);
        setIsErrorModalOpen(true);
      });
  };

  const onReauthenticateSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    runReauthenticate();
  };

  const getLocalizedProperty = (item: any, prop: string) => {
    try {
      return item.metadata?.[`i18n.${prop}.${currentLanguage}`] || item[prop];
    } catch {
      return item[prop];
    }
  };

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [privacyProfiling, setPrivacyProfiling] = useState(userDataFmt.profile?.privacy?.privacyProfiling ?? false);
  const [privacyMarketing, setPrivacyMarketing] = useState(userDataFmt.profile?.privacy?.privacyMarketing ?? false);
  const [privacySendNews, setPrivacySendNews] = useState(userDataFmt.profile?.privacy?.privacySendNews ?? false);
  const [privacySendPhoneNews, setPrivacySendPhoneNews] = useState(userDataFmt.profile?.privacy?.privacySendPhoneNews ?? false);
  const [privacySendCompanyData, setPrivacySendCompanyData] = useState(userDataFmt.profile?.privacy?.privacySendCompanyData ?? false);
  const [phoneNumber, setPhoneNumber] = useState(userDataFmt.profile?.formData?.phoneNumber ?? '');
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState(false);

  const handlePhoneChange = (event) => {
    const isValid = event.target.value ? isValidPhoneNumber(event.target.value) : true;
    setPhoneError(!isValid);

    setNewPhoneNumber(event.target.value);
  };

  if (!currentUser) return <Redirect to={'/'} />;

  if (!signupData || !userDataFmt) return <FullscreenLoader />;

  return (
    <>
      <Title>{t('labels.profilo')}</Title>

      <div style={{ marginBottom: '72px' }}>
        <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
          <Topbar />
        </div>
      </div>

      <Container className={'mt-4'}>
        <Card bg={'dark'}>
          <Card.Body>
            <div className={'d-flex align-items-center'}>
              <Gravatar email={currentUser.email} options={{ default: 'mp', size: 50 }}>
                {(url) => (
                  <Image
                    src={currentUser.photoURL ?? url}
                    style={{ width: '50px', backgroundColor: generateColorFromArbitraryString(currentUser?.email ?? '') }}
                    roundedCircle
                  />
                )}
              </Gravatar>
              <div className={'d-flex flex-column'}>
                <div className={'d-flex align-items-center ml-3'}>
                  <div>{currentUser.email}</div>
                  {currentUser.emailVerified && <MaskedIcon bg={faCertificate} bgColor={'#3393d0'} fg={faCheck} inverse className={'ml-1'} small />}
                  {!currentUser.emailVerified && (
                    <Button
                      as={Badge}
                      variant={'warning'}
                      className={'ml-2'}
                      onClick={() => {
                        verifyEmail();
                      }}
                    >
                      {t('profile.labels.verificaMail')}
                    </Button>
                  )}
                </div>
                <div className={'ml-3 text-muted'}>
                  {t('profile.labels.metodiAccesso')}:
                  {signInMethods
                    .map((method) => {
                      const methodsMap = {
                        password: { icon: faEnvelope, label: 'Email' },
                        'google.com': { icon: faGoogle, label: 'Google' },
                        'apple.com': { icon: faApple, label: 'Apple' },
                      };
                      const methodData = methodsMap[method];
                      if (methodData)
                        return (
                          <Badge className={'ml-1'} variant={'light'} key={method}>
                            <FontAwesomeIcon className={'mr-1'} size={'sm'} icon={methodData.icon} />
                            <span>{methodData.label}</span>
                          </Badge>
                        );
                      return null;
                    })
                    .filter(Boolean)}
                </div>
              </div>
            </div>
            <dl className={'mt-3 mb-0'}>
              <dt>{t('labels.nome')}</dt>
              <dd>
                {userDataFmt.profile.formData.nome} {userDataFmt.profile.formData.cognome}
              </dd>
              <dt>{t('profile.labels.categoriaProfessionale')}</dt>
              {!!signupData?.categoriaProfessionale && (
                <dd>
                  {t(
                    `labels.${
                      signupData.categoriaProfessionale.values
                        .slice()
                        .sort((a, b) => a.order - b.order)
                        .find((option) => option.id == userDataFmt.profile.formData.categoriaProfessionale)
                        .value?.toLowerCase() || 'emptyLabel'
                    }`
                  )}
                </dd>
              )}
              <dt>{t('profile.labels.phoneNumber')}</dt>
              <dd>{phoneNumber}</dd>
            </dl>
          </Card.Body>
          <Card.Footer className={'d-flex justify-content-end'}>
            <DropdownButton variant={'primary'} id={'profile-options'} size={'sm'} title={t('labels.azioni')}>
              <Dropdown.Item
                onClick={() => {
                  setNewPhoneNumber(phoneNumber);
                  setPhoneError(phoneNumber ? !isValidPhoneNumber(phoneNumber) : false);
                  setIsPhoneNumberChangeModalOpen(true);
                }}
              >
                {t('profile.labels.cambiaTelefono')}
              </Dropdown.Item>
              {signInMethods.includes('password') && (
                <Dropdown.Item onClick={() => setIsPasswordChangeModalOpen(true)}>{t('profile.labels.cambiaPassword')}</Dropdown.Item>
              )}
              <Dropdown.Item
                onClick={() => {
                  setIsLogoutAllModalOpen(true);
                }}
              >
                {t('profile.labels.disconnettiSessioni')}
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={() => {
                  setIsAccountDeletionModalOpen(true);
                }}
              >
                <span className={'text-danger'}>{t('profile.labels.eliminaAccount')}</span>
              </Dropdown.Item>
            </DropdownButton>
          </Card.Footer>
        </Card>
      </Container>

      <Container className={'mt-4'}>
        <Card bg={'dark'}>
          <Card.Body>
            {/*<Form.Group style={{ gridArea: 'span 1 / span 2' }}>
              <Form.Check
                type={'checkbox'}
                id={'checkPrivacyDefault'}
                disabled={true}
                checked={true}
                label={
                  <>
                    <div>{t('firstLogin.labels.privacyDescription4', { title: process.env.REACT_APP_TITLE })}</div>
                  </>
                }
              />
            </Form.Group>*/}

            <Form.Group style={{ gridArea: 'span 1 / span 2' }}>
              <Form.Check
                type={'checkbox'}
                id={'checkPrivacyMarketing'}
                disabled={loading}
                checked={privacyMarketing}
                onChange={(e) => setPrivacyMarketing(e.target.checked)}
                label={
                  <>
                    <div>
                      {t('firstLogin.labels.acconsentoTrattamento', { letter: 'c)' })}
                      <Legalese just={'privacy'} />, {t('firstLogin.labels.acconsentoTrattamento3')}
                    </div>
                  </>
                }
              />
            </Form.Group>

            <Form.Group style={{ gridArea: 'span 1 / span 2' }}>
              <Form.Check
                type={'checkbox'}
                id={'checkPrivacySendNews'}
                disabled={loading}
                checked={privacySendNews}
                onChange={(e) => setPrivacySendNews(e.target.checked)}
                label={
                  <>
                    <div>
                      {t('firstLogin.labels.acconsentoTrattamento', { letter: 'e)' })}
                      <Legalese just={'privacy'} />, {t('firstLogin.labels.acconsentoTrattamento2', { title: process.env.REACT_APP_TITLE })}
                    </div>
                  </>
                }
              />
            </Form.Group>

            <Form.Group style={{ gridArea: 'span 1 / span 2' }}>
              <Form.Check
                type={'checkbox'}
                id={'checkPrivacyProfiling'}
                disabled={loading}
                checked={privacyProfiling}
                onChange={(e) => setPrivacyProfiling(e.target.checked)}
                label={
                  <>
                    <div>{t('firstLogin.labels.accettaCondizioni')}</div>
                  </>
                }
              />
            </Form.Group>

            <Form.Group style={{ gridArea: 'span 1 / span 2' }}>
              <Form.Check
                type={'checkbox'}
                id={'checkPrivacySendPhoneNews'}
                disabled={loading}
                checked={privacySendPhoneNews}
                onChange={(e) => setPrivacySendPhoneNews(e.target.checked)}
                label={
                  <>
                    <div>
                      {t('firstLogin.labels.acconsentoTrattamento', { letter: 'f)' })}
                      <Legalese just={'privacy'} />, {t('firstLogin.labels.acconsentoTrattamento4', { title: process.env.REACT_APP_TITLE })}
                    </div>
                  </>
                }
              />
            </Form.Group>

            <Form.Group style={{ gridArea: 'span 1 / span 2' }}>
              <Form.Check
                type={'checkbox'}
                id={'checkPrivacySendCompanyData'}
                disabled={loading}
                checked={privacySendCompanyData}
                onChange={(e) => setPrivacySendCompanyData(e.target.checked)}
                label={
                  <>
                    <div>
                      {t('firstLogin.labels.acconsentoTrattamento', { letter: 'g)' })}
                      <Legalese just={'privacy'} />, {t('firstLogin.labels.acconsentoTrattamento5', { title: process.env.REACT_APP_TITLE })}
                    </div>
                  </>
                }
              />
            </Form.Group>
          </Card.Body>
          <Card.Footer className={'d-flex justify-content-end'}>
            <ButtonGroup size={'sm'}>
              <Button
                disabled={loading}
                variant={success ? 'success' : 'primary'}
                onClick={() => {
                  setLoading(true);
                  database
                    .doc(`userData/${currentUser.uid}`)
                    .set(
                      {
                        profile: { privacy: { privacyMarketing, privacyProfiling, privacySendNews, privacySendPhoneNews, privacySendCompanyData } },
                      },
                      { merge: true }
                    )
                    .finally(() => {
                      setLoading(false);
                      setSuccess(true);
                      setTimeout(() => setSuccess(false), 2000);
                    });
                }}
              >
                {t(success ? 'tv.labels.operazioneSuccesso' : 'labels.aggiorna')}
              </Button>
            </ButtonGroup>
          </Card.Footer>
        </Card>
      </Container>

      <Container className={'mt-4'}>
        <Card bg={'dark'}>
          <Card.Body>
            <Card.Title>
              {(() => {
                if (!subscription) return t('profile.labels.abbonamentoNonAttivo');
                if (subscription.cancel_at) return t('profile.labels.noAbbonamentoAttivo');
                return t('profile.labels.abbonamentoAttivo');
              })()}
            </Card.Title>
            {!!subscription && (
              <>
                <div>
                  <strong>{getLocalizedProperty(subscription.items[0].price.product, 'name')}</strong>
                </div>
                <p>{getLocalizedProperty(subscription.items[0].price.product, 'description')}</p>
                <div>{t('profile.labels.attivatoIl', { date: formatSecondsTimestamp(subscription.created.seconds, undefined, currentLocale) })}</div>
                <div>
                  {t('profile.labels.periodoFatturazione', {
                    dateStart: formatSecondsTimestamp(subscription.current_period_start.seconds, undefined, currentLocale),
                    dateEnd: formatSecondsTimestamp(subscription.current_period_end.seconds, undefined, currentLocale),
                  })}
                </div>
                {!subscription.cancel_at && (
                  <>
                    <p className={'text-muted'}>
                      {t('profile.labels.dataAddebito', {
                        date: formatSecondsTimestamp(subscription.current_period_end.seconds, 'dd/MM/yy'),
                        amount: (subscription.items[0].price.unit_amount / 100).toFixed(2),
                      })}
                    </p>
                    <p className={'text-muted'}>{t('profile.labels.seApplicatoCodiceScontoDescrizione')}</p>
                  </>
                )}
                {!!subscription.cancel_at && (
                  <>
                    <p className={'text-muted'}>
                      {t('profile.labels.abbonamentoAnnullato', { date: formatSecondsTimestamp(subscription.canceled_at.seconds, 'dd/MM/yy') })}{' '}
                      <strong>{formatSecondsTimestamp(subscription.cancel_at.seconds, 'dd/MM/yy')}</strong>
                    </p>
                    <p className={'text-muted'}>{t('profile.labels.abbonamentoAnnullato2')}</p>
                  </>
                )}
                {!!subscription && customerData && (
                  <div>
                    <Card.Title>{t('profile.labels.metodoPagamentoImpostato')}</Card.Title>
                    <div className={'d-flex flex-column flex-md-row align-items-start align-items-md-center'}>
                      {customerData.paymentMethod === undefined
                        ? `${t('labels.caricamento')}...`
                        : (() => {
                            if (customerData.paymentMethod === null) return t('profile.labels.cliccaGestisciPiano');
                            try {
                              const { brand, last4, exp_month, exp_year, three_d_secure_usage } = customerData.paymentMethod.card;
                              const { supported: is3dSecureSupported } = three_d_secure_usage;
                              return (
                                <>
                                  <div>
                                    <strong>{brand.toUpperCase()}</strong> <span>{t('profile.labels.terminaCon')}</span> <strong>***{last4}</strong>{' '}
                                    <span>
                                      ({t('profile.labels.scadenza')?.toLowerCase()}: {exp_month}/{('' + exp_year).slice(-2)})
                                    </span>
                                  </div>
                                  {is3dSecureSupported && (
                                    <Badge pill variant={'success'} className={'ml-0 ml-md-1'}>
                                      <FontAwesomeIcon icon={faShieldAlt} className={'mr-1'} />
                                      {t('labels.secure3D')}
                                    </Badge>
                                  )}
                                </>
                              );
                            } catch {
                              return t('profile.labels.erroreCaricamentoMetodoPagamento');
                            }
                          })()}
                    </div>
                  </div>
                )}

                {!!subscription && customerData && staticData.SHOW_CREDIT && (
                  <div className={'mt-3'}>
                    <Card.Title className={'mb-0'}>
                      {t('profile.labels.credito')}:{' '}
                      {customerData.balance === undefined ? (
                        `${t('labels.caricamento')}...`
                      ) : (
                        <span className={customerData.balance > 0 ? 'text-danger' : customerData.balance < 0 ? 'text-success' : ''}>
                          &euro; <span className={customerData.balance != 0 ? 'font-weight-bold' : ''}>{((customerData.balance * -1) / 100).toFixed(2)}</span>
                        </span>
                      )}
                    </Card.Title>
                    <div className={'text-muted'}>
                      {t('profile.labels.creditoDescrizione1')}{' '}
                      {/*{customerData.balance >= 0 && !isNative && staticData.ENABLE_EARLY_RENEW && !subscription.cancel_at && (*/}
                      {customerData.balance >= 0 && !isNative && staticData.ENABLE_EARLY_RENEW && subscription.items[0].plan.interval == 'year' && (
                        <>
                          <br />
                          {t('profile.labels.puoiRinnovareAnticipo')}:
                          <Button as={Badge} variant={'info'} className={'ml-1'} onClick={handleStripeCredit}>
                            <FontAwesomeIcon icon={faCoins} className={'mr-1'} />
                            {earlyRenewLoading ? <>{t('labels.attendi')}&hellip;</> : t('profile.labels.rinnovaAnticipo')}
                          </Button>
                          <div className={'small text-muted'}>{t('profile.labels.creditoDescrizione2')}</div>
                        </>
                      )}
                    </div>
                  </div>
                )}

                <When condition={!!subscription && subscription.items[0].plan.interval == 'month'}>
                  <When condition={!!committedPlanUpgrade}>
                    {() => (
                      <If condition={isNative}>
                        <Then>
                          <Alert variant={'info'} className={'mb-0 mt-3'}>
                            {t('profile.labels.cambioPianoInfo')}{' '}
                            <Button as={Badge} variant={'info'} onClick={() => history.push('/help')}>
                              <FontAwesomeIcon icon={faChevronRight} className={'mr-1'} />
                              {t('profile.labels.accediHelpCenter')}
                            </Button>
                          </Alert>
                        </Then>
                        <Else>
                          <If condition={!!committedPlanUpgrade.id}>
                            <Then>
                              {() => (
                                <Alert variant={'warning'} className={'mb-0 mt-3'}>
                                  <div className={'font-weight-bold'}>{t('profile.labels.aggiornamentoProgrammato')}</div>
                                  {t('profile.labels.aggiornamentoProgrammatoInData')}{' '}
                                  <span className={'font-weight-bold'}>
                                    {formatSecondsTimestamp(committedPlanUpgrade.currentPeriodEnd.seconds, undefined, currentLocale)}
                                  </span>{' '}
                                  {t('profile.labels.alCosto')}{' '}
                                  <span className={'font-weight-bold'}>
                                    &euro; {(committedPlanUpgrade.amount / 100).toFixed(2)} / {getPriceInterval(committedPlanUpgrade.interval)}
                                  </span>
                                  .{' '}
                                  <Button
                                    as={Badge}
                                    variant={'danger'}
                                    onClick={() => {
                                      if (planUpgradeLoading) return;
                                      setPlanUpgradeLoading(true);
                                      updateCustomerSubscription(null, null, null, committedPlanUpgrade.id)
                                        .then((cancelResult) => {
                                          if (!cancelResult) return;
                                          window.location.reload();
                                        })
                                        .catch(console.error)
                                        .finally(() => {
                                          setPlanUpgradeLoading(false);
                                        });
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faChevronRight} className={'mr-1'} />
                                    {planUpgradeLoading ? <>{t('labels.attendi')}&hellip;</> : t('profile.labels.annullaAggiornamento')}
                                  </Button>
                                </Alert>
                              )}
                            </Then>
                            <Else>
                              {/*<If condition={!!staticData.ALLOW_AUTONOMOUS_YEARLY_UPGRADE && !subscription.cancel_at}>*/}
                              <If condition={!!staticData.ALLOW_AUTONOMOUS_YEARLY_UPGRADE}>
                                <Then>
                                  {() => (
                                    <Alert variant={'info'} className={'mb-0 mt-3'}>
                                      <div className={'font-weight-bold'}>{t('profile.labels.passaPianoAnnuale')}</div>
                                      {t('profile.labels.risparmiaColPianoAnnuale')}{' '}
                                      <Button
                                        as={Badge}
                                        variant={'info'}
                                        onClick={() => {
                                          if (planUpgradeLoading) return;
                                          setPlanUpgradeLoading(true);
                                          updateCustomerSubscription(null, true)
                                            .then((pendingResult) => {
                                              if (!pendingResult) return;
                                              setPlanUpgradeData(pendingResult);
                                              setIsPlanUpgradeModalOpen(true);
                                            })
                                            .catch(console.error)
                                            .finally(() => {
                                              setPlanUpgradeLoading(false);
                                            });
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faChevronRight} className={'mr-1'} />
                                        {planUpgradeLoading ? <>{t('labels.attendi')}&hellip;</> : t('labels.cliccaQui')}
                                      </Button>
                                    </Alert>
                                  )}
                                </Then>
                                <Else>
                                  <Alert variant={'info'} className={'mb-0 mt-3'}>
                                    {t('profile.labels.cambioPianoInfo')}{' '}
                                    <Button as={Badge} variant={'info'} onClick={() => history.push('/help')}>
                                      <FontAwesomeIcon icon={faChevronRight} className={'mr-1'} />
                                      {t('profile.labels.accediHelpCenter')}
                                    </Button>
                                  </Alert>
                                </Else>
                              </If>
                            </Else>
                          </If>
                        </Else>
                      </If>
                    )}
                  </When>
                </When>
              </>
            )}
            {!subscription && <div> {t('profile.labels.codiceScontoNonDisponibile')}</div>}
          </Card.Body>
          <Card.Footer className={'d-flex justify-content-end'}>
            <ButtonGroup size={'sm'}>
              {!!subscription && (
                <>
                  <Button
                    variant={'primary'}
                    disabled={portalUrlLoading}
                    onClick={() => {
                      setPortalUrlLoading(true);
                      getCustomerPortalUrl()
                        .then(async (res) => {
                          if (isNative) {
                            await Browser.open({ url: res.url });
                          } else window.location.href = res.url;
                        })
                        .finally(() => setPortalUrlLoading(false));
                    }}
                  >
                    {portalUrlLoading && <FontAwesomeIcon className={'mr-2'} icon={faCircleNotch} spin />}
                    {portalUrlLoading ? <span>{t('labels.attendi')} &hellip;</span> : <span>{t('profile.labels.gestisciPiano')}</span>}
                  </Button>
                </>
              )}
              {!subscription && !isNative && (
                <Button variant={'primary'} as={Link} to={'/plans'}>
                  {t('profile.labels.attivaPiano')}
                </Button>
              )}
            </ButtonGroup>
          </Card.Footer>
        </Card>
      </Container>

      {!!subscription && !!upgradePrices.length && !isNative && staticData.ALLOW_PLAN_CHANGE && (
        <Container className={'mt-4'}>
          <Card bg={'dark'}>
            <Card.Body>
              <Card.Title>{t('profile.labels.eseguiUpgradePiano')}</Card.Title>
              <p>
                {t('profile.labels.registraPiuDispositivi', { title: process.env.REACT_APP_TITLE })}
                <br />
                {t('profile.labels.differenzaPrezzoInfo')}
                {staticData.ALLOW_PLAN_CHANGE_BACK && (
                  <>
                    <br />
                    {t('profile.labels.differenzaPrezzoInfo2')}
                  </>
                )}
              </p>
              {upgradePrices.map((price, i, arr) => {
                const indexOfActivePrice = arr.findIndex((p) => p.id == subscription.price.id);
                const isBefore = i < indexOfActivePrice;
                const isActive = i == indexOfActivePrice;
                const isAfter = i > indexOfActivePrice;
                return (
                  <div key={price.id} className={'d-flex justify-content-between border mb-2 rounded ' + (isActive ? 'border-light' : 'border-secondary')}>
                    <div className={'d-flex'}>
                      <div
                        className={
                          'd-flex flex-column justify-content-center border border-dark rounded-left ' +
                          (isActive ? 'font-weight-bold text-primary' : 'text-dark')
                        }
                        style={{ width: '140px', backgroundColor: '#bdc3c7' }}
                      >
                        <div className={'text-center'}>{price.metadata?.NAME}</div>
                      </div>
                      <div className={'ml-2 align-self-center'}>
                        <div>{t('profile.labels.accediConNDispositivi', { devices: price.metadata?.DEVICES })}</div>
                      </div>
                    </div>
                    <div className={'d-flex'}>
                      <div className={'align-self-center mr-2'}>
                        {!isActive && (isAfter || staticData.ALLOW_PLAN_CHANGE_BACK) && (
                          <Button
                            size={((): any => {
                              if (isBefore) return 'sm';
                              if (isAfter) return null;
                            })()}
                            variant={(() => {
                              if (isBefore) return 'info';
                              if (isAfter) return 'primary';
                            })()}
                            className={(() => {
                              if (isBefore) return '';
                              if (isAfter) return 'font-weight-bold';
                            })()}
                            onClick={() => selectUpgradedPrice(price.id)}
                            disabled={upgradeLoading}
                          >
                            <FontAwesomeIcon
                              icon={(() => {
                                if (selectedUpgradeLoading == price.id) return faCircleNotch;
                                if (isBefore) return faUndoAlt;
                                if (isAfter) return faAngleDoubleUp;
                              })()}
                              spin={selectedUpgradeLoading == price.id}
                              className={'mr-1'}
                            />
                            {(() => {
                              if (selectedUpgradeLoading == price.id) return 'Attendi...';
                              if (isBefore) return 'Torna indietro';
                              if (isAfter) return 'Upgrade';
                            })()}
                          </Button>
                        )}
                      </div>
                      <div className={'p-3 border border-dark rounded-right text-center'} style={{ width: '165px', backgroundColor: '#bdc3c7' }}>
                        {isActive && (
                          <div className={'text-primary font-weight-bold'}>
                            <FontAwesomeIcon icon={faCheckCircle} className={'mr-1'} /> Attivo
                          </div>
                        )}
                        {!isActive && (
                          <div className={'text-dark'}>
                            &euro; <strong>{(price.unit_amount / 100).toFixed(2)}</strong> / {t(`labels.${getPriceInterval(price.interval)}`)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
              <small className={'text-muted mt-2'}>{t('profile.labels.accessoMultiploWarning')}</small>
            </Card.Body>
          </Card>
        </Container>
      )}

      <Container className={'mt-4'}>
        <Card bg={'dark'}>
          <Card.Body>
            <Card.Title>{t('profile.labels.listaDispositiviTitolo')}</Card.Title>
            {!registeredDevices && <FullscreenLoader fullscreen={false} />}
            {!!registeredDevices && !!currentDeviceId && (
              <>
                <p>
                  {t('profile.labels.listaDispositiviDescrizione')}
                  <br />
                  <strong>{t('profile.labels.listaDispositiviDescrizione2')}</strong>
                </p>
                {!!registeredDevices.length && (
                  <ListGroup>
                    {registeredDevices.map((device) => (
                      <ListGroup.Item key={device.id} variant={'dark'}>
                        <div className={'d-flex justify-content-between flex-column flex-md-row'}>
                          <div>
                            <div className={'d-flex align-items-center'}>
                              {!!device.browser ? `${device.browser} su ` : ''}
                              {device.os}
                              {currentDeviceId == device.id && (
                                <Badge pill variant={'info'} className={'ml-2'}>
                                  {t('profile.labels.questoDispositivo')}
                                </Badge>
                              )}
                            </div>
                            <small>{t('profile.labels.ultimoUtilizzo', { date: formatSecondsTimestamp(device.ts.seconds, 'dd/MM/yy') })}</small>
                          </div>
                          <div className={'d-flex align-items-center justify-content-end justify-content-md-start'}>
                            {!!device.lockedUntil && (
                              <Badge variant={'danger'} className={'mr-2'}>
                                {t('profile.labels.rimovibileDal', { date: formatSecondsTimestamp(device.lockedUntil.seconds, 'dd/MM/yy') })}
                              </Badge>
                            )}
                            {!device.lockedUntil && (
                              <Button
                                size={'sm'}
                                variant={'danger'}
                                onClick={() => {
                                  removeDevice(device.id).then(getRegisteredDevices).then(setRegisteredDevices);
                                }}
                              >
                                {t('profile.labels.rimuovi')}
                              </Button>
                            )}
                          </div>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
                {!registeredDevices.length && <div>{t('profile.labels.nessunDispositivoRegistrato')}</div>}
              </>
            )}
          </Card.Body>
        </Card>

        <div className={'small mt-5 text-center'}>
          <kbd className={'text-muted'}>{currentUser?.uid}</kbd> <kbd className={'text-muted'}>{userDataFmt?.alias}</kbd>
        </div>
      </Container>

      <Footer />

      <Modal centered={isNative} show={isVerifyEmailModalOpen} onHide={() => setIsVerifyEmailModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>{t('profile.labels.emailInviata')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('profile.labels.verificaMailEClicca')}</Modal.Body>
        <Modal.Footer>
          <Button variant={'primary'} onClick={() => setIsVerifyEmailModalOpen(false)}>
            {t('labels.chiudi')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal centered={isNative} show={isLogoutAllModalOpen} onHide={() => setIsLogoutAllModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>{t('profile.labels.disconnessioneTutteSessioni')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('profile.labels.disconnessioneWarning')}</Modal.Body>
        <Modal.Footer>
          <Button variant={'secondary'} onClick={() => setIsLogoutAllModalOpen(false)}>
            {t('profile.labels.annulla')}
          </Button>
          <Button
            variant={'danger'}
            onClick={() => {
              setIsLogoutAllModalOpen(false);
              setIsWaitingModalOpen(true);
              logoutAll().then(() => {
                history.push('/');
                window.location.reload();
              });
            }}
          >
            {t('profile.labels.conferma')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal centered={isNative} show={isPhoneNumberChangeModalOpen} onHide={() => setIsPhoneNumberChangeModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>{t('profile.labels.cambiaTelefono')}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={onPhoneNumberChangeSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label htmlFor={'phoneNumber'}>{t('profile.labels.inserisciNuovoTelefono')}</Form.Label>
              <Form.Control
                size={'lg'}
                id={'phoneNumber'}
                value={newPhoneNumber}
                required={true}
                onChange={handlePhoneChange}
                onKeyPress={(event) => {
                  const key = event.key;
                  const regex = /^[0-9+]*$/;
                  if (!regex.test(key)) {
                    event.preventDefault();
                  }
                }}
                isInvalid={phoneError}
              />
              <Form.Control.Feedback type="invalid">{t('profile.labels.erroreFormatoTelefono')}</Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={'secondary'} onClick={() => setIsPhoneNumberChangeModalOpen(false)}>
              {t('profile.labels.annulla')}
            </Button>
            <Button variant={'primary'} type={'submit'}>
              {t('profile.labels.conferma')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal centered={isNative} show={isPasswordChangeModalOpen} onHide={() => setIsPasswordChangeModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>{t('profile.labels.cambioPassword')}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={onPasswordChangeSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label htmlFor={'password'}>{t('profile.labels.inserisciPassword')}</Form.Label>
              <InputGroup>
                <Form.Control
                  required
                  size={'lg'}
                  type={passwordVisible ? 'text' : 'password'}
                  id={'password'}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <InputGroup.Append>
                  <Button variant={'secondary'} onClick={() => setPasswordVisible((previousState) => !previousState)}>
                    <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye} fixedWidth={true} />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={'secondary'} onClick={() => setIsPasswordChangeModalOpen(false)}>
              {t('profile.labels.annulla')}
            </Button>
            <Button variant={'primary'} type={'submit'}>
              {t('profile.labels.conferma')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal centered={isNative} show={isWaitingModalOpen} onHide={() => setIsWaitingModalOpen(false)} backdrop={'static'} keyboard={false}>
        <Modal.Header>
          <Modal.Title>{t('profile.labels.attendere')}&hellip;</Modal.Title>
        </Modal.Header>
      </Modal>

      <Modal centered={isNative} show={isPhoneNumberChangedModalOpen} onHide={() => setIsPhoneNumberChangedModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>{t('profile.labels.telefonoCambiato')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('profile.labels.telefonoAggiornato')}</Modal.Body>
        <Modal.Footer>
          <Button variant={'primary'} onClick={() => setIsPhoneNumberChangedModalOpen(false)}>
            {t('labels.chiudi')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal centered={isNative} show={isSuccessModalOpen} onHide={() => setIsSuccessModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>{t('profile.labels.passwordCambiata')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('profile.labels.passwordAggiornata')}</Modal.Body>
        <Modal.Footer>
          <Button variant={'primary'} onClick={() => setIsSuccessModalOpen(false)}>
            {t('labels.chiudi')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal centered={isNative} show={isReauthenticateModalOpen} onHide={() => setIsReauthenticateModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>{t('profile.labels.necessarioAccesso')}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={onReauthenticateSubmit}>
          <Modal.Body>
            <Form.Group>
              {signInMethods.includes('password') && (
                <>
                  <Form.Label htmlFor={'password'}>{t('profile.labels.confermaPassword')}</Form.Label>
                  <InputGroup>
                    <Form.Control
                      required
                      size={'lg'}
                      type={confirmationPasswordVisible ? 'text' : 'password'}
                      id={'password'}
                      value={confirmationPassword}
                      onChange={(e) => setConfirmationPassword(e.target.value)}
                    />
                    <InputGroup.Append>
                      <Button variant={'secondary'} onClick={() => setConfirmationPasswordVisible((previousState) => !previousState)}>
                        <FontAwesomeIcon icon={confirmationPasswordVisible ? faEyeSlash : faEye} fixedWidth={true} />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </>
              )}
              {!signInMethods.includes('password') && (
                <>
                  <Form.Label>{t('profile.labels.perAccedererieffettuaAccesso')}</Form.Label>
                  <LoginWithGoogle
                    block={true}
                    onAuthorize={() => {
                      setIsReauthenticateModalOpen(false);
                      window.location.reload();
                    }}
                    reauth={true}
                  />
                </>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={'secondary'} onClick={() => setIsReauthenticateModalOpen(false)}>
              {t('profile.labels.annulla')}
            </Button>
            {signInMethods.includes('password') && (
              <Button variant={'primary'} type={'submit'}>
                {t('profile.labels.conferma')}
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal centered={isNative} show={isAccountDeletionModalOpen} onHide={() => setIsAccountDeletionModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>{t('profile.labels.eliminazioneAccount')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {signInMethods.includes('password') ? (
            <>
              {t('profile.labels.eliminazioneAccountDescrizione1')}
              <br />
              <strong>{t('profile.labels.eliminazioneAccountDescrizione2')}</strong>
            </>
          ) : (
            <>{t('profile.labels.eliminazioneAccountDescrizione3')}</>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant={'secondary'} onClick={() => setIsAccountDeletionModalOpen(false)}>
            {t('profile.labels.annulla')}
          </Button>
          {signInMethods.includes('password') && (
            <Button
              variant={'danger'}
              onClick={() => {
                runDeleteAccount();
              }}
            >
              {t('profile.labels.conferma')}
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={isPlanUpgradeModalOpen} onHide={() => setIsPlanUpgradeModalOpen(false)} backdrop={'static'} keyboard={false}>
        <Modal.Header>
          <Modal.Title>{t('profile.labels.passaggioPianoAnnuale')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!!planUpgradeData && (
            <>
              <p>
                {t('profile.labels.staiPassandoAnnulaAlCosto')}{' '}
                <span className={'font-weight-bold'}>
                  &euro; {(planUpgradeData.amount / 100).toFixed(2)} / {getPriceInterval(planUpgradeData.interval)}
                </span>
                .
              </p>
              <Alert variant={'info'}>
                {t('profile.labels.passaggioPianoDescrizione1')}{' '}
                <span className={'font-weight-bold'}>{formatSecondsTimestamp(planUpgradeData.currentPeriodEnd._seconds, undefined, currentLocale)}</span>.<br />
                {t('profile.labels.passaggioPianoDescrizione2')}
              </Alert>
              <p>{t('profile.labels.passaggioPianoDescrizione3')}</p>
              <p className="mb-0 font-weight-bold">{t('profile.labels.confermaPianoAnnuale')}</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant={'secondary'} disabled={planUpgradeLoading} onClick={() => setIsPlanUpgradeModalOpen(false)}>
            {t('labels.annulla')}
          </Button>
          <Button
            variant={'primary'}
            disabled={planUpgradeLoading}
            onClick={() => {
              setPlanUpgradeLoading(true);
              updateCustomerSubscription(null, null, planUpgradeData.id)
                .then((commitResult) => {
                  if (!commitResult) return;
                  window.location.reload();
                })
                .catch(console.error)
                .finally(() => {
                  setPlanUpgradeLoading(false);
                });
            }}
          >
            {planUpgradeLoading ? <>{t('labels.attendi')}&hellip;</> : t('labels.conferma')}
          </Button>
        </Modal.Footer>
      </Modal>

      <ErrorModal isModalOpen={isErrorModalOpen} setIsModalOpen={setIsErrorModalOpen} errorCode={errorCode} />
    </>
  );
};

export { ProfileComponent as Profile };
