import { faGraduationCap, faListAlt, faSearch, faSyncAlt, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useCapacitor } from '../../../hooks/useCapacitor';
import './BottomBarNavigation.scss';
import { useMobileDetect } from '../../../hooks/useMobileDetect';
import { useTranslation } from 'react-i18next';

const BottomBarNavigationComponent = ({ onSetInVideo = (isInVideo) => {} }) => {
  const { isTrial, isLoggedIn, userDataFmt } = useFirebase();
  const [pagePath, setPagePath] = useState<string>(null);
  const { isNative, Orientation } = useCapacitor();
  const { isTV } = useMobileDetect();
  const history = useHistory();
  const [inVideo, setInVideo] = useState(false);
  const [isAso, setIsAso] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const historyCallback = () => {
      if (window.location.pathname.includes('/watch/')) {
        setInVideo(true);
        if (isNative && !isTV) Orientation?.lock('landscape').catch(console.error);
      } else {
        setInVideo(false);
        if (isNative && !isTV) Orientation?.lock('portrait').catch(console.error);
      }
    };

    const unsub = history.listen(historyCallback);

    historyCallback();

    return () => unsub();
  }, []);

  useEffect(() => {
    if (onSetInVideo) onSetInVideo(inVideo);
  }, [inVideo]);

  useEffect(() => {
    const unsub = history.listen(() => {
      setPagePath(window.location.pathname);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    if (!userDataFmt) return;
    const myCategory = userDataFmt.profile?.formData?.categoriaProfessionale;
    setIsAso(myCategory == 'aso');
  }, [userDataFmt]);

  const BottombarButton = ({ url = null, onClick = () => {}, icon, label, margin = true }) => (
    <Button
      as={Link}
      to={url}
      onClick={() => {
        if (url) return;
        onClick();
      }}
      disabled={pagePath == url}
      active={url && pagePath == url}
      className={'bottom-navbar-button'}
    >
      <FontAwesomeIcon icon={icon} className={'mt-auto'} />
      <span className={'my-1'}>{label}</span>
    </Button>
  );

  if (inVideo || !isNative || !isLoggedIn) return <></>;

  return (
    <div className={`bottom-bar-container ios-padding-bottom ${isTrial && isNative ? 'higher-bottom-bar' : ''}`}>
      <BottombarButton url={'/browse'} icon={faVideo} label={t('labels.video')} />
      {isAso && <BottombarButton url={'/courses'} icon={faGraduationCap} label={t('labels.corsi')} />}
      <BottombarButton url={'/list'} icon={faListAlt} label={t('labels.mieiToot')} />
      <BottombarButton url={'/search'} icon={faSearch} label={t('labels.cerca')} />
      <BottombarButton
        onClick={() => {
          window.location.reload();
        }}
        icon={faSyncAlt}
        label={t('labels.aggiorna')}
      />
    </div>
  );
};

export { BottomBarNavigationComponent as BottomBarNavigation };
