import { Badge, Button } from 'react-bootstrap';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useEffect, useState } from 'react';
import { FullscreenLoader } from '../FullscreenLoader/FullscreenLoader';
import { formatSecondsTimestamp, parseDuration } from '../../utils/utils';
import { Copyable } from '../Copyable/Copyable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle, faDotCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';

const getStatus = (bool: boolean, status: string, className = '') => (
  <Badge pill variant={bool ? 'success' : 'danger'} className={className}>
    {status}
  </Badge>
);

const UserCourseComponent = ({ uid, cid }) => {
  const { getUserCourse, currentUser } = useFirebase();

  const [course, setCourse] = useState<any>(null);
  const [certificationLoading, setCertificationLoading] = useState(false);
  const [testAttemptsLoading, setTestAttemptsLoading] = useState<string>(null);
  const [testAttempts, setTestAttempts] = useState<any>({});

  useEffect(() => {
    getUserCourse(uid, cid, 'GET_COURSE_STATUS').then(setCourse).catch(console.error);
  }, []);

  return (
    <div className={'mt-3'}>
      {!course && <FullscreenLoader fullscreen={false} />}
      {!!course && (
        <>
          <div>
            <Button
              variant={'success'}
              disabled={certificationLoading || !course.completed}
              onClick={() => {
                setCertificationLoading(true);
                getUserCourse(uid, cid, 'SEND_CERTIFICATION_MYSELF')
                  .catch(console.error)
                  .finally(() => {
                    setCertificationLoading(false);
                  });
              }}
            >
              Invia attestato via email a te stesso
            </Button>
            {course.completed && !certificationLoading && (
              <span className={'ml-2 text-muted'}>L'attestato verrà inviato all'indirizzo {currentUser.email}</span>
            )}
            {certificationLoading && <span className={'ml-2'}>Attendi&hellip;</span>}
            {!course.completed && <span className={'ml-2 text-muted'}>&Egrave; possibile scaricare l'attestato solo dopo aver completato il corso.</span>}
          </div>

          <div className={'mt-3'}>
            <div className={'h4'}>
              {course.name}{' '}
              <small>
                <Copyable text={course.id} />
              </small>{' '}
              {getStatus(course.completed, course.status)}
            </div>
            <div className="h5 mt-3">Unità</div>
            {course.chapters.flatMap((chapter) =>
              chapter.units.flatMap((unit) => (
                <details key={chapter.id + '_' + unit.id}>
                  <summary>
                    {unit.name}{' '}
                    <small>
                      <Copyable text={unit.id} />
                    </small>{' '}
                    {getStatus(unit.completed, unit.status)}{' '}
                    {!!unit.reasonNotAccessible && <small className={'text-muted ml-2'}>{unit.reasonNotAccessible}</small>}
                  </summary>
                  <div className={'ml-4'}>
                    <div>
                      Durata: <b>{parseDuration(unit.content.duration)}</b>
                    </div>
                    {!!unit.minimumTime && (
                      <div>
                        Presenza minima: <b>{parseDuration(unit.minimumTime * 1000)}</b>
                      </div>
                    )}
                    <div>
                      Tempo presenza: <b>{parseDuration((unit.content.timePresence ?? 0) * 1000)}</b>
                    </div>
                    <div>
                      Numero accessi: <b>{unit.openings || 0}</b>
                    </div>
                    {!!unit.lastOpened && (
                      <div>
                        Ultimo accesso: <b>{formatSecondsTimestamp(unit.lastOpened._seconds, 'dd MMMM Y HH:mm')}</b>
                      </div>
                    )}
                  </div>
                </details>
              ))
            )}
            <div className="h5 mt-3">Test</div>
            {course.tests.map((test) => (
              <details key={test.id}>
                <summary>
                  {test.name}{' '}
                  <small>
                    <Copyable text={test.id} />
                  </small>{' '}
                  {getStatus(test.completed, test.status)}
                  {!!test.reasonNotAccessible && <small className={'text-muted ml-2'}>{test.reasonNotAccessible}</small>}
                </summary>
                <div className="ml-4">
                  <div>
                    Domande: <b>{test.questions.length}</b>
                  </div>
                  <div>
                    Tentativi: <b>{test.attempts}</b>
                    {test.attempts > 0 && !testAttempts[test.id] && (
                      <Button
                        as={Badge}
                        className={'ml-1'}
                        onClick={() => {
                          if (testAttemptsLoading) return;

                          setTestAttemptsLoading(test.id);
                          getUserCourse(uid, cid, 'GET_TEST_ATTEMPTS', test.id)
                            .then((attempts) => setTestAttempts((current) => ({ ...current, [test.id]: attempts })))
                            .finally(() => setTestAttemptsLoading(null));
                        }}
                      >
                        {testAttemptsLoading == test.id ? <>Attendi&hellip;</> : 'Visualizza tentativi'}
                      </Button>
                    )}
                  </div>
                  {!!testAttempts[test.id] && (
                    <details open>
                      <summary>Dettaglio tentativi</summary>
                      {testAttempts[test.id].map((attempt, i) => (
                        <details key={attempt.id} className={'ml-3'}>
                          <summary className={'h6 mb-0'}>
                            Tentativo #{i + 1}{' '}
                            <small>
                              <Copyable text={attempt.id} />
                            </small>{' '}
                            {getStatus(attempt.completed, attempt.status)}
                          </summary>
                          <div className="ml-4">
                            <div>
                              Data: <b>{formatSecondsTimestamp(attempt.ts._seconds, 'dd MMMM Y HH:mm')}</b>
                            </div>
                            {attempt.attempt.map((element) => (
                              <div key={element.question.id} className={'border-left pl-2 my-1 rounded-left'}>
                                <div>
                                  <span className={'font-weight-bold'}>{element.question.value}</span>{' '}
                                  <small>
                                    <Copyable text={element.question.id} />
                                  </small>
                                </div>
                                {element.answers.map((answer) => (
                                  <div key={answer.id}>
                                    <FontAwesomeIcon
                                      className={(() => {
                                        if (answer.selected && !answer.correct) return 'text-danger';
                                        if (answer.selected && answer.correct) return 'text-success';
                                        if (!answer.correct) return 'text-secondary';
                                      })()}
                                      icon={(() => {
                                        if (answer.selected && !answer.correct) return faTimesCircle;
                                        if (answer.selected && answer.correct) return faCheckCircle;
                                        if (answer.correct) return faDotCircle;
                                        return faCircle;
                                      })()}
                                      size={'sm'}
                                    />{' '}
                                    <span
                                      className={(() => {
                                        if (answer.selected && !answer.correct) return 'text-danger';
                                        if (answer.selected && answer.correct) return 'text-success';
                                        if (!answer.correct) return 'text-secondary';
                                      })()}
                                    >
                                      {answer.value}
                                    </span>{' '}
                                    <small>
                                      <Copyable text={answer.id} />
                                    </small>
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        </details>
                      ))}
                    </details>
                  )}
                </div>
              </details>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export { UserCourseComponent as UserCourse };
