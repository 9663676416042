import { BadgeDifficulty } from '../Gaming/BadgeDifficulty/BadgeDifficulty';
import { Exp } from '../Gaming/Exp/Exp';
import { useGaming } from '../../../contexts/GamingContext';
import { useEffect } from 'react';
import './BadgeUnlockPopup.scss';

const BadgeUnlockPopupComponent = () => {
  const { achievementUnlocked, setAchievementUnlocked, nf } = useGaming();

  useEffect(() => {
    if (!achievementUnlocked) return;
    try {
      document.querySelector('.circle').classList.add('circle_animate');
      document.querySelector('.banner').classList.add('banner-animate');
      document.querySelector('.achieve_disp').classList.add('achieve_disp_animate');
      setTimeout(() => {
        try {
          document.querySelector('.circle').classList.remove('circle_animate');
          document.querySelector('.banner').classList.remove('banner-animate');
          document.querySelector('.achieve_disp').classList.remove('achieve_disp_animate');
          setTimeout(() => setAchievementUnlocked(false));
        } catch {}
      }, 11000);
    } catch {}
  }, [achievementUnlocked]);

  if (!achievementUnlocked) return null;

  return (
    <div className={'achievement'}>
      <div className={'animation'}>
        <div className={'circle'}>
          <div className={'img trophy_animate trophy_img'}>
            <img className={'trophy_1'} src={achievementUnlocked.image} />
          </div>
          <div className={'img xbox_img'}>
            <img src={process.env.PUBLIC_URL + '/oo-logo-w.png'} />
          </div>
        </div>
        <div className={'banner-outer'}>
          <div className={'banner'}>
            <div className={'achieve_disp'}>
              <span className={'unlocked'}>{achievementUnlocked.name}</span>
              <div className={'score_disp'}>
                <div className={'gamerscore'}>
                  <BadgeDifficulty difficulty={achievementUnlocked.difficulty} className={'small'} />
                  <span className={'mx-2'}> • </span>
                  <div className={'small d-flex align-items-center'}>
                    <Exp className={'mr-1'} />
                    <span>{nf(achievementUnlocked.exp)} EXP</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { BadgeUnlockPopupComponent as BadgeUnlockPopup };
