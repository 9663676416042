import { useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useCapacitor } from '../../../hooks/useCapacitor';
import { formatSecondsDuration } from '../../utils/utils';
import './TrialBar.scss';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from '../../../hooks/useCurrentLanguage';

const TrialBarComponent = () => {
  const { isTrial, trialTimeLeft, isTrialExpired, userDataFmt, staticData } = useFirebase();
  const { t } = useTranslation();
  const { currentLocale } = useCurrentLanguage();
  const { isNative } = useCapacitor();
  const [hideBar, setHideBar] = useState(false);
  const [inVideo, setInVideo] = useState(false);
  const history = useHistory();
  const [usedVideos, setUsedVideos] = useState(0);
  const [totalVideos, setTotalVideos] = useState(0);

  useEffect(() => {
    setUsedVideos(userDataFmt?.videoData?.length || 0);
  }, [userDataFmt]);

  useEffect(() => {
    setTotalVideos(userDataFmt?.customTrialVideos ?? staticData?.DEFAULT_TRIAL_VIDEOS ?? 0);
  }, [staticData, userDataFmt]);

  useEffect(() => {
    history.listen(() => {
      if (window.location.pathname.includes('/watch/')) {
        setInVideo(true);
      } else setInVideo(false);
      if (window.location.pathname.includes('/first-login')) {
        setHideBar(true);
      } else setHideBar(false);
    });
  }, []);

  if (!userDataFmt) return <></>;
  if (!isTrial || hideBar) return <></>;
  if (userDataFmt.isNewTrial) return <></>;
  if (!totalVideos) return <></>;

  return (
    <Navbar className={`trial-bar trial-bar-custom ${isNative && !inVideo ? 'bottom-56px' : ''}`} bg={'warning'}>
      <div style={{ flex: 1, color: '#141414' }} className={'text-center d-flex align-items-center justify-content-center'}>
        {!isTrialExpired && (
          <div className={'d-flex align-items-center flex-column flex-md-row'}>
            <span>
              {t('trialBar.labels.tempoVisioneRimanente')} <strong>{formatSecondsDuration(trialTimeLeft, currentLocale)}</strong>
            </span>
            <div>
              {Array(totalVideos)
                .fill(1)
                .map((_, i) => {
                  const isLocked = i + 1 <= usedVideos;
                  return <FontAwesomeIcon icon={isLocked ? faLock : faLockOpen} key={i.toString()} className={'ml-1'} fixedWidth={true} />;
                })}
            </div>
          </div>
        )}
        {isTrialExpired && <strong>{t('trialBar.labels.versioneProvaTerminata')}</strong>}
        {/* <Button className={'ml-3'} size={'sm'} variant={'dark'} as={Link} to={'/plans'}>
          Acquista piano
        </Button> */}
      </div>
    </Navbar>
  );
};

export { TrialBarComponent as TrialBar };
