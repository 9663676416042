import { ReactComponent as SvgHat } from './svgs/hat.svg';
import { WithTooltip } from '../../components/WithTooltip/WithTooltip';
import CalendarStrings from './calendar-strings';
import { useMobileDetect } from '../../../hooks/useMobileDetect';
import { useTranslation } from 'react-i18next';

const CalendarStripComponent = ({ cal, today, onBallClick, showTooltipOn, hideTooltips = false }) => {
  const { isMobile } = useMobileDetect();
  const { t } = useTranslation();

  const getCellBackground = (cell) => {
    const isToday = +cell.id == today;
    const isPast = +cell.id < today;
    const isFuture = +cell.id > today;

    /**
     * locked:   [past, today, future]
     * unlocked: [past, today, future]
     */
    const CellColors = [
      ['#e74c3c', '#f1c40f', '#bdc3c7'],
      ['#27ae60', '#2ecc71', null],
    ];

    const [colorPast, colorToday, colorFuture] = CellColors[+!!cell.unlocked];

    if (isFuture) return colorFuture;
    if (isToday) return colorToday;
    if (isPast) {
      if (cal.calendar.allowPreviousDays) return colorToday;
      return colorPast;
    }
  };

  const getCellTooltip = (cell) => {
    const isToday = +cell.id == today;
    const isPast = +cell.id < today;
    const isFuture = +cell.id > today;

    const daysFuture = +cell.id - today;

    /**
     * locked:   [past, today, future]
     * unlocked: [past, today, future]
     */
    const CellTooltips = [
      [CalendarStrings.Locked.Past(t), CalendarStrings.Locked.Today(t), CalendarStrings.Locked.Future(t, daysFuture)],
      [CalendarStrings.Unlocked.Past(t, false), CalendarStrings.Unlocked.Today(t, today), null],
    ];

    const [tooltipPast, tooltipToday, tooltipFuture] = CellTooltips[+!!cell.unlocked];

    if (isFuture) return tooltipFuture;
    if (isToday) return tooltipToday;
    if (isPast) {
      if (cal.calendar.allowPreviousDays && !cell.unlocked) return tooltipToday;
      return tooltipPast;
    }
  };

  return (
    <div className={'d-flex calendar-strip'}>
      {cal.elements.map((cell) => (
        <WithTooltip key={cell.id} big={false} text={getCellTooltip(cell)} hide={hideTooltips || isMobile} initialShow={!isMobile && cell.id == showTooltipOn}>
          <div
            style={{
              backgroundColor: getCellBackground(cell),
              cursor: +cell.id > today || (+cell.id < today && !cell.unlocked && !cal.calendar.allowPreviousDays) ? 'default' : 'pointer',
            }}
            onClick={() => onBallClick(cell.id)}
            className={'calendar-ball ' + (+cell.id == today && !cell.unlocked ? 'pulsate ' : '')}
          >
            {cell.id}
            {cell.id == 25 && <SvgHat className={'santa-svg'} />}
          </div>
        </WithTooltip>
      ))}
    </div>
  );
};

export { CalendarStripComponent as CalendarStrip };
