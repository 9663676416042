import { Capacitor } from '@capacitor/core';
import { App, AppPlugin } from '@capacitor/app';
import { StatusBar, StatusBarPlugin } from '@capacitor/status-bar';
import { PrivacyScreen, PrivacyScreenPlugin } from '@capacitor-community/privacy-screen';
import { KeepAwake, KeepAwakePlugin } from '@capacitor-community/keep-awake';
import { Storage, StoragePlugin } from '@capacitor/storage';

export const useCapacitor = (): {
  isNative: boolean;
  platform: string;
  App: AppPlugin;
  StatusBar: StatusBarPlugin;
  PrivacyScreen: PrivacyScreenPlugin;
  KeepAwake: KeepAwakePlugin;
  Storage: StoragePlugin;
  Orientation?: ScreenOrientation;
} => {
  const isNative = Capacitor.isNativePlatform();
  const platform = Capacitor.getPlatform();
  const Orientation = window.screen?.orientation;
  return { isNative, platform, App, StatusBar, PrivacyScreen, KeepAwake, Storage, Orientation };
};
