import {
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faHandPointRight as faClickableStarringIcon,
  faClosedCaptioning,
  faMusic,
  faPause,
  faPlay,
  faSyncAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as firebase from 'firebase/app';
import Hls from 'hls.js';
import { useEffect, useReducer, useRef, useState } from 'react';
import { Alert, Button, Form, ListGroup, Modal } from 'react-bootstrap';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import { withUserAgent } from 'react-ua';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useCapacitor } from '../../../hooks/useCapacitor';
import { useHover } from '../../../hooks/useHover';
import { useMobileDetect } from '../../../hooks/useMobileDetect';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { NextVideo } from '../../components/NextVideo/NextVideo';
import { calculateDeviceIdentifier, checkSupportedDRM, createShakaPlayer, isFastNet, isPlaying, parseDuration } from '../../utils/utils';
import './Watch.scss';
import { PlyrOptions } from './plyr-options';
import { Browser } from '@capacitor/browser';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import ISO6391 from 'iso-639-1';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import shaka from 'shaka-player';
import 'shaka-player/dist/controls.css';
import { useGaming } from '../../../contexts/GamingContext';
import { useCurrentLanguage } from '../../../hooks/useCurrentLanguage';
import { useQuery } from '../../../hooks/useQuery';
import { AppDownload } from '../../components/AppDownload/AppDownload';
import { Title } from '../../components/Title/Title';
import { Video } from '../../components/Video/Video';
import { VideoRating } from '../../components/VideoRating/VideoRating';
import { VideoWatermark } from '../../components/VideoWatermark/VideoWatermark';
import { getPlyrControls } from './plyr-controls';

declare const Plyr: any;

interface VideoConfig {
  adBeforeVideo: boolean;
  xRayEnabled: boolean;
  xRayActivated: boolean;
  timestampsEnabled: boolean;
  timestampsOpen: boolean;
}

enum VideoConfigAction {
  UPDATE,
  ENABLE_XRAY,
  TOGGLE_XRAY,
  CLOSE_XRAY,
  ENABLE_TIMESTAMPS,
  TOGGLE_TIMESTAMPS,
  CLOSE_TIMESTAMPS,
  SET_AD_BEFORE_VIDEO_ENABLED,
}

const videoConfigReducer = (state: VideoConfig, { type, payload }: { type: VideoConfigAction; payload?: any }): VideoConfig => {
  switch (type) {
    case VideoConfigAction.UPDATE:
      return { ...state, ...payload };
    case VideoConfigAction.ENABLE_XRAY:
      return { ...state, xRayActivated: payload };
    case VideoConfigAction.TOGGLE_XRAY:
      return { ...state, xRayActivated: !state.xRayActivated };
    case VideoConfigAction.CLOSE_XRAY:
      return { ...state, xRayActivated: false };
    case VideoConfigAction.ENABLE_TIMESTAMPS:
      return { ...state, timestampsEnabled: payload };
    case VideoConfigAction.TOGGLE_TIMESTAMPS:
      return { ...state, timestampsOpen: !state.timestampsOpen };
    case VideoConfigAction.CLOSE_TIMESTAMPS:
      return { ...state, timestampsOpen: false };
    case VideoConfigAction.SET_AD_BEFORE_VIDEO_ENABLED:
      return { ...state, adBeforeVideo: payload };
    default:
      return state;
  }
};

const WatchComponent = withUserAgent(({ ua }) => {
  const history = useHistory();
  const { courseId, id } = useParams<{ id: string; courseId?: string }>();
  const {
    requestVideoData,
    userDataFmt,
    staticData,
    updateUserData,
    getVideoShardedCounterIncrementor,
    getVideoOpeningsShardedCounterIncrementor,
    updateUserVideoAnalytics,
    globalSecondsIncrementor,
    isTrialExpired,
    isTrial,
    trialTimeLeft,
    getDeviceIdentifier,
    checkDeviceRegistrationAbility,
    registerDevice,
    series,
    currentUser,
    database,
    realtimeDatabase,
    fv,
    analytics,
    isVideoLocked,
  } = useFirebase();

  const { track, getAchievements, pauseVideo } = useGaming();
  const { isNative, App, StatusBar, platform, PrivacyScreen, KeepAwake } = useCapacitor();
  const { isMobile, isTV } = useMobileDetect();
  const { t: preloadTime, s: seriesId = null } = useQuery();

  const [backButtonContainerRef, isBackButtonHover] = useHover<HTMLDivElement>();
  const [ratingDivContainerRef, isRatingDivHover] = useHover<HTMLDivElement>();

  const [isFastConnection, setIsFastConnection] = useState<boolean>(null);
  const [isSlowInternetModalOpen, setIsSlowInternetModalOpen] = useState(false);

  const [video, setVideo] = useState<any>(null);
  const [nextVideo, setNextVideo] = useState<any>(null);
  const [showNextVideo, setShowNextVideo] = useState(false);
  const [showSkipIntro, setShowSkipIntro] = useState(false);
  const [videoSecondsIncrementor, setVideoSecondsIncrementor] = useState<() => void>(null);
  const [areControlsVisible, setAreControlsVisible] = useState<boolean>(true);
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
  const [xRay, setXRay] = useState([]);
  const [activeMarker, setActiveMarker] = useState<number>(null);
  const [deviceId, setDeviceId] = useState<string>(null);
  const [videoLoading, setVideoLoading] = useState(true);
  const [supportedDrm, setSupportedDrm] = useState<string>(null);
  const [playerControls, setPlayerControls] = useState('');
  const [seconds, setSeconds] = useState(0);
  const [globalRtdbUserRecord, setGlobalRtdbUserRecord] = useState(null);

  const [failedPreconditionModalOpen, setFailedPreconditionModalOpen] = useState(false);
  const [permissionDeniedModalOpen, setPermissionDeniedModalOpen] = useState(false);
  const [unavailableModalOpen, setUnavailableModalOpen] = useState(false);

  const [videoSwitched, setVideoSwitched] = useState(true);
  const switchedTime = useRef(0);

  const [isTrialModalOpen, setIsTrialModalOpen] = useState(false);
  const [isMaxDevicesModalOpen, setIsMaxDevicesModalOpen] = useState(false);
  const [isMaxVideosTrialModalOpen, setIsMaxVideosTrialModalOpen] = useState(false);
  const [isMobileNonNativeModalOpen, setIsMobileNonNativeModalOpen] = useState(false);
  const [isMaxSimultaneousUsersModalOpen, setIsMaxSimultaneousUsersModalOpen] = useState(false);

  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
  useEffect(() => {
    if (isRatingModalOpen) return;
    try {
      videoElementRef.current?.play?.();
      if (video.pip) pipVideoElementRef.current?.play?.();
    } catch {}
  }, [isRatingModalOpen]);
  const [videoReportChecks, setVideoReportChecks] = useState(new Set<string>());
  const [reportDetails, setReportDetails] = useState('');
  const [videoReportLoading, setVideoReportLoading] = useState(false);

  const [i18nForceItalianFlag, setI18nForceItalianFlag] = useState(false);
  const [selectedSubLanguage, setSelectedSubLanguage] = useState<string>(null);
  const [selectedAudioLanguage, setSelectedAudioLanguage] = useState<string>(null);
  const [isI18nModalOpen, setIsI18nModalOpen] = useState(false);
  const [isForeground, setIsForeground] = useState(true);
  // const [isAppGoneBackground, setIsAppGoneBackground] = useState(false);

  useEffect(() => {
    document.documentElement.style.setProperty('--hubspot-chat-display', 'none');
    return () => {
      document.documentElement.style.setProperty('--hubspot-chat-display', 'block');
    };
  }, []);

  useEffect(() => {
    return () => {
      getAchievements();
    };
  }, []);

  useEffect(() => {
    if (isI18nModalOpen) {
      const activeTrack = shakaInstance.current.getTextTracks().find((track) => track.active);
      setSelectedSubLanguage(activeTrack?.language ?? '');
      if (!selectedAudioLanguage) setSelectedAudioLanguage(shakaInstance.current.getAudioLanguages()[0]);
      return;
    }
    try {
      videoElementRef.current?.play?.();
      if (video.pip) pipVideoElementRef.current?.play?.();
    } catch {}
  }, [isI18nModalOpen]);
  const i18nIconDefault = faCircle;
  const i18nIconSelected = faCheckCircle;
  const [requestData, setRequestData] = useState(false);

  const [continueFetch, setContinueFetch] = useState(false);
  const { t } = useTranslation();
  const { currentLanguage } = useCurrentLanguage();

  const [config, dispatch] = useReducer(videoConfigReducer, {
    adBeforeVideo: false,
    xRayEnabled: true,
    xRayActivated: !isNative,
    timestampsEnabled: true,
    timestampsOpen: false,
  });

  const videoElementRef = useRef<HTMLVideoElement>();
  const pipVideoElementRef = useRef<HTMLVideoElement>();

  const previousTimeUpdate = useRef<number>(null);
  const hlsInstance = useRef<Hls>(null);
  const hlsPipInstance = useRef<Hls>(null);
  const playerInstance = useRef<typeof Plyr>(null);
  const shakaInstance = useRef<shaka.Player>(null);
  const shakaPipInstance = useRef<shaka.Player>(null);

  const shakaClean = () => {
    shakaInstance.current?.destroy();
    shakaInstance.current = null;
    shakaPipInstance.current?.destroy();
    shakaPipInstance.current = null;
  };

  const clean = (includeShaka = false) => {
    playerInstance.current?.pause();
    hlsInstance.current?.destroy();
    hlsInstance.current = null;
    hlsPipInstance.current?.destroy();
    hlsPipInstance.current = null;
    if (includeShaka) shakaClean();
  };

  const runTrialExpired = () => {
    clean(true);
    setIsTrialModalOpen(true);
  };

  const runFailedPrecondition = () => {
    clean(true);
    setFailedPreconditionModalOpen(true);
  };

  const runUnavailable = () => {
    clean(true);
    setUnavailableModalOpen(true);
  };

  const runPermissionDenied = () => {
    clean(true);
    setPermissionDeniedModalOpen(true);
  };

  const runMaxSimultaneousUsers = () => {
    clean(true);
    setIsMaxSimultaneousUsersModalOpen(true);
  };

  const runMaxDevices = () => {
    clean(true);
    setIsMaxDevicesModalOpen(true);
  };

  const runMaxVideosTrial = () => {
    clean(true);
    setIsMaxVideosTrialModalOpen(true);
  };

  const runGotoNext = () => {
    setShowNextVideo(false);
    setNextVideo(null);
    history.replace({ pathname: `/watch/${nextVideo.id}`, search: `t=0&s=${seriesId}` });
    window.location.reload();
  };

  useEffect(() => {
    if (!staticData) return;
    if (staticData.CHECK_NETWORK_SPEED) {
      isFastNet(setIsFastConnection, { threshold: staticData.NETWORK_SPEED_THRESHOLD });
    }
  }, [staticData]);

  useEffect(() => {
    if (isFastConnection == false) {
      setIsSlowInternetModalOpen(true);
    }
  }, [isFastConnection]);

  useEffect(() => {
    if (!video) return;
    const controls = getPlyrControls(isNative);
    setPlayerControls(controls);
  }, [nextVideo, isNative, video]);

  useEffect(() => {
    if (!playerInstance.current) return;
    if (!video) return;
    if (pauseVideo) {
      playerInstance.current.pause();
      if (video.pip) pipVideoElementRef.current.pause();
    } else {
      playerInstance.current.play().catch(console.error);
      if (video.pip) pipVideoElementRef.current.play().catch(console.error);
    }
  }, [pauseVideo, playerInstance.current, video]);

  const updatePlayersTime = (time: number, op: '+' | '-' = null) => {
    const newTime = (() => {
      if (!op) return time;
      const currentTime = playerInstance.current.currentTime;
      switch (op) {
        case '+':
          return currentTime + time;
        case '-':
          return currentTime - time;
      }
    })();

    playerInstance.current.currentTime = newTime;
    videoElementRef.current.currentTime = newTime;
    shakaInstance.current.getMediaElement().currentTime = newTime;
    if (video.pip) {
      pipVideoElementRef.current.currentTime = newTime;
      shakaPipInstance.current.getMediaElement().currentTime = newTime;
    }
  };

  const toggleFullscreen = (set: boolean = null) => {
    const isFullscreen = () =>
      !!(
        (document as any).webkitCurrentFullScreenElement ||
        (document as any).mozFullScreenElement ||
        (document as any).webkitFullscreenElement ||
        (document as any).webkitIsFullScreen ||
        document.fullscreenElement
      );
    // const setTrue = () => document.documentElement.requestFullscreen()?.catch(console.error);
    // const setFalse = () => document.exitFullscreen()?.catch(console.error);
    const setTrue = () => {
      const element = document.documentElement;
      const _requestFullscreen =
        element.requestFullscreen || (element as any).mozRequestFullScreen || (element as any).webkitRequestFullScreen || (element as any).msRequestFullscreen;
      _requestFullscreen?.call(element)?.catch?.(console.error);
    };
    const setFalse = () => {
      const _cancelFullscreen =
        document.exitFullscreen ||
        (document as any).mozCancelFullScreen ||
        (document as any).webkitExitFullscreen ||
        (document as any).webkitCancelFullScreen ||
        (document as any).msExitFullscreen;
      _cancelFullscreen?.call(document)?.catch?.(console.error);
    };
    const checkAndSetTrue = () => !isFullscreen() && setTrue();
    const checkAndSetFalse = () => isFullscreen() && setFalse();

    switch (set) {
      case true:
        checkAndSetTrue();
        break;
      case false:
        checkAndSetFalse();
        break;
      default:
        if (isFullscreen()) setFalse();
        else if (!isFullscreen()) setTrue();
    }
  };

  useEffect(() => {
    console.info(ua);
  }, []);

  useEffect(() => {
    if (!selectedAudioLanguage) return;

    const container = document.getElementById('i18n-button');
    if (!container) return;

    const svg = container.querySelector('svg');
    if (svg) svg.remove();

    const prevImg = document.getElementById('current-selected-lang');
    if (prevImg) prevImg.remove();

    const img = new Image();
    img.src = `https://flagcdn.com/28x21/${(() => {
      if (i18nForceItalianFlag) return 'it';
      if (selectedAudioLanguage == 'en') return 'gb';
      return selectedAudioLanguage;
    })()}.png`;
    img.id = `current-selected-lang`;
    container.append(img);
  }, [selectedAudioLanguage, i18nForceItalianFlag]);

  useEffect(() => {
    if (!selectedAudioLanguage || !currentLanguage) return;
    track('VIDEO_LANGUAGE_CHANGE', { selectedAudioLanguage: i18nForceItalianFlag ? 'it' : selectedAudioLanguage, currentLanguage });
  }, [selectedAudioLanguage, currentLanguage, i18nForceItalianFlag]);

  useEffect(() => {
    localStorage.setItem('plyr', JSON.stringify({ speed: 1 }));
  }, []);

  useEffect(() => {
    if (isMobile && !isNative) setIsMobileNonNativeModalOpen(true);
  }, []);

  useEffect(() => {
    if (isNative) {
      StatusBar.hide().catch(console.error);
      PrivacyScreen.enable().catch(console.error);
      KeepAwake.keepAwake().catch(console.error);
      return () => {
        StatusBar.show().catch(console.error);
        PrivacyScreen.disable().catch(console.error);
        KeepAwake.allowSleep().catch(console.error);
      };
    }
  }, [isNative]);

  useEffect(() => {
    if (isNative && platform == 'ios') {
      const top = document.documentElement.style.getPropertyValue('--ios-safe-area-top');
      const bottom = document.documentElement.style.getPropertyValue('--ios-safe-area-bottom');
      if (top) document.documentElement.style.setProperty('--ios-safe-area-top', `0`);
      if (bottom) document.documentElement.style.setProperty('--ios-safe-area-bottom', `0`);
      return () => {
        if (top) document.documentElement.style.setProperty('--ios-safe-area-top', top);
        if (bottom) document.documentElement.style.setProperty('--ios-safe-area-bottom', bottom);
      };
    }
  }, [isNative, platform]);

  useEffect(() => {
    if (isNative) {
      const listener = App.addListener('appStateChange', ({ isActive }) => {
        setIsForeground(isActive);

        if (isActive) initConcurrencyWatch();
        else {
          (async () => {
            const clientName = `client_${await calculateDeviceIdentifier()}`;
            const currentRtdbDetails = realtimeDatabase.ref(`users/${currentUser.uid}/${clientName}/details`);
            const onValueCallback = async (_: firebase.default.database.DataSnapshot) => {
              currentRtdbDetails
                .update({
                  watching: firebase.default.database.ServerValue.increment(-1),
                })
                .catch(console.error);
              currentRtdbDetails.onDisconnect().cancel();
            };
            currentRtdbDetails.once('value', onValueCallback).catch(console.error);
          })();
        }

        if (!isActive && playerInstance.current) playerInstance.current.pause();
      });
      return () => {
        listener.remove().catch(console.error);
      };
    }
  }, []);

  useEffect(() => {
    if (!xRay.length) return;
    const increment = firebase.default.database.ServerValue.increment;
    for (const { cleanDescription, id } of xRay) {
      realtimeDatabase
        .ref(`tools/${id}`)
        .update({
          description: cleanDescription,
          seconds: increment(1),
          [`users/${currentUser.uid}/seconds`]: increment(1),
        })
        .catch(console.error);
    }
  }, [xRay]);

  const getFullName = () => {
    const getProp = (prop) => userDataFmt?.profile?.formData?.[prop]?.trim?.();
    return `${getProp('nome')} ${getProp('cognome')}`.trim() || null;
  };

  // Inizializzazione logiche di visione contemporanea init/dispose componente
  useEffect(() => {
    initConcurrencyWatch();

    return () => {
      (async () => {
        const clientName = `client_${await calculateDeviceIdentifier()}`;
        const currentRtdbDetails = realtimeDatabase.ref(`users/${currentUser.uid}/${clientName}/details`);

        currentRtdbDetails.onDisconnect().cancel();
        if (isMaxSimultaneousUsersModalOpen) return;

        const onExitCallback = async (detailSnapshot: firebase.default.database.DataSnapshot) => {
          if (detailSnapshot.val()?.watching > 0) {
            currentRtdbDetails
              .update({
                watching: firebase.default.database.ServerValue.increment(-1),
                ua: { browser: ua.browser?.name ?? 'Browser sconosciuto', os: ua.os?.name ?? 'Sistema sconosciuto' },
                ts: firebase.default.database.ServerValue.TIMESTAMP,
                video: id,
                lastVideoUpdate: firebase.default.database.ServerValue.TIMESTAMP,
              })
              .catch(console.error);
          } else {
            currentRtdbDetails.remove().catch(console.error);
          }
        };
        currentRtdbDetails.once('value', onExitCallback).catch(console.error);
      })();
    };
  }, []);

  // Serve per incrementare ogni 10 secondi il valore lastVideoUpdate nel realtime database associato all'utente
  useEffect(() => {
    if (!globalRtdbUserRecord) return;
    let interval = null;

    interval = setInterval(() => {
      const onValueCallback = async (snapshot: firebase.default.database.DataSnapshot) => {
        const data = snapshot.val() || {};
        if (data && data.watching > 0) {
          globalRtdbUserRecord
            .update({
              lastVideoUpdate: firebase.default.database.ServerValue.TIMESTAMP,
            })
            .catch(console.error);
          setSeconds((seconds) => seconds + 1);
        } else {
          clearInterval(interval);
          return;
        }
      };

      globalRtdbUserRecord.once('value', onValueCallback).catch(console.error);
    }, 10000);

    return () => clearInterval(interval);
  }, [globalRtdbUserRecord, seconds]);

  useEffect(() => {
    if (isMobile && !isNative) return;
    if (!continueFetch) return;

    getDeviceIdentifier()
      .then(checkDeviceRegistrationAbility)
      .then(({ deviceId, canRegister, existing = false }) => {
        if (!canRegister && staticData.LOCK_DEVICES_NUMBER) {
          runMaxDevices();
          return null;
        }
        const appAlias = `${process.env.REACT_APP_TITLE} App`;
        const unknownBrowser = 'Browser sconosciuto';
        const unknownOs = 'Sistema sconosciuto';
        const browserName = (() => {
          const browser = ua.browser?.name;
          if (!browser) return unknownBrowser;
          if (['Chrome WebView', 'WebKit'].includes(browser)) return appAlias;
          return browser;
        })();
        const osName = ua.os?.name ?? unknownOs;
        const deviceObject = {
          browser: browserName,
          browserVersion: browserName != appAlias ? ua.browser?.version ?? null : null,
          os: osName,
          osVersion: osName != unknownOs ? ua.os?.version ?? null : null,
          deviceId,
        };
        return registerDevice(deviceObject, existing);
      })
      .then((deviceId) => {
        if (deviceId) setDeviceId(deviceId);
      });
  }, [isNative, isMobile, continueFetch]);

  useEffect(() => {
    const payload: Partial<VideoConfig> = {
      adBeforeVideo: false,
      xRayEnabled: true,
      timestampsEnabled: true,
    };
    dispatch({ type: VideoConfigAction.UPDATE, payload });
  }, []);

  useEffect(() => {
    return () => {
      updateUserData().catch(console.error);
    };
  }, []);

  useEffect(() => {
    return () => {
      shakaClean();
    };
  }, []);

  useEffect(() => {
    if (!id) return;
    if (isTrialExpired) return runTrialExpired();
    if (!userDataFmt) return;
    if (!staticData) return;
    if (isTrial == null) return;

    if (requestData) return;

    const watchedVideos = userDataFmt.videoData?.length || 0;
    const totalVideos = userDataFmt.customTrialVideos ?? staticData.DEFAULT_TRIAL_VIDEOS ?? 0;
    const isThisVideoAlreadyWatched = !!(userDataFmt.videoData || []).find((videoData) => videoData.id == id);
    if (isTrial && !isThisVideoAlreadyWatched && watchedVideos >= totalVideos && !userDataFmt.isNewTrial) {
      runMaxVideosTrial();
      return;
    }

    const incrementor = getVideoShardedCounterIncrementor(id);
    setVideoSecondsIncrementor(() => incrementor);
    getVideoOpeningsShardedCounterIncrementor(id)();
    database.doc(`userData/${currentUser.uid}/videos/${id}`).set({ hidden: false }, { merge: true }).catch(console.error);

    checkSupportedDRM().then((drm) => {
      setSupportedDrm(drm);
      setRequestData(true);
    });
  }, [id, userDataFmt, staticData, isTrial]);

  useEffect(() => {
    if (!requestData) return;
    requestVideoData(id, supportedDrm).then((video) => {
      if (video.httpErrorCode) {
        switch (video.code) {
          case 'failed-precondition':
            return runFailedPrecondition();
          case 'unavailable':
            return runUnavailable();
          case 'permission-denied':
            return runPermissionDenied();
        }
      }
      setVideo(video);
      updateUserData().catch(console.error);
      track('OPEN_VIDEO', { videoId: id, categories: video.tags.map((x) => x.id) });
      if (video.pip) {
        track('OPEN_VIDEO', { videoId: video.pip.id, categories: video.pip.tags.map((x) => x.id) });
      }
    });
  }, [requestData]);

  useEffect(() => {
    if (isTrialExpired) runTrialExpired();
  }, [isTrialExpired]);

  useEffect(() => {
    if (!nextVideo) return;
    if (!playerInstance.current) return;

    const callback = () => {
      const roundedTime = playerInstance.current.currentTime | 0;
      const outroSeconds = video.outroSeconds ?? 40;
      const outroStart = Math.floor(video.duration / 1000) - outroSeconds;
      const isAfterEndThreshold = roundedTime >= outroStart;
      if (nextVideo) setShowNextVideo(isAfterEndThreshold);
      setShowSkipIntro(roundedTime < (video.introSeconds ?? 19));
    };

    playerInstance.current.on('timeupdate', callback);

    return () => playerInstance.current.off('timeupdate', callback);
  }, [nextVideo, playerInstance.current]);

  useEffect(() => {
    if (!playerInstance.current) return;

    const callback = () => {
      const roundedTime = playerInstance.current.currentTime | 0;
      setShowSkipIntro(roundedTime < (video.introSeconds ?? 19));
    };

    playerInstance.current.on('timeupdate', callback);

    return () => playerInstance.current.off('timeupdate', callback);
  }, [playerInstance.current]);

  // TOD: TESTARE SE FUNZIONA LA SELEZIONE AUTOMATICA DELLA TRACCIA
  // E' stato messo un useEffect su videoLoading imposto la lingua predefinita dato che getAudioLanguages()
  // ritorna qualcosa solo dopo che il video è stato caricato altrimenti ritorna array vuoto
  useEffect(() => {
    if (shakaInstance.current?.getAudioLanguages()?.length > 0) {
      const audioFound = shakaInstance.current.getAudioLanguages().find((currentAudio) => currentAudio === currentLanguage);
      if (audioFound) {
        shakaInstance.current.configure({ abr: { enabled: false } });
        shakaInstance.current.selectAudioLanguage(audioFound);
        shakaInstance.current.configure({ abr: { enabled: true } });
        setSelectedAudioLanguage(audioFound);
      } else {
        setI18nForceItalianFlag(false);
        const languageRoles = shakaInstance.current.getAudioLanguagesAndRoles().filter((audio) => !!audio.language);
        if (languageRoles.length == 1 && languageRoles[0].language == 'en') {
          setI18nForceItalianFlag(true);
        }
      }
    }
  }, [videoLoading]);

  useEffect(() => {
    const element = document.querySelector<HTMLDivElement>('#xrayBtnWrapper');
    if (!element) return;
    element.style.backgroundColor = config.xRayActivated ? '#3393d0' : 'transparent';
  }, [config]);

  useEffect(() => {
    if (!activeMarker || !video) return;
    const activeMarkerObject = video.ts.find((ts) => ts.at == activeMarker);
    if (!activeMarkerObject) return;
    document.querySelector('#currentChapter').innerHTML = activeMarkerObject.description;
  }, [activeMarker, video]);

  useEffect(() => {
    if (!userDataFmt) return;
    if (!deviceId) return;
    if (!video?.hls || !videoElementRef.current) return;
    // if (!videoSwitched) return;
    if (!playerControls) return;

    setActiveMarker(null);

    if (!playerInstance.current) {
      const options: any = {
        ...PlyrOptions,
        title: video.title,
        controls: playerControls,
      };
      playerInstance.current = new Plyr(videoElementRef.current, options);
    }

    if (shaka.Player.isBrowserSupported()) {
      if (!shakaInstance.current || videoSwitched) {
        shaka.polyfill.installAll();
        shakaInstance.current = createShakaPlayer(videoElementRef.current, video, supportedDrm, staticData.HANDLE_SUBS);
        if (video.pip) shakaPipInstance.current = createShakaPlayer(pipVideoElementRef.current, video.pip, supportedDrm, staticData.HANDLE_SUBS);
        setVideoSwitched(false);
      }
    }

    if (!video.xray || !video.xray.length) dispatch({ type: VideoConfigAction.CLOSE_XRAY });

    const languageChangeCallback = () => {
      // Caption support is still flaky. See: https://github.com/sampotts/plyr/issues/994
      setTimeout(() => {
        if (!hlsInstance.current) return;
        hlsInstance.current.subtitleTrack = playerInstance.current.currentTrack;
      }, 50);
    };
    playerInstance.current.on('languagechange', languageChangeCallback);

    const timeUpdateCallback = () => {
      if (video.pip && pipVideoElementRef.current && !isPlaying(pipVideoElementRef.current)) {
        pipVideoElementRef.current.currentTime = playerInstance.current.currentTime;
      }

      const roundedTime = playerInstance.current.currentTime | 0;

      const activeMarker = (
        (video.ts || [])
          .slice()
          .reverse()
          .find((ts) => roundedTime >= ts.at) ?? {}
      ).at;
      setActiveMarker(activeMarker ?? null);

      if (video.xray && video.xray.length && roundedTime != previousTimeUpdate.current) {
        const elementsInCurrentTimeFrame = video.xray.filter(({ from, to }) => roundedTime >= from && roundedTime <= to);
        setXRay(elementsInCurrentTimeFrame);
      }

      if (roundedTime != previousTimeUpdate.current && roundedTime > 0) {
        analytics.logEvent('video_progress', { user_id: currentUser.uid, video_id: video.id, progress: roundedTime });
        globalSecondsIncrementor?.();
        videoSecondsIncrementor();
        previousTimeUpdate.current = roundedTime;
        track('VIDEO_WATCH', {
          videoId: id,
          bonusDocumentId: video.bonus,
          selectedAudioLanguage,
          timestamp: Date.now(),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
        if (video.pip)
          track('VIDEO_WATCH', {
            videoId: video.pip.id,
            bonusDocumentId: video.bonus,
            selectedAudioLanguage,
            timestamp: Date.now(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          });
        if (roundedTime % 10 == 0 && !isVideoLocked(video)) {
          getAchievements();
          updateUserVideoAnalytics(id, { time: roundedTime, at: fv.serverTimestamp(), seriesId: seriesId }).catch(console.error);
        }
      }
    };
    playerInstance.current.on('timeupdate', timeUpdateCallback);

    const loadStartCallback = () => setVideoLoading(true);
    playerInstance.current.on('loadstart', loadStartCallback);

    const canPlayCallback = () => setVideoLoading(false);
    playerInstance.current.on('canplay', canPlayCallback);

    playerInstance.current.once('canplay', () => {
      if (preloadTime != null) {
        // TODO: gestire preloadTime settando il tempo corretto
        // per ora è usato sulle serie quindi va bene skippare la intro
        skipIntro();
      } else {
        const time = switchedTime.current || video.time;
        if (time) updatePlayersTime(time);
      }

      if (!config.adBeforeVideo && isForeground) {
        try {
          playerInstance.current.play().catch(console.error);
        } catch (e) {
          console.error(e);
        }
      }
    });

    const controlsHiddenCallback = () => setAreControlsVisible(false);
    playerInstance.current.on('controlshidden', controlsHiddenCallback);

    const controlsShownCallback = () => setAreControlsVisible(true);
    playerInstance.current.on('controlsshown', controlsShownCallback);

    const pauseCallback = () => {
      try {
        if (video.pip) pipVideoElementRef.current.pause();
      } catch {}
      setIsVideoPlaying(false);
      if (!isVideoLocked(video)) {
        updateUserVideoAnalytics(id, { time: playerInstance.current.currentTime, at: fv.serverTimestamp(), seriesId }).catch(console.error);
      }
    };
    playerInstance.current.on('pause', pauseCallback);

    const playingCallback = () => {
      try {
        if (video.pip) pipVideoElementRef.current.play().catch(console.error);
      } catch {}
      dispatch({ type: VideoConfigAction.CLOSE_TIMESTAMPS });
      setIsVideoPlaying(true);
    };
    playerInstance.current.on('playing', playingCallback);

    const xrayButton = document.getElementById('xray-button');
    const xrayButtonClickCallback = () => dispatch({ type: VideoConfigAction.TOGGLE_XRAY });
    if (xrayButton) xrayButton.addEventListener('click', xrayButtonClickCallback);

    const tsButton = document.getElementById('timestamp-button');
    const tsButtonClickCallback = (e) => {
      dispatch({ type: VideoConfigAction.TOGGLE_TIMESTAMPS });
      e.stopPropagation();
    };
    if (tsButton) tsButton.addEventListener('click', tsButtonClickCallback);

    const i18nButton = document.getElementById('i18n-button');
    const i18nButtonClickCallback = () => {
      // toggleFullscreen(false);
      try {
        videoElementRef.current?.pause?.();
        if (video.pip) pipVideoElementRef.current?.pause?.();
      } catch {}
      setIsI18nModalOpen(true);
    };
    if (i18nButton) i18nButton.addEventListener('click', i18nButtonClickCallback);

    const ratingButton = document.getElementById('rating-button');
    const ratingButtonClickCallback = () => {
      try {
        videoElementRef.current?.pause?.();
        if (video.pip) pipVideoElementRef.current?.pause?.();
      } catch {}
      const { right } = ratingButton.getBoundingClientRect();
      let ratingButtonRightDistance = window.innerWidth - right;
      if (!isNative) ratingButtonRightDistance += 20;
      document.documentElement.style.setProperty('--rating-modal-right-margin', `${ratingButtonRightDistance}px`);
      setIsRatingModalOpen(true);
    };
    if (ratingButton) ratingButton.addEventListener('click', ratingButtonClickCallback);

    const helpButton = document.getElementById('help-button');
    const helpButtonClickCallback = () => {
      // toggleFullscreen(false);
      try {
        videoElementRef.current?.pause?.();
        if (video.pip) pipVideoElementRef.current?.pause?.();
      } catch {}
      setIsHelpModalOpen(true);
    };
    if (helpButton) helpButton.addEventListener('click', helpButtonClickCallback);

    const nextVideoButton = document.getElementById('next-video-button');
    const nextVideoButtonClickCallback = () => runGotoNext();
    if (nextVideoButton) nextVideoButton.addEventListener('click', nextVideoButtonClickCallback);

    const restartButton = document.getElementById('restart-button');
    const restartButtonClickCallback = () => updatePlayersTime(0);
    if (restartButton) restartButton.addEventListener('click', restartButtonClickCallback);

    const rewindButton = document.getElementById('rewind-button');
    const rewindButtonClickCallback = () => updatePlayersTime(0, '-');
    if (rewindButton) rewindButton.addEventListener('click', rewindButtonClickCallback);

    const ffButton = document.getElementById('ff-button');
    const ffButtonClickCallback = () => updatePlayersTime(0, '+');
    if (ffButton) ffButton.addEventListener('click', ffButtonClickCallback);

    const fullscreenButton = document.getElementById('fullscreen-button');
    const fullscreenButtonClickCallback = () => toggleFullscreen();
    if (fullscreenButton) fullscreenButton.addEventListener('click', fullscreenButtonClickCallback);

    return () => {
      clean();

      playerInstance.current.off('languagechange', languageChangeCallback);
      playerInstance.current.off('timeupdate', timeUpdateCallback);
      playerInstance.current.off('loadstart', loadStartCallback);
      playerInstance.current.off('canplay', canPlayCallback);
      playerInstance.current.off('controlshidden', controlsHiddenCallback);
      playerInstance.current.off('controlsshown', controlsShownCallback);
      playerInstance.current.off('pause', pauseCallback);
      playerInstance.current.off('playing', playingCallback);

      if (xrayButton) xrayButton.removeEventListener('click', xrayButtonClickCallback);
      if (tsButton) tsButton.removeEventListener('click', tsButtonClickCallback);
      if (fullscreenButton) fullscreenButton.removeEventListener('click', fullscreenButtonClickCallback);
      if (i18nButton) i18nButton.removeEventListener('click', i18nButtonClickCallback);
      if (ratingButton) ratingButton.removeEventListener('click', ratingButtonClickCallback);
      if (helpButton) helpButton.removeEventListener('click', helpButtonClickCallback);
      if (nextVideoButton) nextVideoButton.removeEventListener('click', nextVideoButtonClickCallback);
      if (restartButton) restartButton.removeEventListener('click', restartButtonClickCallback);
      if (rewindButton) rewindButton.removeEventListener('click', rewindButtonClickCallback);
      if (ffButton) ffButton.removeEventListener('click', ffButtonClickCallback);
      if (fullscreenButton) fullscreenButton.removeEventListener('click', fullscreenButtonClickCallback);
    };
  }, [video, userDataFmt, deviceId, videoSwitched, playerControls, nextVideo, switchedTime.current]);

  useEffect(() => {
    if (!video?.hls || !videoElementRef.current) return;
    if (nextVideo) return;

    // console.info({ seriesId, videoSeriesId: video?.seriesId });
    const seriesPartOf = (() => {
      if (seriesId || video?.seriesId) return series.find((serie) => serie.id == seriesId || serie.id == video?.seriesId);
      return series.find((serie) => serie.seasons.find((season) => season.episodes.find((episode) => episode.video.id == video.id)));
    })();
    // console.info({ seriesPartOf });
    if (!seriesPartOf) return;

    const allEpisodes = seriesPartOf.seasons.map((season) => season.episodes).flat(Infinity);
    const indexOfCurrentEpisode = allEpisodes.findIndex((episode) => episode.video.id == video.id);
    // console.info({ indexOfCurrentEpisode });
    if (indexOfCurrentEpisode < 0) return;

    const next = allEpisodes[indexOfCurrentEpisode + 1];
    // console.info({ next });
    if (!next?.video) return;
    if (isVideoLocked(next.video)) return;
    requestVideoData(next.video.id, supportedDrm).then(setNextVideo);
  }, [video, seriesId, nextVideo, series, supportedDrm]);

  // useEffect(() => {
  //   if (!activeMarker) return;
  //   const allMarkers = document.querySelectorAll('.progress-timestamp-marker');
  //   allMarkers.forEach((marker) => {
  //     if (marker.classList.contains('active')) marker.classList.remove('active');
  //     if (+marker.id == activeMarker) marker.classList.add('active');
  //   });
  // }, [activeMarker]);

  // useEffect(() => {
  //   function canPlayListener() {
  //     videoElementRef.current?.play();
  //   }
  //   videoElementRef.current?.addEventListener('canplay', canPlayListener);
  //   return () => videoElementRef.current?.removeEventListener('canplay', canPlayListener);
  // }, [videoElementRef.current]);

  const initConcurrencyWatch = () => {
    const increment = firebase.default.database.ServerValue.increment;
    (async () => {
      const clientName = `client_${await calculateDeviceIdentifier()}`;
      const currentRtdbDetails = realtimeDatabase.ref(`users/${currentUser.uid}/${clientName}/details`);
      const currentRtdbUser = realtimeDatabase.ref(`users/${currentUser.uid}`);
      setGlobalRtdbUserRecord(currentRtdbDetails);

      const onValueCallback = async (snapshot: firebase.default.database.DataSnapshot) => {
        const data = snapshot.val() || {};
        const watching = Object.keys(data).reduce((acc, key) => (key.includes('client_') ? acc + (data[key]?.details?.watching || 0) : acc), 0);

        if (watching >= staticData.DEFAULT_SIMULTANEOUS_VIEWS_V2 && staticData.LOCK_SIMULTANEOUS_VIEWS) runMaxSimultaneousUsers();
        else setContinueFetch(true);

        currentRtdbDetails
          .onDisconnect()
          .update({
            watching: increment(-1),
            ua: { browser: ua.browser?.name ?? 'Browser sconosciuto', os: ua.os?.name ?? 'Sistema sconosciuto' },
            ts: firebase.default.database.ServerValue.TIMESTAMP,
            video: id,
            lastVideoUpdate: firebase.default.database.ServerValue.TIMESTAMP,
          })
          .catch(console.error);

        currentRtdbDetails
          .update({
            name: getFullName(),
            watching: increment(1),
            video: id,
            ts: firebase.default.database.ServerValue.TIMESTAMP,
            ua: { browser: ua.browser?.name ?? 'Browser sconosciuto', os: ua.os?.name ?? 'Sistema sconosciuto' },
            lastVideoUpdate: firebase.default.database.ServerValue.TIMESTAMP,
          })
          .catch(console.error);
      };

      currentRtdbUser.once('value', onValueCallback).catch(console.error);
    })();
  };

  const skipIntro = () => {
    updatePlayersTime(video.introSeconds ?? 19);
  };

  const switchVideo = () => {
    switchedTime.current = playerInstance.current.currentTime;
    const promises = [shakaInstance.current.destroy()];
    if (video.pip) promises.push(shakaPipInstance.current.destroy());
    Promise.all(promises)
      .then(() => {
        setVideo((current) => ({ ...current, ...video.pip, pip: current }));
        setVideoSwitched(true);
      })
      .catch(console.error);
  };

  if (!id) return <Redirect to={'/browse'} />;

  return (
    <>
      <Title>{video ? video.title : `${t('labels.caricamento')}...`}</Title>

      {!!video?.title ? (
        <div id={'video-page-content'}>
          <Modal show={isSlowInternetModalOpen} onHide={() => setIsSlowInternetModalOpen(false)} centered>
            <Modal.Header>
              <Modal.Title>{t('watch.labels.connessioneLenta')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t('watch.labels.esperienzaNonOttimale', { title: process.env.REACT_APP_TITLE })}</Modal.Body>
            <Modal.Footer>
              <Button variant={'primary'} onClick={() => setIsSlowInternetModalOpen(false)}>
                {t('labels.chiudi')}
              </Button>
            </Modal.Footer>
          </Modal>
          {isMobile && (
            <div className={'native-video-xray video-xray ' + (config.xRayActivated ? 'xray-visible' : '')}>
              <div className={'text-container'}>
                <div className={'xray-element main-xray-element d-flex align-items-center'}>
                  <FontAwesomeIcon icon={faTimes} size={'lg'} className={'mr-2'} onClick={() => dispatch({ type: VideoConfigAction.TOGGLE_XRAY })} />
                  {t('watch.labels.inUtilizzo')}
                </div>
                {xRay.map((xray, i) => {
                  return (
                    <div
                      className={'xray-element-wrapper ' + (!!xray.url ? 'clickable' : '')}
                      key={i.toString()}
                      onClick={() => {
                        if (!xray.url) return;
                        database.doc(`tracking_starring/${video.id}`).set({ [xray.url]: fv.increment(1) }, { merge: true });
                        if (isNative) Browser.open({ url: xray.url });
                        else window.open(xray.url, '_blank');
                        track('STARRING_CLICK', { url: xray.url, videoGuid: video.id });
                      }}
                    >
                      {!!xray.image && <img src={xray.image} alt={xray.description} />}
                      <div className={'xray-element'}>
                        {!!xray.url && <FontAwesomeIcon icon={faClickableStarringIcon} className={'mr-1'} />}
                        {xray.description}
                      </div>
                    </div>
                  );
                })}
                {!xRay.length && <div>{t('watch.labels.nessunoStrumentoUtilizzo')}</div>}
              </div>
            </div>
          )}
          <div
            id={'main'}
            className={`${areControlsVisible || isRatingDivHover /* && !isVideoPlaying */ ? 'overlay-visible' : ''} ${isTrial ? 'less-higher-overlay' : ''}`}
            onClick={(e) => {
              if (config.timestampsOpen) {
                dispatch({ type: VideoConfigAction.CLOSE_TIMESTAMPS });
              }
              // else {
              //   if ((e.target as HTMLElement).matches('.plyr__control')) return;
              //   if (isVideoPlaying) playerInstance.current?.pause?.();
              //   else playerInstance.current?.play?.()?.catch?.(console.error);
              // }
            }}
          >
            <div
              ref={backButtonContainerRef}
              className={`icon-button big-icon ${isMobile ? 'mobile-button' : ''} ${
                areControlsVisible || isBackButtonHover || isRatingDivHover ? 'big-icon-visible' : ''
              }`}
              onClick={() => {
                const isInCourse = !!courseId;
                clean();
                if (isInCourse) {
                  // TODO: mettere una conferma?
                  history.push('..');
                } else {
                  history.goBack();
                }
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} color={'#6c757e'} size={isNative ? '2x' : null} />
            </div>
            <div className={'video-overlay ' + (isNative ? 'native' : '')}>
              <div className={'video-title ' + (isNative ? 'native' : '')}>{video.title}</div>

              {!!(video.description && video.description.length) && (
                <div className={'video-description ' + (isNative ? 'native' : '')}>
                  {video.description.map((row, i) => (
                    <div key={i.toString()} style={{ marginLeft: row.startsWith('-') ? '8px' : 0 }}>
                      {row || <>&nbsp;</>}
                    </div>
                  ))}
                </div>
              )}
              {!!video.author && !isMobile && (
                <div className={'video-author'}>
                  {!!video.author.photo && (
                    <div className={'author-photo'}>
                      <img src={video.author.photo} alt={video.author.name} />
                    </div>
                  )}
                  <div className={'author-data'}>
                    <div className={'author-name'}>{video.author.name}</div>
                    {!!video.author.description && <div className={'author-description mt-2'}>{video.author.description}</div>}
                  </div>
                </div>
              )}
              {/*<div className={'mt-4'} style={{ pointerEvents: 'all' }} ref={ratingDivContainerRef}>
                <VideoRating video={video} inline={true} hideTooltips={true} />
              </div>*/}
            </div>

            {!isMobile && (
              <div className={'video-xray xray-visible'}>
                {!!(config.xRayActivated && !video.xray?.length && !videoLoading) && (
                  <div className={'xray-element main-xray-element'}>{t('watch.labels.noStarring')}</div>
                )}
                {!!(config.xRayActivated && xRay.length) && (
                  <>
                    <div className={'xray-element main-xray-element'}>{t('watch.labels.inUso')}</div>
                    {xRay.map((xray, i) => {
                      return (
                        <div
                          className={'xray-element-wrapper ' + (!!xray.url ? 'clickable' : '')}
                          onClick={() => {
                            if (!xray.url) return;
                            database.doc(`tracking_starring/${video.id}`).set({ [xray.url]: fv.increment(1) }, { merge: true });
                            window.open(xray.url, '_blank');
                            track('STARRING_CLICK', { url: xray.url, videoGuid: video.id });
                          }}
                          key={i.toString()}
                        >
                          {!!xray.image && <img src={xray.image} alt={xray.description} />}
                          <div className={'xray-element'}>
                            {!!xray.url && <FontAwesomeIcon icon={faClickableStarringIcon} className={'mr-1'} />}
                            {xray.description}
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            )}

            <Video id={video.id} className={'main-video'} preload={'metadata'} controls innerRef={videoElementRef} poster={video.thumbnail} />
            {!!video.pip && (
              <div className={'pip-video ' + (isNative ? 'native' : '')} onClick={switchVideo}>
                <Video id={video.pip.id} preload={'metadata'} muted innerRef={pipVideoElementRef} />
                <div className="pip-overlay">
                  <FontAwesomeIcon color={'#B0B0B0'} icon={faSyncAlt} size={'4x'} />
                </div>
              </div>
            )}

            <VideoWatermark
              withPip={!!video.pip}
              onTamper={() => {
                clean();
                shakaClean();
              }}
            >
              {isNative ? userDataFmt.alias : currentUser.uid}
            </VideoWatermark>
          </div>
          <div className={'side ' + (config.timestampsOpen ? 'expanded' : '')}>
            <div className={'text-muted mb-3 d-flex justify-content-between align-items-center'}>
              <span>{t('labels.timestamps')}</span>
              <FontAwesomeIcon
                icon={faTimes}
                style={{ cursor: 'pointer' }}
                size={'lg'}
                onClick={() => dispatch({ type: VideoConfigAction.CLOSE_TIMESTAMPS })}
              />
            </div>
            {!(video.ts && video.ts.length) && <div>{t('labels.noTimestamp')}</div>}
            {!!(video.ts && video.ts.length) && (
              <>
                {video.ts.map((ts) => (
                  <div
                    className={'timestamp-clickable ' + (activeMarker == ts.at ? 'active' : '')}
                    key={ts.at}
                    onClick={() => {
                      updatePlayersTime(ts.at);
                      track('TIMESTAMP_USED');
                      if (!isVideoLocked(video)) {
                        updateUserVideoAnalytics(id, { time: ts.at, at: fv.serverTimestamp(), seriesId }).catch(console.error);
                      }
                      if (isNative) {
                        dispatch({ type: VideoConfigAction.TOGGLE_TIMESTAMPS });
                      }
                    }}
                  >
                    <div style={{ fontWeight: 700 }}>{parseDuration(ts.at * 1000)}</div>
                    <div>{ts.description}</div>
                  </div>
                ))}
                <small className={'text-muted text-center mt-3 d-block'}>{t('watch.labels.clickPerNavigare')}</small>
              </>
            )}
          </div>

          {isNative && playerInstance.current && (
            <div className={'mobile-overlay-controls ' + (areControlsVisible ? 'moc-visible' : '')}>
              {/*<Button variant={'outline-light'}>
                <FontAwesomeIcon icon={faUndo} fixedWidth={true} onClick={() => playerInstance.current.rewind()} />
              </Button>*/}
              <Button variant={'outline-light'} size={'lg'} className={'mx-5'} onClick={() => playerInstance.current.togglePlay()}>
                <FontAwesomeIcon icon={playerInstance.current.paused ? faPlay : faPause} fixedWidth={true} size={'lg'} />
              </Button>
              {/*<Button variant={'outline-light'}>
                <FontAwesomeIcon icon={faRedo} fixedWidth={true} onClick={() => playerInstance.current.forward()} />
              </Button>*/}
            </div>
          )}

          <Modal centered={isNative} show={isMobileNonNativeModalOpen} onHide={() => setIsMobileNonNativeModalOpen(false)} backdrop={'static'} keyboard={false}>
            <Modal.Header>
              <Modal.Title>{t('watch.labels.noFunzionalita')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{t('watch.labels.necessariaApplicazione', { title: process.env.REACT_APP_TITLE })}</div>
              <AppDownload />
            </Modal.Body>
            <Modal.Footer>
              <Button variant={'secondary'} as={Link} to={'/browse'}>
                {t('watch.labels.tornaIndietro')}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal centered={isNative} show={isTrialModalOpen} onHide={() => history.push('/plans')}>
            <Modal.Header>
              <Modal.Title>{t('watch.labels.periodoProvaTerminato')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t('watch.labels.periodoProvaTerminatoDescrizione', { title: process.env.REACT_APP_TITLE })}</Modal.Body>
            <Modal.Footer>
              <Button variant={'secondary'} onClick={() => history.push('/browse')}>
                {t('labels.tornaHome')}
              </Button>
              {!isNative && (
                <Button variant={'primary'} onClick={() => history.push('/plans')}>
                  {t('watch.labels.acquistaPiano')}
                </Button>
              )}
            </Modal.Footer>
          </Modal>

          <Modal centered={isNative} show={isMaxDevicesModalOpen} onHide={() => history.push('/profile')}>
            <Modal.Header>
              <Modal.Title>{t('watch.labels.raggiuntoMaxDispositivi')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t('watch.labels.raggiuntoMaxDispositiviDescription')}</Modal.Body>
            <Modal.Footer>
              <Button variant={'secondary'} onClick={() => history.push('/browse')}>
                {t('labels.tornaHome')}
              </Button>
              <Button variant={'primary'} onClick={() => history.push('/profile')}>
                {t('labels.vaiProfilo')}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal centered={isNative} show={isMaxSimultaneousUsersModalOpen} onHide={() => history.push('/browse')}>
            <Modal.Header>
              <Modal.Title>{t('watch.labels.limiteVisione')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t('watch.labels.limiteVisioneDescrizione')}</Modal.Body>
            <Modal.Footer>
              <Button variant={'secondary'} onClick={() => history.push('/browse')}>
                {t('labels.tornaHome')}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal dialogClassName={'video-rating-dialog'} show={isRatingModalOpen} onHide={() => setIsRatingModalOpen(false)} className={'dark-modal'}>
            <Modal.Header className={'align-items-center'} closeButton>
              <Modal.Title>{t('videoRating.labels.valutaVideo')}</Modal.Title>
              <VideoRating
                video={video}
                onRateSubmit={(rating) => {
                  if (!rating) return;
                  setIsRatingModalOpen(false);
                }}
              />
            </Modal.Header>
          </Modal>

          <Modal scrollable centered={true} show={isHelpModalOpen} onHide={() => setIsHelpModalOpen(false)} backdrop={'static'} keyboard={false}>
            <Modal.Header closeButton={!videoReportLoading}>
              <div>
                <Modal.Title>{t('watch.labels.cosaSuccede')}</Modal.Title>
                <small className={'text-muted'}>{t('watch.labels.cosaSuccedeDescrizione')}</small>
              </div>
            </Modal.Header>
            <Modal.Body>
              {[
                {
                  id: 'etichette',
                  label: t('watch.labels.problemiEtichette'),
                  hint: t('watch.labels.problemiEtichetteHint'),
                },
                {
                  id: 'video',
                  label: t('watch.labels.problemiVideo'),
                  hint: t('watch.labels.problemiVideoHint'),
                },
                {
                  id: 'audio',
                  label: t('watch.labels.problemiAudio'),
                  hint: t('watch.labels.problemiAudioHint'),
                },
                {
                  id: 'sottotitoli',
                  label: t('watch.labels.problemiSottotitoli'),
                  hint: t('watch.labels.problemiSottotitoliHint'),
                },
                {
                  id: 'buffering',
                  label: t('watch.labels.problemiConnessione'),
                  hint: t('watch.labels.problemiConnessioneHint'),
                },
              ].map(({ id, label, hint }) => (
                <Form.Group key={id}>
                  <Form.Check
                    type={'checkbox'}
                    label={label}
                    id={id}
                    disabled={videoReportLoading}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      if (checked) setVideoReportChecks((current) => new Set([...Array.from(current), id]));
                      else {
                        const newSet = new Set(Array.from(videoReportChecks));
                        newSet.delete(id);
                        setVideoReportChecks(newSet);
                      }
                    }}
                  />
                  <Form.Text className={'text-muted'}>{hint}</Form.Text>
                </Form.Group>
              ))}

              <Form.Group>
                <Form.Label>{t('watch.labels.dettagliAggiuntivi')}</Form.Label>
                <Form.Control as={'textarea'} required disabled={videoReportLoading} value={reportDetails} onChange={(e) => setReportDetails(e.target.value)} />
              </Form.Group>

              <Alert variant={'warning'} className={'mb-0'}>
                {t('watch.labels.nonRiceveraiRisposta')} <Link to={'/help'}>{t('labels.helpCenter')?.toLowerCase()}</Link>.
              </Alert>
            </Modal.Body>
            <Modal.Footer>
              <Button variant={'secondary'} disabled={videoReportLoading} onClick={() => setIsHelpModalOpen(false)}>
                {t('labels.annulla')}
              </Button>
              <Button
                variant={'primary'}
                disabled={!videoReportChecks.size || videoReportLoading || !reportDetails}
                onClick={() => {
                  setVideoReportLoading(true);
                  database
                    .collection('videoReports')
                    .add({
                      types: Array.from(videoReportChecks),
                      details: reportDetails,
                      ts: fv.serverTimestamp(),
                      uid: currentUser.uid,
                      video: database.doc(`videos/${id}`),
                      time: playerInstance.current?.currentTime | 0,
                      config,
                      platform,
                      ack: false,
                      ua: {
                        os: ua.os?.name ?? null,
                        os_version: ua.os?.version ?? null,
                        browser: ua.browser?.name ?? null,
                        browser_version: ua.browser?.version ?? null,
                      },
                    })
                    .then(() => {
                      setIsHelpModalOpen(false);
                      setVideoReportChecks(new Set());
                      setReportDetails('');
                    })
                    .catch(console.error)
                    .finally(() => {
                      setVideoReportLoading(false);
                    });
                }}
              >
                {videoReportLoading ? t('labels.attendiDots') : t('watch.labels.inviaSegnalazione')}
              </Button>
            </Modal.Footer>
          </Modal>

          {shakaInstance.current && (
            <Modal
              show={isI18nModalOpen}
              onHide={() => setIsI18nModalOpen(false)}
              centered
              scrollable
              className={'translucent-modal'}
              backdrop={'static'}
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{t('watch.labels.linguaSottotitoli')}</Modal.Title>
              </Modal.Header>
              <div className={'d-flex'}>
                {staticData.ENABLE_AUDIO_SELECTION && !!shakaInstance.current.getAudioLanguagesAndRoles().length && !i18nForceItalianFlag && (
                  <ListGroup className={'transparent-list clickable flex-grow-1'}>
                    <ListGroup.Item disabled>
                      <div className={'d-flex align-items-center'}>
                        <FontAwesomeIcon icon={faMusic} fixedWidth={true} className={'mr-1'} />
                        <small>{t('labels.audio')}</small>
                      </div>
                    </ListGroup.Item>
                    {shakaInstance.current
                      .getAudioLanguagesAndRoles()
                      .map((audio) => {
                        if (!audio.language) return null;
                        return (
                          <ListGroup.Item
                            key={audio.language}
                            className={audio.language == selectedAudioLanguage ? 'active' : ''}
                            onClick={() => {
                              if (audio.language == selectedAudioLanguage) return;
                              shakaInstance.current.configure({ abr: { enabled: false } });
                              shakaInstance.current.selectAudioLanguage(audio.language);
                              shakaInstance.current.configure({ abr: { enabled: true } });
                              setSelectedAudioLanguage(audio.language);
                            }}
                          >
                            <div className={'d-flex align-items-center'}>
                              <FontAwesomeIcon
                                className={'mr-1'}
                                icon={audio.language == selectedAudioLanguage ? i18nIconSelected : i18nIconDefault}
                                fixedWidth={true}
                              />
                              {ISO6391.getNativeName(audio.language) || audio.label || audio.language}
                            </div>
                          </ListGroup.Item>
                        );
                      })
                      .filter(Boolean)}
                  </ListGroup>
                )}

                <ListGroup className={'transparent-list clickable flex-grow-1'}>
                  <ListGroup.Item disabled>
                    <div className={'d-flex align-items-center'}>
                      <FontAwesomeIcon icon={faClosedCaptioning} fixedWidth={true} className={'mr-1'} />
                      <small>{t('labels.sottotitoli')}</small>
                    </div>
                  </ListGroup.Item>
                  {shakaInstance.current
                    .getTextTracks()
                    .map((track, index) => {
                      if (!track.language) return null;
                      return (
                        <ListGroup.Item
                          key={track.id}
                          className={track.active && track.language == selectedSubLanguage ? 'active' : ''}
                          onClick={() => {
                            if (track.active && track.language == selectedSubLanguage) return;
                            if (ua.engine?.name == 'WebKit') {
                              shakaInstance.current.selectTextLanguage(track.language);
                              playerInstance.current.currentTrack = index;
                            } else {
                              shakaInstance.current.selectTextTrack(track);
                              playerInstance.current.currentTrack = 0;
                            }
                            setSelectedSubLanguage(track.language);
                            shakaInstance.current.setTextTrackVisibility(true);
                          }}
                        >
                          <div className={'d-flex align-items-center'}>
                            {
                              <FontAwesomeIcon
                                className={'mr-1'}
                                icon={track.active && track.language == selectedSubLanguage ? i18nIconSelected : i18nIconDefault}
                                fixedWidth={true}
                              />
                            }
                            {track.label || track.language}
                          </div>
                        </ListGroup.Item>
                      );
                    })
                    .filter(Boolean)}
                  <ListGroup.Item
                    className={!selectedSubLanguage ? 'active' : ''}
                    onClick={() => {
                      if (!selectedSubLanguage) return;
                      shakaInstance.current.setTextTrackVisibility(false);
                      shakaInstance.current.selectTextLanguage('');
                      playerInstance.current.currentTrack = -1;
                      setSelectedSubLanguage(null);
                    }}
                  >
                    <div className={'d-flex align-items-center'}>
                      <FontAwesomeIcon className={'mr-1'} icon={selectedSubLanguage ? i18nIconDefault : i18nIconSelected} fixedWidth={true} />
                      {t('labels.sottotitoliDisattiva')}
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </div>
              <Modal.Footer>
                <Button variant={'dark'} onClick={() => setIsI18nModalOpen(false)} size={'sm'}>
                  {t('labels.chiudi')}
                </Button>
              </Modal.Footer>
            </Modal>
          )}

          {showSkipIntro && !videoLoading && (
            <Button variant={'outline-light'} className={'skip-intro-button'} onClick={() => skipIntro()}>
              {t('watch.labels.saltaIntroUpper')} <FontAwesomeIcon icon={faChevronRight} className={'ml-1'} />
            </Button>
          )}

          {nextVideo && showNextVideo && <NextVideo next={nextVideo} onCountdownComplete={() => runGotoNext()} />}
        </div>
      ) : (
        <FullscreenLoader />
      )}

      <Modal centered={isNative} show={failedPreconditionModalOpen} onHide={() => history.replace('/')}>
        <Modal.Header>
          <Modal.Title>{t('watch.labels.videoNonDisponibile')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('watch.labels.categoriaNonPermetteAccesso')}</Modal.Body>
        <Modal.Footer>
          <Button variant={'secondary'} onClick={() => history.replace('/')}>
            {t('labels.tornaHome')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal centered={isNative} show={permissionDeniedModalOpen} onHide={() => history.replace('/')}>
        <Modal.Header>
          <Modal.Title>{t('watch.labels.videoNonDisponibile')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('watch.labels.videoRiservatoAbbonati')}</Modal.Body>
        <Modal.Footer>
          <Button variant={'secondary'} onClick={() => history.replace('/')}>
            {t('labels.tornaHome')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal centered={isNative} show={unavailableModalOpen} onHide={() => history.replace('/')}>
        <Modal.Header>
          <Modal.Title>{t('watch.labels.videoNonDisponibile')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('watch.labels.videoNonDisponibileDescrizione')}</Modal.Body>
        <Modal.Footer>
          <Button variant={'secondary'} onClick={() => history.replace('/')}>
            {t('labels.tornaHome')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal centered={isNative} show={isMaxVideosTrialModalOpen} onHide={() => history.push('/plans')}>
        <Modal.Header>
          <Modal.Title>{t('watch.labels.necessarioAbbonamento')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('watch.labels.necessarioAbbonamentoDescription')}</Modal.Body>
        <Modal.Footer>
          <Button variant={'secondary'} onClick={() => history.push('/browse')}>
            {t('labels.tornaHome')}
          </Button>
          {!isNative && (
            <Button variant={'primary'} onClick={() => history.push('/plans')}>
              {t('labels.aquistaPiano')}
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* {isTrial && !isTrialExpired && (
        <Toast className={'trial-toast'} style={{ position: 'fixed', top: 12, right: !!video?.pip ? '320px' : '12px', zIndex: 999999 }}>
          <Toast.Header>
            <strong className={'mr-auto'}>Versione di prova</strong>
          </Toast.Header>
          <Toast.Body>Tempo rimanente: {formatSecondsDuration(trialTimeLeft)}</Toast.Body>
        </Toast>
      )} */}
    </>
  );
});

export default WatchComponent;
