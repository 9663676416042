import './Copyable.scss';
import { WithTooltip } from '../WithTooltip/WithTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCopy, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CopyableComponent = ({ text }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return;
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, [copied]);

  if (!text)
    return (
      <>
        <span className={'text-warning'}>
          <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
        </span>
        <code>(null)</code>
      </>
    );
  return (
    <WithTooltip text={copied ? 'Copiato!' : 'Copia'} big={false}>
      <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
        <div className={'copyable-wrapper ' + (copied ? 'text-success' : '')}>
          <code className={'copyable-code'}>{text}</code>
          <FontAwesomeIcon icon={copied ? faCheck : faCopy} fixedWidth={true} />
        </div>
      </CopyToClipboard>
    </WithTooltip>
  );
};

export { CopyableComponent as Copyable };
