import { Title } from '../../components/Title/Title';
import { Topbar } from '../../components/Topbar/Topbar';
import { Button, ButtonGroup, Card, Container, Form, InputGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { Footer } from '../../components/Footer/Footer';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { FormEvent, useEffect, useRef, useState } from 'react';
import firebase from 'firebase';
import { multiFactorAssertion, phoneAuthCredential, phoneAuthProvider } from '../../../firebase';
import { useCapacitor } from '../../../hooks/useCapacitor';
import { useQuery } from '../../../hooks/useQuery';
import { ErrorModal } from '../../components/ErrorModal/ErrorModal';
import { LoginWithGoogle } from '../../components/LoginWithGoogle/LoginWithGoogle';

const TwoFAComponent = () => {
  const { currentUser, mfaGetSession, mfaUnenroll, mfaEnroll, reauthenticate, checkEmail } = useFirebase();
  const { isNative } = useCapacitor();
  const { mfaEnroll: qpMfaEnroll } = useQuery();

  const [loadingMfaStep1, setLoadingMfaStep1] = useState(false);
  const [loadingMfaStep2, setLoadingMfaStep2] = useState(false);

  const [errorCode, setErrorCode] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [confirmationPasswordVisible, setConfirmationPasswordVisible] = useState(false);
  const [signInMethods, setSignInMethods] = useState<string[]>([]);

  const [isReauthenticateModalOpen, setIsReauthenticateModalOpen] = useState(false);
  const [isMfaEnrollModalOpen, setIsMfaEnrollModalOpen] = useState(false);
  const [isMfaUnenrollModalOpen, setIsMfaUnenrollModalOpen] = useState(false);

  const reauthenticationCallback = useRef(null);

  const [mfa, setMfa] = useState<
    Partial<{ loaded: boolean; session: any; phoneNumber: string; enrollStep: number; verificationId: string; verificationCode: string }>
  >({
    loaded: false,
    phoneNumber: '',
    verificationCode: '',
    enrollStep: 1,
  });
  const updateMfa = (data: any) => setMfa((current) => ({ ...(current || {}), ...data }));
  const recaptchaCallback = useRef<() => Promise<void>>();
  const recaptchaVerifier = useRef<firebase.auth.RecaptchaVerifier>();

  const startMfaEnroll = () => {
    mfaGetSession().then((session) => {
      updateMfa({ session, loaded: true });
      setIsMfaEnrollModalOpen(true);
    });
  };

  useEffect(() => {
    if (qpMfaEnroll != '1') return;
    startMfaEnroll();
  }, [qpMfaEnroll]);

  const runUnenrollMfa = (factorId: string) => {
    setErrorCode('');
    mfaUnenroll(factorId)
      .then(() => {
        const url = new URL(window.location.href);
        url.searchParams.set('mfaUnenroll', '1');
        window.location.assign(url.search);
      })
      .catch((error) => {
        if (error.code == 'auth/requires-recent-login') {
          setIsMfaUnenrollModalOpen(false);
          setIsReauthenticateModalOpen(true);
          return;
        }
        setErrorCode(error.code);
        setIsErrorModalOpen(true);
      });
  };

  useEffect(() => {
    if (!isMfaEnrollModalOpen) return;
    setLoadingMfaStep1(true);
    recaptchaVerifier.current = new firebase.auth.RecaptchaVerifier('confirm-phone-btn', {
      size: 'invisible',
      callback: () => {
        recaptchaCallback.current();
      },
    });
    recaptchaVerifier.current.render().then(() => setLoadingMfaStep1(false));

    return () => recaptchaVerifier.current?.clear();
  }, [isMfaEnrollModalOpen]);

  useEffect(() => {
    recaptchaCallback.current = async () => {
      setErrorCode('');
      try {
        const phoneInfoOptions = {
          phoneNumber: '+39' + mfa.phoneNumber,
          session: mfa.session,
        };
        setLoadingMfaStep1(true);
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier.current);
        setLoadingMfaStep1(false);
        updateMfa({ enrollStep: 2, verificationId });
      } catch (error: any) {
        if (error.code == 'auth/requires-recent-login') {
          setIsMfaEnrollModalOpen(false);
          setIsReauthenticateModalOpen(true);
          return;
        }
        setErrorCode(error.code);
        setIsErrorModalOpen(true);
        (recaptchaVerifier.current as any).reset();
      }
    };
  }, [mfa, recaptchaVerifier.current]);

  const validateCode = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const credentials = phoneAuthCredential(mfa.verificationId, mfa.verificationCode);
    const assertion = multiFactorAssertion(credentials);
    setLoadingMfaStep2(true);
    mfaEnroll(assertion)
      .then(() => {
        updateMfa({ enrollStep: 3 });
      })
      .catch((error) => {
        setErrorCode(error.code);
        setIsErrorModalOpen(true);
      })
      .finally(() => setLoadingMfaStep2(false));
  };

  const onReauthenticateSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    runReauthenticate();
  };

  const runReauthenticate = () => {
    reauthenticate(confirmationPassword)
      .then(() => {
        setIsReauthenticateModalOpen(false);
        if (reauthenticationCallback.current) {
          reauthenticationCallback.current();
        } else {
          // const url = new URL(window.location.href);
          // url.searchParams.set('mfaEnroll', '1');
          // window.location.assign(url.search);
        }
      })
      .catch((error) => {
        setErrorCode(error.code);
        setIsReauthenticateModalOpen(false);
        setIsErrorModalOpen(true);
      });
  };

  useEffect(() => {
    if (currentUser) checkEmail(currentUser.email).then(setSignInMethods);
  }, []);

  return (
    <>
      <Title>2FA</Title>

      <div style={{ marginBottom: '72px' }}>
        <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
          <Topbar />
        </div>
      </div>

      <Container className={'my-5'}>
        <Card bg={'dark'}>
          <Card.Body>
            <Card.Title>Autenticazione a due fattori</Card.Title>
            <p>
              L'autenticazione a due fattori rappresenta un livello di sicurezza aggiuntivo per proteggere il tuo account.
              <br />
              Ogni tentativo di accesso dovrà essere confermato con un codice OTP che riceverai via SMS.
            </p>

            {!!currentUser.multiFactor.enrolledFactors.length ? (
              <>
                <span>
                  <FontAwesomeIcon icon={faLock} fixedWidth={true} className={'mr-2'} color={'#3393d0'} />
                  L'autenticazione a due fattori è attiva sul numero che termina con{' '}
                  <strong>***{(currentUser.multiFactor.enrolledFactors[0] as any).phoneNumber.slice(-4)}</strong>
                </span>
              </>
            ) : (
              <>
                <span>
                  <FontAwesomeIcon icon={faLockOpen} fixedWidth={true} className={'mr-2'} color={'#ffc107'} />
                  L'autenticazione a due fattori non è attualmente attiva.
                </span>
              </>
            )}
          </Card.Body>
          <Card.Footer className={'d-flex justify-content-end'}>
            <ButtonGroup size={'sm'}>
              {!!currentUser.multiFactor.enrolledFactors.length ? (
                <Button
                  variant={'danger'}
                  onClick={() => {
                    setIsMfaUnenrollModalOpen(true);
                  }}
                >
                  Disattiva
                </Button>
              ) : (
                <Button
                  variant={'primary'}
                  onClick={() => {
                    startMfaEnroll();
                  }}
                >
                  Attiva
                </Button>
              )}
            </ButtonGroup>
          </Card.Footer>
        </Card>
      </Container>

      <Footer />

      <Modal centered={isNative} show={isReauthenticateModalOpen} onHide={() => setIsReauthenticateModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>Necessario accesso</Modal.Title>
        </Modal.Header>
        <Form onSubmit={onReauthenticateSubmit}>
          <Modal.Body>
            <Form.Group>
              {signInMethods.includes('password') && (
                <>
                  <Form.Label htmlFor={'password'}>Per procedere è necessario confermare la password attuale.</Form.Label>
                  <InputGroup>
                    <Form.Control
                      required
                      size={'lg'}
                      type={confirmationPasswordVisible ? 'text' : 'password'}
                      id={'password'}
                      value={confirmationPassword}
                      onChange={(e) => setConfirmationPassword(e.target.value)}
                    />
                    <InputGroup.Append>
                      <Button variant={'secondary'} onClick={() => setConfirmationPasswordVisible((previousState) => !previousState)}>
                        <FontAwesomeIcon icon={confirmationPasswordVisible ? faEyeSlash : faEye} fixedWidth={true} />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </>
              )}
              {!signInMethods.includes('password') && (
                <>
                  <Form.Label>Per procedere è necessario effettuare nuovamente l'accesso.</Form.Label>
                  <LoginWithGoogle
                    block={true}
                    onAuthorize={() => {
                      setIsReauthenticateModalOpen(false);
                      window.location.reload();
                    }}
                    reauth={true}
                  />
                </>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={'secondary'} onClick={() => setIsReauthenticateModalOpen(false)}>
              Annulla
            </Button>
            {signInMethods.includes('password') && (
              <Button variant={'primary'} type={'submit'}>
                Conferma
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal centered={isNative} show={isMfaEnrollModalOpen} onHide={() => setIsMfaEnrollModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>Impostazione autenticazione a due fattori</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>+39</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  placeholder={'Inserisci il tuo numero di cellulare'}
                  type={'tel'}
                  required
                  disabled={mfa.enrollStep > 1 || loadingMfaStep1}
                  value={mfa.phoneNumber}
                  onChange={(e) => updateMfa({ phoneNumber: e.target.value })}
                />
                <InputGroup.Append>
                  <Button disabled={mfa.enrollStep > 1 || loadingMfaStep1} type={'submit'} variant={'primary'} id={'confirm-phone-btn'}>
                    {loadingMfaStep1 ? <span>Attendi&hellip;</span> : <span>Conferma</span>}
                  </Button>
                </InputGroup.Append>
              </InputGroup>
              <Form.Text className="text-muted">Riceverai un SMS a questo numero. Si applicano le tariffe standard.</Form.Text>
            </Form.Group>
          </Form>

          {mfa.enrollStep > 1 && (
            <Form onSubmit={validateCode}>
              <Form.Group>
                <Form.Label>Ti abbiamo inviato un SMS con un codice.</Form.Label>
                <InputGroup>
                  <Form.Control
                    placeholder={'Inserisci il codice'}
                    type={'tel'}
                    required
                    disabled={mfa.enrollStep > 2 || loadingMfaStep2}
                    value={mfa.verificationCode}
                    onChange={(e) => updateMfa({ verificationCode: e.target.value })}
                  />
                  <InputGroup.Append>
                    <Button disabled={mfa.enrollStep > 2 || loadingMfaStep2} type={'submit'} variant={'primary'}>
                      {loadingMfaStep2 ? <span>Attendi&hellip;</span> : <span>Conferma</span>}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Form>
          )}

          {mfa.enrollStep > 2 && <span>Operazione completata con successo!</span>}
        </Modal.Body>
        <Modal.Footer>
          {mfa.enrollStep <= 2 && (
            <Button variant={'secondary'} onClick={() => setIsMfaEnrollModalOpen(false)}>
              Annulla
            </Button>
          )}
          {mfa.enrollStep == 3 && (
            <Button variant={'primary'} onClick={() => setIsMfaEnrollModalOpen(false)}>
              Chiudi
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal centered={isNative} show={isMfaUnenrollModalOpen} onHide={() => setIsMfaUnenrollModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>Disattivazione autenticazione a due fattori</Modal.Title>
        </Modal.Header>
        <Modal.Body>Effettuando questa operazione, disattiverai l'autenticazione a due fattori su questo account.</Modal.Body>
        <Modal.Footer>
          <Button variant={'secondary'} onClick={() => setIsMfaUnenrollModalOpen(false)}>
            Annulla
          </Button>
          <Button
            variant={'danger'}
            onClick={() => {
              runUnenrollMfa(currentUser.multiFactor.enrolledFactors[0].uid);
            }}
          >
            Conferma
          </Button>
        </Modal.Footer>
      </Modal>

      <ErrorModal isModalOpen={isErrorModalOpen} setIsModalOpen={setIsErrorModalOpen} errorCode={errorCode} />
    </>
  );
};

export { TwoFAComponent as TwoFA };
