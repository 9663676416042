import { TFunction } from 'i18next';

export default {
  Locked: {
    Past: (t: TFunction) => t('adventCalendar.labels.sorpresaNonSbloccata'),
    Today: (t: TFunction) => t('adventCalendar.labels.sbloccaSubitoSorpresa'),
    Future: (t: TFunction, daysFuture: number) =>
      daysFuture == 1 ? t('adventCalendar.labels.tornaDomani') : t('adventCalendar.labels.tornaTra', { daysFuture }),
  },
  Unlocked: {
    Past: (t: TFunction, inCell: boolean) => (inCell ? t('adventCalendar.labels.cliccaPerVisualizzare') : t('adventCalendar.labels.sorpresaSbloccata')),
    Today: (t: TFunction, today: number) => (today == 25 ? t('adventCalendar.labels.haiSbloccatoBuonNatale') : t('adventCalendar.labels.haiSbloccatoTorna')),
  },
};
