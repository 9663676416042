import { CSSProperties, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { randomBetween, SECONDS } from '../../utils/utils';
import { useDetectChanges } from '../../../hooks/useDetectChanges';

const VideoWatermarkComponent = ({ children, onTamper = () => {}, withPip = false }) => {
  const outlineRef = useRef<HTMLDivElement>();
  const watermarkRef = useRef<HTMLDivElement>();

  const { windowWidth, windowHeight } = useWindowSize();

  const bodyObserver = useRef<MutationObserver>(null);
  const watermarkObserver = useRef<MutationObserver>(null);
  const outlineObserver = useRef<MutationObserver>(null);

  const calculatedBoundingClientRect = useRef<{ top: number; left: number; width: number; height: number }>(null);

  const [watermarkPosition, setWatermarkPosition] = useState<[col: number, row: number]>([0, 0]);

  const getGridArea = (col, row) => `${row} / ${col} / ${row} / ${col}`;

  const watermarkStyles: CSSProperties = {
    color: 'rgba(255, 255, 255, 0.8)',
    userSelect: 'none',
    fontFamily: "'Nanum Gothic Coding', monospace",
    padding: '4px 6px',
    paddingBottom: '4px',
    paddingTop: '4px',
    paddingLeft: '6px',
    paddingRight: '6px',
    borderRadius: '2px',
    textRendering: 'geometricPrecision',
    pointerEvents: 'none',
    width: 'min-content',
    height: 'min-content',
    display: 'flex',
    alignItems: 'center',
  };

  const watermarkLogoStyles: CSSProperties = {
    filter: 'brightness(10) grayscale(1)',
    height: '1em',
    marginRight: '0.5em',
    opacity: 0.8,
  };

  const outlineStyles: CSSProperties = {
    position: 'absolute',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    display: 'grid',
    gridTemplateColumns: '',
    gridTemplateRows: '',
  };

  const parseElementStyles = (element: HTMLElement): { [p: string]: any } => {
    return Object.fromEntries(Object.entries(element.style).filter(([k, v]) => !!v && isNaN(+k) && v != 'initial' && !k.startsWith('webkit')));
  };

  const detectTamper = (observer: MutationObserver) => {
    observer.disconnect();
    onTamper();
  };

  const cleanupObservers = () => {
    watermarkObserver.current?.disconnect?.();
    outlineObserver.current?.disconnect?.();
    bodyObserver.current?.disconnect?.();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const numberOfColumns = Math.floor(outlineRef.current.clientWidth / watermarkRef.current.clientWidth);
      const numberOfRows = Math.floor(outlineRef.current.clientHeight / watermarkRef.current.clientHeight);

      const availableColumns = [1, numberOfColumns];
      const availableRows = [1, 2, 3, numberOfRows - 3, numberOfRows - 4, numberOfRows - 5];

      const [col, row] = (() => {
        const MAX_ITERATIONS = 1000;
        let currentIteration = 0;
        while (true) {
          const randomColumn = randomBetween(1, numberOfColumns);
          const randomRow = randomBetween(1, numberOfRows);

          if (availableColumns.includes(randomColumn) || availableRows.includes(randomRow) || currentIteration >= MAX_ITERATIONS) {
            return [randomColumn, randomRow];
          }

          currentIteration++;
        }
      })();

      watermarkRef.current.style.gridArea = getGridArea(col, row);

      cleanupObservers();
      setWatermarkPosition([col, row]);
    }, SECONDS(10));

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const observerConfig: MutationObserverInit = {
      attributes: true,
      attributeOldValue: true,
      childList: true,
      subtree: true,
      characterData: true,
      characterDataOldValue: true,
    };

    watermarkObserver.current?.disconnect?.();
    watermarkObserver.current = new MutationObserver((mutationsList) => {
      for (const { type } of mutationsList) {
        if (type != 'attributes') return detectTamper(watermarkObserver.current);
        const parsedElementStyles = parseElementStyles(watermarkRef.current);

        for (const [key, value] of Object.entries(parsedElementStyles)) {
          if (!['grid-area'].includes(key)) continue;

          if (key == 'grid-area' && value != getGridArea(...watermarkPosition)) {
            return detectTamper(watermarkObserver.current);
          }
        }
      }
    });
    watermarkObserver.current.observe(watermarkRef.current, observerConfig);

    outlineObserver.current?.disconnect?.();
    outlineObserver.current = new MutationObserver((mutationsList) => {
      for (const { type } of mutationsList) {
        if (type == 'attributes') {
          const parsedElementStyles = parseElementStyles(outlineRef.current);
          const elementAllowedCssProperties = new Set(Object.keys(outlineStyles));
          if (Object.keys(parsedElementStyles).some((key) => !elementAllowedCssProperties.has(key))) {
            return detectTamper(outlineObserver.current);
          }
          for (const [key, value] of Object.entries(parsedElementStyles)) {
            if (['top', 'left', 'width', 'height'].includes(key)) {
              if (parseInt(value) != calculatedBoundingClientRect.current[key]) {
                return detectTamper(watermarkObserver.current);
              }
            } else if (['gridTemplateColumns', 'gridTemplateRows'].includes(key)) {
              if (
                (key == 'gridTemplateColumns' && value != `repeat(auto-fill, minmax(${watermarkRef.current.clientWidth}, 1fr))`) ||
                (key == 'gridTemplateRows' && value != `repeat(auto-fill, minmax(${watermarkRef.current.clientHeight}, 1fr))`)
              ) {
                // return detectTamper(watermarkObserver.current);
              }
            } else {
              const originalValue = 'string' == typeof outlineStyles[key] ? outlineStyles[key].toLowerCase() : outlineStyles[key];
              const normalizedValue = value.replace(/"/g, "'").toLowerCase();
              if (originalValue != normalizedValue) {
                return detectTamper(watermarkObserver.current);
              }
            }
          }
        }
      }
    });
    outlineObserver.current.observe(outlineRef.current, observerConfig);

    bodyObserver.current?.disconnect?.();
    bodyObserver.current = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        for (const removedNode of Array.from(mutation.removedNodes)) {
          if (removedNode instanceof HTMLDivElement) {
            if ([watermarkRef.current, outlineRef.current].includes(removedNode)) {
              return detectTamper(bodyObserver.current);
            }
          }
        }
      }
    });
    bodyObserver.current.observe(document.querySelector('body'), { subtree: true, childList: true });

    return cleanupObservers;
  }, [watermarkPosition]);

  useLayoutEffect(() => {
    if (!outlineRef.current) return;
    const RATIO_16_9 = 1.777777;
    const isBiggerThan16by9 = window.innerWidth / window.innerHeight >= RATIO_16_9;
    if (isBiggerThan16by9) {
      const totalBoxHeight = window.innerHeight | 0;
      const totalBoxWidth = Math.ceil(totalBoxHeight * RATIO_16_9);
      const halfWindowWidth = (window.innerWidth * 0.5) | 0;
      const left = halfWindowWidth - ((totalBoxWidth * 0.5) | 0);
      calculatedBoundingClientRect.current = { top: 0, left, width: totalBoxWidth, height: totalBoxHeight };
      outlineRef.current.style.height = `${totalBoxHeight}px`;
      outlineRef.current.style.width = `${totalBoxWidth}px`;
      outlineRef.current.style.top = '0';
      outlineRef.current.style.left = `${left}px`;
    } else {
      const totalBoxWidth = window.innerWidth | 0;
      const totalBoxHeight = Math.ceil(totalBoxWidth / RATIO_16_9);
      const halfWindowHeight = (window.innerHeight * 0.5) | 0;
      const top = halfWindowHeight - ((totalBoxHeight * 0.5) | 0);
      calculatedBoundingClientRect.current = { top, left: 0, width: totalBoxWidth, height: totalBoxHeight };
      outlineRef.current.style.width = `${totalBoxWidth}px`;
      outlineRef.current.style.height = `${totalBoxHeight}px`;
      outlineRef.current.style.top = `${top}px`;
      outlineRef.current.style.left = '0';
    }
    outlineRef.current.style.gridTemplateColumns = `repeat(auto-fill, minmax(${watermarkRef.current.clientWidth}px, 1fr))`;
    outlineRef.current.style.gridTemplateRows = `repeat(auto-fill, minmax(${watermarkRef.current.clientHeight}px, 1fr))`;
  }, [windowWidth, windowHeight, outlineRef.current, watermarkRef.current]);

  return (
    <div style={outlineStyles} ref={outlineRef}>
      <div ref={watermarkRef} style={{ ...watermarkStyles, gridArea: getGridArea(...watermarkPosition) }}>
        <img src={process.env.PUBLIC_URL + '/icon.png'} alt={null} style={watermarkLogoStyles} />
        {children}
      </div>
    </div>
  );
};

export { VideoWatermarkComponent as VideoWatermark };
