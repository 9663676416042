import { Title } from '../../components/Title/Title';
import { Topbar } from '../../components/Topbar/Topbar';
import { Badge, Button, Card, Container, Modal, ModalBody } from 'react-bootstrap';
import { Footer } from '../../components/Footer/Footer';
import './Shop.scss';
import { useEffect, useState } from 'react';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useStripe } from '@stripe/react-stripe-js';
import { useQuery } from '../../../hooks/useQuery';
import { useCurrentLanguage } from '../../../hooks/useCurrentLanguage';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { canAccessFeature } from '../../utils/utils';

const nf = new Intl.NumberFormat(undefined, { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 });

const ShopComponent = () => {
  const stripe = useStripe();
  const { database, buyShop, staticData, userDataFmt } = useFirebase();
  const { currentLanguage } = useCurrentLanguage();
  const { t } = useTranslation();

  const { sid } = useQuery();
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<any[]>(null);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productDetailModalOpen, setProductDetailModalOpen] = useState(false);

  useEffect(() => {
    database
      .collection('plans')
      .where('metadata.SHOP', '==', 'yes')
      .where('metadata.VISIBLE', '==', 'yes')
      .orderBy('metadata.ORDER', 'asc')
      .get()
      .then((collection) => {
        return Promise.all(
          collection.docs.map<any>(async (doc) => {
            const price = (await doc.ref.collection('prices').where('active', '==', true).get()).docs[0];
            return {
              id: doc.id,
              ...doc.data(),
              price: { id: price.id, ...price.data() },
            };
          })
        );
      })
      .then((docs) => {
        docs.sort((a, b) => +(b.metadata.FEATURED == 'yes') - +(a.metadata.FEATURED == 'yes'));
        setProducts(docs.map((doc) => ({ ...doc, metadata: { ...doc.metadata, CONTENT: doc.metadata.CONTENT?.split('\n') } })));
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (sid) setSuccessModalOpen(true);
  }, []);

  const getLocalizedProperty = (item: any, prop: string) => {
    try {
      return item.metadata?.[`i18n.${prop}.${currentLanguage}`] || item[prop];
    } catch {
      return item[prop];
    }
  };

  const getProductMoreInfo = (product: any) => {
    const allKeys = Object.keys(product.metadata).filter((key) => key.startsWith('i18n') && key.includes('MORE_INFO'));
    const moreInfoKeys = Array.from(new Set(allKeys.map((t) => t.split('.')[1]))).sort();
    return moreInfoKeys.map((key) => getLocalizedProperty(product, key));
  };

  if (!staticData.LOADED || !userDataFmt) return <FullscreenLoader />;
  if (!canAccessFeature('SHOP', currentLanguage, staticData, userDataFmt)) {
    return <Redirect to={'/browse'} />;
  }

  if (!products || loading) return <FullscreenLoader />;

  return (
    <>
      <Title>Shop</Title>

      <div style={{ marginBottom: '72px' }}>
        <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
          <Topbar />
        </div>
      </div>

      <Container className={'my-5'}>
        <div className={'d-flex align-items-center justify-content-center w-100 mx-auto mb-3'}>
          <img className={'w-100'} src="https://d260e0gu5nbefz.cloudfront.net/gaming/banners/banner_shop.jpg" />
        </div>

        <div className={'shop-grid'}>
          {products.map((product) => (
            <Card bg={'dark'} key={product.id}>
              <Card.Img
                variant={'top'}
                src={product.images[0]}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedProduct(product);
                  setProductDetailModalOpen(true);
                }}
              />
              <div className="badge-featured">
                <div>
                  {product.metadata.FEATURED && (
                    <Badge variant={'warning'}>
                      <FontAwesomeIcon icon={faStar} /> <span>{t('shop.inVetrina')}</span>
                    </Badge>
                  )}
                </div>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{ cursor: 'pointer', color: '#5a5a5a' }}
                  size={'lg'}
                  onClick={() => {
                    setSelectedProduct(product);
                    setProductDetailModalOpen(true);
                  }}
                />
              </div>
              <Card.Body>
                <Card.Title>{getLocalizedProperty(product, 'name')}</Card.Title>
                <Card.Text>{getLocalizedProperty(product, 'description')}</Card.Text>
                <Card.Text className={'small'}>
                  {!!getLocalizedProperty(product, 'content') && (
                    <>
                      <div className={'text-secondary font-weight-bold'}>{t('shop.content').toUpperCase()}</div>
                      {getLocalizedProperty(product, 'content')
                        .split('\n')
                        .map((content: string, i: number) => (
                          <span key={i.toString()} style={{ display: 'block' }}>
                            &bull; {content}
                          </span>
                        ))}
                    </>
                  )}
                </Card.Text>
              </Card.Body>
              <Card.Footer className={'d-flex align-items-center justify-content-between'}>
                <span className={product.metadata.FULL_PRICE ? 'text-primary-bright' : ''}>€ {nf.format(product.price.unit_amount / 100)}</span>
                {!!product.metadata.FULL_PRICE && (
                  <small className={'ml-2 mr-auto text-muted'}>
                    <del>€ {nf.format(product.metadata.FULL_PRICE / 100)}</del>
                  </small>
                )}
                <Button
                  size={'sm'}
                  variant={product.metadata.BUYABLE === 'yes' ? 'primary' : 'secondary'}
                  disabled={product.metadata.BUYABLE != 'yes'}
                  onClick={() => {
                    if (product.metadata.BUYABLE != 'yes') return;
                    setLoading(true);
                    buyShop(product.price.id)
                      .then(({ id }) => stripe.redirectToCheckout({ sessionId: id }))
                      .catch(console.error)
                      .finally(() => setLoading(false));
                  }}
                >
                  {product.metadata.BUYABLE == 'yes' ? t('shop.acquista') : t('shop.nonDisponibile')}
                </Button>
              </Card.Footer>
            </Card>
          ))}
        </div>
      </Container>

      <Footer />

      <Modal centered show={successModalOpen} onHide={() => setSuccessModalOpen(false)} backdrop={'static'} keyboard={false}>
        <Modal.Body>
          <Modal.Title className={'text-success mb-2'}>{t('shop.acquistoSuccesso')}</Modal.Title>
          {/*<div className={'mb-2'}>{t('giftDetails.labels.acquistoEffettuatoDescrizione')}</div>*/}
          {/*<div>{t('giftDetails.labels.acquistoRiepilogo')}</div>*/}
          <div className={'d-flex justify-content-start mt-3'}>
            <Button variant={'outline-dark'} onClick={() => setSuccessModalOpen(false)}>
              {t('labels.chiudi')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {productDetailModalOpen && selectedProduct && (
        <Modal
          className={'product-detail-modal dark-modal'}
          centered
          show={productDetailModalOpen && selectedProduct}
          onHide={() => {
            setProductDetailModalOpen(false);
            setSelectedProduct(null);
          }}
          backdrop={'static'}
          keyboard={false}
        >
          <ModalBody>
            <div className={'d-flex'}>
              <div className={'mr-3'}>
                <img src={selectedProduct.images[0]} style={{ maxWidth: '300px' }} />
              </div>
              <div className={'d-flex flex-column flex-grow-1'}>
                <div className="d-flex align-items-center">
                  <Modal.Title className={'flex-grow-1'}>{getLocalizedProperty(selectedProduct, 'name')}</Modal.Title>
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setProductDetailModalOpen(false);
                      setSelectedProduct(null);
                    }}
                  />
                </div>
                <span>€ {nf.format(selectedProduct.price.unit_amount / 100)}</span>
                <div className={'small mt-3'}>
                  {getProductMoreInfo(selectedProduct).map((moreInfo, i) => (
                    <div className={'mt-1'} key={i.toString()}>
                      {moreInfo}
                    </div>
                  ))}
                </div>
                {!!getLocalizedProperty(selectedProduct, 'content') && (
                  <>
                    <div className={'text-secondary font-weight-bold small mt-3'}>{t('shop.content').toUpperCase()}</div>
                    {getLocalizedProperty(selectedProduct, 'content')
                      .split('\n')
                      .map((content: string, i: number) => (
                        <span className={'small'} key={i.toString()} style={{ display: 'block' }}>
                          &bull; {content}
                        </span>
                      ))}
                  </>
                )}
                <div className={'d-flex justify-content-end mt-3'}>
                  <Button
                    className={'mt-3'}
                    size={'sm'}
                    variant={selectedProduct.metadata.BUYABLE === 'yes' ? 'primary' : 'secondary'}
                    disabled={selectedProduct.metadata.BUYABLE != 'yes'}
                    onClick={() => {
                      if (selectedProduct.metadata.BUYABLE != 'yes') return;
                      setLoading(true);
                      buyShop(selectedProduct.price.id)
                        .then(({ id }) => stripe.redirectToCheckout({ sessionId: id }))
                        .catch(console.error)
                        .finally(() => setLoading(false));
                    }}
                  >
                    {selectedProduct.metadata.BUYABLE == 'yes' ? t('shop.acquista') : t('shop.nonDisponibile')}
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default ShopComponent;
