import { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { getLanguageName, iso2to1 } from '../../utils/utils';
import { WithTooltip } from '../WithTooltip/WithTooltip';

const CountryFlagComponent = ({
  language,
  size = '16x12',
  className = '',
  variant = 'light',
  withTooltip = false,
  withText = false,
  onErrorChange = (state: boolean) => {},
  onClick = null,
}) => {
  const [_language, setLanguage] = useState(language);
  const [imageError, setImageError] = useState(false);
  const [languageName, setLanguageName] = useState(null);

  useEffect(() => {
    onErrorChange(imageError);
  }, [imageError]);

  useEffect(() => {
    if (!language) return;
    setImageError(false);
    if (language.length == 3) {
      try {
        setLanguage(iso2to1(language).toLowerCase());
      } catch {
        setLanguage(language.toLowerCase());
      }
    } else setLanguage(language.toLowerCase());
  }, [language]);

  useEffect(() => {
    setLanguageName(getLanguageName(_language));
  }, [_language]);

  if (!language || !_language) return null;

  if (imageError)
    return (
      <Badge className={className} variant={variant}>
        {_language?.toUpperCase()}
      </Badge>
    );

  return (
    <WithTooltip hide={!withTooltip} text={languageName} big={false}>
      <span className={className} style={{ cursor: onClick ? 'pointer' : null }} onClick={() => onClick?.()}>
        <img src={`https://flagcdn.com/${size}/${_language == 'en' ? 'gb' : _language}.png`} alt={_language} onError={() => setImageError(true)} />
        {withText && <span className={'ml-1'}>{languageName}</span>}
      </span>
    </WithTooltip>
  );
};

export { CountryFlagComponent as CountryFlag };
