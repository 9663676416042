import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const HistoryEventsComponent = ({ enabled }) => {
  const history = useHistory();

  useEffect(() => {
    if (enabled) {
      history.listen((location, action) => {
        console.group(action);
        console.log(location.pathname);
        console.log(location.search);
        console.log(location.hash);
        console.groupEnd();
      });
    }
  }, []);

  return null;
};

export { HistoryEventsComponent as HistoryEvents };
