import { useEffect, useRef, useState } from 'react';
import { Placeholder } from '../../components/Placeholder/Placeholder';
import { format } from 'date-fns';
import { Alert, Badge, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { Copyable } from '../../components/Copyable/Copyable';
import { CSVParser } from '../../components/CSVParser/CSVParser';
import { fileToDataUrl, storageUploadTask } from '../../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const AdminCompany = ({ company, reload }) => {
  const input = useRef(null);
  const { getCompanyUsers, updateCompany, currentUser, videoDataStorage } = useFirebase();

  const [logoValid, setLogoValid] = useState(!!company.logoUrl);
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [companyName, setCompanyName] = useState<string>(company.name);
  const [users, setUsers] = useState<{ [p: number]: any[] }>({});
  const [usersFilter, setUsersFilter] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isAddSingleUserModalOpen, setIsAddSingleUserModalOpen] = useState(false);
  const [userEmail, setUserEmail] = useState<string>(null);
  const [invalidateCache, setInvalidateCache] = useState(false);
  const [usersCsvParserOpen, setUsersCsvParserOpen] = useState(false);
  const [newLogo, setNewLogo] = useState<{ file: File; dataUrl: string }>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [showError, setShowError] = useState<{ show: boolean; message?: string }>({ show: false, message: null });
  const [showDeleteError, setShowDeleteError] = useState<{ show: boolean; message?: string }>({ show: false, message: null });
  const [showWarning, setShowWarning] = useState<{ show: boolean; message?: string }>({ show: false, message: null });

  useEffect(() => {
    if (!editMode) {
      setShowSuccess(false);
      setShowError({ show: false, message: null });
      setShowWarning({ show: false, message: null });
      return;
    }
    if (invalidateCache) {
      setUsers({});
      setCurrentPage(0);
      setInvalidateCache(false);
    }
    if (!!users[currentPage]) return;
    fetchCompanyUsers();
  }, [editMode, currentPage, invalidateCache]);

  useEffect(() => {
    setLoading(true);
    setInvalidateCache(true);
  }, [usersFilter]);

  const fetchCompanyUsers = () => {
    setLoading(true);
    getCompanyUsers(company.id, currentPage, usersFilter ?? null)
      .then((u) => setUsers((prev) => ({ ...prev, [currentPage]: u })))
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    const dataUrl = await fileToDataUrl(file);
    if (dataUrl) setNewLogo({ file, dataUrl });
  };

  const updateCompanyInfo = async () => {
    const data = ((): { [p: string]: any } => {
      if (newLogo) return { name: companyName, logo: newLogo.file.name.replace(/\s+/g, '_') };
      return { name: companyName };
    })();
    if (data.logo) {
      setUploadProgress(0);
      await storageUploadTask({
        item: newLogo.file,
        storage: videoDataStorage,
        currentUser,
        path: `companies_data/${company.id}/${data.logo}`,
        onProgress: setUploadProgress,
      });
      setUploadProgress(null);
    }
    updateCompany({ companyData: data }, company.id)
      .then(() => {
        reload();
        setNewLogo(null);
        setShowSuccess(true);
      })
      .catch((err) => {
        console.error(err);
        setShowError({ show: true });
      });
  };

  const deleteCompany = () => {
    return new Promise<void>((resolve, reject) => {
      setLoading(true);
      updateCompany({ deleteCompany: true }, company.id)
        .then(({ deleteErrors }) => {
          if (deleteErrors) {
            setShowDeleteError({ show: true, message: deleteErrors.join('\n') });
            reject();
          } else {
            setShowDeleteSuccess(true);
            reload();
            resolve();
          }
        })
        .catch((err) => {
          console.error(err);
          setShowDeleteError({ show: true });
          reject();
        })
        .finally(() => setLoading(false));
    });
  };

  return (
    <>
      <div className={'d-flex align-items-center'} style={{ gap: '2rem' }}>
        {logoValid ? (
          <img src={company.logoUrl} alt={''} onError={() => setLogoValid(false)} width={134} height={69} />
        ) : (
          <Placeholder width={134} height={69} />
        )}
        <div className={'d-flex flex-column'}>
          <div className={'d-flex align-items-center'} style={{ gap: '1rem' }}>
            <h5 className={'m-0'}>{company.name}</h5>
            <Badge variant={company.enabled ? 'success' : 'secondary'}>{company.enabled ? 'Attiva' : 'Non attiva'}</Badge>
          </div>
          <span className={'text-muted'}>Creata il: {format(new Date(company.createdAt._seconds * 1000), 'dd MMMM yyyy HH:mm')}</span>
          <span className={'text-muted'}>Ultima modifica: {format(new Date(company.updatedAt._seconds * 1000), 'dd MMMM yyyy HH:mm')}</span>
          <Copyable text={company.id} />
        </div>
        <div className={'ml-auto d-flex align-items-center'} style={{ gap: '1rem' }}>
          <Button
            variant={company.enabled ? 'secondary' : 'success'}
            onClick={() =>
              updateCompany({ enabled: !company.enabled }, company.id)
                .then(() => {
                  reload();
                  setShowSuccess(true);
                })
                .catch((err) => {
                  console.error(err);
                  setShowError({ show: true });
                })
            }
          >
            {company.enabled ? 'Disattiva' : 'Attiva'}
          </Button>
          <Button variant={'primary'} onClick={() => setEditMode(true)}>
            Modifica
          </Button>
          {/* <Button
            variant={'danger'}
            onClick={() => {
              setShowDeleteError({ show: false });
              setShowDeleteSuccess(false);
              setDeleteMode(true);
            }}
          >
            Elimina
          </Button> */}
        </div>
      </div>

      {editMode && (
        <Modal size={'lg'} show={editMode} onHide={() => setEditMode(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Pannello di gestione azienda</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showSuccess && (
              <Alert variant={'success'} dismissible onClose={() => setShowSuccess(false)}>
                Modifica effettuata correttamente
              </Alert>
            )}
            {showError.show && (
              <Alert variant={'danger'} dismissible onClose={() => setShowError({ show: false })}>
                {showError.message ? showError.message : 'Si è verificato un errore. Riprova'}
              </Alert>
            )}
            {showWarning.show && (
              <Alert variant={'warning'} dismissible onClose={() => setShowWarning({ show: false })} style={{ whiteSpace: 'break-spaces' }}>
                {showWarning.message ? showWarning.message : 'Si è verificato un errore. Riprova'}
              </Alert>
            )}
            <div className={'d-flex align-items-start'} style={{ gap: '2rem' }}>
              <div className={'d-flex flex-column'} style={{ gap: '1rem' }}>
                {logoValid || newLogo ? (
                  <img src={!!newLogo ? newLogo.dataUrl : company.logoUrl} alt={''} width={134} height={69} />
                ) : (
                  <Placeholder width={134} height={69} />
                )}
                <div className={'d-flex'}>
                  <Button size={'sm'} variant={'primary'} className={'w-100 mr-2'} onClick={() => input.current.click()}>
                    {logoValid ? 'Cambia logo' : 'Aggiungi logo'}
                  </Button>
                  {!!newLogo && (
                    <Button size={'sm'} variant={'danger'} onClick={() => setNewLogo(null)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  )}
                  <input type={'file'} className={'d-none'} ref={input} accept={'image/png, image/jpg, image/jpeg'} onChange={handleImageUpload} />
                </div>
              </div>
              <Form.Group style={{ flex: 1 }}>
                <Form.Label>Nome azienda</Form.Label>
                <Form.Control type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
              </Form.Group>
            </div>
            <div className={'mb-5 w-100 d-flex justify-content-end'}>
              <Button size={'sm'} onClick={updateCompanyInfo}>
                {uploadProgress != null ? `Salvataggio al ${uploadProgress}%` : 'Salva'}
              </Button>
            </div>

            <div className={'w-100 d-flex align-items-center justify-content-between mb-4'}>
              <InputGroup style={{ flex: 1 }}>
                <Form.Control type="text" value={usersFilter} placeholder="Ricerca utente per email" onChange={(e) => setUsersFilter(e.target.value)} />
              </InputGroup>
              <InputGroup.Append>
                <Button
                  onClick={() => {
                    setUsersFilter('');
                  }}
                  disabled={loading}
                >
                  Reset
                </Button>
              </InputGroup.Append>
              <Button variant="outline-primary" className={'m-2'} onClick={() => setUsersCsvParserOpen(true)} disabled={loading}>
                Aggiungi utenti da csv
              </Button>
              <Button onClick={() => setIsAddSingleUserModalOpen(true)} disabled={loading}>
                Aggiungi utente
              </Button>
            </div>

            {loading && <FullscreenLoader fullscreen={false} />}

            {!loading && users[currentPage]?.length == 0 && (
              <div className={'w-100 d-flex align-items-center justify-content-center'}>
                Non ci sono {currentPage == 0 ? '' : 'altri'} utenti da visualizzare
              </div>
            )}

            {!loading && !!users[currentPage] && (
              <>
                {users[currentPage].map((user) => (
                  <div className={'d-flex align-items-center mb-3'} key={user.uid}>
                    <span>{user.email}</span>
                    <Button
                      size={'sm'}
                      variant={'danger'}
                      className={'ml-auto'}
                      onClick={() =>
                        updateCompany({ userToDelete: user.uid }, company.id)
                          .then(() => {
                            setInvalidateCache(true);
                            setShowSuccess(true);
                          })
                          .catch((err) => {
                            console.error(err);
                            setShowError({ show: true });
                          })
                      }
                    >
                      Rimuovi
                    </Button>
                  </div>
                ))}
                <div className={'w-100 d-flex align-items-center justify-content-end mt-4'} style={{ gap: '1rem' }}>
                  <Button size={'sm'} disabled={currentPage === 0} onClick={() => setCurrentPage(currentPage - 1)}>
                    Precedente
                  </Button>
                  <Button size={'sm'} disabled={users[currentPage].length < 1} onClick={() => setCurrentPage(currentPage + 1)}>
                    Successiva
                  </Button>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>
      )}

      {isAddSingleUserModalOpen && (
        <Modal show={isAddSingleUserModalOpen} onHide={() => setIsAddSingleUserModalOpen(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Aggiungi nuovo utente all'azienda {company.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group style={{ flex: 1 }}>
              <Form.Label>Email Utente</Form.Label>
              <Form.Control type="text" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} />
              <small className={'w-100 d-flex justify-content-end'}>
                Stai cercando la tua email? <Copyable text={currentUser.email} />
              </small>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <div className={'w-100 d-flex justify-content-end'}>
              <Button
                size={'sm'}
                variant="outline-secondary"
                className={'mr-2'}
                onClick={() => {
                  setIsAddSingleUserModalOpen(false);
                  setUserEmail(null);
                }}
              >
                Annulla
              </Button>
              <Button
                size={'sm'}
                onClick={() => {
                  updateCompany({ users: [userEmail] }, company.id)
                    .then(({ duplicated, nonExistent, occupied }) => {
                      setIsAddSingleUserModalOpen(false);
                      setUserEmail(null);
                      if (duplicated.length)
                        setShowWarning({
                          show: true,
                          message: `L'utente è già assegnato a questa azienda e non verrà aggiunto`,
                        });
                      if (nonExistent.length)
                        setShowWarning({
                          show: true,
                          message: `L'utente non è registrato alla piattaforma e non verrà aggiunto`,
                        });
                      if (occupied.length)
                        setShowWarning({
                          show: true,
                          message: `L'utente è già assegnato all'azienda ${occupied[0].companyName} e non verrà aggiunto`,
                        });
                      if (!duplicated.length && !nonExistent.length && !occupied.length) {
                        setShowSuccess(true);
                        setInvalidateCache(true);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                      setShowError({ show: true });
                    });
                }}
              >
                Aggiungi
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {usersCsvParserOpen && (
        <CSVParser
          show={usersCsvParserOpen}
          onHide={() => setUsersCsvParserOpen(false)}
          parser={'users-emails'}
          onRowsReady={(emails) => {
            setLoading(true);
            updateCompany({ users: emails }, company.id)
              .then(({ duplicated, nonExistent, occupied, created }) => {
                let message: string = '';
                if (duplicated.length) {
                  message += `I seguenti utenti sono già assegnati a questa azienda e non verranno aggiunti:\n`;
                  for (const email of duplicated) {
                    message += `• ${email}\n`;
                  }
                }
                if (nonExistent.length) {
                  message += `I seguenti utenti non hanno una mail valida e non verranno aggiunti:\n`;
                  for (const email of nonExistent) {
                    message += `• ${email}\n`;
                  }
                }
                if (occupied.length) {
                  message += `I seguenti utenti sono già assegnati ad un'altra azienda e non verranno aggiunti:\n`;
                  for (const user of occupied) {
                    message += `• ${user.email} (${user.companyName})\n`;
                  }
                }
                if (created.length) {
                  message += `I seguenti utenti sono stati REGISTRATI alla piattaforma:\n`;
                  for (const user of created) {
                    message += `• ${user.email} (${user.password})\n`;
                  }
                  message += `SALVARE LA PASSWORD SE NECESSARIO, è l'ultima volta che potrà essere visualizzata!`;
                }
                if (message != '') setShowWarning({ show: true, message });
                else setShowSuccess(true);

                setInvalidateCache(true);
              })
              .catch((err) => {
                console.error(err);
                setShowError({ show: true });
              })
              .finally(() => setLoading(false));
          }}
        />
      )}

      <Modal show={deleteMode} onHide={() => setDeleteMode(false)}>
        <Modal.Header>
          <Modal.Title>Conferma eliminazione azienda</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showDeleteSuccess && (
            <Alert variant={'success'} dismissible onClose={() => setShowDeleteSuccess(false)}>
              Azienda eliminata con successo
            </Alert>
          )}
          {showDeleteError.show && (
            <Alert variant={'danger'} dismissible onClose={() => setShowDeleteError({ show: false })}>
              {showDeleteError.message ? showDeleteError.message : 'Si è verificato un errore. Riprova'}
            </Alert>
          )}
          <div className={'text-center mb-2 py-1 rounded'} style={{ backgroundColor: 'gainsboro' }}>
            {company.name}
          </div>
          Confermi la volontà di eliminare questa azienda?
          <br />
          <strong>Ricorda che questa operazione NON PU&Ograve; essere annullata!</strong>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={'secondary'} disabled={loading} onClick={() => setDeleteMode(false)}>
            Annulla
          </Button>
          <Button
            variant={'danger'}
            disabled={loading}
            onClick={
              showDeleteSuccess
                ? () => {
                    setDeleteMode(false);
                  }
                : () => deleteCompany()
            }
          >
            {showDeleteSuccess ? 'Chiudi' : 'Elimina'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminCompany;
