import { Badge, Dropdown, Image } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCommentDots, faFileVideo, faLifeRing, faUser } from '@fortawesome/free-regular-svg-icons';
import {
  faAngleLeft,
  faBug,
  faCalendar,
  faChartLine,
  faCloudUploadAlt,
  faFlask,
  faGift,
  faHandshake,
  faLayerGroup,
  faRetweet,
  faRss,
  faScroll,
  faShieldAlt,
  faShoppingCart,
  faSignOutAlt,
  faStream,
  faTicketAlt,
  faTv,
  faUserCog,
  faVial,
} from '@fortawesome/free-solid-svg-icons';
import { forwardRef, useEffect, useState } from 'react';
import Gravatar from 'react-awesome-gravatar';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { canAccessFeature, generateColorFromArbitraryString } from '../../utils/utils';
import { useCapacitor } from '../../../hooks/useCapacitor';
import { useTranslation } from 'react-i18next';
import { BetaBadge } from '../BetaBadge/BetaBadge';
import { faStripeS } from '@fortawesome/free-brands-svg-icons';
import { Feedback } from '../Feedback/Feedback';
import { When } from 'react-if';
import './ProfileCircle.scss';
import { useCurrentLanguage } from '../../../hooks/useCurrentLanguage';
import { useGaming } from '../../../contexts/GamingContext';
import { GamingRecap } from '../Gaming/GamingRecap/GamingRecap';

const CommonChild = ({ label, icon = null, beta = false, append = null }) => (
  <div className={'d-flex justify-content-between align-items-center'}>
    <span>
      {label} {append} {beta && <BetaBadge />}
    </span>
    {!!icon && <FontAwesomeIcon size={'xs'} icon={icon} className={'text-muted ml-2'} fixedWidth={true} />}
  </div>
);

const NestedDropdownToggle = forwardRef(({ children, onClick }: any, ref) => (
  <a
    className={'dropdown-item'}
    href={''}
    ref={ref as any}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <CommonChild
      label={
        <>
          <FontAwesomeIcon icon={faAngleLeft} className={'text-secondary mr-1'} /> {children}
        </>
      }
    />
  </a>
));

const DropdownItemLink = ({ label, to = null, onClick = null, icon, beta = false, append = null }) => {
  if (to) {
    return (
      <Dropdown.Item as={Link} to={to}>
        <CommonChild label={label} icon={icon} beta={beta} append={append} />
      </Dropdown.Item>
    );
  }

  return (
    <Dropdown.Item onClick={onClick}>
      <CommonChild label={label} icon={icon} beta={beta} append={append} />
    </Dropdown.Item>
  );
};

const ProfileCircleComponent = ({ up = false }) => {
  const { currentUser, userDataFmt, logout, staticData } = useFirebase();
  const { player, isEnabled: isGamingEnabled } = useGaming();
  const [isAdmin, setIsAdmin] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const history = useHistory();
  const { isNative } = useCapacitor();
  const { t } = useTranslation();
  const { currentLanguage } = useCurrentLanguage();

  const handleLogout = async () => {
    history.push('/?logout=1');
    await logout();
    window.location = window.location.pathname as any;
  };

  const handleFeedback = () => {
    setFeedbackModalOpen(true);
  };

  useEffect(() => {
    setIsAdmin(!!currentUser && !!userDataFmt?.admin);
  }, [currentUser, userDataFmt]);

  return (
    <>
      <Dropdown drop={up ? 'up' : 'down'}>
        <Dropdown.Toggle as={GravatarDropdownToggle}>
          {{
            player,
            user: currentUser ?? {},
            ENABLE_GAMING: isGamingEnabled,
          }}
        </Dropdown.Toggle>
        <Dropdown.Menu align={'right'}>
          <Dropdown.Header>{currentUser?.email ?? ''}</Dropdown.Header>
          <DropdownItemLink to={'/profile'} label={t('labels.profilo')} icon={faUser} />
          {canAccessFeature('SHOP', currentLanguage, staticData, userDataFmt) && <DropdownItemLink to={'/shop'} label={'Shop'} icon={faShoppingCart} />}

          {!isNative && staticData.ENABLE_GIFTS && <DropdownItemLink to={'/gift'} label={t('labels.regalaAbbonamento')} icon={faGift} />}
          {!!staticData.SHOW_TV_LOGIN_BUTTON && <DropdownItemLink to={'/tv'} label={t('labels.accediTV')} icon={faTv} />}
          {/* {(isGamingEnabled ||
            staticData.SHOW_BOOKS ||
            userDataFmt?.experiments?.[Experiments.Library] ||
            staticData.SHOW_SHOP ||
            userDataFmt?.experiments?.[Experiments.Shop]) && (
            <>
              <Dropdown.Divider />
              <Dropdown.Header>Ancora più {process.env.REACT_APP_TITLE}</Dropdown.Header>

              {isGamingEnabled && <DropdownItemLink to={'/play'} label={'Gamification'} icon={faGamepad} />}
              {(staticData.SHOW_BOOKS || userDataFmt?.experiments?.[Experiments.Library]) && <DropdownItemLink to={'/books'} label={'Library'} icon={faBook} />}

              <DropdownItemLink onClick={() => window.open('https://blog.tootor.it', '_blank')} label={'Blog'} icon={faRssSquare} />
              <DropdownItemLink to={'/live'} label={process.env.REACT_APP_TITLE + ' Live'} icon={faYoutube} />
            </>
          )} */}
          {isAdmin && (
            <>
              <Dropdown.Divider />
              <Dropdown.Header>Admin tools</Dropdown.Header>

              <Dropdown drop={'left'}>
                <Dropdown.Toggle as={NestedDropdownToggle}>Supporto</Dropdown.Toggle>
                <Dropdown.Menu>
                  <DropdownItemLink to={'/admin/users'} label={'Gestione utenti'} icon={faUserCog} />
                  <DropdownItemLink to={'/admin/tickets'} label={'Tickets'} icon={faTicketAlt} />
                  <DropdownItemLink to={'/admin/reports'} label={'Segnalazioni video'} icon={faBug} />
                  <DropdownItemLink to={'/admin/feedbacks'} label={'Feedbacks'} icon={faCommentDots} />
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown drop={'left'}>
                <Dropdown.Toggle as={NestedDropdownToggle}>Contenuti</Dropdown.Toggle>
                <Dropdown.Menu>
                  <DropdownItemLink to={'/admin/content'} label={'Gestione contenuti'} icon={faFileVideo} />
                  <DropdownItemLink to={'/admin/upload'} label={'Upload'} icon={faCloudUploadAlt} />
                  <DropdownItemLink to={'/admin/publish'} label={'Pubblica video'} icon={faRss} />
                  <DropdownItemLink to={'/admin/replace'} label={'Sostituisci video'} icon={faRetweet} />
                  <DropdownItemLink to={'/admin/coming-soon'} label={'Aggiungi Coming Soon'} icon={faClock} />
                  <DropdownItemLink to={'/admin/stats'} label={'Statistiche'} icon={faChartLine} />
                  <DropdownItemLink to={'/admin/companies'} label={'Gestione aziende'} icon={faHandshake} />
                  <DropdownItemLink to={'/admin/one-to-one'} label={'Sessioni One To One'} icon={faCalendar} />
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown drop={'left'}>
                <Dropdown.Toggle as={NestedDropdownToggle}>Sistema</Dropdown.Toggle>
                <Dropdown.Menu>
                  {/* <DropdownItemLink to={'/admin/notifications'} label={'Notifiche'} icon={faBell} beta /> */}
                  <DropdownItemLink to={'/admin/cache'} label={'Gestione cache'} icon={faLayerGroup} />
                  <DropdownItemLink to={'/admin/realtime'} label={'RWS'} icon={faStream} beta />
                  <DropdownItemLink to={'/admin/logs'} label={'Logs'} icon={faScroll} />
                  <DropdownItemLink
                    to={'/admin/lab'}
                    label={'Lab'}
                    icon={faFlask}
                    append={
                      <Badge variant={Object.values(userDataFmt?.experiments || {}).filter(Boolean).length ? 'success' : 'secondary'}>
                        <FontAwesomeIcon icon={faVial} className={'mr-1'} />
                        {Object.values(userDataFmt?.experiments || {}).filter(Boolean).length}
                      </Badge>
                    }
                  />
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown drop={'left'}>
                <Dropdown.Toggle as={NestedDropdownToggle}>Sicurezza</Dropdown.Toggle>
                <Dropdown.Menu>
                  <DropdownItemLink to={'/admin/2fa'} label={'Autenticazione a due fattori'} icon={faShieldAlt} />
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown drop={'left'}>
                <Dropdown.Toggle as={NestedDropdownToggle}>Statistiche esterne</Dropdown.Toggle>
                <Dropdown.Menu>
                  <DropdownItemLink to={'/admin/stripe-dashboard'} label={'Stripe'} icon={faStripeS} beta />
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
          <Dropdown.Divider />
          {!!staticData.SHOW_FEEDBACK_MENU && !isNative && currentLanguage == 'it' && (
            <DropdownItemLink onClick={handleFeedback} label={'Feedback'} icon={faCommentDots} />
          )}
          <DropdownItemLink to={'/help'} label={t('labels.helpCenter')} icon={faLifeRing} />
          <DropdownItemLink onClick={handleLogout} label={t('labels.logout')} icon={faSignOutAlt} />
          {!!userDataFmt?.alias && (
            <>
              <Dropdown.Divider />
              <Dropdown.Header>
                {t('labels.account')}: {userDataFmt?.alias}
              </Dropdown.Header>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>

      <Feedback show={feedbackModalOpen} onHide={() => setFeedbackModalOpen(false)} />

      <When condition={!!staticData.SHOW_FEEDBACK_MENU && !isNative && currentLanguage == 'it'}>
        <div className={'feedback-fixed-tab d-flex align-items-center py-3 pl-2 pr-3 bg-primary rounded-left'} onClick={handleFeedback}>
          <FontAwesomeIcon icon={faCommentDots} className={'mb-2'} />
          <span>Feedback</span>
        </div>
      </When>
    </>
  );
};

export { ProfileCircleComponent as ProfileCircle };

const GravatarDropdownToggle = forwardRef(({ children, onClick, size = 35 }: any, _) => {
  const [_url, setUrl] = useState(children.user?.photoURL);

  return (
    <Gravatar email={children.user?.email ?? ''} options={{ default: 'mp', size }}>
      {(url) => (
        <div
          className={children.ENABLE_GAMING ? 'gaming-box p-0 rounded d-flex align-items-center py-md-2 px-md-3' : ''}
          style={{ cursor: 'pointer', position: 'relative' }}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {children.ENABLE_GAMING && <GamingRecap className={'mr-3'} />}
          <Image
            src={_url ?? url}
            onError={() => setUrl(url)}
            style={{
              width: `${size}px`,
              height: `${size}px`,
              backgroundColor: generateColorFromArbitraryString(children.user?.email ?? ''),
              // outline: '2px solid #ffc107',
            }}
            roundedCircle
          />
        </div>
      )}
    </Gravatar>
  );
});
