import { Badge, Button, Form, ListGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faMinus, faMinusSquare, faPlus, faPlusSquare, faSquare, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FormEvent, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { formatSecondsTimestamp } from '../../utils/utils';
import { Copyable } from '../Copyable/Copyable';

const AdminNotesComponent = ({
  notes = [],
  path,
  onSubmit = () => {},
  context,
}: {
  notes: firebase.firestore.DocumentData[];
  path: string;
  onSubmit: () => void;
  context: string[];
}) => {
  const { database, fv, userDataFmt } = useFirebase();

  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [positiveNotes, setPositiveNotes] = useState(0);
  const [negativeNotes, setNegativeNotes] = useState(0);
  const [neutralNotes, setNeutralNotes] = useState(0);

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState<{ note: string; rating: number }>({
    note: '',
    rating: 0,
  });

  useEffect(() => {
    setPositiveNotes(notes.filter((note: any) => note.rating == 1).length);
    setNegativeNotes(notes.filter((note: any) => note.rating == -1).length);
    setNeutralNotes(notes.filter((note: any) => note.rating == 0).length);
  }, [notes]);

  const submitNote = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    database
      .collection(path + '/notes')
      .add({ ...formData, created: fv.serverTimestamp(), author: `${userDataFmt.profile.formData.nome} ${userDataFmt.profile.formData.cognome}` })
      .then(() => {
        setFormData({
          note: '',
          rating: 0,
        });
        onSubmit();
      })
      .catch(console.error);
  };

  return (
    <>
      <Button variant={'outline-warning'} onClick={() => setNotesModalOpen(true)}>
        <FontAwesomeIcon icon={faComments} className={'mr-2'} />
        <span>Note</span>
        <Badge className={'ml-2'} variant={!!notes.length ? 'primary' : 'secondary'}>
          {notes.length}
        </Badge>
        {positiveNotes - negativeNotes != 0 && (
          <Badge
            className={'ml-2'}
            variant={(() => {
              const netRating = positiveNotes - negativeNotes;
              if (netRating > 0) return 'success';
              if (netRating < 0) return 'danger';
            })()}
          >
            {positiveNotes - negativeNotes > 0 && <FontAwesomeIcon icon={faPlus} size={'xs'} fixedWidth={true} />}
            {positiveNotes - negativeNotes < 0 && <FontAwesomeIcon icon={faMinus} size={'xs'} fixedWidth={true} />}
            {Math.abs(positiveNotes - negativeNotes)}
          </Badge>
        )}
      </Button>

      <Modal show={notesModalOpen} onHide={() => setNotesModalOpen(false)} scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'small mb-2'}>
            Questa funzionalità serve ad inserire note interne, contestualizzate all'elemento da cui sono state aperte. Le note sono visibili solamente alle
            utenze di amministrazione.
          </div>
          <div className={'small mb-2'}>
            Contesto: <Copyable text={context.join('/')} />
          </div>
          <div>
            Totale: <Badge variant={'primary'}>{notes.length}</Badge> | Positive: <Badge variant={'success'}>{positiveNotes}</Badge> | Neutre:{' '}
            <Badge variant={'secondary'}>{neutralNotes}</Badge> | Negative: <Badge variant={'danger'}>{negativeNotes}</Badge>
          </div>

          {!notes.length && <div className={'mt-3 text-center'}>Nessuna nota in questo contesto.</div>}

          {!!notes.length && (
            <ListGroup className={'mt-3'}>
              {notes.map((note) => (
                <ListGroup.Item key={note.id} variant={'light'}>
                  <small
                    className={(() => {
                      if (note.rating == 1) return 'text-success';
                      if (note.rating == -1) return 'text-danger';
                      return 'text-secondary';
                    })()}
                  >
                    <div className={'d-flex align-items-center'}>
                      <span>{note.author}</span>
                      <small className={'ml-auto text-secondary'}>
                        <Copyable text={note.id} />
                      </small>
                      <div className={'ml-1'}>
                        {formatSecondsTimestamp(note.created._seconds, 'dd/MM/y HH:mm')}
                        <FontAwesomeIcon
                          icon={note.rating ? (note.rating == 1 ? faPlusSquare : faMinusSquare) : faSquare}
                          size={'sm'}
                          className={'ml-1'}
                          fixedWidth={true}
                        />
                      </div>
                    </div>
                  </small>
                  <div className={'text-dark'}>{note.note}</div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Form onSubmit={submitNote} className={'flex-grow-1'}>
            <Form.Group>
              <Form.Control
                as={'textarea'}
                disabled={loading}
                placeholder={'Aggiungi una nota...'}
                name={'note'}
                rows={3}
                required
                value={formData.note}
                onChange={(e) => setFormData((current) => ({ ...current, note: e.target.value }))}
              />
            </Form.Group>
            <div className={'d-flex justify-content-between align-items-center'}>
              <div>
                <Form.Check
                  inline
                  disabled={loading}
                  type={'radio'}
                  label={<span className={'text-success'}>Positivo</span>}
                  id={'rating-positive'}
                  name={'rating'}
                  checked={formData.rating == 1}
                  onChange={() => setFormData((current) => ({ ...current, rating: 1 }))}
                />
                <Form.Check
                  inline
                  disabled={loading}
                  type={'radio'}
                  label={<span className={'text-secondary'}>Neutro</span>}
                  id={'rating-neutral'}
                  name={'rating'}
                  checked={formData.rating == 0}
                  onChange={() => setFormData((current) => ({ ...current, rating: 0 }))}
                />
                <Form.Check
                  inline
                  disabled={loading}
                  type={'radio'}
                  label={<span className={'text-danger'}>Negativo</span>}
                  id={'rating-negative'}
                  name={'rating'}
                  checked={formData.rating == -1}
                  onChange={() => setFormData((current) => ({ ...current, rating: -1 }))}
                />
              </div>
              <Button variant={'primary'} type={'submit'} disabled={loading}>
                Invia
              </Button>
            </div>
          </Form>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { AdminNotesComponent as AdminNotes };
