export const getPlyrControls = (isNative: boolean) => {
  const plyrProgressDefinition = `
        <div class="plyr_progress_wrapper">
          <div class="plyr__progress">
            <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
            <progress class="plyr__progress__buffer" min="0" max="100" value="0">% ${/*t('watch.labels.caricato')*/ ''}</progress>
            <span role="tooltip" class="plyr__tooltip">00:00</span>
          </div>
          <div class="plyr__time plyr__time--current" aria-label="Current time">00:00</div>
          <div class="plyr__time plyr__time--duration" aria-label="Duration">00:00</div>
        </div>`;

  const playPauseButtonDefinition = `
          <button type="button" class="plyr__control" aria-label="Play" data-plyr="play">
            <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-pause"></use></svg>
            <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-play"></use></svg>
<!--            <span class="label&#45;&#45;pressed plyr__tooltip" role="tooltip">${/*t('labels.pausa')*/ ''}</span>-->
<!--            <span class="label&#45;&#45;not-pressed plyr__tooltip" role="tooltip">${/*t('labels.play')*/ ''}</span>-->
          </button>`;

  const rewindButtonDefinition = `
          <button type="button" class="plyr__control" data-plyr="rewind" id="rewind-button">
            <svg version="1.1" id="Livello_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 18 18" style="enable-background:new 0 0 18 18;" xml:space="preserve">
              <style type="text/css">
                .st0{fill:none;stroke:#EFEFEF;stroke-width:1.5;}
                .st1{fill:#EFEFEF;}
                .st2{font-family:'ArialMT';}
                .st3{font-size:7.0052px;}
              </style>
              <path class="st0" d="M2,10.1c0,3.9,3.1,7,7,7s7-3.1,7-7s-3.1-7-7-7H7.6"/>
              <polyline class="st0" points="10.1,0.6 7.6,3 10.1,5.5 "/>
              <polyline class="st0" points="6.9,0.6 4.4,3.1 6.9,5.5 "/>
              <text transform="matrix(1 0 0 1 4.626 12.8779)" class="st1 st2 st3">1</text>
              <text transform="matrix(1 0 0 1 8.7031 12.8779)" class="st1 st2 st3">0</text>
            </svg>
<!--            <span class="plyr__tooltip" role="tooltip">${/*t('watch.labels.indietroDi')*/ ''} {seektime}s</span>-->
          </button>`;

  const ffButtonDefinition = `
          <button type="button" class="plyr__control" data-plyr="fast-forward" id="ff-button">
            <svg version="1.1" id="Livello_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 18 18" style="enable-background:new 0 0 18 18;" xml:space="preserve">
              <style type="text/css">
                .st0{fill:none;stroke:#EFEFEF;stroke-width:1.5;}
                .st1{fill:#EFEFEF;}
                .st2{font-family:'ArialMT';}
                .st3{font-size:7.0052px;}
              </style>
              <path class="st0" d="M10.4,3.1H9c-3.9,0-7,3.1-7,7s3.1,7,7,7s7-3.1,7-7"/>
              <polyline class="st0" points="7.9,5.5 10.4,3 7.9,0.6 "/>
              <polyline class="st0" points="11.1,5.5 13.6,3.1 11.1,0.6 "/>
              <text transform="matrix(1 0 0 1 4.626 12.8779)" class="st1 st2 st3">1</text>
              <text transform="matrix(1 0 0 1 8.7031 12.8779)" class="st1 st2 st3">0</text>
            </svg>
<!--            <span class="plyr__tooltip" role="tooltip">${/*t('watch.labels.avanzaDi')*/ ''} {seektime}s</span>-->
          </button>`;

  const muteButtonDefinition = `
          <button type="button" class="plyr__control" aria-label="Mute" data-plyr="mute">
            <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-muted"></use></svg>
            <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-volume"></use></svg>
<!--            <span class="label&#45;&#45;pressed plyr__tooltip" role="tooltip">Riattiva audio</span>-->
<!--            <span class="label&#45;&#45;not-pressed plyr__tooltip" role="tooltip">Silenzia audio</span>-->
          </button>
          <!-- <div class="plyr__volume">
              <input data-plyr="volume" type="range" min="0" max="1" step="0.05" value="1" autocomplete="off" aria-label="Volume">
          </div> -->`;

  const ratingButtonDefinition = `
          <button type="button" id="rating-button" class="plyr__control">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M466.27 286.69C475.04 271.84 480 256 480 236.85c0-44.015-37.218-85.58-85.82-85.58H357.7c4.92-12.81 8.85-28.13 8.85-46.54C366.55 31.936 328.86 0 271.28 0c-61.607 0-58.093 94.933-71.76 108.6-22.747 22.747-49.615 66.447-68.76 83.4H32c-17.673 0-32 14.327-32 32v240c0 17.673 14.327 32 32 32h64c14.893 0 27.408-10.174 30.978-23.95 44.509 1.001 75.06 39.94 177.802 39.94 7.22 0 15.22.01 22.22.01 77.117 0 111.986-39.423 112.94-95.33 13.319-18.425 20.299-43.122 17.34-66.99 9.854-18.452 13.664-40.343 8.99-62.99zm-61.75 53.83c12.56 21.13 1.26 49.41-13.94 57.57 7.7 48.78-17.608 65.9-53.12 65.9h-37.82c-71.639 0-118.029-37.82-171.64-37.82V240h10.92c28.36 0 67.98-70.89 94.54-97.46 28.36-28.36 18.91-75.63 37.82-94.54 47.27 0 47.27 32.98 47.27 56.73 0 39.17-28.36 56.72-28.36 94.54h103.99c21.11 0 37.73 18.91 37.82 37.82.09 18.9-12.82 37.81-22.27 37.81 13.489 14.555 16.371 45.236-5.21 65.62zM88 432c0 13.255-10.745 24-24 24s-24-10.745-24-24 10.745-24 24-24 24 10.745 24 24z"/>
            </svg>
          </button>`;

  const helpButtonDefinition = `
          <button type="button" id="help-button" class="plyr__control">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="question-circle" class="svg-inline--fa fa-question-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"></path>
            </svg>
<!--            <span class="plyr__tooltip" role="tooltip">Prossimo video</span>-->
          </button>`;

  const nextVideoButtonDefinition = `
          <button type="button" id="next-video-button" class="plyr__control">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="step-forward" class="svg-inline--fa fa-step-forward fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path fill="currentColor" d="M384 44v424c0 6.6-5.4 12-12 12h-48c-6.6 0-12-5.4-12-12V291.6l-195.5 181C95.9 489.7 64 475.4 64 448V64c0-27.4 31.9-41.7 52.5-24.6L312 219.3V44c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12z"></path>
            </svg>
<!--            <span class="plyr__tooltip" role="tooltip">Prossimo video</span>-->
          </button>`;

  const xrayButtonDefinition = `
          <button type="button" id="xray-button" class="plyr__control">
            <div id="xrayBtnWrapper"><svg version="1.1" id="Livello_3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 18 18" style="enable-background:new 0 0 18 18;" xml:space="preserve" class="icon--not-pressed">
              <style type="text/css">
                .st10{fill:#ebebeb;}
              </style>
              <path class="st10" d="M8.4,2.2C8.4,1,7.4,0,6.1,0C4.9,0,3.9,1,3.9,2.2c0,1.1,0.8,2,1.9,2.2v1.8C5.3,6.3,4.9,6.8,4.9,7.3v9.5
                c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2V7.3c0-0.5-0.4-1-0.8-1.1V4.4C7.6,4.2,8.4,3.3,8.4,2.2z M4.9,2.2C4.9,1.6,5.5,1,6.1,1
                s1.2,0.5,1.2,1.2S6.8,3.4,6.1,3.4S4.9,2.9,4.9,2.2z"/>
              <path class="st10" d="M14.1,2.2c0-1.2-1-2.2-2.2-2.2c-0.1,0-0.3,0-0.4,0v1.1c0.1,0,0.2-0.1,0.4-0.1c0.7,0,1.2,0.5,1.2,1.2
                s-0.5,1.2-1.2,1.2c-0.1,0-0.3,0-0.4-0.1v1.1v1.1v0.6c-0.5,0.2-0.8,0.6-0.8,1.1v9.5c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2V7.3
                c0-0.5-0.4-1-0.8-1.1V4.4C13.3,4.2,14.1,3.3,14.1,2.2z"/>
            </svg></div>
<!--            <span class="label&#45;&#45;pressed plyr__tooltip" role="tooltip">Disattiva strumentazione</span>-->
<!--            <span class="label&#45;&#45;not-pressed plyr__tooltip" role="tooltip">Attiva strumentazione</span>-->
<!--            <span class="plyr__tooltip" role="tooltip">Strumentazione</span>-->
          </button>`;

  const tsButtonDefinition = `
          <button type="button" id="timestamp-button" class="plyr__control">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path fill="currentColor" d="M432 304c0 114.9-93.1 208-208 208S16 418.9 16 304c0-104 76.3-190.2 176-205.5V64h-28c-6.6 0-12-5.4-12-12V12c0-6.6 5.4-12 12-12h120c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-28v34.5c37.5 5.8 71.7 21.6 99.7 44.6l27.5-27.5c4.7-4.7 12.3-4.7 17 0l28.3 28.3c4.7 4.7 4.7 12.3 0 17l-29.4 29.4-.6.6C419.7 223.3 432 262.2 432 304zm-176 36V188.5c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12V340c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12z"></path>
            </svg>
<!--            <span class="label&#45;&#45;pressed plyr__tooltip" role="tooltip">Chiudi timestamps</span>-->
<!--            <span class="label&#45;&#45;not-pressed plyr__tooltip" role="tooltip">Apri timestamps</span>-->
<!--            <span class="label&#45;&#45;not-pressed plyr__tooltip" role="tooltip">Timestamps</span>-->
          </button>`;

  const captionsButtonDefinition = `
          <button type="button" class="plyr__control" data-plyr="captions">
            <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-captions-on"></use></svg>
            <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-captions-off"></use></svg>
<!--            <span class="label&#45;&#45;pressed plyr__tooltip" role="tooltip">Disattiva sottotitoli</span>-->
<!--            <span class="label&#45;&#45;not-pressed plyr__tooltip" role="tooltip">Attiva sottotitoli</span>-->
          </button>`;

  const i18nButtonDefinition = `
          <button type="button" class="plyr__control" id="i18n-button" style="position: relative">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="globe" class="svg-inline--fa fa-globe fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
              <path fill="currentColor" d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z"></path>
            </svg>
          </button>`;

  const restartButtonDefinition = `
          <button type="button" class="plyr__control" data-plyr="restart" id="restart-button">
            <svg role="presentation"><use xlink:href="#plyr-restart"></use></svg>
<!--            <span class="plyr__tooltip" role="tooltip">Ricomincia</span>-->
          </button>`;

  const fullscreenButtonDefinition = `
          <button type="button" class="plyr__control" data-plyr="fullscreen" id="fullscreen-button">
            <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-exit-fullscreen"></use></svg>
            <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-enter-fullscreen"></use></svg>
<!--            <span class="label&#45;&#45;pressed plyr__tooltip" role="tooltip">Exit fullscreen</span>-->
<!--            <span class="label&#45;&#45;not-pressed plyr__tooltip" role="tooltip">Enter fullscreen</span>-->
          </button>`;

  return `
      <div class="plyr__controls">
        ${plyrProgressDefinition}
        <div class="play_controls_section" style="align-items: center;">
          ${playPauseButtonDefinition}
          ${rewindButtonDefinition}
          ${ffButtonDefinition}
          ${muteButtonDefinition}
          <div class="spacer" id="currentChapter"></div>
          ${ratingButtonDefinition}
          ${isNative ? '' : helpButtonDefinition}
          ${'' /*nextVideo ? nextVideoButtonDefinition : ''*/}
          ${xrayButtonDefinition}
          ${tsButtonDefinition}
          ${i18nButtonDefinition}
          ${restartButtonDefinition}
          ${isNative ? '' : fullscreenButtonDefinition}
        </div>
      </div>
      `;
};
