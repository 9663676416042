import { createContext, useContext, useEffect, useState } from 'react';
import { FullscreenLoader } from '../app/components/FullscreenLoader/FullscreenLoader';
import { useMobileDetect } from '../hooks/useMobileDetect';

export interface FreeGeoLocalization {
  ip: string;
  country_code: string;
  country_name: string;
  region_code: string;
  region_name: string;
  city: string;
  zip_code: string;
  time_zone: string;
  latitude: number;
  longitude: number;
  metro_code: number;
}

const domainRedirect = {
  IT: '.it',
  ES: '.es',
};

const GeolocationContext = createContext<FreeGeoLocalization>(null);

export const useGeolocation = () => {
  return useContext(GeolocationContext);
};

export const GeolocationProvider = ({ children }) => {
  const { isDesktop } = useMobileDetect();

  const [geolocationData, setGeolocationData] = useState(null);
  const [geolocationReady, setGeolocationReady] = useState(false);

  useEffect(() => {
    const baseUrl =
      window.location.hostname == 'localhost'
        ? 'http://localhost:5001/tootor-development/europe-west3'
        : window.location.origin.includes('tootor-dev') || process.env.NODE_ENV == 'development'
        ? 'https://europe-west3-tootor-development.cloudfunctions.net'
        : 'https://europe-west3-tootor-production.cloudfunctions.net';
    fetch(`${baseUrl}/http-geoip`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ data: {} }),
    })
      .then((res) => res.json())
      .then((result) => {
        setGeolocationData(result);
        if (!isDesktop || !result || window.location.hostname == 'localhost') return;
        // controllare se sono in un paese con una restrizione sul dominio
        const targetDomain = domainRedirect[result.country_code];
        if (targetDomain != null) {
          let pattern = /\.(\w)+$/i;
          let resultRegex = window.location.hostname.match(pattern);
          if (resultRegex[0] != targetDomain) {
            window.location.href = window.location.href.replace(resultRegex[0], targetDomain).replace('www.', '');
          }
        }
        // www.tootor.it viene redirettato su tootor.it
        if (window.location.host.startsWith('www.')) {
          window.location.href = window.location.href.replace('www.', '');
        }
      })
      .then(() => {
        setGeolocationReady(true);
      });
  }, []);

  if (!geolocationReady) return <FullscreenLoader />;
  return <GeolocationContext.Provider value={geolocationData}>{children}</GeolocationContext.Provider>;
};
