import './FullscreenLoader.scss';
import { useEffect, useState } from 'react';
import { randomBetween, setRandomInterval } from '../../utils/utils';

const FullscreenLoaderComponent = ({ fullscreen = true, className = '', forcePercentage = false }) => {
  const [int, setInt] = useState<any>(null);
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    if (percent >= 100) int.clear();
  }, [percent]);

  useEffect(() => {
    const interval = setRandomInterval(() => setPercent((current) => Math.min(current + randomBetween(1, 4), 99)), 90, 300);
    setInt(interval);
    return () => {
      interval.clear();
      setPercent(0);
    };
  }, []);

  return (
    <div id={'fullscreen-loader'} className={className + (fullscreen ? 'fullscreen-loader' : '')}>
      <div className="spinner" />
      {(fullscreen || forcePercentage) && <div className={'loader-percent'}>{percent}%</div>}
    </div>
  );
};

export { FullscreenLoaderComponent as FullscreenLoader };
