import { useElements, useStripe } from '@stripe/react-stripe-js';
import { FormEvent, useEffect, useReducer, useRef, useState } from 'react';
import { Button, Container, Form, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { ErrorModal } from '../../components/ErrorModal/ErrorModal';
import { Footer } from '../../components/Footer/Footer';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { Topbar } from '../../components/Topbar/Topbar';
import { formReducer, isValidPhoneNumber, verifyHashedQueryParams } from '../../utils/utils';
import { useQuery } from '../../../hooks/useQuery';
import { Browser } from '@capacitor/browser';
import { useCapacitor } from '../../../hooks/useCapacitor';
import './FirstLogin.scss';
import firebase from 'firebase/app';
import { Legalese } from '../../components/Legalese/Legalese';
import { useTranslation } from 'react-i18next';
import { Title } from '../../components/Title/Title';
import { Copyable } from '../../components/Copyable/Copyable';

const FirstLoginComponent = () => {
  const { signupData, updateUserData, userDataFmt, addCheckoutSession, plans, promoCodes, updateHubspotProperties, tags, database, currentUser, staticData } =
    useFirebase();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // const [billingData, setBillingData] = useState<PaymentMethodCreateParams.BillingDetails>({ address: {} });
  const [formData, setFormData] = useReducer(formReducer, {});
  const history = useHistory();
  const params = useQuery();
  const [loading, setLoading] = useState(false);
  const [tagsLoading, setTagsLoading] = useState(true);
  const { isNative } = useCapacitor();
  const [userTags, setUserTags] = useState<firebase.firestore.DocumentData[]>([]);

  const [privacyDefault, setPrivacyDefault] = useState(false);
  const [privacyProfiling, setPrivacyProfiling] = useState(false);
  const [privacyMarketing, setPrivacyMarketing] = useState(false);
  const [privacySendNews, setPrivacySendNews] = useState(false);
  const [privacySendPhoneNews, setPrivacySendPhoneNews] = useState(false);
  const [privacySendCompanyData, setPrivacySendCompanyData] = useState(false);

  const [profilingModalOpen, setProfilingModalOpen] = useState(false);
  const [afterProfiling, setAfterProfiling] = useState(false);
  const { t } = useTranslation();

  const [promoCodeReminderOpen, setPromoCodeReminderOpen] = useState(false);
  const skipFirstFalse = useRef(true);

  const stripe = useStripe();
  const elements = useElements();

  const proceedButtonText = t('labels.registrati');
  const [phoneError, setPhoneError] = useState(false);
  const phoneInputRef = useRef(null);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // if (!stripe || !elements) return;

    // const cardElement = elements.getElement(CardElement);

    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: cardElement,
    //   billing_details: {
    //     email: currentUser.email,
    //     name: billingData.name,
    //     address: {
    //       city: billingData.address.city,
    //       line1: billingData.address.line1,
    //       postal_code: billingData.address.postal_code,
    //       state: billingData.address.state,
    //       country: 'IT',
    //     },
    //     phone: billingData.phone,
    //   },
    // });

    // if (error) {
    //   setErrorMessage(error.message);
    //   setIsModalOpen(true);
    //   return;
    // }

    if (phoneError) {
      if (phoneInputRef.current) {
        phoneInputRef.current.focus();
      }
      return;
    }

    setProfilingModalOpen(true);
    return;
  };

  useEffect(() => {
    if (!afterProfiling) return;

    (async () => {
      setLoading(true);

      const nome = (isNative ? currentUser.displayName || currentUser.email?.split('@')[0] : formData.nome) || '';
      const cognome = (isNative ? currentUser.displayName || currentUser.email?.split('@')[1] : formData.cognome) || '';

      const userData: any = {
        profile: {
          formData: { ...formData },
          tags: userTags.filter((tag) => tag.active).map((tag) => database.doc(`tags/${tag.id}`)),
          privacy: { privacyDefault, privacyProfiling, privacyMarketing, privacySendNews, privacySendPhoneNews, privacySendCompanyData } /*, paymentMethod */,
        },
      };

      userData.profile.formData.nome = nome;
      userData.profile.formData.cognome = cognome;

      if (formData.categoriaProfessionale == 'aso') userData.customTrialSeconds = 1800;
      userData.isNewTrial = !!staticData.ENABLE_NEW_TRIAL_FOR_NEW_USERS;
      userData.customTrialVideos = userData.isNewTrial ? 0 : staticData.DEFAULT_TRIAL_VIDEOS;
      await updateUserData(userData);

      const hubspotTags = tags
        .filter((tag) => tag.hubspotTag)
        .map((tag) => ({ ...tag, active: !!(userTags.find((t) => t.id == tag.id) || {}).active }))
        .reduce((acc, tag) => {
          const hubspotTag = (tag as any).hubspotTag;
          if (hubspotTag) acc[hubspotTag] = tag.active || false;
          return acc;
        }, {});

      await updateHubspotProperties({
        firstname: nome,
        lastname: cognome,
        numero_di_telefono: userDataFmt?.profile?.formData?.phoneNumber || formData.phoneNumber,
        categoria_professionale: userDataFmt?.profile?.formData?.categoriaProfessionale || formData.categoriaProfessionale,
        accettazione_privacy: privacyDefault,
        accettazione_profilazione: privacyProfiling,
        accettazione_marketing: privacyMarketing,
        accettazione_news: privacySendNews,
        accettazione_news_telefono: privacySendPhoneNews,
        accettazione_dati_azienda: privacySendCompanyData,
        ...hubspotTags,
      });

      if (await verifyHashedQueryParams(params, ['price'])) {
        if (params.code) setPromoCodeReminderOpen(true);
        else await createAndGoToCheckout();
      } else {
        // uso window.location.href invece di history per forzare il caricamento
        // del tempo della trial (nel caso uno sia aso)
        window.location.href = '/browse';
      }
    })();
  }, [afterProfiling]);

  useEffect(() => {
    if (promoCodeReminderOpen) return;
    if (skipFirstFalse.current) {
      skipFirstFalse.current = false;
      return;
    }
    createAndGoToCheckout().catch(console.error);
  }, [promoCodeReminderOpen]);

  const createAndGoToCheckout = async () => {
    try {
      const { sessionId, url } = await addCheckoutSession(params.price);
      if (isNative) {
        await Browser.open({ url });
      } else await stripe.redirectToCheckout({ sessionId });
    } catch (e) {
      try {
        alert(JSON.stringify(e, null, 2));
      } catch {
        alert(e);
      }
    }
  };

  const handleChange = (event) => {
    if (event.target.name == 'phoneNumber') {
      const isValid = event.target.value ? isValidPhoneNumber(event.target.value) : true;
      setPhoneError(!isValid);
    }

    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  };

  useEffect(() => {
    if (tags.length) {
      setTagsLoading(false);
      setUserTags(
        tags
          .filter((tag) => tag.hubspotTag)
          .filter((tag) => {
            const categoriaProfessionale = userDataFmt?.profile?.formData?.categoriaProfessionale || formData.categoriaProfessionale;
            return tag.visibleFor[categoriaProfessionale];
          })
          .map((tag) => ({ ...tag, active: false }))
      );
    }
  }, [tags, userDataFmt, formData]);

  useEffect(() => {
    if (userTags.some((tag) => tag.active)) {
      setPrivacyProfiling(true);
    }
  }, [userTags]);

  useEffect(() => {
    if (!privacyProfiling) setUserTags((current) => current.map((tag) => ({ ...tag, active: false })));
  }, [privacyProfiling]);

  const toggleTagActive = (id: string) => {
    setUserTags((current) => current.map((tag) => ({ ...tag, active: tag.id == id ? !tag.active : tag.active })));
  };

  const isTagActive = (id: string) => {
    return userTags.find((tag) => tag.id == id).active;
  };

  if (!userDataFmt || !currentUser || !signupData?.privacyChecks) return <FullscreenLoader />;
  // if (!shouldCompleteFirstLogin(userDataFmt)) return <Redirect to={'/'} />;

  return (
    <>
      <Title>{t('firstLogin.labels.titolo')}</Title>

      <div style={{ marginBottom: '72px' }}>
        <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
          <Topbar />
        </div>
      </div>

      <Container className={'my-5'}>
        <h1 className={'display-4 text-center'}>{t('firstLogin.labels.benvenutoSu', { title: process.env.REACT_APP_TITLE })}</h1>
        <p className={'text-center mb-5'}>{t('firstLogin.labels.abbiamoBisognoInfo')}</p>

        <Form onSubmit={onSubmit} style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridGap: '0.75rem' }}>
          {!isNative && (
            <Form.Group className={'mb-0'} style={{ gridArea: `span 1 / span 1` }}>
              <Form.Control name={'nome'} disabled={loading} required={true} size={'lg'} placeholder={`${t('labels.nome')}*`} onChange={handleChange} />
            </Form.Group>
          )}
          {!isNative && (
            <Form.Group className={'mb-0'} style={{ gridArea: `span 1 / span 1` }}>
              <Form.Control name={'cognome'} disabled={loading} required={true} size={'lg'} placeholder={`${t('labels.cognome')}*`} onChange={handleChange} />
            </Form.Group>
          )}
          <Form.Group className={'mb-0'} style={{ gridArea: `span 1 / span 2` }}>
            <Form.Control
              ref={phoneInputRef}
              name={'phoneNumber'}
              disabled={loading}
              required={true}
              size={'lg'}
              placeholder={`${t('profile.labels.phoneNumber')}`}
              onChange={handleChange}
              onKeyPress={(event) => {
                const key = event.key;
                const regex = /^[0-9+]*$/;
                if (!regex.test(key)) {
                  event.preventDefault();
                }
              }}
              isInvalid={phoneError}
            />
            <Form.Control.Feedback type="invalid">{t('profile.labels.erroreFormatoTelefono')}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className={'mb-0'} style={{ gridArea: `span 1 / span 2` }}>
            <Form.Control name={'categoriaProfessionale'} required={true} size={'lg'} as={'select'} onChange={handleChange} disabled={loading}>
              <option value={''}>{t('labels.selezionaCategoriaProfessionale') + '*'}</option>
              <option value={'odontoiatra'}>{t('labels.odontoiatra')}</option>
              <option value={'aso'}>{t('labels.aso')}</option>
            </Form.Control>
          </Form.Group>

          <div style={{ gridArea: 'span 2 / span 2' }}>
            {t('firstLogin.labels.privacyDescription', { proceedButtonText })} <Legalese just={'tos'} /> {t('firstLogin.labels.privacyDescription2')}{' '}
            <Legalese just={'privacy'} />
            {t('firstLogin.labels.privacyDescription3')}
          </div>
          {/* <Form.Group>
            <Form.Label>Dati di fatturazione</Form.Label>
            <Form.Control required size={'lg'} placeholder={'Nome completo'} onChange={(e) => setBillingData((data) => ({ ...data, name: e.target.value }))} />
          </Form.Group>

          <Form.Group>
            <Form.Control
              required
              size={'lg'}
              placeholder={'Indirizzo'}
              onChange={(e) => setBillingData((data) => ({ ...data, address: { ...data.address, line1: e.target.value } }))}
            />
          </Form.Group>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Control
                required
                size={'lg'}
                placeholder={'Città'}
                onChange={(e) => setBillingData((data) => ({ ...data, address: { ...data.address, city: e.target.value } }))}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Control
                required
                size={'lg'}
                placeholder={'Provincia'}
                onChange={(e) => setBillingData((data) => ({ ...data, address: { ...data.address, state: e.target.value } }))}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Control
                required
                size={'lg'}
                placeholder={'CAP'}
                onChange={(e) => setBillingData((data) => ({ ...data, address: { ...data.address, postal_code: e.target.value } }))}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Control
                required
                size={'lg'}
                placeholder={'Numero di telefono'}
                onChange={(e) => setBillingData((data) => ({ ...data, phone: e.target.value }))}
              />
            </Form.Group>
          </Form.Row> */}

          {/* <div className={'form-group'}>
            <div className={'form-control form-control-lg'} style={{ height: '2.4em', paddingTop: '0.6em' }}>
              <CardElement options={{ style: { base: { fontSize: '20px', fontFamily: 'Source Sans Pro, sans-serif' } } }} />
            </div>
          </div> */}

          <Form.Group style={{ gridArea: 'span 1 / span 2' }}>
            <Form.Check
              type={'checkbox'}
              required={signupData.privacyChecks.checkPrivacyDefault}
              id={'checkPrivacyDefault'}
              disabled={loading}
              checked={privacyDefault}
              onChange={(e) => setPrivacyDefault(e.target.checked)}
              label={
                <>
                  <div>
                    {t('firstLogin.labels.privacyDescription4', { title: process.env.REACT_APP_TITLE })}
                    {!signupData.privacyChecks.checkPrivacyDefault && <small className={'text-italic'}> {t('firstLogin.labels.facoltativo')}</small>}
                  </div>
                </>
              }
            />
          </Form.Group>

          <Form.Group style={{ gridArea: 'span 1 / span 2' }}>
            <Form.Check
              type={'checkbox'}
              id={'checkPrivacyMarketing'}
              required={signupData.privacyChecks.checkPrivacyMarketing}
              disabled={loading}
              checked={privacyMarketing}
              onChange={(e) => setPrivacyMarketing(e.target.checked)}
              label={
                <>
                  <div>
                    {t('firstLogin.labels.acconsentoTrattamento', { letter: 'c)' })} {t('legalese.labels.privacy')},{' '}
                    {t('firstLogin.labels.acconsentoTrattamento3')}
                    {!signupData.privacyChecks.checkPrivacyMarketing && <small className={'text-italic'}> {t('firstLogin.labels.facoltativo')}</small>}
                  </div>
                </>
              }
            />
          </Form.Group>

          <Form.Group style={{ gridArea: 'span 1 / span 2' }}>
            <Form.Check
              type={'checkbox'}
              id={'checkPrivacySendNews'}
              required={signupData.privacyChecks.checkPrivacySendNews}
              disabled={loading}
              checked={privacySendNews}
              onChange={(e) => setPrivacySendNews(e.target.checked)}
              label={
                <>
                  <div>
                    {t('firstLogin.labels.acconsentoTrattamento', { letter: 'e)' })} {t('legalese.labels.privacy')},{' '}
                    {t('firstLogin.labels.acconsentoTrattamento2', { title: process.env.REACT_APP_TITLE })}
                    {!signupData.privacyChecks.checkPrivacySendNews && <small className={'text-italic'}> {t('firstLogin.labels.facoltativo')}</small>}
                  </div>
                </>
              }
            />
          </Form.Group>

          <Form.Group style={{ gridArea: 'span 1 / span 2' }}>
            <Form.Check
              type={'checkbox'}
              id={'checkPrivacySendPhoneNews'}
              required={signupData.privacyChecks.checkPrivacySendPhoneNews}
              disabled={loading}
              checked={privacySendPhoneNews}
              onChange={(e) => setPrivacySendPhoneNews(e.target.checked)}
              label={
                <>
                  <div>
                    {t('firstLogin.labels.acconsentoTrattamento', { letter: 'f)' })} {t('legalese.labels.privacy')},{' '}
                    {t('firstLogin.labels.acconsentoTrattamento4', { title: process.env.REACT_APP_TITLE })}
                    {!signupData.privacyChecks.checkPrivacySendPhoneNews && <small className={'text-italic'}> {t('firstLogin.labels.facoltativo')}</small>}
                  </div>
                </>
              }
            />
          </Form.Group>

          <Form.Group style={{ gridArea: 'span 1 / span 2' }}>
            <Form.Check
              type={'checkbox'}
              id={'checkPrivacySendCompanyData'}
              required={signupData.privacyChecks.checkPrivacySendCompanyData}
              disabled={loading}
              checked={privacySendCompanyData}
              onChange={(e) => setPrivacySendCompanyData(e.target.checked)}
              label={
                <>
                  <div>
                    {t('firstLogin.labels.acconsentoTrattamento', { letter: 'g)' })} {t('legalese.labels.privacy')},{' '}
                    {t('firstLogin.labels.acconsentoTrattamento5', { title: process.env.REACT_APP_TITLE })}
                    {!signupData.privacyChecks.checkPrivacySendCompanyData && <small className={'text-italic'}> {t('firstLogin.labels.facoltativo')}</small>}
                  </div>
                </>
              }
            />
          </Form.Group>

          <div className={'d-flex justify-content-center mt-4'} style={{ gridArea: 'span 1 / span 2' }}>
            <Button size={'lg'} variant={'primary'} type={'submit'} disabled={!stripe || loading || tagsLoading}>
              {!stripe || loading ? <>{t('labels.attendi')}&hellip;</> : proceedButtonText}
            </Button>
          </div>
        </Form>
      </Container>

      <Footer />

      <ErrorModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} errorMessage={errorMessage} />

      <Modal
        size={'xl'}
        centered
        scrollable
        show={profilingModalOpen}
        onHide={() => setProfilingModalOpen(false)}
        backdrop={'static'}
        keyboard={false}
        className={'dark-modal'}
      >
        <Modal.Header className={'d-flex justify-content-center'}>
          <Modal.Title className={'text-center'}>{t('firstLogin.labels.personalizzazioneEsperienza')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{t('firstLogin.labels.qualiArgomentiInteressano')}</div>
          <p>{t('firstLogin.labels.accettaCondizioni', { letter: 'e)' })}</p>
          <div className={'profiling-tags-container-wrapper'}>
            <div className={'profiling-tags-container'}>
              {tagsLoading && <FullscreenLoader fullscreen={false} />}
              {!tagsLoading &&
                userTags.map((tag) => (
                  <div
                    key={tag.id}
                    className={'profiling-tag ' + (isTagActive(tag.id) ? 'active' : '')}
                    onClick={() => toggleTagActive(tag.id)}
                    data-label={tag.label}
                    style={{ '--background': 'url(' + (tag.image || `https://picsum.photos/256/150?random=${tag.id}`) + ')' } as any}
                  />
                ))}
            </div>
          </div>
          <Form.Group className={'mt-2'}>
            <Form.Check
              type={'checkbox'}
              id={'checkPrivacyProfiling'}
              disabled={loading}
              checked={privacyProfiling}
              onChange={(e) => setPrivacyProfiling(e.target.checked)}
              label={
                <>
                  <small>
                    {t('firstLogin.labels.accettaCondizioni', { letter: 'c)' })}
                    <Legalese just={'privacy'} />, {t('firstLogin.labels.acconsentoTrattamento3')}
                  </small>
                </>
              }
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={'secondary'}
            className={'mr-auto'}
            onClick={() => {
              setProfilingModalOpen(false);
              setPrivacyProfiling(false);
            }}
          >
            {t('labels.annulla')}
          </Button>
          {!privacyProfiling && (
            <Button
              variant={'dark'}
              onClick={() => {
                setProfilingModalOpen(false);
                setAfterProfiling(true);
              }}
            >
              {t('firstLogin.labels.procediSenzaPersonalizzazione')}
            </Button>
          )}
          {!!privacyProfiling && (
            <Button
              variant={'primary'}
              disabled={!userTags.filter((tag) => tag.active).length}
              onClick={() => {
                setProfilingModalOpen(false);
                setAfterProfiling(true);
              }}
            >
              {t('labels.conferma')}
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={promoCodeReminderOpen} onHide={() => setPromoCodeReminderOpen(false)} backdrop={'static'} centered keyboard={false}>
        <Modal.Header>
          <Modal.Title>{t('firstLogin.labels.ricorcaCodiceTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {t('firstLogin.labels.ricordaCodice')}{' '}
            <strong>
              <Copyable text={params?.code} />
            </strong>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={'primary'} onClick={() => setPromoCodeReminderOpen(false)}>
            {t('firstLogin.labels.vaiPagamento')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FirstLoginComponent;
