import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { RatingStars } from '../RatingStars/RatingStars';
import { useEffect, useMemo, useState } from 'react';
import './Feedback.scss';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { Else, If, Then, When } from 'react-if';
import { Link } from 'react-router-dom';

const RatingBox = ({ label, value, setter, disabled = false }) => (
  <div className={'feedback-rating-box border rounded d-flex flex-column align-items-center p-2 ' + (!!value ? 'border-success' : 'border-warning')}>
    <div>{label}</div>
    <RatingStars onRate={setter} disabled={disabled} />
  </div>
);

const FeedbackComponent = ({ show, onHide }) => {
  const { database, fv, currentUser, userDataFmt } = useFirebase();

  const [loading, setLoading] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);

  const [rating1, setRating1] = useState<number>(null);
  const [rating2, setRating2] = useState<number>(null);
  const [rating3, setRating3] = useState<number>(null);
  const [comment, setComment] = useState('');

  const questions = useMemo(
    () => [
      { label: `Come valuti la facilità di fruizione?`, rating: rating1, setter: setRating1 },
      { label: `Come valuti la qualità dei contenuti?`, rating: rating2, setter: setRating2 },
      { label: `In generale, come valuti la tua esperienza su ${process.env.REACT_APP_TITLE}?`, rating: rating3, setter: setRating3 },
    ],
    [rating1, rating2, rating3]
  );

  const getFullName = () => {
    const getProp = (prop) => userDataFmt?.profile?.formData?.[prop]?.trim?.();
    return `${getProp('nome')} ${getProp('cognome')}`.trim() || null;
  };

  useEffect(() => {
    if (show) return;
    questions.forEach((q) => q.setter(null));
    setComment('');
    setLoading(false);
    setFeedbackSent(false);
  }, [show]);

  return (
    <Modal show={show} onHide={() => onHide(false)} centered backdrop={'static'} keyboard={false} scrollable>
      <Modal.Header>
        <Modal.Title>Feedback</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>La tua opinione è importante.</p>
        <p>
          Lascia un feedback per farci presente la qualità della tua esperienza di fruizione di {process.env.REACT_APP_TITLE} e se possiamo cambiare qualcosa
          per renderla migliore.
        </p>
        <If condition={feedbackSent}>
          <Then>
            <Alert variant={'success'} className={'mb-0'}>
              <h5>Feedback inviato con successo!</h5>
              <div>Grazie per il tuo contributo a rendere {process.env.REACT_APP_TITLE} una piattaforma migliore.</div>
            </Alert>
          </Then>
          <Else>
            <Alert variant={'warning'}>
              <div className={'font-weight-bold'}>Hai bisogno di aiuto?</div>
              <div>
                Contattaci tramite il nostro{' '}
                <Link to={'/help'} onClick={() => onHide(false)}>
                  help center
                </Link>
                .
              </div>
            </Alert>
            {questions.map((q, i) => (
              <RatingBox key={i.toString()} label={q.label} value={q.rating} setter={q.setter} disabled={loading} />
            ))}
            <p className={'mt-3'}>Se lo desideri, aggiungi un commento:</p>
            <Form.Control
              as={'textarea'}
              rows={4}
              placeholder={'Suggerisci come possiamo migliorare la tua esperienza...'}
              value={comment}
              disabled={loading}
              onChange={(e) => setComment(e.target.value)}
            />
          </Else>
        </If>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={'secondary'} onClick={() => onHide(false)} disabled={loading && !feedbackSent}>
          {feedbackSent ? 'Chiudi' : 'Annulla'}
        </Button>
        <When condition={!feedbackSent}>
          <Button
            variant={'primary'}
            disabled={!rating1 || !rating2 || !rating3 || loading}
            onClick={() => {
              setLoading(true);
              database
                .collection('feedbacks')
                .add({
                  uid: currentUser.uid,
                  name: getFullName(),
                  questions: questions.map((q) => q.label),
                  ratings: questions.map((q) => q.rating),
                  comment: comment || '',
                  created: fv.serverTimestamp(),
                })
                .then(() => setFeedbackSent(true))
                .catch(console.error)
                .finally(() => setLoading(false));
            }}
          >
            {loading ? <>Attendi&hellip;</> : 'Invia'}
          </Button>
        </When>
      </Modal.Footer>
    </Modal>
  );
};

export { FeedbackComponent as Feedback };
