import { Container } from 'react-bootstrap';
import './CourseBox.scss';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CourseBoxComponent = ({ course }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Container
      className={'course-box'}
      style={{ backgroundImage: `url(${!!course.header ? course.header : null})` }}
      onClick={() => {
        history.push(`/course/${course.id}`);
      }}
    >
      <div className={'d-flex mb-4'}>
        <h2 style={{ minHeight: '80px' }} className={'mb-0 crop-text-2'}>
          {course.name}
        </h2>
        <div className={'d-flex flex-column ml-auto align-items-center'} style={{ minWidth: '100px' }}>
          {/* TODO: Controllare se esiste il certificato */}
          {course.withCertificate && <div className={'certification-chip'}>{t('course.labels.withCertificate')}</div>}
          <div className={'mt-auto units-length'}>
            <b>{course.chapters.flatMap((chapter) => chapter.units).length}</b> Video
          </div>
        </div>
      </div>
      {!!course.description && (
        <div className="crop-text-3">
          {course.description.map((segment, i) => (
            <p className={'m-0'} key={i.toString()}>
              {segment}
            </p>
          ))}
        </div>
      )}
      {!!course.header && <div className={'course-image-wrapper'}></div>}
    </Container>
  );
};

export { CourseBoxComponent as CourseBox };
