import { Container } from 'react-bootstrap';
import { useFirebase } from '../../../contexts/FirebaseContext';
import './ActyFaq.scss';
import { useEffect, useState } from 'react';

import { Topbar } from '../../components/Topbar/Topbar';
import { useTranslation } from 'react-i18next';
import { Footer } from '../../components/Footer/Footer';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { Title } from '../../components/Title/Title';
import { innerHtml } from '../../utils/utils';
import { useCurrentLanguage } from '../../../hooks/useCurrentLanguage';

const ActyFaq = () => {
  const { getActyFaq } = useFirebase();
  const { currentLanguage } = useCurrentLanguage();
  const { t } = useTranslation();
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getActyFaq()
      .then(({ content }) => {
        setContent(content);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    try {
      document.documentElement.style.setProperty('--background-image-url', `url(${process.env.PUBLIC_URL}/acty-assets/bg-faq/${currentLanguage}.jpg)`);
    } catch (e) {
      console.error(e);
    }
  }, []);

  if (loading) return <FullscreenLoader fullscreen={true} />;

  return (
    <>
      <Title>{t('relatoriFaq.labels.titolo')}</Title>

      <div className={'header'}>
        <Topbar />
        <Container fluid className={'header-content-wrapper'}>
          <div className={'header-content'}>
            <div className={'title fade-in'} {...innerHtml(content.titleContent.replaceAll('WEB_EXPERT_LOGIN', content.loginUrl))} />
          </div>
        </Container>
      </div>
      <div className={'new-layout'}>
        <Container>
          <div className={'d-flex flex-column align-items-center'} {...innerHtml(content.bodyContent.replaceAll('WEB_EXPERT_GUIDE', content.guideUrl))} />
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default ActyFaq;
