import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';

const TitleHandlerComponent = () => {
  const history = useHistory();
  const [pageTitle, setPageTitle] = useState('');

  const pageTitleMap = {
    // '/signup': 'Registrazione',
    // '/first-login': 'Primo accesso',
    // '/profile': 'Profilo',
  };

  useEffect(() => {
    return history.listen((location) => {
      setPageTitle(pageTitleMap[location.pathname]);
    });
  }, []);

  return (
    <Helmet defaultTitle={process.env.REACT_APP_TITLE} titleTemplate={'%s - ' + process.env.REACT_APP_TITLE}>
      {!!pageTitle && <title>{pageTitle}</title>}
    </Helmet>
  );
};

export { TitleHandlerComponent as TitleHandler };
