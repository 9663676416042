import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lazy, Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useGaming } from '../../../contexts/GamingContext';
import { useMobileDetect } from '../../../hooks/useMobileDetect';
import { useWindowSize } from '../../../hooks/useWindowSize';

const CalendarCellComponent = ({
  cellData,
  today,
  calendar,
  currentMonth,
  onLoadChange = (isLoading: boolean) => null,
  onClick = () => null,
  onUnlock,
  onViewContent,
  onProWithoutSubscription,
  // onProWithoutYearlySubscription,
}) => {
  const { id, unlocked, pro, content: preloadContent } = cellData;
  const { id: year, allowPreviousDays } = calendar;
  const { isMobile } = useMobileDetect();
  const cellContainerRef = useRef<HTMLDivElement>();
  const [cardMaxHeight, setCardMaxHeight] = useState(0);
  const [isLtMd, setIsLtMd] = useState(false);
  const { windowWidth } = useWindowSize();

  const CellElement = useMemo(
    () =>
      lazy(() =>
        import(`./svgs/cells-new/${id}${isMobile ? '-m' : ''}.svg`)
          .catch(() => import(`./svgs/cells-new/${id}.svg`))
          .then(({ default: defaultImport }) => ({ default: () => <img src={defaultImport} alt={''} /> }))
      ),
    []
  );

  // TODO: verificare corretto funzionamento, in quanto le logiche sono state cambiate per far funzionare gennaio 2023.
  // necessaria verifica in ottica di dicembre 2023-gennaio 2024
  const isToday = +id == today && currentMonth == 12;
  const isPast = +id < today;
  const isFuture = +id > today && currentMonth == 12;

  const { unlockAdventDay, subscription } = useFirebase();
  const { track } = useGaming();

  const [active, setActive] = useState(unlocked);
  const [toggleable, setToggleable] = useState(!active);
  // const [toggleable, setToggleable] = useState((isToday || (isPast && allowPreviousDays)) && !active);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(unlocked ? preloadContent : null);

  const setCardSize = () => {
    const containerWidth = cellContainerRef.current?.clientWidth;
    const height = containerWidth / 2.56;
    setCardMaxHeight(height);
    setIsLtMd(windowWidth < 768);
  };

  useEffect(() => {
    setTimeout(() => setCardSize());
  }, [windowWidth, cellContainerRef.current]);

  useEffect(() => {
    onLoadChange(loading);
  }, [loading]);

  const toggleActive = () => {
    console.log({ active, preloadContent, isPast, isToday, isFuture, allowPreviousDays });
    onClick();
    if (toggleable) setActive((current) => !current);
    else if (active && unlocked) onViewContent();
  };

  const getContainerClasses = () => {
    const classes = ['calendar-cell', `a${id}`];
    if (isPast) classes.push('cell-past');
    if (isToday) classes.push('cell-today');
    if (isToday && !unlocked) classes.push('animated');
    if (isFuture) classes.push('cell-future prevent-hover');
    if (isPast && !allowPreviousDays && !unlocked) classes.push('prevent-hover grayscale');
    if (isPast && allowPreviousDays && !unlocked) classes.push('animated');
    return classes.join(' ');
  };

  useEffect(() => {
    if (!active) return;
    if (preloadContent) return;
    if (isPast && !allowPreviousDays) return;

    if (isFuture) {
      setActive(false);

      return;
    }

    if (pro && !subscription) {
      setActive(false);
      onProWithoutSubscription();
      return;
    }

    // if (pro && subscription.items[0].plan.interval == 'month') {
    //   setActive(false);
    // onProWithoutYearlySubscription();
    //   return;
    // }

    setToggleable(false);
    setLoading(true);
    unlockAdventDay(id, year)
      .then((res) => {
        if (res?.content) {
          setContent(res.content);
          onUnlock(res.content);
          track('UNLOCK_CALENDAR_CELL');
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [active, unlocked]);

  return (
    <>
      {/*<WithTooltip
      big={false}
      text={(() => {
        if (content) return CalendarStrings.Unlocked.Past(true);
        if (isToday) return CalendarStrings.Locked.Today();
        if (!content && isPast && !allowPreviousDays) return CalendarStrings.Locked.Past();
        if (!content && isPast && allowPreviousDays) return CalendarStrings.Locked.Today();
      })()}
      hide={loading || isFuture}
    >*/}
      <Suspense fallback={null}>
        <div className={getContainerClasses()} data-id={'calendar-cell-' + id} onClick={toggleActive} ref={cellContainerRef} onLoad={setCardSize}>
          <div className={`cell-front inner${id} ` + (active ? 'cell-active ' : '')}>
            <CellElement />
            {!!pro && !active && (
              <div className={'cell-lock-icon'}>
                <Badge className={'badge-custom-warning'}>VIP</Badge>
              </div>
            )}
            {/*{!!pro && !subscription && <FontAwesomeIcon icon={faTag} className={'cell-lock-icon'} />}*/}
          </div>
          <div className={`cell-back back${id} ` + (active ? 'cell-active ' : '') + (loading ? '' : unlocked ? 'green ' : 'red ')}>
            {loading && <FontAwesomeIcon icon={faSpinner} pulse />}
            {!loading && (
              <>
                {!!content && (
                  <div className={'content-lock open'}>
                    {!!cellData.company && (
                      <div className={'cell-lock-icon'}>
                        <Badge className={'badge-custom-warning'}>{cellData.company}</Badge>
                      </div>
                    )}
                    <span>{id}</span>
                  </div>
                )}
                {/*{isPast && !allowPreviousDays && !content && (
                <div className={'content-lock closed'}>
                  <SvgGiftClosed />
                  <span>{id}</span>
                </div>
              )}*/}
              </>
            )}
          </div>
        </div>
      </Suspense>
      {/*</WithTooltip>*/}
    </>
  );
};

export { CalendarCellComponent as CalendarCell };
