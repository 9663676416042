import { Badge } from 'react-bootstrap';

const MultiplierComponent = ({ multiplier }) => {
  return (
    <Badge variant={'dark'} className={'text-warning'}>
      {multiplier}x
    </Badge>
  );
};

export { MultiplierComponent as Multiplier };
