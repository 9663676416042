import { Button, Pagination } from 'react-bootstrap';

function PaginationComponent({ currentPage, totalPages, onPageChange }) {
  if (totalPages <= 0) {
    return null;
  }

  let startPage = Math.max(1, currentPage - 2);
  let endPage = Math.min(totalPages, startPage + 4);

  if (totalPages - startPage < 4) {
    startPage = Math.max(1, totalPages - 4);
  }

  return (
    <div className="d-flex justify-content-center">
      <Button variant="outline-secondary mr-2" onClick={() => onPageChange(1)} disabled={currentPage === 1}>
        {'<<'}
      </Button>

      {startPage > 1 && (
        <Button variant="outline-secondary mr-2" onClick={() => onPageChange(startPage - 1)}>
          {'...'}
        </Button>
      )}

      {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((page) => (
        <Button key={page} active={page === currentPage} variant="outline-secondary mr-2" onClick={() => onPageChange(page)}>
          {page}
        </Button>
      ))}

      {endPage < totalPages && (
        <Button variant="outline-secondary mr-2" onClick={() => onPageChange(endPage + 1)}>
          {'...'}
        </Button>
      )}

      <Button variant="outline-secondary mr-2" onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages}>
        {'>>'}
      </Button>
    </div>
  );
}

export default PaginationComponent;
