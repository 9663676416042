import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';

export const useHover = <T extends HTMLElement>(wait = 0): [ref: MutableRefObject<T>, value: boolean] => {
  const [value, setValue] = useState(false);
  const memoizedCallback = useCallback(
    throttle((val: boolean) => setValue(val), wait, { leading: false, trailing: true }),
    []
  );

  const ref = useRef<T>(null);

  const handleMouseOver = () => memoizedCallback(true);
  const handleMouseOut = () => memoizedCallback(false);

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);

      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [ref.current]);

  return [ref, value];
};
