import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MaskedIconComponent = ({
  className = '',
  bg,
  fg = null,
  bgColor = null,
  fgColor = null,
  inverse = false,
  small = false,
  foregroundStyles = null,
  fixedWidth = false,
}) => {
  return (
    <span className={'fa-layers fa-fw ' + className}>
      <FontAwesomeIcon icon={bg} color={bgColor} size={small ? null : 'lg'} fixedWidth={fixedWidth} />
      {!!fg && <FontAwesomeIcon icon={fg} inverse={inverse} transform={small ? 'shrink-8' : 'shrink-6'} color={fgColor} style={foregroundStyles} />}
    </span>
  );
};

export { MaskedIconComponent as MaskedIcon };
