import { useTranslation } from 'react-i18next';
import { usePersistent } from './usePersistent';
import { useFirebase } from '../contexts/FirebaseContext';
import * as Locales from 'date-fns/locale';
import { useGeolocation } from '../contexts/GeolocationContext';

export const FALLBACK_LANGUAGE_1 = 'en';
export const FALLBACK_LANGUAGE_2 = 'it';

export const useCurrentLanguage = (): {
  currentLanguage: string;
  currentLocale: Locale;
  supportedLanguages: string[];
  updateLanguage: (newLanguage: string, reload?: boolean) => void;
} => {
  const f = useFirebase();
  const { i18n } = useTranslation();
  const { country_code } = useGeolocation();
  const supportedLanguages = Object.keys(i18n.services.resourceStore.data);
  const [currentLanguage, setCurrentLanguage] = usePersistent<string>(
    'currentLanguage',
    supportedLanguages.includes(country_code.toLowerCase())
      ? country_code.toLowerCase()
      : supportedLanguages.includes(FALLBACK_LANGUAGE_1)
      ? FALLBACK_LANGUAGE_1
      : FALLBACK_LANGUAGE_2
  );

  const updateLanguage = async (newLanguage: string, reload = true) => {
    const currentLanguage = supportedLanguages.includes(newLanguage)
      ? newLanguage
      : supportedLanguages.includes(FALLBACK_LANGUAGE_1)
      ? FALLBACK_LANGUAGE_1
      : FALLBACK_LANGUAGE_2;
    setCurrentLanguage(currentLanguage);
    if (f && f.currentUser) await f.database.doc(`userData/${f.currentUser.uid}`).set({ preferredLanguage: currentLanguage }, { merge: true });
    if (reload) window.location.reload();
  };
  const currentLocale: Locale = Locales[currentLanguage];

  return { currentLanguage, currentLocale, supportedLanguages, updateLanguage };
};
