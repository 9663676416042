import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Title } from '../../components/Title/Title';
import { Topbar } from '../../components/Topbar/Topbar';
import { Button, Container, Modal } from 'react-bootstrap';
import { Footer } from '../../components/Footer/Footer';
import '../Acty/Acty.scss';
import { useState, useEffect } from 'react';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { intlFormat, format } from 'date-fns';
import { useCurrentLanguage } from '../../../hooks/useCurrentLanguage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const ActyAppointmentComponent = () => {
  const { currentLanguage } = useCurrentLanguage();
  const { getActyAppointment } = useFirebase();
  const history = useHistory();
  const { t } = useTranslation();
  const [appointment, setAppointment] = useState(null);
  const [loading, setLoading] = useState(true);
  const { actyAppointmentId } = useParams<{ actyAppointmentId: string }>();
  const [isAppointmentAvailable, setIsAppointmentAvailable] = useState(true);

  useEffect(() => {
    setLoading(true);
    getActyAppointment(actyAppointmentId)
      .then((data) => {
        if (Object.keys(data).length === 0) {
          setIsAppointmentAvailable(false);
          return;
        }
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setAppointment(data);
        data.formattedDate = intlFormat(
          new Date(data.timestamp._seconds * 1000),
          {
            timeZone: tz,
            hour: 'numeric',
            minute: 'numeric',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          },
          { locale: currentLanguage }
        );
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  return loading ? (
    <FullscreenLoader fullscreen={true} />
  ) : (
    <>
      <Title>{t('acty.labels.prenotazioneTitle')}</Title>

      {isAppointmentAvailable && (
        <>
          <div style={{ marginBottom: '72px' }}>
            <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
              <Topbar />
            </div>
          </div>

          <Container className={'my-5'}>
            <div className={'mb-5 text-center'}>
              <h2>{t('acty.labels.prenotazioneDel', { date: appointment.formattedDate })}</h2>
            </div>
            <div style={{ gap: '0.25rem' }} className={'d-flex align-items-center mb-3'}>
              <Button
                className={'btn-dark mr-2'}
                onClick={() => {
                  history.push('/acty/appointments');
                }}
                disabled={loading}
              >
                <FontAwesomeIcon className={'mr-2'} icon={faArrowLeft} /> {t('acty.labels.tornaAppuntamentiBtn')}
              </Button>
            </div>
            {/* <div className={'display-5 mb-5 text-center'}>Docente {appointment.tutor}</div> */}
            <iframe src={appointment.meeting_url} width="100%" height="480" title="Appuntamento" allow="camera;microphone;fullscreen;display-capture" />
          </Container>
          <Footer />
        </>
      )}

      {!isAppointmentAvailable && (
        <>
          <Modal centered show={!isAppointmentAvailable} onHide={() => history.push(`/acty/appointments`)}>
            <Modal.Header>
              <Modal.Title>{t('acty.labels.noPrenotazioneTitleModal')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t('acty.labels.noPrenotazioneBodyModal')}</Modal.Body>
            <Modal.Footer>
              <Button variant={'secondary'} onClick={() => history.push(`/acty/appointments`)}>
                {t('acty.labels.noPrenotazioneBtnModal')}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default ActyAppointmentComponent;
