import { Badge, Container } from 'react-bootstrap';
import { Footer } from '../../components/Footer/Footer';
import { Title } from '../../components/Title/Title';
import { Topbar } from '../../components/Topbar/Topbar';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useEffect, useState } from 'react';
import { formatSecondsTimestamp, innerHtml } from '../../utils/utils';
import { useParams } from 'react-router-dom';
import './BlogArticle.scss';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { CountryFlag } from '../../components/CountryFlag/CountryFlag';
import { Helmet } from 'react-helmet-async';
import { ShareButtons } from './ShareButtons';

const BlogArticleComponent = () => {
  const { queryHubspot } = useFirebase();
  const { id } = useParams<{ id: string }>();

  const [tags, setTags] = useState({});
  const [post, setPost] = useState<any>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Promise.all([queryHubspot('BLOG_TAGS'), queryHubspot('BLOG_ARTICLE', { id })])
      .then(([tags, post]) => {
        setTags(tags);
        setPost(post);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) return <FullscreenLoader />;

  return (
    <>
      <Helmet>
        <meta property={'og:title'} content={post.name} />
        <meta property={'og:description'} content={post.metaDescription} />
        <meta property={'og:url'} content={window.location.href} />
        <meta property={'og:type'} content={'article'} />
        <meta property={'og:locale'} content={post.language} />
        {!!post.featuredImage && <meta property={'og:image'} content={post.featuredImage} />}
      </Helmet>

      <Title>{post.name}</Title>

      <div style={{ marginBottom: '72px' }}>
        <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
          <Topbar />
        </div>
      </div>

      <div key={post.id} className={'d-flex inside-jumbo-article mb-5'}>
        <div
          className={'overflow-hidden ml-3 inside-article-image'}
          style={{
            backgroundImage: `url(${post.featuredImage})`,
            borderRadius: 0,
            height: '60vh',
          }}
        />
        <div className={'inside-article-content'}>
          <Container>
            <div className={'mb-1'}>
              <CountryFlag language={post.language} className={'mr-2'} withTooltip={true} />
              {(post.tagIds || []).map((tagId) => (
                <Badge variant={'dark'} key={tagId} className={'mr-1'}>
                  {tags[tagId]}
                </Badge>
              ))}
            </div>
            <h4 className={'inside-article-title'} {...innerHtml(post.htmlTitle)} />
            <p {...innerHtml(post.postSummary)} />
            <div className={'small text-muted'}>
              <span className={'text-light'}>{post.authorName}</span> <span className={'mx-2'}>&bull;</span>
              <span>{post.publishDate ? formatSecondsTimestamp(post.publishDate, 'dd/MM/Y HH:mm') : ''}</span>
            </div>
          </Container>
        </div>
      </div>

      <Container className={'my-5'}>
        {/*<div className={'d-flex justify-content-end mb-5'}>
          <ShareButtons description={post.postSummary} />
        </div>*/}

        <div {...innerHtml(post.postBody)} />

        {/*<div className={'d-flex justify-content-center mt-5'}>
          <ShareButtons description={post.postSummary} size={56} />
        </div>*/}

        {/*<div className={'mt-4 d-flex justify-content-center'}>
          <Button onClick={() => window.open(post.url + '#hs_cos_wrapper_blog_comments', '_blank')} size={'lg'}>
            Lascia un commento
          </Button>
        </div>*/}
      </Container>

      <Footer />
    </>
  );
};

export default BlogArticleComponent;
