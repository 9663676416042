import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useCapacitor } from '../../../hooks/useCapacitor';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const SubscribeButtonComponent = ({ className = '', small = false }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isNative } = useCapacitor();
  const [desktopModalOpen, setDesktopModalOpen] = useState(false);

  return (
    <>
      <Button
        variant={'warning'}
        size={small ? 'sm' : null}
        className={className}
        onClick={() => {
          if (isNative) setDesktopModalOpen(true);
          else history.push(`/plans`);
        }}
      >
        <span className={'font-weight-bold'}>{t('labels.abbonati')}</span>
      </Button>

      <Modal show={desktopModalOpen} centered onHide={() => setDesktopModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('watch.labels.necessarioAbbonamento')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('watch.labels.necessarioAbbonamentoNote')}</Modal.Body>
        <Modal.Footer>
          <Button variant={'secondary'} onClick={() => setDesktopModalOpen(false)}>
            {t('labels.chiudi')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { SubscribeButtonComponent as SubscribeButton };
