import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
// import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { FirebaseProvider } from './contexts/FirebaseContext';
import './firebase';
import './index.scss';
import { UserAgentProvider } from 'react-ua';
import App from './app/App';
import { I18nProvider } from './providers/I18nProvider';
import { GeolocationProvider } from './contexts/GeolocationContext';
import { GamingProvider } from './contexts/GamingContext';
import { BrowserRouter } from 'react-router-dom';

if (process.env.NODE_ENV == 'production') {
  console.log('%cATTENZIONE', 'color: red; background-color: yellow; font-size: 24px');
  console.log(
    "%cQuesta funzione del browser è pensata per gli sviluppatori. L'utilizzo di questa console potrebbe consentire a utenti malintenzionati di rubare la tua identità e le tue informazioni attraverso un attacco detto Self-XSS.\nNon inserire o incollare codici che non comprendi.",
    'font-size: 20px'
  );
  console.log = () => {};
  console.warn = () => {};
  // console.error = () => {};
  console.group = () => {};
  console.groupEnd = () => {};
}
console.log('%c%s', 'color: green; font-weight: bold;', process.env.NODE_ENV);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

ReactDOM.render(
  // <React.StrictMode>
  <HelmetProvider>
    <UserAgentProvider>
      <GeolocationProvider>
        <I18nProvider>
          <BrowserRouter>
            <FirebaseProvider>
              <GamingProvider>
                <Elements
                  options={{
                    locale: 'it',
                    fonts: [
                      {
                        cssSrc: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap',
                        display: 'swap',
                        family: 'Source Sans Pro',
                      },
                    ],
                  }}
                  stripe={stripePromise}
                >
                  <App />
                </Elements>
              </GamingProvider>
            </FirebaseProvider>
          </BrowserRouter>
        </I18nProvider>
      </GeolocationProvider>
    </UserAgentProvider>
  </HelmetProvider>,
  // </React.StrictMode>
  document.getElementById('root')
);
