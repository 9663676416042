import * as Sentry from '@sentry/react';
import humanFormat from 'human-format';
import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useFirebase } from './FirebaseContext';
import { useCurrentLanguage } from '../hooks/useCurrentLanguage';
import { Experiments, formReducer } from '../app/utils/utils';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface GamingEvent {
  uid: string;
  action: string;
  ts: number;
  environment: 'DEV' | 'STAGING' | 'PRODUCTION';
  payload?: GenericObject;
}

export type GenericObject<T = string | number> = { [p: string]: T };

export interface IGamingContext {
  nf: (value: number) => string;
  hf: (value: number) => string;
  track: (name: string, eventData?: GenericObject<any>) => void;
  config: GenericObject<any>;
  player: any;
  experience: string;
  humanExperience: string;
  currency: string;
  humanCurrency: string;
  achievements: any[];
  missions: any[];
  socket: any;
  isEnabled: boolean;
  getAchievements: () => void;
  onCurrencyChange: (key: string, callback: () => void) => void;
  onPlayerUpdate: (key: string, callback: () => void) => void;
  onExperienceGain: (key: string, callback: (amount: number) => void) => void;
  onLevelUp: (callback: () => void) => void;

  openNotification: (notification) => void;
  openedNotification: any;
  setOpenedNotification: any;
  showDataModal: boolean;
  setShowDataModal: any;
  setPauseVideo: any;
  pauseVideo: any;
  formLoading: boolean;
  setFormLoading: any;
  formData: any;
  setFormData: any;

  achievementUnlocked: any;
  setAchievementUnlocked: Dispatch<SetStateAction<any>>;
}

const nf = Intl.NumberFormat(undefined, { useGrouping: true, maximumFractionDigits: 0 });

const hf = (value: number) => {
  const scale = new humanFormat.Scale({
    '': 1,
    K: 1_000,
    M: 1_000_000,
    B: 1_000_000_000,
  });
  /* Workaround for https://github.com/JsCommunity/human-format/issues/46 */
  const humanized = humanFormat(value, { maxDecimals: 'auto', separator: '', scale });
  if (humanFormat.parse(humanized, { scale }) <= value) return humanized;
  return humanized.replace(/(\.)?(\d+)(\w)$/, (_, dot, lastDigit, unit) => `${dot || ''}${+lastDigit - 1}${unit}`).replace(/\.0(\w)/, (_, unit) => unit);
};

const GamingContext = createContext<IGamingContext>(null);

export const useGaming = () => {
  return useContext(GamingContext);
};

export const GamingProvider = ({ children }) => {
  const io = (window as any).io;

  const { currentUser, database, addNotifications, readNotification, staticData, userDataFmt, setTrack, setSocketFire, setValidBonus } = useFirebase();
  const { currentLanguage } = useCurrentLanguage();
  const history = useHistory();

  const [achievementUnlocked, setAchievementUnlocked] = useState<any>(null);

  const [socket, setSocket] = useState<any>(null);

  const [config, setConfig] = useState<any>({});
  useEffect(() => {
    database
      .doc('system/gamingConfig')
      .get()
      .then((doc) => {
        const data = doc.data();
        setConfig({ loginStreak: data.loginStreak });
      });
    setTrack(() => track);
  }, []);

  const [isEnabled, setIsEnabled] = useState(false);
  const [player, setPlayer] = useState<any>({});
  const [achievements, setAchievements] = useState<any>([]);
  const [missions, setMissions] = useState<any>([]);

  const [showDataModal, setShowDataModal] = useState<boolean>(false);
  const [openedNotification, setOpenedNotification] = useState<any>();
  const [pauseVideo, setPauseVideo] = useState<boolean>(false);
  const [formLoading, setFormLoading] = useState(false);
  const [formData, setFormData] = useReducer(formReducer, {
    nome: '',
    cognome: '',
    indirizzo: '',
    indirizzo2: '',
    cap: '',
    citta: '',
    provincia: '',
    email: '',
    telefono: '',
    checkProfilazione: false,
    checkComunicazioniDaTootor: false,
    checkMarketingSocietaTerze: false,
  });

  const currencyChangeCbs = useRef<{ [key: string]: () => void }>({});
  const onCurrencyChange = useCallback((key: string, cb) => (currencyChangeCbs.current[key] = cb), []);

  const playerUpdateCbs = useRef<{ [key: string]: () => void }>({});
  const onPlayerUpdate = useCallback((key: string, cb) => (playerUpdateCbs.current[key] = cb), []);

  const experienceGainCbs = useRef<{ [key: string]: (amount: number) => void }>({});
  const onExperienceGain = useCallback((key: string, cb) => (experienceGainCbs.current[key] = cb), []);

  const levelUpCbs = useRef([]);
  const onLevelUp = useCallback((cb) => levelUpCbs.current.push(cb), []);

  const experience = useMemo(() => (player?.experience ? nf.format(player.experience) : '0'), [player]);
  const humanExperience = useMemo(() => {
    if (!player?.experience) return '0';
    return hf(player.experience);
  }, [player?.experience]);

  const currency = useMemo(() => (player?.currency ? nf.format(player.currency) : '0'), [player]);
  const humanCurrency = useMemo(() => {
    if (!player?.currency) return '0';
    return hf(player.experience);
  }, [player?.currency]);

  useEffect(() => {
    console.log(player);
  }, [player]);

  const tryConnect = useCallback(async () => {
    if (!currentUser) return null;
    if (socket) socket.destroy();

    const token = await currentUser.getIdToken(true);
    const ioUrl = (() => {
      if (process.env.NODE_ENV != 'production') return 'http://localhost:5002';
      if (window.location.href.includes('tootor-dev')) return 'https://gaming-dev.tootor.it';
      return 'https://play.tootor.it';
    })();
    return io(ioUrl, {
      query: { lang: currentLanguage },
      auth: {
        uid: currentUser.uid,
        email: currentUser.email,
        token,
        // 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImJmMWMyNzQzYTJhZmY3YmZmZDBmODRhODY0ZTljMjc4ZjMxYmM2NTQiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiTWljaGVsZSBNYXNpbmEiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EtL0FPaDE0R2hOZjR3Xzc1MDVYNGJsUHVrNkVRaXZ1aE14U082UkVJUmxiQkc5PXM5Ni1jIiwiYWRtaW4iOnRydWUsImlzcyI6Imh0dHBzOi8vc2VjdXJldG9rZW4uZ29vZ2xlLmNvbS90b290b3ItZGV2ZWxvcG1lbnQiLCJhdWQiOiJ0b290b3ItZGV2ZWxvcG1lbnQiLCJhdXRoX3RpbWUiOjE2NTgxNDAxMTAsInVzZXJfaWQiOiJzZGU5STh4N21nUFBhTG9wa2JZc3JwU2lyb0EyIiwic3ViIjoic2RlOUk4eDdtZ1BQYUxvcGtiWXNycFNpcm9BMiIsImlhdCI6MTY1ODMxOTczNiwiZXhwIjoxNjU4MzIzMzM2LCJlbWFpbCI6Im1hc2luYW1pY2hlbGVAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsibWFzaW5hbWljaGVsZUBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCIsInNpZ25faW5fc2Vjb25kX2ZhY3RvciI6InBob25lIiwic2Vjb25kX2ZhY3Rvcl9pZGVudGlmaWVyIjoiYzNkYWI5NDItMzg3YS00ZmMxLTk4NmMtODIzNjFiMjVjNTBmIn19.IBIWp5yBlnhbzp3t9ZEj-oeGiAXE8NpcKWBpjviAxXqAOL7MSxPRtduDjEaLppGu4mfU-56QkYpHdR920Fc35Ila8wcEtwR2tDjhF5HlquwycxISc-wDgHCfKWOi-FTpGlUnCFHMPJPgGEGFBpkWxMJB-Yr0Wv7DavAWRXs9MKyh--F3PrLhUOOSMA88uwCsbTgLSE-MAYFzc3TPvAwD12XZvv_Gq_TGnp4i5Ztyof-xt33LFDBCrBZOgnpKTIe-CPlaGFYlp0HMMgP1e82u8BG-ggCi7k0MqQN2wGghLoI4bV4BRD0regRpbT23wD7-39ENzRPyh3R49exFgqadzw',
      },
    });
  }, [currentUser, socket]);

  // useEffect(() => {
  //   if (!currentUser) setPlayer({});
  // }, [currentUser]);

  const track = (name: string, eventData?: GenericObject, _socket?, _isEnabled = false) => {
    const enabled = isEnabled || _isEnabled;
    if (!enabled) return null;
    const sock = _socket || socket;
    if (!sock) return null;
    const event: GamingEvent = {
      uid: currentUser.uid,
      action: name,
      ts: Date.now(),
      environment: (() => {
        if (process.env.NODE_ENV != 'production') return 'DEV';
        if (window.location.href.includes('tootor-dev')) return 'STAGING';
        return 'PRODUCTION';
      })(),
      payload: eventData,
    };
    sock.emit('track-event', uuid(), JSON.stringify(event));
  };

  const getAchievements = useCallback(() => {
    if (!socket) return;
    socket.emit('get-achievements');
  }, [socket]);

  const openNotification = (notification: any) => {
    if (!notification.read) {
      const id = notification.id ?? notification.data?.id;
      readNotification(id);
      if (id) track('READ_NOTIFICATION', { id });
    }
    const action = notification.data?.action;
    if (!action) return;
    if (action == 'request_data' && !notification.data.form) {
      if (showDataModal) return;
      setOpenedNotification(notification);
      setShowDataModal(true);
    } else if (action.startsWith('goto::')) {
      const [, path] = action.split('::');
      history.push(path);
    }
  };

  const ranOnce = useRef(false);

  const prevNotifications = new Set<string>();
  useEffect(() => {
    (async () => {
      if (!io) return;
      if (ranOnce.current) return;
      if (!currentUser) return;
      ranOnce.current = true;
      try {
        const socket = await tryConnect();
        if (!socket) return;

        setSocket(socket);
        setSocketFire(socket);

        socket.onAny((event, ...args) => {
          console.log('<<< %s', event);
          // console.group(event.toUpperCase());
          // for (const arg of args) console.log(arg);
          // console.groupEnd();
        });

        socket.on('connected', (player, achievements, missions) => {
          setPlayer(player);
          setAchievements(achievements);
          setMissions(missions);
        });

        socket.on('gaming-enabled', (isEnabled) => {
          setIsEnabled(isEnabled);
          if (isEnabled) track('LOGIN', null, socket, isEnabled);
        });

        socket.on('please-reconnect', () => {
          window.location.reload();
        });

        socket.on('player-update', (event) => {
          setPlayer((current) => ({ ...current, ...event, currency: current.currency, experienceGranted: null, levelUp: 0 }));
          Object.values(playerUpdateCbs.current).forEach((cb) => cb());
          if (event.experienceGranted) Object.values(experienceGainCbs.current).forEach((cb) => cb(event.experienceGranted));
          if (event.levelUp) levelUpCbs.current.forEach((cb) => cb());
        });

        socket.on('get-achievements-response', (achievements) => {
          setAchievements((prev) => {
            const updatedAchievements = new Map<String, any>();
            for (const achievement of achievements) {
              updatedAchievements.set(achievement.id, achievement);
            }
            return prev.map((elem) => {
              if (updatedAchievements.has(elem.id)) return updatedAchievements.get(elem.id);
              return elem;
            });
          });
        });

        socket.on('get-missions-response', (missions) => {
          setMissions(missions);
        });

        socket.on('fetch-notifications', (notifications) => {
          addNotifications(notifications);
        });

        socket.on('currency-change', ({ amount }) => {
          setPlayer((current) => ({ ...current, currency: (current.currency || 0) + amount }));
          Object.values(currencyChangeCbs.current).forEach((cb) => cb());
        });

        socket.on('new-notification', (event) => {
          console.log(event);
          const { notification, data, ...rest } = event;
          if (prevNotifications.has(data.id)) return;
          prevNotifications.add(data.id);
          const action = data?.action;
          if (action == 'request_data') {
            setPauseVideo(true);
            setOpenedNotification({ ...notification, data });
            setShowDataModal(true);
            socket.emit('get-missions');
            setValidBonus(data.bonusDocumentId);
          }
          addNotifications([{ ...notification, data, read: false }]);
          if (data?.['payload.__name'] == 'badgeUnlock') {
            setAchievementUnlocked({
              name: data['payload.body'],
              difficulty: data['payload.difficulty'],
              exp: data['payload.exp_reward'],
              image: data['payload.image'],
            });
          }
          toast(
            <div
              className={'d-flex align-items-center'}
              onClick={() => {
                openNotification({ ...notification, data: data });
              }}
            >
              <FontAwesomeIcon icon={faBell} className={'mr-3'} />
              <div>
                <div>{notification.title}</div>
                <div className={'text-muted'}>{notification.body}</div>
              </div>
            </div>
          );
        });

        socket.on('connect_error', (error) => {
          try {
            const [, code] = error.toString().match(/(\d+)/);
            if (code == 401) tryConnect();
            else console.error(error);
          } catch {
            Sentry.captureException(error);
            console.error(error);
          }
        });
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
      }
    })();
  }, [currentUser, staticData?.ENABLE_GAMING, userDataFmt?.experiments?.[Experiments.Gaming]]);

  const value: IGamingContext = {
    nf: nf.format,
    hf,
    track,
    config,
    player,
    experience,
    humanExperience,
    currency,
    humanCurrency,
    achievements,
    missions,
    socket,
    isEnabled,
    getAchievements,
    onCurrencyChange,
    onPlayerUpdate,
    onExperienceGain,
    onLevelUp,

    openNotification,
    openedNotification,
    setOpenedNotification,
    showDataModal,
    setShowDataModal,
    setPauseVideo,
    pauseVideo,
    formLoading,
    setFormLoading,
    formData,
    setFormData,

    achievementUnlocked,
    setAchievementUnlocked,
  };

  return <GamingContext.Provider value={value}>{children}</GamingContext.Provider>;
};
