import { Badge, Button, ButtonGroup, Card, Container, Form, Modal, ProgressBar } from 'react-bootstrap';
import { useGaming } from '../../../contexts/GamingContext';
import { Footer } from '../../components/Footer/Footer';
import { Achievement } from '../../components/Gaming/Achievement/Achievement';
import { Exp } from '../../components/Gaming/Exp/Exp';
import { Multiplier } from '../../components/Multiplier/Multiplier';
import { Title } from '../../components/Title/Title';
import { Topbar } from '../../components/Topbar/Topbar';
import './Play.scss';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns/esm';
import { useCurrentLanguage } from '../../../hooks/useCurrentLanguage';
import { Redirect } from 'react-router-dom';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';

const PlayComponent = () => {
  const { userDataFmt, staticData } = useFirebase();
  const { player, achievements, missions, track, experience, config, setShowDataModal, setOpenedNotification } = useGaming();
  const { currentLanguage } = useCurrentLanguage();

  const [debugToolsOpen, setDebugToolsOpen] = useState(false);
  const [guidVideo, setGuidVideo] = useState('');

  if (!player || !userDataFmt || !config) return null;

  if (!staticData.ENABLE_GAMING_FOR_LANGS) return <FullscreenLoader />;
  if (!staticData.ENABLE_GAMING_FOR_LANGS.includes(currentLanguage)) {
    return <Redirect to={'/browse'} />;
  }

  const maxStreak = Math.max(...Object.keys(config.loginStreak).map(Number));

  const scrollToTop = () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  return (
    <>
      <Title>Gamification</Title>

      <div style={{ marginBottom: '72px' }}>
        <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
          <Topbar />
        </div>
      </div>

      <div className={'back-to-top-btn'}>
        <Button className="rounded-circle" onClick={scrollToTop}>
          <FontAwesomeIcon icon={faChevronUp} />
        </Button>
      </div>

      <Container className={'my-5'}>
        <div className={'display-4 mb-3'}>
          <div className={'d-flex align-items-center justify-content-center w-100 mx-auto'}>
            <img className={'w-100'} src="https://d260e0gu5nbefz.cloudfront.net/gaming/banners/banner_gamification.jpg" />
          </div>
          {!!userDataFmt.admin && (
            <>
              <Button variant={'outline-warning'} onClick={() => setDebugToolsOpen(true)}>
                Debug tools
              </Button>
              <Modal show={debugToolsOpen} onHide={() => setDebugToolsOpen(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Debug tools</Modal.Title>
                </Modal.Header>
                <Modal.Body className={'gaming-debug-tools'}>
                  <Form.Group>
                    <Form.Control placeholder={'GUID video'} value={guidVideo} onChange={(e) => setGuidVideo(e.target.value)} />
                  </Form.Group>
                  <ButtonGroup size={'sm'}>
                    <Button onClick={() => track('VIDEO_WATCH', { videoId: '9e76ec91-8409-4bee-ba15-db0d3fd44e78', bonus: true })} disabled={!guidVideo}>
                      VIDEO_WATCH++
                    </Button>
                    <Button onClick={() => track('VIDEO_RATING', { videoId: '9e76ec91-8409-4bee-ba15-db0d3fd44e78' })} disabled={!guidVideo}>
                      VIDEO_RATING++
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup size={'sm'}>
                    <Button onClick={() => track('__DEBUG_NOTIFICATION__')}>NOTIFICATION</Button>
                  </ButtonGroup>
                  <ButtonGroup size={'sm'}>
                    <Button onClick={() => track('__DEBUG_INCREASE_STREAK__')}>STREAK++</Button>
                    <Button onClick={() => track('__DEBUG_DECREASE_STREAK__')} disabled={player.loginStreak <= 1}>
                      STREAK--
                    </Button>
                    <Button onClick={() => track('__DEBUG_RESET_STREAK__')}>STREAK=0</Button>
                  </ButtonGroup>
                  <ButtonGroup size={'sm'}>
                    <Button variant={'warning'} onClick={() => track('NOOP')}>
                      NOOP
                    </Button>
                    <Button variant={'danger'} onClick={() => track('__DEBUG_RESET__')}>
                      RESET PLAYER
                    </Button>
                  </ButtonGroup>
                </Modal.Body>
              </Modal>
            </>
          )}
        </div>

        <Card bg={'dark'}>
          <Card.Header style={{ border: 'none' }}>
            <div className={'mb-2 d-flex align-items-center justify-content-between'}>
              <Card.Title className={'mb-0'}>Livello {player.level}</Card.Title>
              <div className={'small text-secondary'}>
                <Multiplier multiplier={player.multiplier} /> Moltiplicatore
              </div>
              <div className={'small text-secondary'}>
                <Exp /> Esperienza totale: <span className={'text-light'}>{experience}</span>
              </div>
              <div className={'small text-secondary'}>
                Progresso per livello successivo: <span className={'text-light'}>{Math.floor(player.levelProgress * 100)}%</span>
              </div>
            </div>
            <ProgressBar className={'level-progress-bar'}>
              <ProgressBar min={0} max={1} now={player.levelProgress} label={`${player.levelExperience}/${player.nextLevelExperience}`} variant={'primary'} />
            </ProgressBar>
          </Card.Header>
        </Card>

        <Card bg={'dark'} className={'mt-3'}>
          <Card.Header>
            <Card.Title className={'mb-0'}>Accessi consecutivi: {player.loginStreak}</Card.Title>
          </Card.Header>
          <Card.Body>
            <div className={'mb-2'}>Moltiplicatore esperienza</div>
            <div className={'login-streak-row'}>
              {Array.from({ length: maxStreak }, (_, i) => i + 1).map((i) => (
                <div key={i.toString()} className={i <= player.loginStreak ? 'bg-success' : ''}>
                  {(i == player.loginStreak || (i == maxStreak && player.loginStreak > maxStreak)) && (
                    <>
                      <small>Attuale</small>&nbsp;
                      <Badge className={'text-dark'}>{player.multiplier}x</Badge>
                    </>
                  )}
                  {!!config.loginStreak[i] && i > player.loginStreak && (
                    <>
                      <small>Giorno {i}</small>&nbsp;
                      <Multiplier multiplier={config.loginStreak[i]} />
                    </>
                  )}
                </div>
              ))}
            </div>
          </Card.Body>
        </Card>

        {!!missions.length && (
          <Card bg={'dark'} className={'mt-3'}>
            <Card.Header>
              <Card.Title className={'mb-0'}>Eventi speciali</Card.Title>
            </Card.Header>
            <Card.Body className="p-0">
              {missions.map((x) => (
                <div className={'d-flex align-items-center'} style={{ padding: '0.7rem 1.4rem', borderBottom: '1px solid #dcdcdc2e' }}>
                  <div key={x.data.id} className={'d-flex flex-column flex-grow-1'}>
                    {x.videoBonus && <span className={''}>{x.videoBonus.title}</span>}
                    {x.bonusName && <span className={''}>{x.bonusName}</span>}
                    {!x.data.redeemed && !!x.data.to && (
                      <span className={'text-muted'}>Riscatta il premio entro il {format(new Date(x.to._seconds * 1000), 'dd/MM/yyyy')}</span>
                    )}
                    {x.data.redeemed && !!x.data.redeemedAt && (
                      <span className={'text-muted'}>Premio riscattato il {format(new Date(x.data.redeemedAt._seconds * 1000), 'dd/MM/yyyy')}</span>
                    )}
                  </div>
                  <div>
                    {!x.data.form && (
                      <Button
                        size={'sm'}
                        onClick={() => {
                          setShowDataModal(true);
                          setOpenedNotification(x);
                        }}
                      >
                        Riscatta
                      </Button>
                    )}
                    {x.data.form && (
                      <Badge variant={'success'}>
                        <FontAwesomeIcon icon={faCheck} /> Riscattato
                      </Badge>
                    )}
                  </div>
                </div>
              ))}
            </Card.Body>
          </Card>
        )}

        <Card bg={'dark'} className={'mt-3'}>
          <Card.Header>
            <Card.Title className={'mb-0'}>Badge</Card.Title>
          </Card.Header>
          <Card.Body>
            <div className={'badge-container'}>
              {achievements.map((badge, i) => (
                <Achievement badge={badge} key={i.toString()} />
              ))}
            </div>
          </Card.Body>
        </Card>
      </Container>

      <Footer />
    </>
  );
};

export default PlayComponent;
