import { Button, Modal } from 'react-bootstrap';
import { Dispatch, SetStateAction } from 'react';
import { useCapacitor } from '../../../hooks/useCapacitor';
import { useTranslation } from 'react-i18next';

const ErrorModalComponent = ({
  isModalOpen,
  setIsModalOpen,
  errorCode,
  errorMessage,
}: {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  errorCode?: string;
  errorMessage?: string;
}) => {
  const { t } = useTranslation();

  const errorMap = {
    'auth/user-not-found': {
      title: t('errorModal.labels.UtenteNonTrovato'),
      message: t('errorModal.labels.UtenteNonTrovatoDescrizione'),
    },
    'auth/email-already-in-use': {
      title: t('errorModal.labels.emailEsistente'),
      message: t('errorModal.labels.emailEsistenteDescrizione'),
    },
    'auth/wrong-password': {
      title: t('errorModal.labels.passwordErrata'),
      message: t('errorModal.labels.passwordErrataDescrizione'),
    },
    'auth/too-many-requests': {
      title: t('errorModal.labels.accessoSospeso'),
      message: t('errorModal.labels.accessoSospesoDescrizione'),
    },
    'auth/invalid-email': {
      title: t('errorModal.labels.emailNonValida'),
      message: t('errorModal.labels.emailNonValida'),
    },
    'auth/weak-password': {
      title: t('errorModal.labels.passwordDebole'),
      message: t('errorModal.labels.passwordDeboleDescrizione'),
    },
    'auth/invalid-verification-code': {
      title: t('errorModal.labels.codiceNonValido'),
      message: t('errorModal.labels.codiceNonValidoDescrizione'),
    },
    'auth/user-mismatch': {
      title: t('errorModal.labels.utenteNonValido'),
      message: t('errorModal.labels.utenteNonValidoDescrizione'),
    },
    'auth/code-expired': {
      title: t('errorModal.labels.codiceScaduto'),
      message: t('errorModal.labels.codiceScadutoDescrizione'),
    },
    'auth/multi-factor-auth-required': {
      title: t('errorModal.labels.necessariaSicurezzaAvanzata'),
      message: t('errorModal.labels.necessariaSicurezzaAvanzataDescrizione'),
    },
    'custom/google-login-needed': {
      title: t('errorModal.labels.necessarioAccessoGoogle'),
      message: t('errorModal.labels.necessarioAccessoGoogleDescrizione'),
    },
    'custom/password-signup-required': {
      title: t('errorModal.labels.utenteInesistente'),
      message: t('errorModal.labels.utenteInesistenteDescrizione'),
    },
    'custom/locationError': {
      title: t('errorModal.labels.profiloNonAbilitato'),
      message: t('errorModal.labels.profiloNonAbilitatoDescrizione'),
    },
  };

  const error = errorMap[errorCode] ?? {
    title: t('labels.errore'),
    message: errorMessage ?? `${t('errorModal.labels.siEVerificatoErrore')} ${errorCode}`,
  };

  const { isNative } = useCapacitor();

  return (
    <Modal show={isModalOpen} centered={isNative} onHide={() => setIsModalOpen(false)}>
      <Modal.Header>
        <Modal.Title>{error.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{error.message}</Modal.Body>
      <Modal.Footer>
        <Button variant={'primary'} onClick={() => setIsModalOpen(false)}>
          {t('labels.chiudi')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { ErrorModalComponent as ErrorModal };
