import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Title } from '../../components/Title/Title';
import { Topbar } from '../../components/Topbar/Topbar';
import { Card, Container } from 'react-bootstrap';
import { Footer } from '../../components/Footer/Footer';
import './ActyCategories.scss';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useEffect, useState } from 'react';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';

const ActyCategoriesComponent = () => {
  const { getActyTutorCategories } = useFirebase();
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const [actyTutorCategories, setActyTutorCategories] = useState([]);

  useEffect(() => {
    getActyTutorCategories()
      .then(setActyTutorCategories)
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  if (loading) return <FullscreenLoader fullscreen={true} />;

  return (
    <>
      <Title>{t('acty.labels.categorieTitle')}</Title>

      <div style={{ marginBottom: '72px' }}>
        <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
          <Topbar />
        </div>
      </div>

      <Container className={'my-5'}>
        <Container
          className={'one-to-one-box justify-content-center align-items-center pe-none'}
          style={{ backgroundImage: `url(/${process.env.PUBLIC_URL}one-to-one/categorie.jpg)`, pointerEvents: 'none' }}
          onClick={() => {
            history.push(`/acty/appointments`);
          }}
        >
          <h2 className={'mb-0 crop-text-2'}>{t('acty.labels.prenotaLiveMentoring')}</h2>
          <div className={'course-image-wrapper'}></div>
        </Container>
        <div className={'acty-cards-container justify-content-start justify-content-sm-center'}>
          {actyTutorCategories.map((category) => (
            <Container
              key={category.id}
              className={'one-to-one-box border-0 category-box justify-content-center m-0'}
              style={{ backgroundImage: `url(${category.imageUrl})` }}
              onClick={() => {
                history.push(`/acty/categories/${category.id}/speakers`);
              }}
            >
              <div className={'d-flex justify-content-center align-items-center'}>
                <h4 className={'mb-0 crop-text-2'}>{category.label}</h4>
              </div>
              <div className={'course-image-wrapper'}></div>
            </Container>
          ))}
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default ActyCategoriesComponent;
