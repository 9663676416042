import { useCurrentLanguage } from '../../../hooks/useCurrentLanguage';
import './LanguageSelector.scss';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { CountryFlag } from '../CountryFlag/CountryFlag';
import { getLanguageName } from '../../utils/utils';

export const LanguageSelector = ({ className = '', size = 'sm' }) => {
  const { currentLanguage, supportedLanguages, updateLanguage } = useCurrentLanguage();

  const getImageUrl = (state: string) => `https://flagcdn.com/16x12/${state == 'en' ? 'gb' : state}.png`;

  if (!currentLanguage) return null;

  return (
    <DropdownButton
      variant={'dark'}
      title={<img src={getImageUrl(currentLanguage.toLowerCase())} alt={currentLanguage} className={'mr-1'} />}
      size={size as 'sm' | 'lg'}
      className={className}
    >
      {supportedLanguages.map((lang) => (
        <Dropdown.Item
          key={lang}
          disabled={lang == currentLanguage}
          className={lang == currentLanguage ? 'language-selector-item-disabled' : ''}
          onClick={() => updateLanguage(lang)}
        >
          {lang == currentLanguage && <FontAwesomeIcon icon={faCheckCircle} className={'text-success'} />}
          {lang != currentLanguage && <CountryFlag language={lang} />}
          <span className={'ml-2'}>{getLanguageName(lang)}</span>
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};
