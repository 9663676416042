import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faListAlt, IconDefinition as RegularIconDefinition } from '@fortawesome/free-regular-svg-icons';
import {
  faBars,
  faBell,
  faBook,
  faGamepad,
  faGraduationCap,
  faHollyBerry,
  faRssSquare,
  faSearch,
  faUserGraduate,
  faHandshake,
  faVideo,
  IconDefinition as SolidIconDefinition,
  faCalendar,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { Badge, Button, Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useGaming } from '../../../contexts/GamingContext';
import { useGeolocation } from '../../../contexts/GeolocationContext';
import { useCapacitor } from '../../../hooks/useCapacitor';
import { useCurrentLanguage } from '../../../hooks/useCurrentLanguage';
import { useMobileDetect } from '../../../hooks/useMobileDetect';
import { useQuery } from '../../../hooks/useQuery';
import { canAccessFeature, isItalian } from '../../utils/utils';
import { IconButton } from '../IconButton/IconButton';
import { LanguageSelector } from '../LanguageSelector/LanguageSelector';
import { LoginModal } from '../LoginModal/LoginModal';
import { Logo } from '../Logo/Logo';
import { NotificationsList } from '../NotificationsList/NotificationsList';
import { ProfileCircle } from '../ProfileCircle/ProfileCircle';
import './Topbar.scss';

interface ButtonDefinition {
  url?: string;
  externalUrl?: string;
  icon?: SolidIconDefinition | RegularIconDefinition;
  label?: string;
  condition?: () => boolean;
  normalClass?: string;
  activeClass?: string;
  skipDefaultClass?: boolean;
  onClick?: () => void;
}

const TopbarButton = ({ buttonDefinition, pagePath, margin = true }: { buttonDefinition: ButtonDefinition; pagePath: string; margin?: boolean }) => {
  const { url, icon = null, label, normalClass = '', activeClass = '', skipDefaultClass, externalUrl } = buttonDefinition;
  const history = useHistory();

  return (
    <Button
      onClick={() => {
        if (externalUrl) window.open(externalUrl, '_blank');
        else history.push(url);
      }}
      variant={'outline-light'}
      disabled={pagePath == url}
      active={pagePath == url}
      className={classNames(normalClass, { 'topbar-button': !skipDefaultClass, 'mr-1': margin, [activeClass]: pagePath == url })}
      style={{ border: 'none' }}
    >
      <div style={{ whiteSpace: 'nowrap' }}>
        {!!icon && <FontAwesomeIcon icon={icon} />}
        {label && <span className={`d-none d-xl-inline-block ${icon ? ' ml-0 ml-lg-2' : ''}`}>{label}</span>}
      </div>
    </Button>
  );
};

const TopbarDropdownNavItem = ({ url, pagePath, icon = null, label, onClick = null }) => (
  <Dropdown.Item as={Link} to={url} disabled={pagePath == url} onClick={onClick}>
    <div className={'d-flex justify-content-between align-items-center'}>
      <span className={pagePath == url ? 'text-primary' : ''}>{label}</span>
      {!!icon && <FontAwesomeIcon size={'xs'} icon={icon} className={'ml-2 ' + (pagePath == url ? 'text-primary' : 'text-muted')} />}
    </div>
  </Dropdown.Item>
);

const TopbarComponent = ({ bg = 'transparent', variant = 'dark', inHeader = false }) => {
  const { redirectTo } = useQuery();
  const { isLoggedIn, notificationBadge, cleanNotifications, subscription, userDataFmt, staticData, userCompanyData } = useFirebase();
  const { isEnabled: isGamingEnabled } = useGaming();
  const { isNative } = useCapacitor();
  const { isMobile } = useMobileDetect();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [pagePath, setPagePath] = useState<string>(null);
  const [isAso, setIsAso] = useState(false);
  const [companyId, setCompanyId] = useState<string>(null);
  const [companyName, setCompanyName] = useState<string>(null);
  const [isCompanyEnabled, setIsCompanyEnabled] = useState(false);
  const { t } = useTranslation();
  const { country_code } = useGeolocation();
  const { currentLanguage } = useCurrentLanguage();

  const buttons: ButtonDefinition[] = useMemo(
    () => [
      {
        url: '/browse',
        icon: faVideo,
        label: t('labels.video'),
      },
      {
        url: '/courses',
        icon: faGraduationCap,
        label: t('labels.corsi'),
        condition: () => !!isAso && isItalian(country_code),
      },
      {
        url: '/list',
        icon: faListAlt,
        label: t('labels.mieiToot'),
      },
      {
        url: '/tootors',
        icon: faUserGraduate,
        label: t('labels.tootors'),
        condition: () => !!staticData.SHOW_TOOTORS_LIST,
      },
      {
        url: '/play',
        icon: faGamepad,
        label: 'Gamification',
        condition: () => isGamingEnabled,
      },
      {
        url: '/books',
        icon: faBook,
        label: 'Library',
        condition: () => canAccessFeature('BOOKS', currentLanguage, staticData, userDataFmt),
      },
      {
        url: `/company/${companyId}/courses`,
        icon: faHandshake,
        label: companyName,
        condition: () => !!staticData.ENABLE_COMPANIES && companyId != null && isCompanyEnabled && !isAso,
      },
      {
        url: '/acty',
        icon: faCalendar,
        label: t('acty.labels.title'),
        condition: () => !!staticData.ENABLE_ACTY,
      },
      {
        url: '/live',
        icon: faYoutube,
        label: 'Tootor Live',
        condition: () => canAccessFeature('LIVE', currentLanguage, staticData, userDataFmt),
      },
      {
        url: '/advent',
        icon: faHollyBerry,
        label: t('labels.calendarioAvvento'),
        condition: () => !!staticData.SHOW_ADVENT_CALENDAR_2022 && !isAso && currentLanguage === 'it',
        skipDefaultClass: true,
        normalClass: 'advent-calendar-button',
        activeClass: 'advent-calendar-button-active',
      },
      {
        externalUrl: 'https://blog.tootor.it',
        url: 'https://blog.tootor.it',
        icon: faRssSquare,
        label: 'Blog',
        condition: () => currentLanguage === 'it',
        onClick: () => window.open('https://blog.tootor.it', '_blank'),
      },
    ],
    [isAso, country_code, isGamingEnabled, staticData, userDataFmt, currentLanguage, companyName, isCompanyEnabled]
  );

  useEffect(() => {
    if (!redirectTo) return;
    setIsLoginModalOpen(true);
  }, [redirectTo]);

  useEffect(() => {
    setPagePath(window.location.pathname);
  }, []);

  useEffect(() => {
    if (!userDataFmt) return;
    const myCategory = userDataFmt.profile?.formData?.categoriaProfessionale;
    setIsAso(myCategory == 'aso');
  }, [userDataFmt]);

  useEffect(() => {
    if (!userCompanyData) return;
    const companyId = userCompanyData?.id;
    const companyName = userCompanyData?.name;
    const isCompanyEnabled = !!userCompanyData?.enabled;

    setCompanyId(companyId);
    setCompanyName(companyName);
    setIsCompanyEnabled(isCompanyEnabled);
  }, [userCompanyData]);

  return (
    <>
      <Navbar className={'tootor-app-bar'} bg={bg} variant={variant as 'dark' | 'light'} sticky={'top'}>
        <Container fluid>
          <Navbar.Brand>
            {!isLoggedIn && !isNative && currentLanguage === 'it' && (
              <Dropdown className={'d-inline-block d-md-none mr-2'}>
                <Dropdown.Toggle as={MenuButtonToggle} />
                <Dropdown.Menu>
                  {currentLanguage === 'it' && (
                    <TopbarDropdownNavItem
                      key={'https://blog.tootor.it'}
                      url={'https://blog.tootor.it'}
                      label={'Blog'}
                      icon={faRssSquare}
                      pagePath={pagePath}
                      onClick={() => window.open('https://blog.tootor.it', '_blank')}
                    />
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}
            {isLoggedIn && !isNative && (
              <Dropdown className={'d-inline-block d-md-none mr-2'}>
                <Dropdown.Toggle as={MenuButtonToggle} />
                <Dropdown.Menu>
                  {buttons.map((button) => {
                    const element = (
                      <TopbarDropdownNavItem
                        key={button.url}
                        pagePath={pagePath}
                        url={button.url}
                        icon={button.icon}
                        label={button.label}
                        onClick={button.onClick}
                      />
                    );
                    if (!button.condition || button.condition()) return element;
                    return null;
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )}
            {!inHeader && <Logo className={'logo-tootor d-inline d-md-none'} variant={'reduced'} clickable />}
            {!inHeader && <Logo className={'logo-tootor d-none d-md-inline'} clickable />}
            {!isLoggedIn && currentLanguage === 'it' && (
              <div className={'d-inline-flex align-items-center ml-4'}>
                <div className={'d-none d-md-block'}>
                  <TopbarButton
                    pagePath={pagePath}
                    key={'https://blog.tootor.it'}
                    buttonDefinition={{
                      externalUrl: 'https://blog.tootor.it',
                      url: 'https://blog.tootor.it',
                      icon: faRssSquare,
                      label: 'Blog',
                      condition: () => currentLanguage === 'it',
                      onClick: () => window.open('https://blog.tootor.it', '_blank'),
                    }}
                  />
                </div>
              </div>
            )}
            {isLoggedIn && (
              <div className={'d-inline-flex align-items-center ml-4'}>
                <div className={'d-none d-md-block'}>
                  {buttons.map((button) => {
                    const element = <TopbarButton pagePath={pagePath} key={button.url} buttonDefinition={button} />;
                    if (!button.condition || button.condition()) return element;
                    return null;
                  })}
                </div>
              </div>
            )}
          </Navbar.Brand>
          <Nav className={'ml-auto d-flex align-items-center'}>
            {userDataFmt?.profile?.formData?.categoriaProfessionale !== 'aso' && <LanguageSelector className={'mr-2'} />}
            {!isLoggedIn && (
              <Button variant={'warning'} className={'tootor-palette-warning'} onClick={() => setIsLoginModalOpen(true)} size={'sm'}>
                {t('labels.accedi')}
              </Button>
            )}
            {isLoggedIn && (
              <>
                {!subscription && !isNative && (
                  <Button variant={'primary'} as={Link} to={'/plans'} className={'mr-2'} size={'sm'}>
                    {t('labels.abbonati')}
                  </Button>
                )}
                {(!!subscription || !isMobile || isNative) && (
                  <>
                    <IconButton icon={faSearch} to={'/search'} className={'tootor-app-bar-icon-button ' + (isGamingEnabled ? '' : 'mr-2')} />
                    {/* {!isNative && <TopbarButton pagePath={pagePath} buttonDefinition={{ url: '/search', icon: faSearch, label: '' }} margin={false} />} */}
                    <Dropdown
                      className={'mr-2'}
                      onToggle={(isOpen) => {
                        if (isOpen) return;
                        cleanNotifications();
                      }}
                    >
                      <Dropdown.Toggle as={NotificationButtonToggle}>{notificationBadge}</Dropdown.Toggle>
                      <Dropdown.Menu align={'right'} style={{ minWidth: '300px', maxHeight: '500px', overflow: 'auto' }}>
                        <Dropdown.Header>{t('labels.notifiche')}</Dropdown.Header>
                        <NotificationsList isDropdown={true} />
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                )}

                <ProfileCircle />
              </>
            )}
          </Nav>
        </Container>
      </Navbar>

      <LoginModal isModalOpen={isLoginModalOpen} setIsModalOpen={setIsLoginModalOpen} redirectTo={redirectTo} />
    </>
  );
};

export { TopbarComponent as Topbar };

const MenuButtonToggle = forwardRef(({ onClick }: any, _) => (
  <Button
    variant={'outline-light'}
    className={'no-border'}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <FontAwesomeIcon icon={faBars} />
  </Button>
));

const NotificationButtonToggle = forwardRef(({ onClick, children: notificationBadge }: any, _) => (
  <IconButton
    className={'tootor-app-bar-icon-button'}
    icon={faBell}
    badge={<Badge variant={'danger'}>{!!notificationBadge && notificationBadge}</Badge>}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  />
));
