import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faCircleNotch, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { WithTooltip } from '../WithTooltip/WithTooltip';
import { useTranslation } from 'react-i18next';
import { useGaming } from '../../../contexts/GamingContext';

const MyListButtonComponent = ({ videoId, className = '', block = false, inline = false }) => {
  const { myList, addToMyList, removeFromMyList, myListLoading } = useFirebase();
  const { track } = useGaming();
  const { t } = useTranslation();

  const isVideoInMyList = (videoId: string) => {
    if (!myList) return false;
    return !!myList.find((videoInList) => videoInList.id == videoId);
  };

  if (inline)
    return (
      <WithTooltip text={isVideoInMyList(videoId) ? t('myListButton.labels.rimuoviMieiToot') : t('myListButton.labels.aggiungiMieiToot')} hide={myListLoading}>
        <Button
          disabled={myListLoading}
          variant={'outline-light'}
          className={isVideoInMyList(videoId) ? 'text-success' : ''}
          onClick={(event) => {
            event.stopPropagation();
            if (isVideoInMyList(videoId)) {
              removeFromMyList(videoId);
            } else {
              addToMyList(videoId).then(() => track('ADD_MY_TOOT', { videoId }));
            }
          }}
        >
          <FontAwesomeIcon spin={myListLoading} icon={myListLoading ? faCircleNotch : isVideoInMyList(videoId) ? faCheck : faPlus} fixedWidth={true} />
        </Button>
      </WithTooltip>
    );

  return (
    <Button
      className={className}
      disabled={myListLoading}
      block={block}
      variant={isVideoInMyList(videoId) ? 'success' : 'outline-success'}
      onClick={() => {
        if (isVideoInMyList(videoId)) {
          removeFromMyList(videoId);
        } else {
          addToMyList(videoId).then(() => track('ADD_MY_TOOT', { videoId }));
        }
      }}
      style={{ whiteSpace: 'nowrap' }}
    >
      <FontAwesomeIcon spin={myListLoading} className={'mr-2'} icon={myListLoading ? faCircleNotch : isVideoInMyList(videoId) ? faCheckCircle : faPlusCircle} />
      <span>{t('labels.mieiToot')}</span>
    </Button>
  );
};

export { MyListButtonComponent as MyListButton };
