import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { LegalDocument } from './LegalDocument';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const LegaleseComponent = ({ just, text }: { just?: 'privacy' | 'cookie' | 'tos'; text?: string }) => {
  const { t } = useTranslation();

  const TITLES = {
    PRIVACY: t('legalese.labels.privacy'),
    COOKIE: t('legalese.labels.cookie'),
    TOS: t('legalese.labels.tos'),
    CONTACTS: t('legalese.labels.contactUs'),
  };

  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [cookieModalOpen, setCookieModalOpen] = useState(false);
  const [tosModalOpen, setTosModalOpen] = useState(false);

  const ModalAnchor = ({ children, setter }) => (
    <a
      href={'#'}
      onClick={(e) => {
        e.preventDefault();
        setter(true);
      }}
    >
      {children}
    </a>
  );

  const Copyright = () => (
    <span>
      &copy; {new Date().getFullYear()} {process.env.REACT_APP_TITLE}
    </span>
  );

  const Separator = () => <span className={'mx-1'}>&bull;</span>;

  const PrivacyPolicyTrigger = ({ className = '' }) => (
    <>
      <span className={className}>
        <ModalAnchor setter={setPrivacyModalOpen}>{text || TITLES.PRIVACY}</ModalAnchor>
      </span>

      <Modal size={'lg'} show={privacyModalOpen} onHide={() => setPrivacyModalOpen(false)} scrollable>
        <Modal.Header closeButton>
          <Modal.Title>{TITLES.PRIVACY}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LegalDocument doc={'privacy'} />
        </Modal.Body>
      </Modal>
    </>
  );
  const CookiePolicyTrigger = ({ className = '' }) => (
    <>
      <span className={className}>
        <ModalAnchor setter={setCookieModalOpen}>{text || TITLES.COOKIE}</ModalAnchor>
      </span>

      <Modal size={'lg'} show={cookieModalOpen} onHide={() => setCookieModalOpen(false)} scrollable>
        <Modal.Header closeButton>
          <Modal.Title>{TITLES.COOKIE}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LegalDocument doc={'cookie'} />
        </Modal.Body>
      </Modal>
    </>
  );
  const TermsOfServiceTrigger = ({ className = '' }) => (
    <>
      <span className={className}>
        <ModalAnchor setter={setTosModalOpen}>{text || TITLES.TOS}</ModalAnchor>
      </span>

      <Modal size={'lg'} show={tosModalOpen} onHide={() => setTosModalOpen(false)} scrollable>
        <Modal.Header closeButton>
          <Modal.Title>{TITLES.TOS}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LegalDocument doc={'tos'} />
        </Modal.Body>
      </Modal>
    </>
  );

  if (just == 'privacy') return <PrivacyPolicyTrigger />;
  if (just == 'cookie') return <CookiePolicyTrigger />;
  if (just == 'tos') return <TermsOfServiceTrigger />;

  return (
    <>
      <Copyright />
      <Separator />
      <Link to={'/company-info'}>{t('legalese.labels.informazioniSocietarie')}</Link>
      <Separator />
      <PrivacyPolicyTrigger />
      <Separator />
      <CookiePolicyTrigger />
      <Separator />
      <TermsOfServiceTrigger />
      <Separator />
      <Link to={'/contact-us'}>{t('legalese.labels.contactUs')}</Link>
    </>
  );
};

export { LegaleseComponent as Legalese };
