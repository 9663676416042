import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarFill, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import ReactStars from 'react-rating-stars-component';

const RatingStarsComponent = ({ className = '', onRate = (rating: number) => null, disabled = false }) => {
  return (
    <span style={{ pointerEvents: disabled ? 'none' : null }}>
      <ReactStars
        classNames={className}
        edit={!disabled}
        emptyIcon={<FontAwesomeIcon icon={faStar} />}
        halfIcon={<FontAwesomeIcon icon={faStarHalfAlt} />}
        filledIcon={<FontAwesomeIcon icon={faStarFill} />}
        isHalf={false}
        onChange={onRate}
        size={20}
      />
    </span>
  );
};

export { RatingStarsComponent as RatingStars };
