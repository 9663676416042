import { DetailedHTMLProps, MutableRefObject, VideoHTMLAttributes } from 'react';
import { omit } from 'lodash';

const VideoComponent = (
  props: DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> & { innerRef?: MutableRefObject<HTMLVideoElement> }
) => {
  return (
    <video
      playsInline
      disablePictureInPicture
      controlsList="nodownload"
      onClick={props.onClick}
      {...omit(props, ['innerRef', 'onClick'])}
      ref={props.innerRef || null}
    />
  );
};

export { VideoComponent as Video };
