import { FunctionComponent } from 'react';
import { Redirect, Route, RouteComponentProps, useHistory } from 'react-router-dom';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { FullscreenLoader } from '../FullscreenLoader/FullscreenLoader';
import { Button, Modal } from 'react-bootstrap';
import { shouldCompleteFirstLogin } from '../../utils/utils';
import { ContactMaso } from '../ContactMaso/ContactMaso';

interface AdminRoutePropTypes {
  [key: string]: any;
  component?: FunctionComponent<RouteComponentProps>;
  path: string;
  children?: JSX.Element;
}

const AdminRouteComponent = ({ component: Component, path, children, ...rest }: AdminRoutePropTypes) => {
  const { currentUser, userDataFmt } = useFirebase();
  const history = useHistory();

  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        if (!currentUser) return <Redirect to={`/`} />;
        if (!userDataFmt) return <FullscreenLoader />;
        if (!userDataFmt.admin) return <Redirect to={`/`} />;
        if (shouldCompleteFirstLogin(userDataFmt)) return <Redirect to={'/first-login'} />;
        if (process.env.NODE_ENV != 'development' && !currentUser.multiFactor.enrolledFactors.length && !path.includes('2fa'))
          return (
            <Modal show={true} centered backdrop={'static'} keyboard={false}>
              <Modal.Header>
                <Modal.Title>Necessaria autenticazione a due fattori</Modal.Title>
              </Modal.Header>
              <Modal.Body>Per usufruire delle funzionalità di amministrazione è obbligatoria l'attivazione dell'autenticazione a due fattori.</Modal.Body>
              <Modal.Footer>
                <Button variant={'secondary'} onClick={() => history.goBack()}>
                  Torna indietro
                </Button>
                <Button variant={'primary'} onClick={() => history.push(`/admin/2fa?mfaEnroll=1`)}>
                  Vai al profilo
                </Button>
              </Modal.Footer>
            </Modal>
          );
        if (Component)
          return (
            <>
              <Component {...props} />
              <ContactMaso />
            </>
          );
        return (
          <>
            {children} <ContactMaso />
          </>
        );
      }}
    />
  );
};

export { AdminRouteComponent as AdminRoute };
