import { useCallback, useEffect, useState } from 'react';
import { throttle } from 'lodash';

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  });
  const memoizedCallback = useCallback(
    throttle(() => {
      setWindowSize({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
    }),
    []
  );

  useEffect(() => {
    window.addEventListener('resize', memoizedCallback);
    memoizedCallback();

    return () => window.removeEventListener('resize', memoizedCallback);
  }, []);

  return windowSize;
};
