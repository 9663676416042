import { Topbar } from '../../components/Topbar/Topbar';
import { Footer } from '../../components/Footer/Footer';
import { Button, Container, Table } from 'react-bootstrap';
import { Title } from '../../components/Title/Title';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useEffect, useState } from 'react';
import { fetchAndSave } from '../../utils/utils';
import { useMobileDetect } from '../../../hooks/useMobileDetect';
import { useTranslation } from 'react-i18next';

const CompanyComponent = () => {
  const { getLegal } = useFirebase();
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState<string>(null);
  const { isDesktop } = useMobileDetect();
  const { t } = useTranslation();

  useEffect(() => {
    getLegal('bal', 2022)
      .then(({ content }) => {
        setUrl(content);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Title>{t('legalese.labels.informazioniSocietarie')}</Title>

      <div style={{ marginBottom: '72px' }}>
        <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
          <Topbar />
        </div>
      </div>

      <Container className={'my-5'}>
        <div className={'d-flex align-items-center'}>
          <div className={'display-4'}>{t('legalese.labels.informazioniSocietarie')}</div>
        </div>

        <div className={'mt-5'}>
          <p>Informazioni ai sensi art. 2250 c.c.</p>
          <p>
            <strong>TOOTOR SRL</strong>
            <br />
            <em>Milano, Via Cusani 10</em>
            <br />
            <em>Codice fiscale e Partita Iva n. 10378400963</em>
            <br />
            <em>Registro Imprese di Milano, Monza-Brianza e Lodi al n. REA MI - 2526862</em>
            <br />
            <em>Capitale sociale 21.980,36 (interamente versato)</em>
          </p>
          <p className={'text-center'}>***</p>
          <p>Informazioni Facoltative:</p>
          <p>
            Indirizzo di Posta Elettronica Certificata: lance.awareness@pec.it
            <br />
            Codice Destinatario per fatturazione: W7YVJK9
          </p>
          <p className={'text-center'}>***</p>
          <p>
            Ai sensi dell'art. 25 comma 11 del DECRETO-LEGGE 18 ottobre 2012, n. 179 si riportano le informazioni elencate nel comma 12 dell'art. 25 della
            medesima legge.
          </p>
          <p>
            <strong>a) data e luogo di costituzione, nome e indirizzo del notaio;</strong>
          </p>
          <p>
            <em>
              TOOTOR SRL è stata costituita in data 1/6/2018 con atto a rogito notaio Dr. ALBA MARIA FERRARA con studio in Milano Via Visconti di Modrone n. 2.
            </em>
          </p>
          <p>
            <strong>b) sede principale ed eventuali sedi periferiche;</strong>
          </p>
          <p>
            <em>Milano, Via Cusani 10</em>
          </p>
          <p>
            <strong>c) oggetto sociale;</strong>
          </p>
          <p>
            La società ha per oggetto prevalente la commercializzazione di corsi di formazione e corsi di aggiornamento on-line nell'ambito del settore
            sanitario mediante la creazione di una piattaforma informatica altamente tecnologica e interattiva mediante la quale i clienti fruiranno dei servizi
            a loro rivolti.
          </p>
          <p>La società altresì come oggetto le seguenti attività:</p>
          <ul>
            <li>
              La commercializzazione (per quanto tale attività non sia riservata a particolari soggetti) di prodotti medicali, odontoiatrici e di strumenti per
              la gestione domiciliare del paziente;
            </li>
            <li>La vendita di spazi pubblicitari, le sponsorizzazioni nel campo della pubblicità;</li>
            <li>L'attività di graphic design, media marketing e new media marketing;</li>
            <li>
              Affissioni (statiche e speciali), creazione di siti, banner, creazione e gestione di profili social, promozioni consumer e trade, progettazione e
              realizzazione di spazi, di planning e buying media, di magazine e newsletter, in store promotion, utilizzando qualunque tipo di comunicazione off
              line, on line e territorio.
            </li>
          </ul>
          <p>Sempre nei limiti dell'oggetto suindicato e per il suo raggiungimento, la società potrà inoltre:</p>
          <ul>
            <li>Chiedere, ottenere, registrare brevetti per marchi d'impresa;</li>
            <li>
              Stipulare contratti di licenza, comprare o comunque acquistare, detenere, usare, possedere, attuare ed introdurre e vendere, assegnare o
              altrimenti disporre di marchi, nomi commerciali, diritti d'autore, brevetti, invenzioni, miglioramenti o procedimenti usati in relazione a
              brevetti di qualsiasi paese;
            </li>
            <li>
              Usare, esercitare, sviluppare, concedere licenze e comunque profittare di tali marchi, brevetti, diritti d'autore, connessioni, processi e simili,
              o di tali beni e diritti.
            </li>
          </ul>
          <p>
            La società può compiere tutte le operazioni commerciali, immobiliari e finanziare che saranno ritenute utili dagli amministratori per il
            conseguimento dell'oggetto sociale, con esclusione di attività finanziare riservate.
          </p>
          <p>
            <strong>d) breve descrizione dell'attività svolta, comprese l'attività e le spese in ricerca e sviluppo;</strong>
          </p>
          <p>
            <em>
              L'attività della TOOTOR Srl si basa in modo prevalente su sviluppo, produzione e commercializzazione di un innovativo servizio di comunicazione
              all'interno del delicato mondo della salute.
              <br />
              Un progetto a particolare valore in termini di innovazione su cui sta lavorando la Tootor Srl è la creazione di una piattaforma streaming di
              contenuti didattici per odontoiatri, medici e altri professionisti della salute. Questa piattaforma ospiterà video realizzati in modo da mettere
              in luce tutte le criticità connesse con manovre e interventi medici, per poi illustrare come risolverle; ciò sarà possibile grazie a un montaggio
              video appositamente studiato, che unisce riprese video su speciali manichini per esercitazioni e interventi chirurgici su paziente realizzati da
              clinici esperti.
              <br />
              Il particolare apporto in termini tecnologici è dovuto all'utilizzo dei più moderni linguaggi di programmazione che verranno impiegati nella
              realizzazione del sito web, della web app e dell'app che permetteranno di accedere al servizio rispettivamente da pc, smart tv e dispositivi
              mobili.
              <br />
              L'algoritmo sviluppato per la piattaforma riconoscerà le abitudini degli iscritti e sottoporrà loro nuovi contenuti affini agli interessi reali
              dell'utente. Per rendere ulteriormente immersiva l'esperienza didattica, sarà possibile fruire di alcuni contenuti anche in modalità VR (Virtual
              Reality). La realtà aumentata è stata già testata in molti campi; studi scientifici presenti in letteratura internazionale testimoniano
              l'efficacia di questo strumento ai fini dell'apprendimento in ambito scientifico.
              <br />
              Inoltre, sulla piattaforma saranno organizzate dirette streaming per dibattere di argomenti di particolare interesse per il pubblico, durante le
              quali gli spettatori potranno interagire in real time con gli streamer, facendo domande o commentando.
              <br />
              Il servizio verrà proposto in abbonamento, a cifre contenute e in multilingua, così da permettere la massima diffusione dei messaggi didattici
              anche in Paesi dove la formazione medica è meno avanzata.
              <br />
              Il progetto si propone di avere un impatto positivo sul settore medico-sanitario, aiutando i professionisti della salute a sviluppare e
              perfezionare tecniche e trattamenti in un modo completamente nuovo. La piattaforma favorirà inoltre la diffusione di approcci clinici diversi tra
              professionisti di tutto il mondo accrescendo così il bagaglio culturale di coloro i quali sono alle prime armi, ma anche dei più esperti.
              <br />
              Le spese di ricerca e sviluppo sono riferibili allo sviluppo tecnologico e di contenuti della piattaforma streaming avente ad oggetto la
              pubblicazione di video didattici per odontoiatri, medici e altri professionisti della salute.
            </em>
          </p>
          <p>
            <strong>e) elenco dei soci, con trasparenza rispetto a fiduciarie, holding, con autocertificazione di veridicità;</strong>
          </p>
          <div className={'mb-3'}>
            <Table striped bordered size={'sm'} variant={'dark'}>
              <thead>
                <tr>
                  <th>Socio</th>
                  <th className={'text-right'}>Valore</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Battaglia Davide Maria</td>
                  <td className={'text-right'}>8.000,77</td>
                </tr>
                <tr>
                  <td>Grohovaz Anna Maria</td>
                  <td className={'text-right'}>3.086,08</td>
                </tr>
                <tr>
                  <td>Azimut Libera Impresa Società di Gestione del Risparmio S.p.A. in forma abbreviata Azimut Libera Impresa S.G.R. S.p.A.</td>
                  <td className={'text-right'}>2.138,82</td>
                </tr>
                <tr>
                  <td>Grohovaz Alessandra</td>
                  <td className={'text-right'}>1.000,77</td>
                </tr>
                <tr>
                  <td>Monticelli Lorenzo</td>
                  <td className={'text-right'}>1.000,00</td>
                </tr>
                <tr>
                  <td>Exabyte S.r.l.</td>
                  <td className={'text-right'}>769,23</td>
                </tr>
                <tr>
                  <td>Edra S.p.A.</td>
                  <td className={'text-right'}>461,78</td>
                </tr>
                <tr>
                  <td>Fancoli Paolo</td>
                  <td className={'text-right'}>396,83</td>
                </tr>
                <tr>
                  <td>Frigerio Luca</td>
                  <td className={'text-right'}>396,83</td>
                </tr>
                <tr>
                  <td>Frigerio Alessandro</td>
                  <td className={'text-right'}>396,83</td>
                </tr>
                <tr>
                  <td>Giannoni Raffaele</td>
                  <td className={'text-right'}>396,83</td>
                </tr>
                <tr>
                  <td>Rossetti Giuditta</td>
                  <td className={'text-right'}>396,83</td>
                </tr>
                <tr>
                  <td>Rossetti Matteo Anna</td>
                  <td className={'text-right'}>396,83</td>
                </tr>
                <tr>
                  <td>Toppino Alberto</td>
                  <td className={'text-right'}>396,83</td>
                </tr>
                <tr>
                  <td>Grohovaz Fabio</td>
                  <td className={'text-right'}>390,96</td>
                </tr>
                <tr>
                  <td>Venegoni Fabio Maria</td>
                  <td className={'text-right'}>334,33</td>
                </tr>
                <tr>
                  <td>Ravelli Luigi</td>
                  <td className={'text-right'}>316,35</td>
                </tr>
                <tr>
                  <td>Ravelli Alessandro</td>
                  <td className={'text-right'}>276,67</td>
                </tr>
                <tr>
                  <td>Gaslini Carla</td>
                  <td className={'text-right'}>238,10</td>
                </tr>
                <tr>
                  <td>Directa SIM S.p.A.</td>
                  <td className={'text-right'}>187,43</td>
                </tr>
                <tr>
                  <td>Bandecchi Federico</td>
                  <td className={'text-right'}>158,73</td>
                </tr>
                <tr>
                  <td>Calloni Laura</td>
                  <td className={'text-right'}>158,73</td>
                </tr>
                <tr>
                  <td>Ponti Veronica</td>
                  <td className={'text-right'}>158,73</td>
                </tr>
                <tr>
                  <td>Traglio Maurizio</td>
                  <td className={'text-right'}>158,73</td>
                </tr>
                <tr>
                  <td>Guaglio Anna Maria</td>
                  <td className={'text-right'}>79,37</td>
                </tr>
                <tr>
                  <td>Borgonovo Andrea Enrico</td>
                  <td className={'text-right'}>77,15</td>
                </tr>
                <tr>
                  <td>Mancini Evangelista Giovanni</td>
                  <td className={'text-right'}>77,14</td>
                </tr>
                <tr>
                  <td>Scarpelli Marco</td>
                  <td className={'text-right'}>77,14</td>
                </tr>
                <tr>
                  <td>Re Santo</td>
                  <td className={'text-right'}>46,28</td>
                </tr>
                <tr>
                  <td>Battaglia Alessandro</td>
                  <td className={'text-right'}>0,79</td>
                </tr>
                <tr>
                  <td>Furlan Dario</td>
                  <td className={'text-right'}>0,77</td>
                </tr>
                <tr>
                  <td>Lanati Antonella</td>
                  <td className={'text-right'}>0,77</td>
                </tr>
                <tr>
                  <td>Alerni Fabio</td>
                  <td className={'text-right'}>0,77</td>
                </tr>
              </tbody>
              {/* <tfoot>
                <tr>
                  <th>CAPITALE SOCIALE</th>
                  <th className={'text-right'}>19.118,27</th>
                </tr>
              </tfoot> */}
            </Table>
          </div>
        </div>
        <p>
          <strong>f) elenco delle società partecipate;</strong>
        </p>
        <p>
          <em>Non sussistono.</em>
        </p>
        <p>
          <strong>
            g) indicazione dei titoli di studio e delle esperienze professionali dei soci e del personale che lavora nella start-up innovativa, esclusi
            eventuali dati sensibili;
          </strong>
        </p>
        <p>
          <em>
            <strong>DAVIDE MARIA BATTAGLIA, nato a Milano nel maggio 1990.</strong>
            <br />
            Nel 2009 consegue la maturità classica presso il Liceo Classico Statale Giovanni Berchet di Milano.
            <br />
            Nel 2015 si laurea con lode in Odontoiatria e Protesi Dentaria presso l'Università degli Studi di Milano. Laurea Magistrale.
            <br />
            Nel 2017 consegue con lode il master di II livello Odontologia Forense presso l'Università degli Studi di Firenze.
            <br />
            Già durante gli studi, a partire dal 2013, collabora con la redazione della principale rivista del settore odontoiatrico italiano, Il Dentista
            Moderno. Dal 2016 a oggi riveste il ruolo di co-editor ed esperto di comunicazione digitale in ambito medico- odontoiatrico per la stessa rivista.
            <br />
            All'indomani della laurea si iscrive all'ordine e all'albo professionale di appartenenza ed esercita la libera professione odontoiatrica presso
            studi privati, strutture polispecialistiche.
            <br />
            Svolge attività di ricerca presso l'Istituto Stomatologico Italiano da cui scaturisce la pubblicazione di lavori scientifici.
            <br />
            Dal 2017 è professore a contratto della facoltà di Odontoiatria e Protesi Dentaria presso l'Università degli Studi di Milano.
            <br />
            Dal giugno 2018 è co-founder e Amministratore unico dell'azienda Lance Awareness & Marketing Srl.
            <br />
            Da marzo 2021 è Amministratore Delegato dell'azienda Tootor Srl.
          </em>
        </p>
        <p>
          <em>
            <strong>ALESSANDRA GROHOVAZ, nata a Milano nel giugno 1990.</strong>
            <br />
            Nel 2009 consegue la maturità classica presso il Liceo Classico Statale Giovanni Berchet di Milano.
            <br />
            Nel 2013 consegue la Laurea (triennale) a pieni voti in Fisioterapia presso l'Università degli Studi di Milano. Alla fine di questo percorso decide
            di orientarsi verso il mondo della comunicazione e dell'editoria.
            <br />
            Nel 2016 consegue la Laurea (triennale) in Arti, Design e Spettacolo (110/110) presso la Libera Università di Lingue e Comunicazione IULM, corso che
            unisce nozioni di marketing ed economia a nozioni di cultura generale.
            <br />
            Nel 2018 consegue un master in Arti e Mestieri del Racconto (70/70 con lode) presso la Libera Università di Lingue e Comunicazione IULM, sviluppando
            una particolare attitudine per lo storytelling.
            <br />
            Dopo gli studi inizia a lavorare come collaboratrice esterna per il gruppo editoriale GEMS (Guanda, redazione; Salani, progetto audiolibri).
            <br />
            Dal giugno 2018 è co-founder e Editorial Manager dell'azienda Lance Awareness &amp; Marketing Srl.
            <br />
            Da marzo 2021 è Amministratrice Delegata dell'azienda Tootor Srl.
          </em>
        </p>
        <p>
          <em>
            <strong>LORENZO MONTICELLI, nato a Gallarate nel settembre 1990.</strong>
            <br />
            Nel 2015 consegue la laurea magistrale in odontoiatria e protesi dentaria presso l’Università degli studi di Milano.
            <br />
            Terminati gli studi, avvia la propria carriera libero-professionale come odontoiatra.
          </em>
        </p>
        <p>
          <strong>
            h) indicazione dell'esistenza di relazioni professionali, di collaborazione o commerciali con incubatori certificati, investitori istituzionali e
            professionali, università e centri di ricerca;
          </strong>
        </p>
        <p>Non sussistono.</p>
        <p>
          <strong>i) ultimo bilancio depositato, nello standard XBRL;</strong>
        </p>
        <p>L'ultimo bilancio chiuso è al 31/12/2022.</p>
        <div className={'mb-3'}>
          <Button
            disabled={loading || !url || !isDesktop}
            onClick={() => {
              setLoading(true);
              fetchAndSave(url, 'bilancio-2022.pdf')
                .catch(console.error)
                .finally(() => setLoading(false));
            }}
          >
            {loading ? 'Caricamento bilancio...' : isDesktop ? 'Scarica bilancio' : 'Il download del bilancio è disponibile solo su desktop'}
          </Button>
        </div>
        <p>
          <strong>l) elenco dei diritti di privativa su proprietà industriale e intellettuale.</strong>
        </p>
        <p>
          In relazione alle informazioni richieste dall'art. 25 D.L. 18 ottobre 2012, n. 179 si segnala come la società sia titolare di privativa industriale
          direttamente afferente all'oggetto sociale e all'attività di impresa avendo registrato presso SIAE l'algoritmo “PIATTAFORMA TOOTOR” contrassegnato dal
          n D000017318, decorrente dal 15/04/2022; data di pubblicazione 10/09/2021.
        </p>
      </Container>

      <Footer />
    </>
  );
};

export default CompanyComponent;
