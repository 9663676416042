import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/Overlay';
import { useEffect, useState } from 'react';

const WithTooltipComponent = ({
  text,
  children,
  placement = 'top',
  hide = false,
  big = true,
  initialShow = false,
}: {
  text: string;
  children: any;
  placement?: Placement;
  hide?: boolean;
  big?: boolean;
  initialShow?: boolean;
}) => {
  const [show, setShow] = useState(initialShow);

  useEffect(() => {
    setShow(initialShow);
  }, [initialShow]);

  if (hide) return <>{children}</>;
  // noinspection RequiredAttributes
  return (
    <OverlayTrigger
      placement={placement}
      show={show}
      onToggle={setShow}
      overlay={
        <Tooltip id={`tooltip-${encodeURIComponent(text)}`} style={{ fontSize: big ? '1.2rem' : null }}>
          {text}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export { WithTooltipComponent as WithTooltip };
