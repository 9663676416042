import { Badge, Image, Modal, ProgressBar } from 'react-bootstrap';
import { useGaming } from '../../../../contexts/GamingContext';
import { BadgeDifficulty } from '../BadgeDifficulty/BadgeDifficulty';
import { useMemo, useState } from 'react';
import { Exp } from '../Exp/Exp';
import './Achievement.scss';

const AchievementComponent = ({ badge }) => {
  const { nf } = useGaming();
  const [badgeOpen, setOpenBadge] = useState(false);

  const levelUnlocked = useMemo(() => {
    if (!badge) return null;
    return badge.unlocked.lastIndexOf(true);
  }, [badge]);

  const boundLevelUnlocked = useMemo(() => {
    if (levelUnlocked == -1) return 0;
    return levelUnlocked;
  }, [levelUnlocked]);

  const levelInProgress = useMemo(() => {
    if (!badge) return 0;
    return Math.min(levelUnlocked + 1, badge.progress.length - 1);
  }, [levelUnlocked, badge]);

  const isUnlocked = useMemo(() => {
    if (!badge) return false;
    return badge.unlocked.some((x) => !!x);
  }, [badge]);

  const progressTotal = useMemo(() => {
    if (!badge) return 0;
    return badge.progress.reduce((acc, val) => acc + val, 0);
  }, [badge]);

  return (
    <>
      {/*style={{ '--fill-percentage': `${(badge.progress[boundLevelUnlocked + 1] ?? badge.progress[boundLevelUnlocked]) * 100}%` } as any}*/}
      <div key={badge.id} onClick={() => setOpenBadge(true)} className={'gaming-badge ' + (isUnlocked ? 'unlocked' : '')}>
        <div className={'gaming-badge-image'}>
          <Image src={badge.images[boundLevelUnlocked]} />
        </div>
        <div className={'gaming-badge-content'}>
          <div className={'d-flex justify-content-center'}>
            <h6
              className={
                'gaming-badge-title d-flex justify-content-center mb-0 mt-3 ' +
                (isUnlocked ? 'gaming-badge-title-unlocked ' : '') +
                ((badge.progress.length == 3 && levelUnlocked >= 2) || (badge.progress.length == 1 && levelUnlocked >= 0) ? 'text-warning' : '')
              }
            >
              <span>{badge.title}</span>
            </h6>
            {/* <small className={badge.unlocked ? 'text-primary' : ''}>
      {badge.unlocked && <>Sbloccato il {formatSecondsTimestamp(badge.unlocked._seconds)}</>}
      {!badge.unlocked && <>{Math.floor(badge.progress * 100)}%</>}
    </small> */}
          </div>
          {/* <div className={'small gaming-badge-description mb-2'}>
      {badge.description.map((row, i) => (
        <div key={i.toString()}>{row}</div>
      ))}
    </div> */}
          <div className={'level-progress-container'}>
            {/* <div className={'d-flex align-items-center justify-content-center mt-2'}>
              <BadgeDifficulty difficulty={badge.levels[levelUnlocked].difficulty} className={'small'} />
              <span className={'mx-2'}> • </span>
              <div className={'small d-flex align-items-center'}>
                <Exp className={'mr-1'} />
                <span>{nf(badge.levels[levelUnlocked].exp_reward)} EXP</span>
              </div>
              <div className={'small d-flex align-items-center'} style={{ width: '200px' }}>
      <Coin className={'mr-1'} />
      <span>{badge.coin_reward} Coin</span>
    </div>
            </div> */}
            {/* <ProgressBar className={'level-progress-bar mt-1'}>
      <ProgressBar min={0} max={1} now={badge.unlocked ? 1 : badge.progress} variant={badge.unlocked ? 'success' : 'primary'} />
    </ProgressBar> */}
          </div>
        </div>
      </div>
      <Modal className={'dark-modal'} centered show={badgeOpen} onHide={() => setOpenBadge(false)}>
        <Modal.Body className={'p-0'}>
          <div className={'gaming-badge ' + (isUnlocked ? 'unlocked' : '')}>
            <div className={'gaming-badge-image mb-2'}>
              <Image src={badge.images[boundLevelUnlocked]} />
            </div>
            <div className={'gaming-badge-content'}>
              <div className={'d-flex justify-content-center'}>
                <h6
                  className={
                    'gaming-badge-title d-flex justify-content-center mb-0 mt-3 ' +
                    (isUnlocked ? 'gaming-badge-title-unlocked ' : '') +
                    ((badge.progress.length == 3 && levelUnlocked >= 2) || (badge.progress.length == 1 && levelUnlocked >= 0) ? 'text-warning' : '')
                  }
                >
                  <span>{badge.title}</span>
                </h6>
              </div>
              {levelUnlocked >= Object.keys(badge.levels).length - 1 && <div className={'my-3'}>Hai sbloccato il livello massimo!</div>}
              {levelUnlocked < Object.keys(badge.levels).length - 1 && (
                <div className={'mt-3 small text-secondary'}>Per sbloccare il prossimo livello&hellip;</div>
              )}
              {levelUnlocked < Object.keys(badge.levels).length - 1 && (
                <div className={'small gaming-badge-description mb-2'}>
                  {badge.description.map((row, i) => (
                    <div key={i.toString()}>{row}</div>
                  ))}
                </div>
              )}
              {levelUnlocked < Object.keys(badge.levels).length - 1 && (
                <div className={'level-progress-container'}>
                  <div className={'d-flex align-items-center justify-content-center mt-2'}>
                    <BadgeDifficulty difficulty={badge.difficulty} className={'small'} />
                    <span className={'mx-2'}> • </span>
                    <div className={'small d-flex align-items-center'}>
                      <Exp className={'mr-1'} />
                      <span>{nf(badge.exp_reward)} EXP</span>
                    </div>
                  </div>
                </div>
              )}
              <div className={'mt-4 d-flex align-items-center ml-4 mr-5 badge-progress-bar-container'}>
                <ProgressBar min={0} max={badge.progress.length} now={progressTotal} className={'badge-progress-bar'} variant={'primary'} />
                {badge.progress.length == 3 && (
                  <>
                    <Image id={'i0'} src={badge.images[0]} className={'small-badge-image ' + (levelUnlocked >= 0 ? 'small-badge-image-unlocked' : '')} />
                    <Image id={'i1'} src={badge.images[1]} className={'small-badge-image ' + (levelUnlocked >= 1 ? 'small-badge-image-unlocked' : '')} />
                    <Image id={'i2'} src={badge.images[2]} className={'small-badge-image ' + (levelUnlocked >= 2 ? 'small-badge-image-unlocked' : '')} />
                  </>
                )}
                {badge.progress.length == 1 && (
                  <Image id={'i2'} src={badge.images[0]} className={'small-badge-image ' + (levelUnlocked >= 0 ? 'small-badge-image-unlocked' : '')} />
                )}
                <div className={'progress-label'} style={{ '--level': badge.progress.length == 3 ? levelInProgress : 1 } as any}>
                  <Badge variant={'dark'}>{Math.floor(badge.progress[levelInProgress] * 100) + '%'}</Badge>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export { AchievementComponent as Achievement };
