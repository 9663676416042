import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const PlaceholderComponent = ({ width = 300, height = 169, round = false }) => {
  return (
    <div
      style={{ width: `${width}px`, height: `${height}px`, backgroundColor: 'whitesmoke', borderRadius: round ? '50%' : null }}
      className={'d-flex align-items-center justify-content-center'}
    >
      <FontAwesomeIcon icon={faExclamationCircle} size={'2x'} color={'grey'} />
    </div>
  );
};

export { PlaceholderComponent as Placeholder };
