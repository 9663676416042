import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBahai, faCertificate, faCircle, faCrown, faInfinity, faPlay, faStar, faStarOfLife, faStop } from '@fortawesome/free-solid-svg-icons';
import { CSSProperties, useEffect, useState } from 'react';
import './BadgeDifficulty.scss';

const icons = [faCircle, faPlay, faStop, faStar, faCrown, faInfinity];
const classes = [null, null, null, null, null, 'animated-legendary-badge-difficulty'];
const colors = ['#95a5a6', '#27ae60', '#2980b9', '#d35400', '#9b59b6', null];
const labels = ['Comune', 'Non comune', 'Raro', 'Epico', 'Mitico', 'Leggendario'];
const styles: CSSProperties[] = [null, { transform: 'rotate(-90deg)' }, { transform: 'rotate(45deg)' }, null, null, null];
const MAX = icons.length - 1;

const BadgeDifficultyComponent = ({ difficulty, className = '', style = {} }) => {
  const [value, setValue] = useState<number>(null);

  useEffect(() => {
    setValue(
      (() => {
        if (difficulty < 0) {
          console.log('Difficulty < 0, setting 0');
          return 0;
        }
        if (difficulty > MAX) {
          console.log(`Difficulty > ${MAX}, setting ${MAX}`);
          return MAX;
        }
        return difficulty;
      })()
    );
  }, [difficulty]);

  if (value == null) return null;

  return (
    <div className={'d-flex align-items-center ' + className} style={style}>
      <FontAwesomeIcon
        icon={icons[value]}
        className={'mr-1 ' + classes[value]}
        size={'sm'}
        fixedWidth={true}
        style={{ ...styles[value], color: colors[value] }}
      />
      <span>{labels[value]}</span>
    </div>
  );
};

export { BadgeDifficultyComponent as BadgeDifficulty };
