import { Badge } from 'react-bootstrap';

const BetaBadgeComponent = ({ big = false }) => {
  if (big)
    return (
      <span style={{ fontSize: '1.5rem' }}>
        <Badge variant={'secondary'} className={'ml-2'}>
          Beta
        </Badge>
      </span>
    );

  return <Badge variant={'light'}>&beta;</Badge>;
};

export { BetaBadgeComponent as BetaBadge };
