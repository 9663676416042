import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const ScrollToTopComponent = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export { ScrollToTopComponent as ScrollToTop };
