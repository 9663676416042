export const PlyrOptions = {
  clickToPlay: true,
  fullscreen: { enabled: false },
  // controls: ['progress', 'current-time', 'duration', 'mute', 'captions', 'settings'],
  settings: ['captions', 'quality', 'speed'],
  storage: { enabled: false },
  captions: {
    active: true,
    update: true,
    language: 'it',
  },
  speed: {
    selected: 1,
    options: [1],
  },
  i18n: {
    restart: 'Ricomincia',
    rewind: 'Torna indietro di {seektime} secondi',
    play: 'Play',
    pause: 'Pausa',
    fastForward: 'Avanza di {seektime} secondi',
    seek: 'Seek',
    played: 'Riprodotto',
    buffered: 'Caricato',
    currentTime: 'Tempo corrente',
    duration: 'Durata',
    volume: 'Volume',
    mute: 'Silenzia audio',
    unmute: 'Riattiva audio',
    enableCaptions: 'Attiva sottotitoli',
    disableCaptions: 'Disattiva sottotitoli',
    enterFullscreen: 'Attiva schermo intero',
    exitFullscreen: 'Esci da schermo intero',
    frameTitle: 'Player per {title}',
    captions: 'Sottotitoli',
    settings: 'Impostazioni',
    speed: 'Velocità',
    normal: 'Normale',
    quality: 'Qualità',
    loop: 'Loop',
    start: 'Inizio',
    end: 'Fine',
    all: 'Tutto',
    reset: 'Reset',
    disabled: 'Disabilitato',
    advertisement: 'Ad',
  },
};
