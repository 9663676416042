import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useCapacitor } from '../../../hooks/useCapacitor';

const CapacitorActionsHandlerComponent = () => {
  const history = useHistory();
  const { App } = useCapacitor();

  useEffect(() => {
    App.addListener('backButton', ({ canGoBack }) => {
      if (canGoBack) history.goBack();
      else App.exitApp();
    });
  }, []);

  useEffect(() => {
    App.addListener('appUrlOpen', (data) => {
      const slug = data.url.split('.it').pop();
      if (slug) history.push(slug);
    });
  }, []);

  return null;
};

export { CapacitorActionsHandlerComponent as CapacitorActionsHandler };
