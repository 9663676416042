import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
// import 'firebase/messaging';
import 'firebase/performance';
import 'firebase/remote-config';
import 'firebase/storage';
import 'firebase/database';

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

// if (process.env.NODE_ENV == 'production') {
// console.log((firebase as any).appCheck)
//   const appCheck = firebase.appCheck();
// site key: 6Ldc450cAAAAALq7dr-Yk0O0Y4-nVG-bWhDmHURK
// }

export const fieldValue = firebase.firestore.FieldValue;
export const timestamp = firebase.firestore.Timestamp;
export const database = app.firestore();
export const realtimeDatabase = app.database();
export const analytics = firebase.analytics();
export const performance = firebase.performance();
export const auth = app.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
export const appleAuthProvider = new firebase.auth.OAuthProvider('apple.com');
export const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
export const phoneAuthCredential = firebase.auth.PhoneAuthProvider.credential;
export const emailAuthProvider = firebase.auth.EmailAuthProvider.credential;
export const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion;
// export const messaging = (() => {
//   if (firebase.messaging.isSupported()) return app.messaging();
//   return null;
// })();
export const functions = app.functions('europe-west3');
if (process.env.NODE_ENV != 'production') {
  functions.useEmulator('localhost', 5001);
}
// export const remoteConfig = app.remoteConfig();
export const storage = app.storage().ref();
export const videoDataStorage = app
  .storage(
    window.location.origin.includes('tootor-dev') || process.env.NODE_ENV == 'development' ? 'tootor-development-video-data' : 'tootor-production-video-data'
  )
  .ref();
auth.useDeviceLanguage();
export default app;
