import './VideoRating.scss';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useDetectChanges } from '../../../hooks/useDetectChanges';
import { useEffect, useState } from 'react';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faThumbsDown as faThumbsDownFill, faThumbsUp as faThumbsUpFill } from '@fortawesome/free-solid-svg-icons';
import { WithTooltip } from '../WithTooltip/WithTooltip';
import { useTranslation } from 'react-i18next';
import { useGaming } from '../../../contexts/GamingContext';

const VideoRatingComponent = ({
  video,
  className = '',
  children = <></>,
  inline = false,
  hideTooltips = false,
  onRateSubmit = (rating: 1 | -1 | null) => null,
}) => {
  const { t } = useTranslation();
  const { updateUserVideoAnalytics, fv } = useFirebase();
  const { track } = useGaming();
  const detectChanges = useDetectChanges();

  const rate = (rating: 1 | -1) => {
    const isSame = rating == video.rating;
    if (isSame) {
      updateUserVideoAnalytics(video.id, { rating: fv.delete(), votedAt: fv.delete() }).catch(console.error);
      video.rating = null;
    } else {
      updateUserVideoAnalytics(video.id, { rating, votedAt: fv.serverTimestamp() }).catch(console.error);
      video.rating = rating;
    }
    detectChanges();
    track('VIDEO_RATING', { videoId: video.id, rating: isSame ? null : rating });
    onRateSubmit(isSame ? null : rating);
  };

  const WithRatingTooltip = ({ mode, children }: { mode: 'like' | 'dislike'; children: any }) => {
    const text = {
      like: t('videoRating.labels.miPiace'),
      dislike: t('videoRating.labels.nonMiPiace'),
    };
    return WithTooltip({ text: text[mode], children, hide: hideTooltips });
  };

  if (inline)
    return (
      <>
        <WithRatingTooltip mode={'like'}>
          <Button
            variant={'outline-light'}
            className={video.rating == 1 ? 'text-primary' : ''}
            onClick={(event) => {
              event.stopPropagation();
              rate(1);
            }}
          >
            <FontAwesomeIcon icon={video.rating == 1 ? faThumbsUpFill : faThumbsUp} fixedWidth={true} />
          </Button>
        </WithRatingTooltip>
        <WithRatingTooltip mode={'dislike'}>
          <Button
            variant={'outline-light'}
            className={video.rating == -1 ? 'text-danger' : ''}
            onClick={(event) => {
              event.stopPropagation();
              rate(-1);
            }}
          >
            <FontAwesomeIcon icon={video.rating == -1 ? faThumbsDownFill : faThumbsDown} fixedWidth={true} />
          </Button>
        </WithRatingTooltip>
      </>
    );

  return (
    <div className={'d-inline-flex align-items-center'}>
      {children}
      <div className={'d-inline-flex align-items-center ' + className}>
        <WithRatingTooltip mode={'like'}>
          <span className={'rating-icon-button positive-button mr-1 ' + (video.rating == 1 ? 'icon-active' : '')} onClick={() => rate(1)}>
            <FontAwesomeIcon color={video.rating == 1 ? '#2980b9' : null} icon={video.rating == 1 ? faThumbsUpFill : faThumbsUp} fixedWidth={true} />
          </span>
        </WithRatingTooltip>
        <WithRatingTooltip mode={'dislike'}>
          <span className={'rating-icon-button negative-button mr-1 ' + (video.rating == -1 ? 'icon-active' : '')} onClick={() => rate(-1)}>
            <FontAwesomeIcon color={video.rating == -1 ? '#c0392b' : null} icon={video.rating == -1 ? faThumbsDownFill : faThumbsDown} fixedWidth={true} />
          </span>
        </WithRatingTooltip>
      </div>
    </div>
  );
};

export { VideoRatingComponent as VideoRating };
