import { Video } from '../Video/Video';
import { VideoCard } from '../VideoCard/VideoCard';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay, faUndoAlt, faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Blurhash } from 'react-blurhash';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useFirebase } from '../../../contexts/FirebaseContext';

const BillboardComponent = ({
  video: billboard,
  play: billboardPlay,
  setPlay: setBillboardPlay,
  wrapper,
}: {
  video: { [p: string]: any };
  play: boolean;
  setPlay: Dispatch<SetStateAction<boolean>>;
  wrapper: MutableRefObject<HTMLDivElement>;
}) => {
  const videoElementRef = useRef<HTMLVideoElement>();

  const { staticData } = useFirebase();
  const { windowWidth } = useWindowSize();

  const [billboardAudio, setBillboardAudio] = useState(false);
  const [billboardLogoBig, setBillboardLogoBig] = useState(true);
  const [billboardLoading, setBillboardLoading] = useState(true);
  const [billboardLogoLoading, setBillboardLogoLoading] = useState(true);
  const [billboardEnded, setBillboardEnded] = useState(false);
  const [hasBillboardEndedOnce, setHasBillboardEndedOnce] = useState(false);
  const [posterLoaded, setPosterLoaded] = useState(false);

  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });

  const history = useHistory();

  useLayoutEffect(() => {
    if (!wrapper.current) return;
    const width = wrapper.current.clientWidth;
    const height = width / 1.7777;
    setCanvasSize({ width, height });
  }, [windowWidth, wrapper.current]);

  useEffect(() => {
    if (!videoElementRef.current) return;

    try {
      if (billboardPlay) videoElementRef.current.play().catch(console.error);
      else videoElementRef.current.pause();
    } catch {}
  }, [billboardPlay]);

  const underlyingBillboardRef = useRef<HTMLDivElement>();

  const billboardOnPlay = () => {
    if (hasBillboardEndedOnce) setHasBillboardEndedOnce(false);
    setBillboardLogoBig(false);
    setBillboardEnded(false);
  };

  const billboardOnPause = () => {
    setBillboardLogoBig(true);
  };

  const billboardOnCanPlay = () => {
    if (!hasBillboardEndedOnce) {
      setBillboardLoading(false);
      setBillboardPlay(true);
    }
  };

  const billboardOnLoadedMetadata = () => {
    setPosterLoaded(true);
  };

  const billboardOnEnded = () => {
    if (!hasBillboardEndedOnce) setHasBillboardEndedOnce(true);
    setBillboardPlay(false);
    setBillboardEnded(true);
    videoElementRef.current.currentTime = 0;
    videoElementRef.current.src = null;
    videoElementRef.current.autoplay = false;
    videoElementRef.current.src = billboard.trailer;
  };

  return (
    <>
      {!!billboard.thumbnail_hash && canvasSize.width && staticData.ENABLE_BLURHASH && (
        <Blurhash
          className={'blurhash-wrapper ' + (posterLoaded ? 'blurhash-wrapper-hidden' : '')}
          hash={billboard.thumbnail_hash}
          width={canvasSize.width}
          height={canvasSize.height}
        />
      )}
      <Video
        poster={billboard.thumbnail}
        muted={!billboardAudio}
        preload={'metadata'}
        innerRef={videoElementRef}
        src={billboard.trailer}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (billboard.redirectTo) history.push(billboard.redirectTo);
          else underlyingBillboardRef.current.click();
        }}
        onPlay={billboardOnPlay}
        onPause={billboardOnPause}
        onCanPlay={billboardOnCanPlay}
        onEnded={billboardOnEnded}
        onLoadedMetadata={billboardOnLoadedMetadata}
      />
      <div className={'d-none'}>
        <VideoCard
          hidden={true}
          video={billboard}
          keepWatching={false}
          innerRef={underlyingBillboardRef}
          onDetailsOpen={() => {
            if (billboardPlay) setBillboardPlay(false);
          }}
        />
      </div>
      {/*{billboardLoading && (
              <div className={'billboard-loader'}>
                <FullscreenLoader fullscreen={false} forcePercentage={true} />
              </div>
            )}*/}
      {!!billboard.logo && !billboardEnded && (
        <img
          alt={null}
          src={billboard.logo}
          onLoad={() => setBillboardLogoLoading(false)}
          className={'billboard-logo ' + (billboardLogoBig ? 'big-billboard-logo ' : '') + (billboardLogoLoading ? 'invisible' : '')}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            underlyingBillboardRef.current.click();
          }}
        />
      )}
      {/*{billboardEnded && (
              <Button
                size={'lg'}
                variant={'outline-light'}
                className={'billboard-centered-button'}
                onClick={() => {
                  videoElementRef.current.play().catch(console.error);
                  setBillboardEnded(false);
                }}
              >
                <FontAwesomeIcon icon={faUndoAlt} fixedWidth={true} />
              </Button>
            )}*/}
      {!billboardLoading && (
        <div className={'billboard-actions'}>
          <ButtonGroup>
            <Button variant={'outline-light'} onClick={() => setBillboardPlay((current) => !current)}>
              <FontAwesomeIcon icon={billboardEnded ? faUndoAlt : billboardPlay ? faPause : faPlay} fixedWidth={true} />
            </Button>
            <Button variant={'outline-light'} onClick={() => setBillboardAudio((current) => !current)}>
              <FontAwesomeIcon icon={billboardAudio ? faVolumeUp : faVolumeMute} fixedWidth={true} />
            </Button>
          </ButtonGroup>
        </div>
      )}
      <div className={'bottom-shader'} />
    </>
  );
};

export { BillboardComponent as Billboard };
