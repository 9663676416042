import { useEffect, useState } from 'react';

const DeferredRenderComponent = ({ children, timeout = 0 }) => {
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (render) setRender(false);
    if ((window as any).requestIdleCallback) {
      const id = (window as any).requestIdleCallback(() => setRender(true), { timeout });
      return () => (window as any).cancelIdleCallback(id);
    } else setRender(true);
  }, [timeout]);

  if (!render) return null;

  return children;
};

export { DeferredRenderComponent as DeferredRender };
