import { faChevronLeft, faChevronRight, faLock, faSearchPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { Button, Container, Form, Modal, Pagination, ProgressBar } from 'react-bootstrap';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useGaming } from '../../../contexts/GamingContext';
import { useMobileDetect } from '../../../hooks/useMobileDetect';
import { Footer } from '../../components/Footer/Footer';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { Title } from '../../components/Title/Title';
import { Topbar } from '../../components/Topbar/Topbar';
import { canAccessFeature, CloudFunctions, download, getFunctionsUrl } from '../../utils/utils';
import './Ebook.scss';
import { useCurrentLanguage } from '../../../hooks/useCurrentLanguage';
import { Redirect } from 'react-router-dom';

const Ebook = () => {
  const { getEbooks, getNextQuestion, unlockEbook, staticData, currentUser, userDataFmt } = useFirebase();
  const { track } = useGaming();
  const { isMobile } = useMobileDetect();
  const { currentLanguage } = useCurrentLanguage();

  const [ebooks, setEbooks] = useState([]);
  const [nextQuestions, setNextQuestions] = useState<{ [p: string]: any }[]>([]);
  const [nextSubmitEnabled, setNextSubmitEnabled] = useState(false);
  const [targetEbook, setTargetEbook] = useState<{ [p: string]: any }>({});
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [ebookOnLoading, setEbookOnLoading] = useState<{ [p: string]: any }>({});
  const [nextPageOnLoading, setNextPageOnLoading] = useState(false);
  const [prevPageOnLoading, setPrevPageOnLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    _getEbooks(currentPage).catch(console.error);
  }, []);

  const _getEbooks = useCallback(
    (page: number) => {
      return getEbooks(page, isMobile)
        .then(({ list, hasMore }) => {
          setEbooks(list);
          setHasMore(hasMore);
          setEbookOnLoading({});
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        })
        .catch(console.error);
    },
    [isMobile]
  );

  const onBookClick = useCallback(async (book) => {
    if (ebookOnLoading.id) return;
    setTargetEbook(book);
    setEbookOnLoading(book);
    if (book.unlocked) {
      const response = await fetch(getFunctionsUrl(CloudFunctions.GetEbookDownload), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ebookId: book.id,
          uid: currentUser.uid,
          token: await currentUser.getIdToken(true),
        }),
      });
      track('EBOOK_DOWNLOAD', { bookId: book.id });
      setEbookOnLoading({});
      await download(response);
    } else onGetNextQuestion(book);
  }, []);

  const onGetNextQuestion = useCallback((book: any, currentIndex?: number, questionId?: string, answerId?: string, elt?: HTMLInputElement) => {
    if (currentIndex == staticData.DEFAULT_QUESTIONS_PER_EBOOK) {
      setNextSubmitEnabled(true);
    } else {
      setNextSubmitEnabled(false);
      setEbookOnLoading(book);
      getNextQuestion(questionId, answerId)
        .then((next) => {
          if (elt) {
            let nextRadioGroup = elt.parentElement.parentElement.nextElementSibling;
            while (nextRadioGroup != null) {
              if (!nextRadioGroup.matches('div.form-group')) break;
              nextRadioGroup.querySelectorAll('input[type="radio"]').forEach((elt) => {
                (elt as HTMLInputElement).checked = false;
              });
              nextRadioGroup = nextRadioGroup.nextElementSibling;
            }
          }
          setNextQuestions((current) => {
            //if (current.find((q) => q.id == next.id)) return current;
            const base = current.slice(0, currentIndex);
            return [...base, next];
          });
        })
        .catch(console.error)
        .finally(() => {
          setEbookOnLoading({});
          setIsQuestionModalOpen(true);
        });
    }
  }, []);

  const handleHideQuestion = useCallback(() => {
    setIsQuestionModalOpen(false);
    setNextQuestions([]);
    setTargetEbook({});
  }, []);

  const onQuestionAnsweredSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const value = Object.entries(event.target as HTMLFormElement)
        .filter(([k, v]) => !isNaN(+k) && v instanceof HTMLInputElement && v.checked)
        .map(([, input]) => ({ questionId: input.name, answerId: input.id }));
      setEbookOnLoading(targetEbook);
      unlockEbook(targetEbook.id, value)
        .then((result) => {
          if (result?.success) {
            _getEbooks(currentPage).catch(console.error);
            setIsSuccessModalOpen(true);
            handleHideQuestion();
          } else setEbookOnLoading({});
        })
        .catch(console.error);
    },
    [targetEbook, nextQuestions]
  );

  const handleNextPage = useCallback(() => {
    setNextPageOnLoading(true);
    _getEbooks(currentPage + 1)
      .then(() => setCurrentPage(currentPage + 1))
      .catch(console.error)
      .finally(() => setNextPageOnLoading(false));
  }, [currentPage]);

  const handlePrevPage = useCallback(() => {
    setPrevPageOnLoading(true);
    _getEbooks(currentPage - 1)
      .then(() => setCurrentPage(currentPage - 1))
      .catch(console.error)
      .finally(() => setPrevPageOnLoading(false));
  }, [currentPage]);

  const onOverlayClick = useCallback((overlay: HTMLDivElement, hero: HTMLDivElement) => {
    hero.classList.remove('book-cover-hero');
    hero.classList.add('book-cover-hero-leave');
    overlay.classList.remove('book-cover-overlay-showing');
    document.querySelector('.book-cover-close').remove();
    hero.addEventListener(
      'animationend',
      () => {
        overlay.remove();
        hero.remove();
      },
      { once: true }
    );
  }, []);

  if (!staticData.LOADED || !userDataFmt) return <FullscreenLoader />;
  if (!canAccessFeature('BOOKS', currentLanguage, staticData, userDataFmt)) {
    return <Redirect to={'/browse'} />;
  }

  return (
    <>
      <Title>Library</Title>

      <div style={{ marginBottom: '72px' }}>
        <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
          <Topbar />
        </div>
      </div>

      <Container className={'mt-3 mt-lg-5'}>
        <div className={'d-flex align-items-center justify-content-center mb-4 w-100 mx-auto'}>
          <img className={'banner-library-container'} src="https://d260e0gu5nbefz.cloudfront.net/gaming/banners/banner_library.jpg" />
        </div>

        {!ebooks.length && <FullscreenLoader fullscreen={false} />}

        {!!ebooks.length && (
          <>
            <div className={'scaffold-wrapper'}>
              <div className={'top-scaffold'}>&nbsp;</div>
              <div className={'container-ebooks-wrapper'}>
                <div className={'container-ebooks'}>
                  {!!ebooks.length &&
                    ebooks.map((book) => (
                      <div
                        className={'ebook-cover ' + (book == ebookOnLoading ? 'book-loading' : '')}
                        style={{ '--border-color': book.color || '#7f7f7f' } as any}
                        key={book.id}
                        id={'book-' + book.id}
                        onClick={() => onBookClick(book)}
                      >
                        <div className={'ebook-cover-image'} style={{ backgroundImage: `url(${book.coverUrl})` }}></div>
                        {/* <img src={book.coverUrl} alt={book.title} className={'cover'} /> */}
                        {book == ebookOnLoading && (
                          <span className={'ebook-spinner'}>
                            <FontAwesomeIcon icon={faSpinner} color={'#fff'} pulse style={{ filter: 'brightness(0)' }} />
                          </span>
                        )}
                        <span
                          className={'ebook-zoom'}
                          onClick={(e) => {
                            e.stopPropagation();
                            const wrapper = document.querySelector(`#book-${book.id}`);
                            const { top, left, width, height } = wrapper.getBoundingClientRect();
                            const overlay = document.createElement('div');
                            overlay.classList.add('book-cover-overlay');
                            overlay.addEventListener(
                              'click',
                              () => {
                                document.removeEventListener('keydown', onKeyDown);
                                onOverlayClick(overlay, hero);
                              },
                              { once: true }
                            );

                            const onKeyDown = (e) => {
                              if (e.key == 'Escape') {
                                document.removeEventListener('keydown', onKeyDown);
                                onOverlayClick(overlay, hero);
                              }
                            };
                            document.addEventListener('keydown', onKeyDown);

                            const hero = document.createElement('img');
                            hero.classList.add('book-cover-hero');
                            hero.style.setProperty('--top', top.toString());
                            hero.style.setProperty('--left', left.toString());
                            hero.style.width = `${width}px`;
                            hero.style.height = `${height}px`;
                            hero.src = book.coverUrl;
                            hero.addEventListener('click', (e) => e.stopPropagation());
                            const body = document.querySelector('body');
                            body.append(overlay, hero);
                            setTimeout(() => {
                              overlay.classList.add('book-cover-overlay-showing');
                            });

                            hero.addEventListener(
                              'animationend',
                              () => {
                                const closeBtn = document.createElement('div');
                                closeBtn.classList.add('book-cover-close');
                                const { top, left, width } = hero.getBoundingClientRect();
                                closeBtn.style.setProperty('--top', top.toString());
                                closeBtn.style.setProperty('--left', (left + width).toString());
                                closeBtn.addEventListener(
                                  'click',
                                  () => {
                                    document.removeEventListener('keydown', onKeyDown);
                                    onOverlayClick(overlay, hero);
                                  },
                                  { once: true }
                                );
                                body.append(closeBtn);
                              },
                              { once: true }
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faSearchPlus} />
                        </span>
                        {!book.unlocked && (
                          <span className={'ebook-lock'}>
                            <FontAwesomeIcon icon={faLock} />
                          </span>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <Modal onHide={() => handleHideQuestion()} show={isQuestionModalOpen} backdrop={'static'} keyboard={false}>
              <Modal.Header closeButton>
                <Modal.Title>Rispondi a queste {staticData.DEFAULT_QUESTIONS_PER_EBOOK} domande per scaricare gratuitamente l’ebook!</Modal.Title>
              </Modal.Header>
              <Form onSubmit={onQuestionAnsweredSubmit}>
                <Modal.Body>
                  {nextQuestions.map((q, questionIndex) => (
                    <Form.Group key={q.id}>
                      <Form.Label className={'font-weight-bold'}>{q.label}</Form.Label>
                      {q.answers.map((a) => (
                        <Form.Check
                          onChange={(e) => onGetNextQuestion(targetEbook, questionIndex + 1, q.id, a.id, e.target)}
                          key={a.id}
                          type={'radio'}
                          disabled={!!ebookOnLoading.id}
                          label={a.label}
                          id={a.id}
                          name={q.id}
                        />
                      ))}
                    </Form.Group>
                  ))}
                  {!!ebookOnLoading.id && <span>Caricamento&hellip;</span>}
                  <ProgressBar>
                    <ProgressBar
                      min={0}
                      max={staticData.DEFAULT_QUESTIONS_PER_EBOOK}
                      now={nextSubmitEnabled ? staticData.DEFAULT_QUESTIONS_PER_EBOOK : nextQuestions.length - 1}
                      label={
                        nextQuestions.length - 1
                          ? `${nextSubmitEnabled ? staticData.DEFAULT_QUESTIONS_PER_EBOOK : nextQuestions.length - 1}/${staticData.DEFAULT_QUESTIONS_PER_EBOOK}`
                          : null
                      }
                      variant={nextSubmitEnabled ? 'success' : 'primary'}
                    />
                  </ProgressBar>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant={'secondary'} onClick={() => handleHideQuestion()}>
                    Annulla
                  </Button>
                  <Button variant={'primary'} type={'submit'} disabled={!nextSubmitEnabled}>
                    Invia
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>

            <Modal onHide={() => setIsSuccessModalOpen(false)} show={isSuccessModalOpen}>
              <Modal.Header>
                <Modal.Title>Risposte registrate con successo!</Modal.Title>
              </Modal.Header>
              <Modal.Body>Il tuo libro è pronto per il download.</Modal.Body>
              <Modal.Footer>
                <Button variant={'primary'} onClick={() => setIsSuccessModalOpen(false)}>
                  Chiudi
                </Button>
              </Modal.Footer>
            </Modal>

            <div className={'pagination mt-4'}>
              <Pagination>
                <Pagination.Item onClick={() => handlePrevPage()} disabled={currentPage == 1 || !!ebookOnLoading.id}>
                  <FontAwesomeIcon icon={prevPageOnLoading ? faSpinner : faChevronLeft} pulse={prevPageOnLoading} fixedWidth />
                </Pagination.Item>
                <Pagination.Item disabled>Pagina {currentPage}</Pagination.Item>
                <Pagination.Item onClick={() => handleNextPage()} disabled={!hasMore || !!ebookOnLoading.id}>
                  <FontAwesomeIcon icon={nextPageOnLoading ? faSpinner : faChevronRight} pulse={nextPageOnLoading} fixedWidth />
                </Pagination.Item>
              </Pagination>
            </div>
          </>
        )}
      </Container>

      <Footer />
    </>
  );
};

export { Ebook };
