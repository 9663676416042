import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { parse } from 'csv-string';

const CSVParserComponent = ({ show, onHide, onRowsReady, parser }) => {
  const [csvFile, setCsvFile] = useState<File>(null);
  const [csvError, setCsvError] = useState(false);

  useEffect(() => {
    if (!csvFile) return;

    const getFieldIndex = (headerFields: string[], ...fields: string[]): number => {
      for (const field of fields) {
        const index = headerFields.indexOf(field);
        if (index != -1) return index;
      }
      return null;
    };

    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      const result = (e.target as any).result as string;
      const [headerFields, ...rows] = parse(result);
      const indexOfDescription = getFieldIndex(headerFields, 'Marker Name', 'Nome marcatore', 'Email utente');
      if (indexOfDescription == null) {
        console.error({ indexOfDescription });
        setCsvError(true);
        return;
      }

      const extractSeconds = (timestamp) => {
        const [hours, minutes, seconds] = timestamp.split(':');
        return +seconds + +minutes * 60 + +hours * 3600;
      };

      const mappedRows = (() => {
        switch (parser) {
          case 'ts': {
            const indexOfIn = getFieldIndex(headerFields, 'In', 'Attacco');
            if (indexOfIn == null) {
              console.error({ indexOfIn });
              setCsvError(true);
              return;
            }
            return rows
              .map((row) => {
                return { at: extractSeconds(row[indexOfIn]), description: row[indexOfDescription] };
              })
              .filter((row) => !!row.description);
          }
          case 'xray': {
            const indexOfIn = getFieldIndex(headerFields, 'In', 'Attacco');
            const indexOfOut = getFieldIndex(headerFields, 'Out', 'Stacco');
            if (indexOfIn == null || indexOfOut == null) {
              console.error({ indexOfIn, indexOfOut });
              setCsvError(true);
              return;
            }
            return rows
              .map((row) => {
                return { from: extractSeconds(row[indexOfIn]), to: extractSeconds(row[indexOfOut]), description: row[indexOfDescription] };
              })
              .filter((row) => !!row.description);
          }
          case 'users-emails': {
            return rows.map(row => row[indexOfDescription]).filter(email => !!email)
          }
        }
      })();

      onHide();
      onRowsReady(mappedRows);
    });
    reader.readAsText(csvFile);
  }, [csvFile]);

  useEffect(() => {
    if (show) return;
    setCsvFile(null);
    setCsvError(null);
  }, [show]);

  return (
    <Modal show={show} onHide={onHide} backdrop={'static'} keyboard={false}>
      <Modal.Header className={'align-items-baseline'}>
        <Modal.Title>Upload CSV</Modal.Title>
        {/*<span className={'text-light ml-auto'}>(Parser: {parser.toUpperCase()})</span>*/}
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>
            Seleziona un file <small className={'text-muted'}>(CSV)</small>
          </Form.Label>
          <Form.File accept={'text/csv'} onChange={(e) => setCsvFile(e.target.files[0])} disabled={!!csvFile} />
        </Form.Group>
        {csvError && (
          <Alert variant={'danger'} className={'mb-0'}>
            Errore nella lettura del file CSV!
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant={'secondary'} onClick={onHide}>
          Annulla
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { CSVParserComponent as CSVParser };
