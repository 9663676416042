import { Style } from '@capacitor/status-bar';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { FormEvent, lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { ToastContainer } from 'react-toastify';
import { getFID } from 'web-vitals';
import { useFirebase } from '../contexts/FirebaseContext';
import { performance } from '../firebase';
import { useCapacitor } from '../hooks/useCapacitor';
import { FALLBACK_LANGUAGE_2, useCurrentLanguage } from '../hooks/useCurrentLanguage';
import { useMobileDetect } from '../hooks/useMobileDetect';
import './App.scss';
import { AdminRoute } from './components/AdminRoute/AdminRoute';
import { BottomBarNavigation } from './components/BottomBarNavigation/BottomBarNavigation';
import { CapacitorActionsHandler } from './components/CapacitorActionsHandler/CapacitorActionsHandler';
import { ConditionalWrapper } from './components/ConditionalWrapper/ConditionalWrapper';
import { FullscreenLoader } from './components/FullscreenLoader/FullscreenLoader';
import { HistoryEvents } from './components/HistoryEvents/HistoryEvents';
import { TrackingEvents } from './components/HubSpotEvents/HubSpotEvents';
import { Legalese } from './components/Legalese/Legalese';
import { Logo } from './components/Logo/Logo';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import { ScrollToTop } from './components/ScrollToTop/ScrollToTop';
import { TitleHandler } from './components/TitleHandler/TitleHandler';
import { TrialBar } from './components/TrialBar/TrialBar';
import { TwoFA } from './pages/2FA/2FA';
import AdventCalendar from './pages/AdventCalendar/AdventCalendar';
import Browse from './pages/Browse/Browse';
import Acty from './pages/Acty/Acty';
import ActySpeakersList from './pages/ActySpeakersList/ActySpeakersList';
import Company from './pages/Company/Company';
import Courses from './pages/Courses/Courses';
import { Ebook } from './pages/Ebook/Ebook';
import FirstLogin from './pages/FirstLogin/FirstLogin';
import Play from './pages/Play/Play';
import { Profile } from './pages/Profile/Profile';
import Shop from './pages/Shop/Shop';
import Signup from './pages/Signup/Signup';
import Success from './pages/Success/Success';
import UserNotifications from './pages/UserNotifications/UserNotifications';
import { Users } from './pages/Users/Users';
import { Wait } from './pages/Wait/Wait';
import Watch from './pages/Watch/Watch';
import YoutubeLive from './pages/YoutubeLive/YoutubeLive';
import 'react-toastify/dist/ReactToastify.css';
import { useGaming } from '../contexts/GamingContext';
import GiftDetails from './pages/GiftDetails/GiftDetails';
import BlogComponent from './pages/Blog/Blog';
import BlogArticleComponent from './pages/BlogArticle/BlogArticle';
import { BadgeUnlockPopup } from './components/BadgeUnlockPopup/BadgeUnlockPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Companies from './pages/Companies/Companies';
import ActyAppointmentList from './pages/ActyAppointmentList/ActyAppointmentList';
import ActyAppointment from './pages/ActyAppointment/ActyAppointment';
import ActyCreateReservation from './pages/ActyCreateReservation/ActyCreateReservation';
import ActyCategories from './pages/ActyCategories/ActyCategories';
import AdminActyAppointments from './pages/AdminActyAppointments/AdminActyAppointments';
import ActyFaq from './pages/ActyFaq/ActyFaq';

const Home = lazy(() => import('./pages/Home/Home'));
const Help = lazy(() => import('./pages/Help/Help'));
const Legal = lazy(() => import('./pages/Legal/Legal'));
const AuthHandler = lazy(() => import('./pages/AuthHandler/AuthHandler'));
const Collaborate = lazy(() => import('./pages/Collaborate/Collaborate'));
const Reservation = lazy(() => import('./pages/Reservation/Reservation'));
const MyList = lazy(() => import('./pages/MyList/MyList'));
const Search = lazy(() => import('./pages/Search/Search'));
const Series = lazy(() => import('./pages/Series/Series'));
const Test = lazy(() => import('./pages/Test/Test'));
const Course = lazy(() => import('./pages/Course/Course'));
const Plans = lazy(() => import('./pages/Plans/Plans'));
const TV = lazy(() => import('./pages/TV/TV'));
const Tootors = lazy(() => import('./pages/Tootors/Tootors'));
const PlatformComingSoon = lazy(() => import('./pages/PlatformComingSoon/PlatformComingSoon'));
const Tickets = lazy(() => import('./pages/Tickets/Tickets'));
const Ticket = lazy(() => import('./pages/Ticket/Ticket'));
const Gift = lazy(() => import('./pages/Gift/Gift'));
const VideoReports = lazy(() => import('./pages/VideoReports/VideoReports'));
const Feedbacks = lazy(() => import('./pages/Feedbacks/Feedbacks'));
const Realtime = lazy(() => import('./pages/Realtime/Realtime'));
const Publish = lazy(() => import('./pages/Publish/Publish'));
const Replace = lazy(() => import('./pages/Replace/Replace'));
const ComingSoon = lazy(() => import('./pages/ComingSoon/ComingSoon'));
const Content = lazy(() => import('./pages/Content/Content'));
const Stats = lazy(() => import('./pages/Stats/Stats'));
const Logs = lazy(() => import('./pages/Logs/Logs'));
const Lab = lazy(() => import('./pages/Lab/Lab'));
const Cache = lazy(() => import('./pages/Cache/Cache'));
const Upload = lazy(() => import('./pages/Upload/Upload'));
const StripeDashboard = lazy(() => import('./pages/StripeDashboard/StripeDashboard'));
// const Notifications = lazy(() => import('./pages/Notifications/Notifications'));
const Contacts = lazy(() => import('./pages/Contacts/Contacts'));

const fidTrace = performance.trace('fid');
fidTrace.start();
getFID(() => fidTrace.stop());

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  environment: process.env.NODE_ENV,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['::1'],
    }),
  ],
  tracesSampleRate: 1.0,
  release: window['SENTRY_RELEASE']?.id ?? process.env.REACT_APP_VERSION,
  beforeSend(event: Sentry.Event) {
    if (window.navigator.userAgent.includes('iubenda-radar')) return null;
    return event;
  },
});

const withSuspense = (WrappedComponent) => () =>
  (
    <Suspense fallback={<FullscreenLoader />}>
      <WrappedComponent />
    </Suspense>
  );

export default function App() {
  const { isTrial, isLoggedIn, currentUser, sendEmailVerification, logout, database, staticData, userDataFmt, fv } = useFirebase();
  const {
    track,
    openedNotification,
    setOpenedNotification,
    showDataModal,
    setShowDataModal,
    formLoading,
    setFormLoading,
    formData,
    setFormData,
    setPauseVideo,
  } = useGaming();

  const [configLoaded, setConfigLoaded] = useState(true);
  const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isUpdateAvailableModalOpen, setIsUpdateAvailableModalOpen] = useState(false);
  const [suggestApp, setSuggestApp] = useState(false);
  const [inVideo, setInVideo] = useState(false);

  const { isNative, platform, StatusBar, Orientation } = useCapacitor();
  const { isMobile, isTV } = useMobileDetect();
  const { i18n, t } = useTranslation();
  const { currentLanguage, updateLanguage } = useCurrentLanguage();

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormLoading(true);
    const notificationId = openedNotification.id ?? openedNotification.data.id;
    database
      .doc(`userData/${currentUser.uid}/notifications/${notificationId}`)
      .set({ data: { ...openedNotification.data, form: formData, redeemed: true, redeemedAt: fv.serverTimestamp() } }, { merge: true })
      .then(() => {
        track('DATA_SUBMITTED', { id: notificationId });
      })
      .catch(console.error)
      .finally(() => {
        setFormLoading(false);
        setShowDataModal(false);
        setOpenedNotification(null);
        setPauseVideo(false);
        // onUpdate();
      });
  };

  const handleChange = (event) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  };

  const handleRefresh = () => {
    window.location.reload();
    return Promise.resolve();
  };

  useEffect(() => {
    (async () => {
      if (userDataFmt?.profile?.formData?.categoriaProfessionale === 'aso') {
        await i18n.changeLanguage(FALLBACK_LANGUAGE_2);
        await updateLanguage(FALLBACK_LANGUAGE_2, false);
      } else {
        await i18n.changeLanguage(currentLanguage);
      }
    })();

    const cleanup = database.doc('system/actions').onSnapshot((snapshot) => {
      const data = snapshot.data();
      for (const [key, value] of Object.entries(data)) {
        switch (key) {
          case 'FORCE_RELOAD': {
            const storedValue = localStorage.getItem('__FR_VERSION');
            if (value != storedValue) {
              localStorage.setItem('__FR_VERSION', value);
              window.location.reload();
            }
            break;
          }
          case 'ADMIN_UPDATE': {
            const storedValue = localStorage.getItem('__AU_VERSION');
            if (value != storedValue) {
              localStorage.setItem('__AU_VERSION', value);
              if (!!userDataFmt?.admin) setIsUpdateAvailableModalOpen(true);
            }
            break;
          }
        }
      }
    });

    return () => cleanup();
  }, [userDataFmt]);

  // useEffect(() => {
  //   (async () => {
  //     if (process.env.NODE_ENV != 'production') {
  //       remoteConfig.settings.minimumFetchIntervalMillis = 0;
  //     }
  //     await remoteConfig.fetchAndActivate();
  //     setConfigLoaded(true);
  //   })();
  // }, []);

  const resetHubspotChatBottom = useCallback(() => {
    document.documentElement.style.setProperty('--hubspot-chat-bottom', '0');
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute('lang', currentLanguage);
  }, []);

  useEffect(() => {
    if (getCookieConsentValue()) {
      resetHubspotChatBottom();
    }
  }, []);

  useEffect(() => {
    if (isNative) {
      if (isTV) Orientation?.lock('landscape').catch(console.error);
      else Orientation?.lock('portrait').catch(console.error);
      if (platform == 'ios') {
        StatusBar.setStyle({ style: Style.Dark }).catch(console.error);
      }
    }
  }, [isNative, isTV]);

  useEffect(() => {
    setSuggestApp(isMobile && !isNative && isLoggedIn);
  }, [isMobile, isNative, isLoggedIn]);

  useEffect(() => {
    if (!currentUser || !staticData) return;
    if (window.location.pathname.startsWith('/__/auth/action')) return;
    if (staticData.FORCE_EMAIL_VERIFICATION) setIsVerifyEmailModalOpen(!currentUser.emailVerified);
  }, [currentUser, staticData]);

  const verifyEmail = () => {
    sendEmailVerification('/browse').then(() => {
      setIsVerifyEmailModalOpen(false);
      setIsConfirmModalOpen(true);
    });
  };

  const handleLogout = async () => {
    await logout();
    window.location.reload();
  };

  if (!configLoaded || !Object.keys(staticData).length) return <FullscreenLoader />;

  if (isVerifyEmailModalOpen)
    return (
      <Modal show={true} backdrop={'static'} keyboard={false} centered>
        <div style={{ position: 'absolute', top: '-42px', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Logo style={{ height: '36px' }} />
        </div>
        <Modal.Header>
          <Modal.Title>{t('app.labels.verificaNecessaria')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('app.labels.verificaEmail')}
          <br />
          {t('app.labels.verificaEmail2')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant={'secondary'} onClick={() => handleLogout()}>
            {t('labels.logout')}
          </Button>
          <Button variant={'primary'} onClick={() => verifyEmail()} className={'ml-auto'}>
            {t('app.labels.inviaMailConferma')}
          </Button>
        </Modal.Footer>
      </Modal>
    );

  if (isConfirmModalOpen)
    return (
      <Modal show={true} backdrop={'static'} keyboard={false} centered>
        <div style={{ position: 'absolute', top: '-42px', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Logo style={{ height: '36px' }} />
        </div>
        <Modal.Header>
          <Modal.Title>{t('app.labels.emailInviata')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('app.labels.verificaMailEClicca')}</Modal.Body>
        <Modal.Footer>
          <Button variant={'secondary'} onClick={() => handleLogout()}>
            {t('labels.logout')}
          </Button>
          <Button variant={'primary'} onClick={() => verifyEmail()} className={'ml-auto'}>
            {t('app.labels.inviaMailConferma2')}
          </Button>
        </Modal.Footer>
      </Modal>
    );

  return (
    <>
      {/* <Button style={{ zIndex: 10000 }} onClick={() => animationNotificationBadge()}>
        Prova
      </Button> */}
      <ScrollToTop />
      <TitleHandler />
      <CapacitorActionsHandler />
      <TrackingEvents />
      <HistoryEvents enabled={false} />

      <ConditionalWrapper
        condition={isNative}
        wrapper={(children) => (
          <PullToRefresh
            className={(() => {
              let classes = [];
              if (isNative) classes.push('scrollable-viewport');
              if (!isLoggedIn || (!isTrial && inVideo)) classes.push('force-full-height');
              if (isTrial && isNative) classes.push('higher-81px');
              if (isTrial && inVideo) classes.push('lesser-25px');
              return classes.join(' ');
            })()}
            onRefresh={handleRefresh}
            isPullable={false}
          >
            {children}
          </PullToRefresh>
        )}
      >
        <Switch>
          <Route path={'/'} exact component={withSuspense(Home)} />
          <Route path={'/signup'} component={Signup} />
          <Route path={'/help'} component={withSuspense(Help)} />
          <Route path={'/legal/:doc?'} component={withSuspense(Legal)} />
          <Route path={'/company-info'} component={Company} />
          <Route path={'/blog'} exact component={BlogComponent} />
          <Route path={'/blog/:id/:slug'} exact component={BlogArticleComponent} />
          <Route path={'/__/auth/action'} component={withSuspense(AuthHandler)} />
          <Route path={'/collaborate'} component={withSuspense(Collaborate)} />
          <Route path={'/reservation/:event'} component={withSuspense(Reservation)} />
          <Route path={'/acty-faq'} component={ActyFaq} />
          <Route path={'/contact-us'} component={withSuspense(Contacts)} />
          <PrivateRoute path={'/browse'} component={Browse} />
          <PrivateRoute path={'/wait'} component={Wait} />
          <PrivateRoute path={'/advent'} component={AdventCalendar} />
          <PrivateRoute path={'/list'} component={withSuspense(MyList)} />
          <PrivateRoute path={'/search'} component={withSuspense(Search)} />
          <PrivateRoute path={'/first-login'} component={FirstLogin} />
          <PrivateRoute path={'/watch/:id'} component={Watch} />
          <PrivateRoute path={'/series/:id'} component={withSuspense(Series)} />
          <PrivateRoute path={'/profile'} component={Profile} />
          <PrivateRoute path={'/play'} component={Play} />
          <PrivateRoute path={'/shop'} component={Shop} />
          <PrivateRoute path={'/courses'} component={Courses} countryRestrictions={['IT']} />
          <PrivateRoute path={'/company/:id/courses'} component={Courses} countryRestrictions={['IT']} />
          <PrivateRoute path={'/course/:courseId/watch/:id'} component={Watch} countryRestrictions={['IT']} />
          <PrivateRoute path={'/course/:courseId/test/:id'} component={withSuspense(Test)} countryRestrictions={['IT']} />
          <PrivateRoute path={'/course/:id'} component={withSuspense(Course)} countryRestrictions={['IT']} />
          <PrivateRoute path={'/plans'} component={withSuspense(Plans)} />
          <PrivateRoute path={'/success'} component={Success} />
          <PrivateRoute path={'/tv'} component={withSuspense(TV)} />
          <PrivateRoute path={'/tootors'} component={withSuspense(Tootors)} />
          <PrivateRoute path={'/live'} component={YoutubeLive} />
          <PrivateRoute path={'/platform-coming-soon'} component={withSuspense(PlatformComingSoon)} />
          <PrivateRoute path={'/tickets'} component={withSuspense(Tickets)} />
          <PrivateRoute path={'/ticket/:id'} component={withSuspense(Ticket)} />
          <PrivateRoute exact path={'/gift'} component={withSuspense(Gift)} countryRestrictions={['IT']} />
          <PrivateRoute path={'/gift/details/:id'} component={GiftDetails} countryRestrictions={['IT']} />
          <PrivateRoute path={'/books'} component={Ebook} />
          <PrivateRoute exact path={'/acty'} component={Acty} />
          <PrivateRoute exact path={'/acty/categories'} component={ActyCategories} />
          <PrivateRoute exact path={'/acty/categories/:actyCategoryId/speakers'} component={ActySpeakersList} />
          <PrivateRoute path={'/acty/categories/:actyCategoryId/speakers/:actyTutorId'} component={ActyCreateReservation} />
          <PrivateRoute exact path={'/acty/appointments'} component={ActyAppointmentList} />
          <PrivateRoute path={'/acty/appointments/:actyAppointmentId'} component={ActyAppointment} />
          <PrivateRoute path={'/notifications'} component={UserNotifications}></PrivateRoute>
          <AdminRoute path={'/admin/tickets'} component={withSuspense(Tickets)} />
          <AdminRoute path={'/admin/ticket/:id'} component={withSuspense(Ticket)} />
          <AdminRoute path={'/admin/reports'} component={withSuspense(VideoReports)} />
          <AdminRoute path={'/admin/feedbacks'} component={withSuspense(Feedbacks)} />
          <AdminRoute path={'/admin/realtime'} component={withSuspense(Realtime)} />
          <AdminRoute path={'/admin/users'}>
            <Users />
          </AdminRoute>
          <AdminRoute path={'/admin/publish'} component={withSuspense(Publish)} />
          <AdminRoute path={'/admin/replace'} component={withSuspense(Replace)} />
          <AdminRoute path={'/admin/coming-soon'} component={withSuspense(ComingSoon)} />
          <AdminRoute path={'/admin/content'} component={withSuspense(Content)} />
          <AdminRoute path={'/admin/stats'} component={withSuspense(Stats)} />
          <AdminRoute path={'/admin/logs'} component={withSuspense(Logs)} />
          <AdminRoute path={'/admin/lab'} component={withSuspense(Lab)} />
          <AdminRoute path={'/admin/cache'} component={withSuspense(Cache)} />
          <AdminRoute path={'/admin/upload'} component={withSuspense(Upload)} />
          <AdminRoute path={'/admin/stripe-dashboard'} component={withSuspense(StripeDashboard)} />
          <AdminRoute path={'/admin/companies'} component={Companies} />
          <AdminRoute path={'/admin/one-to-one'} component={AdminActyAppointments} />
          {/* <AdminRoute path={'/admin/notifications'} component={withSuspense(Notifications)} /> */}
          <AdminRoute path={'/admin/2fa'} component={TwoFA} />
          <Route path={'*'} component={() => <Redirect to={'/'} />} />
        </Switch>
      </ConditionalWrapper>

      <BottomBarNavigation onSetInVideo={setInVideo} />
      <TrialBar />

      {/*{suggestApp && (
        <div className={'bottom-bar-suggest-app'}>
          <span>Scarica {process.env.REACT_APP_TITLE} per usufruire di tutte le funzionalità!</span>
          <Button className={'custom-download-button'} variant={'primary'}>
            Scarica APP
          </Button>
        </div>
      )}*/}

      {!isNative && (
        <CookieConsent
          onAccept={resetHubspotChatBottom}
          buttonText={<FontAwesomeIcon icon={faCheck} size={'sm'} />}
          disableButtonStyles={true}
          location={'bottom'}
          containerClasses={'flex-row-reverse'}
          buttonClasses={'btn btn-success btn-sm ml-2'}
          contentClasses={'m-2'}
        >
          <div>🍪&nbsp; {t('app.labels.sitoUsaCookie')}</div>
          <small>
            {t('app.labels.controllaDati')} <Legalese just={'privacy'} /> {t('app.labels.controllaDati2')} <Legalese just={'cookie'} />
          </small>
        </CookieConsent>
      )}

      <Modal show={isUpdateAvailableModalOpen} onHide={() => setIsUpdateAvailableModalOpen(false)} centered>
        <Modal.Header>
          <Modal.Title>È disponibile un aggiornamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Non è obbligatorio effettuarlo subito, ma è necessario per disporre delle nuove funzionalità e va quindi eseguito quanto prima.</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={'secondary'} onClick={() => setIsUpdateAvailableModalOpen(false)}>
            Più tardi
          </Button>
          <Button variant={'primary'} onClick={() => window.location.reload()}>
            Aggiorna
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      {showDataModal && (
        <Modal show={showDataModal} onHide={() => setShowDataModal(false)} centered scrollable>
          <Modal.Header>
            <Modal.Title as={'h5'}>{t('labels.redeemReward')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {openedNotification.data?.['payload.text'] && <p>{openedNotification.data['payload.text']}</p>}
            <Form onSubmit={handleFormSubmit}>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Control
                      name={'nome'}
                      disabled={formLoading}
                      value={formData.nome}
                      onChange={handleChange}
                      placeholder={t('adventCalendar.labels.nome')}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Control
                      name={'cognome'}
                      disabled={formLoading}
                      value={formData.cognome}
                      onChange={handleChange}
                      placeholder={t('adventCalendar.labels.cognome')}
                      required
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Control
                      name={'indirizzo'}
                      disabled={formLoading}
                      value={formData.indirizzo}
                      onChange={handleChange}
                      placeholder={t('adventCalendar.labels.riga1')}
                      required
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Control
                      name={'indirizzo2'}
                      disabled={formLoading}
                      value={formData.indirizzo2}
                      onChange={handleChange}
                      placeholder={t('adventCalendar.labels.riga2')}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col xs={3}>
                  <Form.Group>
                    <Form.Control
                      name={'cap'}
                      disabled={formLoading}
                      value={formData.cap}
                      onChange={handleChange}
                      placeholder={'CAP*'}
                      type={'number'}
                      min={0}
                      max={99999}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Control
                      name={'citta'}
                      disabled={formLoading}
                      value={formData.citta}
                      onChange={handleChange}
                      placeholder={t('adventCalendar.labels.citta')}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group>
                    <Form.Control
                      name={'provincia'}
                      disabled={formLoading}
                      value={formData.provincia}
                      onChange={handleChange}
                      placeholder={t('adventCalendar.labels.provincia')}
                      minLength={2}
                      maxLength={2}
                      required
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Control
                      name={'email'}
                      disabled={formLoading}
                      value={formData.email}
                      onChange={handleChange}
                      placeholder={t('adventCalendar.labels.email')}
                      type={'email'}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Control
                      name={'telefono'}
                      disabled={formLoading}
                      value={formData.telefono}
                      onChange={handleChange}
                      placeholder={t('adventCalendar.labels.telefono')}
                      type={'tel'}
                      required
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col>
                  <Form.Check
                    type={'checkbox'}
                    disabled={formLoading}
                    name={'checkProfilazione'}
                    onChange={(e) =>
                      setFormData({
                        name: e.target.name,
                        value: e.target.checked,
                      })
                    }
                    checked={formData.checkProfilazione}
                    label={
                      <div className={'small text-muted'} style={{ lineHeight: '1rem' }}>
                        {t('adventCalendar.labels.accettoProfilazione', { title: process.env.REACT_APP_TITLE })}{' '}
                      </div>
                    }
                  />
                  <Form.Check
                    type={'checkbox'}
                    disabled={formLoading}
                    name={'checkComunicazioniDaTootor'}
                    onChange={(e) =>
                      setFormData({
                        name: e.target.name,
                        value: e.target.checked,
                      })
                    }
                    checked={formData.checkComunicazioniDaTootor}
                    label={
                      <div className={'small text-muted'} style={{ lineHeight: '1rem' }}>
                        {t('adventCalendar.labels.accettoComunicazioniDaTootor', { title: process.env.REACT_APP_TITLE })}{' '}
                      </div>
                    }
                  />
                  <Form.Check
                    type={'checkbox'}
                    disabled={formLoading}
                    name={'checkMarketingSocietaTerze'}
                    onChange={(e) =>
                      setFormData({
                        name: e.target.name,
                        value: e.target.checked,
                      })
                    }
                    checked={formData.checkMarketingSocietaTerze}
                    label={
                      <div className={'small text-muted'} style={{ lineHeight: '1rem' }}>
                        {t('adventCalendar.labels.accettoMarketingSocietaTerze', { title: process.env.REACT_APP_TITLE })}{' '}
                      </div>
                    }
                  />
                  {openedNotification.data['customPrivacyPolicyLink'] && (
                    <Form.Check
                      type={'checkbox'}
                      disabled={formLoading}
                      name={'customPrivacyPolicyLink'}
                      onChange={(e) =>
                        setFormData({
                          name: e.target.name,
                          value: e.target.checked,
                        })
                      }
                      checked={formData.customPrivacyPolicyLink}
                      label={
                        <span className={'small text-muted'}>
                          <a href={openedNotification.data['customPrivacyPolicyLink']}>{openedNotification.data['customPrivacyPolicyLink']}</a>
                        </span>
                      }
                    />
                  )}
                </Col>
              </Form.Row>

              <div className={'mt-3 d-flex justify-content-end'}>
                <Button
                  variant={'secondary'}
                  type={'submit'}
                  disabled={formLoading}
                  className={'mr-2'}
                  onClick={() => {
                    setPauseVideo(false);
                    setShowDataModal(false);
                  }}
                >
                  {t('labels.nonOra')}
                </Button>
                <Button variant={'info'} type={'submit'} disabled={formLoading}>
                  {formLoading ? t('labels.invioInCorso') : t('labels.invia')}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      )}

      <BadgeUnlockPopup />
    </>
  );
}
