import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { FormEvent, useEffect, useRef, useState } from 'react';
import firebase from 'firebase/app';
import { multiFactorAssertion, phoneAuthCredential, phoneAuthProvider } from '../../../firebase';
import { useCapacitor } from '../../../hooks/useCapacitor';
import { useTranslation } from 'react-i18next';

// declare const SMSReceive: any;

const MFACodeComponent = ({ show, onHide, resolver, onSuccess, onError = (e: any) => {} }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const recaptchaVerifier = useRef<firebase.auth.RecaptchaVerifier>();
  const [step, setStep] = useState(1);
  const [verificationId, setVerificationId] = useState('');
  const [loading, setLoading] = useState(false);
  const [recaptchaReady, setRecaptchaReady] = useState(false);
  const { platform } = useCapacitor();
  const finalSubmitButton = useRef<HTMLButtonElement>();

  useEffect(() => {
    if (!resolver || !show) return;

    if (!show) {
      setStep(1);
      recaptchaVerifier.current?.clear();
      return;
    }

    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[0],
      session: resolver.session,
    };

    recaptchaVerifier.current = new firebase.auth.RecaptchaVerifier('confirm-code-btn', {
      size: 'invisible',
      callback: () => {
        setLoading(true);
        phoneAuthProvider
          .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier.current)
          .then((verificationId) => {
            setVerificationId(verificationId);
            setStep(2);
          })
          .catch((error) => {
            onError(error);
            (recaptchaVerifier.current as any).reset();
          })
          .finally(() => setLoading(false));
      },
    });
    recaptchaVerifier.current.render().then(() => setRecaptchaReady(true));

    return () => {
      setStep(1);
      recaptchaVerifier.current?.clear();
    };
  }, [resolver, show]);

  // useEffect(() => {
  //   return () => {
  //     try {
  //       if (platform == 'android') {
  //         SMSReceive.stopWatch();
  //       }
  //     } catch {}
  //   };
  // }, []);

  // useEffect(() => {
  //   if (step != 2) return;
  //   if (platform == 'android') {
  //     SMSReceive.startWatch(() => {
  //       document.addEventListener('onSMSArrive', (e: any) => {
  //         const { body }: { body: string } = e.data;
  //         if (!body.includes('verification code for localhost')) return;
  //         const [smsCode] = body.match(/\d{6}/);
  //         if (!smsCode) return;
  //         setCode(smsCode);
  //         SMSReceive.stopWatch();
  //         finalSubmitButton.current.click();
  //       });
  //     });
  //   }
  //   return () => {
  //     try {
  //       if (platform == 'android') {
  //         SMSReceive.stopWatch();
  //       }
  //     } catch {}
  //   };
  // }, [step]);

  const onCodeSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const credentials = phoneAuthCredential(verificationId, code);
    const assertion = multiFactorAssertion(credentials);
    setLoading(true);
    resolver
      .resolveSignIn(assertion)
      .then(onSuccess)
      .catch((error) => {
        if (error.code == 'auth/code-expired') {
          setVerificationId(null);
          setStep(1);
          (recaptchaVerifier.current as any).reset();
        }
        onError(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Autenticazione a due fattori</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {step == 1 && (
          <Form>
            <Form.Group>
              <Form.Label>Per questo account è attiva l'autenticazione a due fattori.</Form.Label>
            </Form.Group>
            <Button block variant={'primary'} disabled={loading || !recaptchaReady} type={'submit'} id={'confirm-code-btn'}>
              {loading || !recaptchaReady ? (
                <span>{t('labels.attendi')}&hellip;</span>
              ) : (
                <span>Invia SMS al numero ***{resolver?.hints?.[0]?.phoneNumber?.slice?.(-4)}</span>
              )}
            </Button>
          </Form>
        )}
        {step == 2 && (
          <Form onSubmit={onCodeSubmit}>
            <Form.Group>
              <Form.Label htmlFor={'code'}>Inserisci il codice che abbiamo inviato al tuo numero di cellulare.</Form.Label>
              <InputGroup>
                <Form.Control
                  autoFocus
                  style={{ textAlign: 'center' }}
                  required
                  size={'lg'}
                  id={'code'}
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
                <InputGroup.Append>
                  <Button variant={'primary'} ref={finalSubmitButton} disabled={loading} type={'submit'}>
                    {t('labels.conferma')}
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant={'secondary'} onClick={onHide}>
          {t('labels.annulla')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { MFACodeComponent as MFACode };
