import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { Footer } from '../../components/Footer/Footer';
import { NotificationsList } from '../../components/NotificationsList/NotificationsList';
import { Title } from '../../components/Title/Title';
import { Topbar } from '../../components/Topbar/Topbar';

const UserNotifications = () => {
  const { t } = useTranslation();
  const { getUserNotifications } = useFirebase();

  const [page, setPage] = useState<number>(0);
  const [userNotifications, setUserNotifications] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [finished, setFinished] = useState<boolean>(false);

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = () => {
    getUserNotifications(page).then((data) => {
      if (!data.length) setPage((currentPage) => currentPage - 1);
      if (data.length < 15) setFinished(true);
      setUserNotifications((prev) => {
        if (prev.length) return [...prev, ...data];
        return data;
      });
      setPage((currentPage) => currentPage + 1);
      setLoading(false);
    });
  };

  const update = () => {
    setLoading(true);
    getUserNotifications(0).then((data) => {
      setUserNotifications(data);
      setPage(1);
      setLoading(false);
      setFinished(false);
    });
  };

  return (
    <>
      <Container className={'mt-5'}>
        <Title>Notifiche</Title>

        <div style={{ marginBottom: '72px' }}>
          <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
            <Topbar />
          </div>
        </div>

        <div>
          <h1>{t('labels.notifiche')}</h1>
          <NotificationsList
            isDropdown={false}
            userNotifications={userNotifications}
            fetchOthers={getNotifications}
            loading={loading}
            finished={finished}
            onUpdate={update}
          />
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default UserNotifications;
