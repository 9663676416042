import './UserData.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExternalLinkAlt, faTimesCircle, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { createHashedQueryParams, formatSecondsDuration, formatSecondsTimestamp, formatStringDate } from '../../utils/utils';
import { Alert, Badge, Button, Form, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { FullscreenLoader } from '../FullscreenLoader/FullscreenLoader';
import { WithTooltip } from '../WithTooltip/WithTooltip';
import { AdminNotes } from '../AdminNotes/AdminNotes';
import { Copyable } from '../Copyable/Copyable';

const UserDataSectionSeparator = ({ children }) => (
  <Alert variant={'dark'} className={'mb-0 bg-secondary pt-5 pb-3'} style={{ borderRadius: '0' }}>
    {children}
  </Alert>
);

const UserDataComponent = ({ data, onUserUpdate = () => {} }) => {
  const { updateUser, staticData, currentUser } = useFirebase();
  const [loading, setLoading] = useState(false);

  const [dangerZoneCheck, setDangerZoneCheck] = useState(false);
  const [accountDeleteModalOpen, setAccountDeleteModalOpen] = useState(false);

  const [editNameModalOpen, setEditNameModalOpen] = useState(false);
  const [newName, setNewName] = useState(data.userData?.profile?.formData?.nome || '');
  const [newSurname, setNewSurname] = useState(data.userData?.formData?.profile?.cognome || '');
  useEffect(() => {
    if (!editNameModalOpen) {
      setNewName(data.userData?.profile?.formData?.nome || '');
      setNewSurname(data.userData?.profile?.formData?.cognome || '');
    }
  }, [editNameModalOpen]);

  const [customTrialModalOpen, setCustomTrialModalOpen] = useState(false);
  const [customTime, setCustomTime] = useState(data.userData?.customTrialSeconds ?? staticData.DEFAULT_TRIAL_SECONDS);
  useEffect(() => {
    if (!customTrialModalOpen) setCustomTime(data.userData?.customTrialSeconds ?? staticData.DEFAULT_TRIAL_SECONDS);
  }, [customTrialModalOpen]);

  const [trialExtensionModalOpen, setTrialExtensionModalOpen] = useState(false);
  const [trialExtension, setTrialExtension] = useState(0);
  useEffect(() => {
    if (!trialExtensionModalOpen) setTrialExtension(0);
  }, [trialExtensionModalOpen]);

  const [customVideoModalOpen, setCustomVideoModalOpen] = useState(false);
  const [customVideos, setCustomVideos] = useState(data.userData?.customTrialVideos ?? staticData.DEFAULT_TRIAL_VIDEOS ?? 0);
  useEffect(() => {
    if (!customVideoModalOpen) setCustomVideos(data.userData?.customTrialVideos ?? staticData.DEFAULT_TRIAL_VIDEOS ?? 0);
  }, [customVideoModalOpen]);

  const [customDevicesModalOpen, setCustomDevicesModalOpen] = useState(false);
  const [customDevices, setCustomDevices] = useState(data.userData?.customDevices ?? 2);
  useEffect(() => {
    if (!customVideoModalOpen) setCustomDevices(data.userData?.customDevices ?? 2);
  }, [customDevicesModalOpen]);

  const [impersonateModalOpen, setImpersonateModalOpen] = useState(false);
  const [impersonationLink, setImpersonationLink] = useState('');
  useEffect(() => {
    (async () => {
      const baseUrl = process.env.NODE_ENV == 'production' ? 'https://impersonate.tootor.it' : 'http://localhost:3002';
      const params = {
        uid: data.uid,
        sender: currentUser.uid,
      };
      const hashedParams = await createHashedQueryParams(params);
      setImpersonationLink(`${baseUrl}?${hashedParams}`);
    })();
  }, []);

  const triggerUpdate = (updater) => {
    return new Promise<void>((resolve) => {
      setLoading(true);
      updateUser(data.uid, updater).then(() => {
        setLoading(false);
        resolve();
        onUserUpdate();
      });
    });
  };

  const BoolValue = ({ value, className = '' }) => (
    <div className={(value ? 'text-success ' : 'text-danger ') + className}>
      <FontAwesomeIcon icon={value ? faCheckCircle : faTimesCircle} size={'sm'} />
    </div>
  );

  const DateValue = ({ value }) => <span>{value ? formatStringDate(value.replace('GMT', 'Z'), 'EEE, d MMM y HH:mm:ss X', 'dd/MM/y HH:mm') : '(null)'}</span>;

  if (!data) return null;

  return (
    <>
      {!data.error && (
        <div className={'text-right text-lg-center my-3'}>
          <AdminNotes notes={data.userData.notes} path={`userData/${data.uid}`} onSubmit={() => onUserUpdate()} context={['user', data.uid]} />
        </div>
      )}
      <div className={'user-data-container border border-dark rounded'}>
        {!!data.error && <pre className={'m-0'}>{JSON.stringify(data, null, 2)}</pre>}
        {!data.error && (
          <>
            {currentUser.uid == data.uid && (
              <Alert variant={'info'} className={'mb-0'} style={{ borderBottomLeftRadius: '0', borderBottomRightRadius: '0' }}>
                Stai guardando le informazioni relative a te stesso.
              </Alert>
            )}
            <div className={'user-data-content'}>
              <UserDataSectionSeparator>Informazioni di base e di contatto</UserDataSectionSeparator>
              <div>
                <strong>UID:</strong>
                <Copyable text={data.uid} />
                {!!staticData.ALLOW_IMPERSONATION && (
                  <Button
                    variant={'warning'}
                    className={'ml-auto'}
                    size={'sm'}
                    onClick={() => setImpersonateModalOpen(true)}
                    disabled={data.uid == currentUser.uid}
                  >
                    <FontAwesomeIcon icon={faUserSecret} /> Impersona
                  </Button>
                )}
              </div>
              <div>
                <strong>Email:</strong>
                <div>
                  <a href={'mailto:' + data.email} target={'_blank'}>
                    {data.email}
                  </a>
                </div>
                {/*{data.providerData.map((provider) => provider.providerId).includes('google.com') ? (
                  <small className={'ml-auto text-muted'}>L'utente ha effettuato l'accesso con Google e non è possibile modificare l'email</small>
                ) : (
                  <Button className={'ml-auto'} size={'sm'} disabled={loading}>
                    Modifica email
                  </Button>
                )}*/}
              </div>
              <div>
                <strong>Nome:</strong>
                <div>{`${data.userData?.profile?.formData?.nome} ${data.userData?.profile?.formData?.cognome}`.trim() || 'N/A'}</div>
                <Button className={'ml-auto'} variant={'primary'} size={'sm'} onClick={() => setEditNameModalOpen(true)}>
                  Modifica
                </Button>
              </div>
              <div>
                <strong>Categoria professionale:</strong>
                <Badge variant={!!data.userData?.profile?.formData?.categoriaProfessionale ? 'primary' : 'secondary'}>
                  {(data.userData?.profile?.formData?.categoriaProfessionale || 'N/A').toUpperCase()}
                </Badge>
                <div className={'ml-auto'}>
                  {['aso', 'odontoiatra']
                    .filter((categoria) => categoria != data.userData?.profile?.formData?.categoriaProfessionale)
                    .map((categoria) => (
                      <Button
                        key={categoria}
                        className={'ml-2'}
                        size={'sm'}
                        disabled={loading}
                        onClick={() =>
                          triggerUpdate({
                            categoriaProfessionale: categoria,
                          })
                        }
                      >
                        Imposta {categoria.toUpperCase()}
                      </Button>
                    ))}
                </div>
              </div>
              <div>
                <strong>Provider di accesso:</strong>
                {data.providerData.map((provider) => (
                  <Badge variant={'light'} key={provider.uid} className={'mr-1'}>
                    {provider.providerId}
                  </Badge>
                ))}
              </div>
              <div>
                <strong>Email verificata?</strong>
                <BoolValue value={data.emailVerified} />
                <Button className={'ml-auto'} size={'sm'} disabled={data.emailVerified || loading} onClick={() => triggerUpdate({ emailVerified: true })}>
                  Verifica manualmente
                </Button>
              </div>
              <div>
                <strong>Admin?</strong>
                <BoolValue value={data.userData.admin} />
              </div>
              <div>
                <strong>Abilitato?</strong>
                <BoolValue value={!data.disabled} />
                <Button
                  variant={!data.disabled ? 'danger' : 'primary'}
                  className={'ml-auto'}
                  size={'sm'}
                  disabled={loading}
                  onClick={() => triggerUpdate({ disabled: !data.disabled })}
                >
                  {!data.disabled ? 'Disattiva' : 'Attiva'} utente
                </Button>
              </div>
              {/*<div>
                <strong>Accesso anticipato?</strong>
                <BoolValue value={data.userData.earlyAccess} />
                <WithTooltip
                  text={'Prima della data di uscita ufficiale, un utente con accesso anticipato potrà comunque accedere ai contenuti.'}
                  big={false}
                  placement={'right'}
                >
                  <small className={'text-muted ml-1'} style={{ cursor: 'help', textDecoration: 'underline dotted' }}>
                    Cosa significa?
                  </small>
                </WithTooltip>
                <Button
                  variant={data.userData.earlyAccess ? 'danger' : 'success'}
                  className={'ml-auto'}
                  size={'sm'}
                  disabled={loading}
                  onClick={() => triggerUpdate({ earlyAccess: !data.userData.earlyAccess })}
                >
                  {data.userData.earlyAccess ? 'Revoca' : 'Concedi'} accesso anticipato
                </Button>
              </div>*/}
              <div>
                <strong>Data creazione:</strong>
                <DateValue value={data.metadata.creationTime} />
              </div>
              <div>
                <strong>Ultimo accesso:</strong>
                <DateValue value={data.metadata.lastSignInTime} />
              </div>
              <div>
                <strong>Ultimo refresh:</strong>
                <DateValue value={data.metadata.lastRefreshTime} />
              </div>
              <div>
                <strong>Alias:</strong>
                {!!data.userData.alias ? <Copyable text={data.userData.alias} /> : <Badge variant={'secondary'}>N/A</Badge>}
              </div>
              <div>
                <strong>MFA:</strong>
                <span>
                  {data.multiFactor?.enrolledFactors?.[0] ? (
                    <span>
                      Abilitata sul numero{' '}
                      <strong>
                        <a href={'tel:' + data.multiFactor.enrolledFactors[0].phoneNumber}>{data.multiFactor.enrolledFactors[0].phoneNumber}</a>
                      </strong>{' '}
                      il <DateValue value={data.multiFactor.enrolledFactors[0].enrollmentTime} />
                    </span>
                  ) : (
                    <BoolValue value={false} />
                  )}
                </span>
              </div>

              <UserDataSectionSeparator>Integrazioni esterne</UserDataSectionSeparator>
              <div>
                <strong>Stripe ID:</strong>
                <Copyable text={data.userData.stripeId} />
                <Button href={data.userData.stripeLink} target={'_blank'} className={'ml-auto'} size={'sm'}>
                  Stripe
                  <FontAwesomeIcon icon={faExternalLinkAlt} size={'sm'} className={'ml-2'} />
                </Button>
              </div>
              <div>
                <strong>HubSpot ID:</strong>
                {!!data.userData.hubspotId ? <Copyable text={data.userData.hubspotId} /> : <Badge variant={'secondary'}>N/A</Badge>}
                <Button
                  disabled={!data.userData.hubspotId}
                  href={`https://app.hubspot.com/contacts/19959072/contact/${data.userData.hubspotId}`}
                  target={'_blank'}
                  className={'ml-auto'}
                  size={'sm'}
                >
                  HubSpot
                  <FontAwesomeIcon icon={faExternalLinkAlt} size={'sm'} className={'ml-2'} />
                </Button>
              </div>

              <UserDataSectionSeparator>Abbonamenti e visioni</UserDataSectionSeparator>
              <details>
                <summary>
                  <strong>
                    Abbonamenti
                    <Badge variant={'light'} className={'ml-1'}>
                      {data.userData.subscriptions?.length || 0}
                    </Badge>
                    {!!data.userData.subscriptions?.some?.((sub) => sub.status == 'active') && (
                      <Badge pill variant={'success'} className={'ml-2'}>
                        <FontAwesomeIcon icon={faCheckCircle} /> Abbonamento attivo
                      </Badge>
                    )}
                  </strong>
                </summary>
                {!data.userData.subscriptions?.length && 'Nessun abbonamento.'}
                {!!data.userData.subscriptions?.length &&
                  data.userData.subscriptions.map((sub) => (
                    <div key={sub.id} className={'border border-dark px-2 py-1'}>
                      <div>
                        <Copyable text={sub.id} />
                        <Badge as={'a'} className={'ml-2'} variant={'primary'} href={sub.stripeLink} target={'_blank'}>
                          Gestisci <FontAwesomeIcon icon={faExternalLinkAlt} size={'xs'} />
                        </Badge>
                      </div>
                      <div>
                        Status: <Badge variant={sub.status == 'active' ? 'success' : 'warning'}>{sub.status.toUpperCase()}</Badge>
                      </div>
                      <div>Data creazione: {formatSecondsTimestamp(sub.created._seconds, 'dd/MM/y HH:mm')}</div>
                      {sub.canceled_at && <div>Data cancellazione: {formatSecondsTimestamp(sub.canceled_at._seconds, 'dd/MM/y HH:mm')}</div>}
                      <div>
                        Periodo di fatturazione attuale: {formatSecondsTimestamp(sub.current_period_start._seconds, 'dd/MM/y')} -{' '}
                        {formatSecondsTimestamp(sub.current_period_end._seconds, 'dd/MM/y')}
                      </div>
                      <div>
                        Prodotto: {sub.product.name}
                        <small className={'ml-1'}>
                          (<Copyable text={sub.product.id} />)
                        </small>
                      </div>
                      <div>
                        Prezzo: {sub.price.unit_amount / 100} {sub.price.currency.toUpperCase()} / {sub.price.interval}
                        <small className={'ml-1'}>
                          (<Copyable text={sub.price.id} />)
                        </small>
                      </div>
                      <div>Dispositivi massimi: {sub.price.metadata?.DEVICES || 2}</div>
                    </div>
                  ))}
              </details>
              <div className={'flex-column flex-xl-row align-items-start align-items-xl-center'}>
                <strong>Tempo di visione:</strong>
                <div className={'d-flex flex-wrap align-items-center'}>
                  <div className={'mr-2'}>
                    {data.userData.views || 0}/
                    {(data.userData.customTrialSeconds ?? staticData.DEFAULT_TRIAL_SECONDS ?? 0) + (data.userData.trialExtension || 0)}
                  </div>

                  {(() => {
                    if (!!data.userData.isNewTrial) return null;
                    const base = data.userData.customTrialSeconds ?? staticData.DEFAULT_TRIAL_SECONDS ?? 0;
                    const extension = data.userData.trialExtension ?? 0;
                    const views = data.userData.views || 0;
                    const remaining = base + extension - views;
                    if (remaining > 0) {
                      return (
                        <Badge variant={'info'} className={'mr-2'}>
                          Rimanente: {formatSecondsDuration(remaining)}
                        </Badge>
                      );
                    }
                    return (
                      <Badge variant={'danger'} className={'mr-2'}>
                        Prova terminata
                      </Badge>
                    );
                  })()}

                  {!!data.userData.customTrialSeconds && (
                    <Badge variant={'secondary'} className={'mr-2'}>
                      Custom: {data.userData.customTrialSeconds}
                    </Badge>
                  )}
                  {!!data.userData.trialExtension && (
                    <Badge variant={'secondary'} className={'mr-2'}>
                      Estensione: {data.userData.trialExtension}
                    </Badge>
                  )}
                  {!!data.userData.trialExtensionCount && <Badge variant={'secondary'}>Numero estensioni: {data.userData.trialExtensionCount}</Badge>}
                </div>
                <div className={'ml-auto mt-3 mt-xl-0'}>
                  <Button size={'sm'} onClick={() => setCustomTrialModalOpen(true)} disabled={!!data.userData.isNewTrial}>
                    Durata personalizzata
                  </Button>
                  <Button size={'sm'} className={'ml-2'} onClick={() => setTrialExtensionModalOpen(true)} disabled={!!data.userData.isNewTrial}>
                    Estendi trial
                  </Button>
                </div>
              </div>
              <div>
                <strong>Nuova trial:</strong>
                <BoolValue value={!!data.userData.isNewTrial} />
                <WithTooltip
                  text={'Un utente senza abbonamento nella nuova trial potrà vedere solo determinati video, scelti a monte.'}
                  big={false}
                  placement={'right'}
                >
                  <small className={'text-muted ml-1'} style={{ cursor: 'help', textDecoration: 'underline dotted' }}>
                    Cosa significa?
                  </small>
                </WithTooltip>
                <Button size={'sm'} className={'ml-auto'} onClick={() => triggerUpdate({ isNewTrial: !data.userData.isNewTrial })}>
                  Cambia
                </Button>
              </div>
              <div>
                <strong>Video acceduti:</strong>
                <span>{data.userData.videos?.length || 0}</span>
              </div>
              {!data.userData.isNewTrial && (
                <div>
                  <strong>Video visualizzabili durante la prova:</strong>
                  <span>{data.userData.customTrialVideos ?? staticData.DEFAULT_TRIAL_VIDEOS ?? 0}</span>
                  <Button size={'sm'} className={'ml-auto'} onClick={() => setCustomVideoModalOpen(true)}>
                    Personalizza
                  </Button>
                </div>
              )}

              <UserDataSectionSeparator>Dispositivi</UserDataSectionSeparator>
              <details>
                <summary>
                  <strong>
                    Dispositivi registrati
                    <Badge variant={'light'} className={'ml-1'}>
                      {data.userData.devices?.length || 0}
                    </Badge>
                  </strong>
                </summary>
                <div className={'my-3'}>
                  {!data.userData.devices?.length && 'Nessun dispositivo registrato.'}
                  {!!data.userData.devices?.length &&
                    data.userData.devices.map((device) => (
                      <div key={device.id} className={'border border-dark px-2 py-1 d-flex justify-content-between align-items-center flex-column flex-md-row'}>
                        <div>
                          {device.browser} {device.browserVersion} - {device.os} {device.osVersion}
                        </div>
                        <div>
                          {!!device.lockedUntil && (
                            <small className={'mr-2'}>Bloccato fino al: {formatSecondsTimestamp(device.lockedUntil._seconds, 'dd/MM/y')}</small>
                          )}
                          <small>Ultimo utilizzo: {formatSecondsTimestamp(device.ts._seconds, 'dd/MM/y')}</small>
                          <Button
                            size={'sm'}
                            variant={'outline-danger'}
                            className={'ml-2'}
                            disabled={loading}
                            onClick={() => triggerUpdate({ deviceRemove: device.id })}
                          >
                            Rimuovi
                          </Button>
                        </div>
                      </div>
                    ))}
                </div>
              </details>
              <div>
                <strong>Dispositivi massimi registrabili:</strong>
                <span>{data.userData.customDevices ?? 2}</span>
                <Button size={'sm'} className={'ml-auto'} onClick={() => setCustomDevicesModalOpen(true)}>
                  Personalizza
                </Button>
              </div>

              <UserDataSectionSeparator>Altri dati</UserDataSectionSeparator>
              <details>
                <summary>
                  <strong>Custom claims</strong>
                </summary>
                <pre>{JSON.stringify(data.customClaims, null, 2)}</pre>
              </details>
              <details>
                <summary>
                  <strong>
                    FCM tokens
                    <Badge variant={'light'} className={'ml-1'}>
                      {data.userData.fcm?.length || 0}
                    </Badge>
                  </strong>
                </summary>
                <span>{!data.userData.fcm?.length && 'Nessun token FCM'}</span>
                {data.userData.fcm?.map((fcm, i) => (
                  <div key={i.toString()} className={'d-flex flex-column'}>
                    <small>
                      <Copyable text={fcm.id} />
                    </small>
                    <small>
                      <span>Creato il {formatSecondsTimestamp(fcm.created._seconds, 'dd/MM/y HH:mm')}</span>
                      <Button size={'sm'} className={'ml-2'} variant={'outline-danger'} onClick={() => triggerUpdate({ fcmRemove: fcm.id })} disabled={loading}>
                        Rimuovi
                      </Button>
                    </small>
                  </div>
                ))}
              </details>
              <details>
                <summary>
                  <strong>Profilo</strong>
                </summary>
                <pre>{JSON.stringify(data.userData?.profile?.formData, null, 2)}</pre>
              </details>
              <details>
                <summary>
                  <strong>Privacy</strong>
                </summary>
                <pre>{JSON.stringify(data.userData?.profile?.privacy, null, 2)}</pre>
              </details>
            </div>
            <Alert variant={'danger'} className={'mb-0 rounded-0'}>
              <details>
                <summary>
                  <strong>Operazioni pericolose</strong>
                </summary>
                <div className={'ml-2 pl-2 border-left border-danger'}>
                  <details className={'mt-3'}>
                    <summary>
                      <strong>Modifica ruolo di amministratore</strong>
                    </summary>
                    <div className={'ml-2 pl-2 border-left border-danger'}>
                      <div>Rendere un utente amministratore produrrà i seguenti effetti:</div>
                      <div>&bull; L'utente avrà accesso a tutte le funzioni di amministrazione;</div>
                      <div>Questa operazione è revocabile e gli effetti prodotti saranno opposti.</div>
                      <Button
                        variant={'danger'}
                        className={'ml-auto'}
                        size={'sm'}
                        disabled={loading}
                        onClick={() => triggerUpdate({ admin: !data.userData.admin })}
                      >
                        {data.userData.admin ? 'Rimuovi ruolo di' : 'Rendi'} amministratore
                      </Button>
                    </div>
                  </details>
                  <div className={'mt-3'}>
                    <strong>
                      Le operazioni che seguono solo altamente pericolose e non sarà possibile annullarne gli effetti. Procedere con molta cautela!
                    </strong>
                  </div>
                  <Form.Check
                    type={'checkbox'}
                    label={'Ho compreso la pericolosità di effettuare queste operazioni.'}
                    id={'dangerConfirmation'}
                    checked={dangerZoneCheck}
                    onChange={(e) => setDangerZoneCheck(e.target.checked)}
                  />
                  {!dangerZoneCheck && <small className={'font-italic'}>Seleziona la casella di controllo per abilitare le funzionalità</small>}
                  {dangerZoneCheck && (
                    <>
                      <details className={'mt-3'}>
                        <summary>
                          <strong>Reset video acceduti</strong>
                        </summary>
                        <div className={'ml-2 pl-2 border-left border-danger'}>
                          <div>Resettare i video acceduti produrrà i seguenti effetti:</div>
                          <div>
                            &bull; L'utente non avrà più nessun video in "Continua a guardare";
                            <br />
                            &bull; Le statistiche video verranno rielaborate senza considerare i dati di questo utente;
                            <br />
                            &bull; Se l'utente è nel periodo di prova, potrà nuovamente sbloccare video;
                          </div>
                          <Button
                            variant={'danger'}
                            size={'sm'}
                            disabled={!data.userData.videos?.length || loading}
                            onClick={() => triggerUpdate({ resetVideos: true })}
                          >
                            <strong>Resetta</strong> <small>(ultima conferma)</small>
                          </Button>
                        </div>
                      </details>

                      <details className={'mt-3'}>
                        <summary>
                          <strong>Eliminazione utente</strong>
                        </summary>
                        <div className={'ml-2 pl-2 border-left border-danger'}>
                          <div>Eliminare l'utente produrrà i seguenti effetti:</div>
                          <div>
                            &bull; L'utente non potrà più effettuare l'accesso;
                            <br />
                            &bull; L'utente dovrà registrarsi nuovamente per poter effettuare l'accesso;
                            <br />
                            &bull; Tutti i dati dell'utente verranno eliminati e non potranno essere ricollegati ad un nuovo profilo;
                          </div>
                          <Button size={'sm'} variant={'danger'} disabled={loading} onClick={() => setAccountDeleteModalOpen(true)}>
                            <strong>Elimina utente</strong> <small>(seguirà un popup di conferma)</small>
                          </Button>
                        </div>
                      </details>
                    </>
                  )}
                </div>
              </details>
            </Alert>
          </>
        )}
      </div>

      {!data.error && (
        <Modal show={editNameModalOpen} onHide={() => setEditNameModalOpen(false)} backdrop={'static'} keyboard={false}>
          <Modal.Header>
            <Modal.Title>Modifica nome</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Nome</Form.Label>
              <Form.Control placeholder={'Nome'} disabled={loading} value={newName} onChange={(e) => setNewName(e.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Cognome</Form.Label>
              <Form.Control placeholder={'Cognome'} disabled={loading} value={newSurname} onChange={(e) => setNewSurname(e.target.value)} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={'secondary'} disabled={loading} onClick={() => setEditNameModalOpen(false)}>
              Annulla
            </Button>
            <Button
              variant={'primary'}
              disabled={loading || !newName || !newSurname}
              onClick={() => {
                triggerUpdate({ name: { newName, newSurname } }).then(() => setEditNameModalOpen(false));
              }}
            >
              Conferma
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {!data.error && (
        <Modal show={customTrialModalOpen} onHide={() => setCustomTrialModalOpen(false)} backdrop={'static'} keyboard={false}>
          <Modal.Header>
            <Modal.Title>Durata trial personalizzata</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Inserire un tempo (in secondi) per impostare un valore di durata del periodo di prova per questo utente. Questo valore è pensato per essere
              impostato una tantum, per estenderlo in corso d'opera utilizzare la funzionalità di estensione.
            </p>
            {data.userData?.customTrialSeconds != null && <Alert variant={'warning'}>Per questo utente è già impostata una durata personalizzata</Alert>}
            <Form.Group className={'mb-0'}>
              <Form.Control disabled={loading} value={customTime} type={'number'} onChange={(e) => setCustomTime(+e.target.value)} />
              <Form.Text className={'text-muted d-flex'}>
                1 ora = 3600 secondi
                <a className={'ml-auto'} href={'https://www.tools4noobs.com/online_tools/hh_mm_ss_to_seconds/'} target={'_blank'}>
                  Convertitore temporale
                </a>
              </Form.Text>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={'secondary'} disabled={loading} onClick={() => setCustomTrialModalOpen(false)}>
              Annulla
            </Button>
            <Button
              variant={'primary'}
              disabled={loading || !customTime}
              onClick={() => {
                triggerUpdate({ customTrial: customTime }).then(() => setCustomTrialModalOpen(false));
              }}
            >
              Conferma
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {!data.error && (
        <Modal show={trialExtensionModalOpen} onHide={() => setTrialExtensionModalOpen(false)} backdrop={'static'} keyboard={false}>
          <Modal.Header>
            <Modal.Title>Estensione trial</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Inserire un tempo (in secondi) per estendere la durata della trial per questo utente. &Egrave; possibile utilizzare questa funzionalità tutte le
              volte che si desidera, ma verrà tenuto traccia di quante volte la trial è stata estesa.
            </p>
            <p>
              Se è già presente un'estensione, il tempo impostato verrà <strong>aggiunto</strong> al tempo esistente.
            </p>
            <Form.Group className={'mb-0'}>
              <Form.Control disabled={loading} value={trialExtension} type={'number'} onChange={(e) => setTrialExtension(+e.target.value)} />
              <Form.Text className={'text-muted d-flex'}>
                1 ora = 3600 secondi
                <a className={'ml-auto'} href={'https://www.tools4noobs.com/online_tools/hh_mm_ss_to_seconds/'} target={'_blank'}>
                  Convertitore temporale
                </a>
              </Form.Text>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={'secondary'} disabled={loading} onClick={() => setTrialExtensionModalOpen(false)}>
              Annulla
            </Button>
            <Button
              variant={'primary'}
              disabled={loading || !trialExtension}
              onClick={() => {
                triggerUpdate({ extendTrial: trialExtension }).then(() => setTrialExtensionModalOpen(false));
              }}
            >
              Conferma
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {!data.error && (
        <Modal show={customVideoModalOpen} onHide={() => setCustomVideoModalOpen(false)} backdrop={'static'} keyboard={false}>
          <Modal.Header>
            <Modal.Title>Personalizzazione video accessibili</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Inserire un numero che corrisponde alla quantità di video a cui l'utente potrà accedere durante il periodo di prova.
              <br />
              Questa impostazione è utilizzabile sulla base di alcune partnership, es. Dentista Moderno.
            </p>
            <p>
              Di default, un utente può accedere a <strong>{staticData.DEFAULT_TRIAL_VIDEOS}</strong> video.
            </p>
            <Form.Group className={'mb-0'}>
              <Form.Control disabled={loading} value={customVideos} type={'number'} onChange={(e) => setCustomVideos(+e.target.value)} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={'secondary'} disabled={loading} onClick={() => setCustomVideoModalOpen(false)}>
              Annulla
            </Button>
            <Button
              variant={'primary'}
              disabled={loading || !customVideos}
              onClick={() => {
                triggerUpdate({ customVideos }).then(() => setCustomVideoModalOpen(false));
              }}
            >
              Conferma
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {!data.error && (
        <Modal show={customDevicesModalOpen} onHide={() => setCustomDevicesModalOpen(false)} backdrop={'static'} keyboard={false}>
          <Modal.Header>
            <Modal.Title>Personalizzazione numero dispositivi</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Inserire un numero che corrisponde alla quantità di dispositivi massimi che l'utente pò registrare sul proprio account.</p>
            <Form.Group className={'mb-0'}>
              <Form.Control disabled={loading} value={customDevices} type={'number'} onChange={(e) => setCustomDevices(+e.target.value)} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={'secondary'} disabled={loading} onClick={() => setCustomDevicesModalOpen(false)}>
              Annulla
            </Button>
            <Button
              variant={'primary'}
              disabled={loading || !customDevices}
              onClick={() => {
                triggerUpdate({ customDevices }).then(() => setCustomDevicesModalOpen(false));
              }}
            >
              Conferma
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <Modal show={loading} backdrop={'static'} keyboard={false} centered>
        <Modal.Body>
          <FullscreenLoader fullscreen={false} />
        </Modal.Body>
      </Modal>

      {!data.error && (
        <Modal show={accountDeleteModalOpen} onHide={() => setAccountDeleteModalOpen(false)}>
          <Modal.Header>
            <Modal.Title>Conferma eliminazione utente</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={'text-center mb-2 py-1 rounded'} style={{ backgroundColor: 'gainsboro' }}>
              {data.email}
            </div>
            Confermi la volontà di eliminare questo utente?
            <br />
            <strong>Ricorda che questa operazione NON PU&Ograve; essere annullata!</strong>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={'secondary'} disabled={loading} onClick={() => setAccountDeleteModalOpen(false)}>
              Annulla
            </Button>
            <Button
              variant={'danger'}
              disabled={loading}
              onClick={() => {
                triggerUpdate({ accountDelete: true }).then(() => setAccountDeleteModalOpen(false));
              }}
            >
              Conferma
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {!data.error && (
        <Modal size={'lg'} show={impersonateModalOpen} onHide={() => setImpersonateModalOpen(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Impersona utente</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!!data.userData.admin && <Alert variant={'danger mb-0'}>Non è possibile impersonare un utente amministratore.</Alert>}
            {!data.userData.admin && (
              <Alert variant={'warning mb-0'}>
                <strong>Attenzione!</strong> Questa funzionalità serve solo per effettuare verifiche tecniche. Ogni operazione effettuata mentre impersoni un
                utente sembrerà fatta dall'utente stesso e produrrà tutti gli effetti collaterali (registrazione dispositivi, video in continua a guardare,
                ecc...){' '}
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant={'secondary'} onClick={() => setImpersonateModalOpen(false)}>
              Chiudi
            </Button>
            {!data.userData.admin && (
              <Button
                variant={'warning'}
                onClick={() => {
                  window.open(impersonationLink, '_blank', 'height=0,width=0,scrollbars=yes');
                }}
              >
                <FontAwesomeIcon icon={faUserSecret} className={'mr-2'} />
                Impersona
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export { UserDataComponent as UserData };
