import './NextVideo.scss';
import { Button } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAnimationFrame } from '../../../hooks/useAnimationFrame';
import { useTranslation } from 'react-i18next';

const NextVideoComponent = ({ next, onCountdownComplete = () => {} }) => {
  const [backgroundWidth, setBackgroundWidth] = useState(0);
  const [ignore, setIgnore] = useState(false);
  const MAX_TIME = 5000;
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    animation.start();
  }, []);

  const animationCallback = useCallback((elapsed) => {
    const percentage = (elapsed / MAX_TIME) * 100;
    setBackgroundWidth(percentage);
  }, []);
  const endConditionCallback = useCallback((elapsed) => elapsed >= MAX_TIME, []);
  const onCompleteCallback = useCallback(onCountdownComplete, []);

  const animation = useAnimationFrame(animationCallback, endConditionCallback, onCompleteCallback);

  useEffect(() => {
    if (ignore) animation.stop();
  }, [ignore]);

  if (ignore) return <></>;

  return (
    <div className={'next-video-container d-flex'}>
      <img src={next.sm_thumbnail || next.thumbnail} width={200} alt={next.title} />
      <div className={'d-flex flex-column mx-2 flex-grow-1'}>
        <div>{t('nextVideo.labels.prossimoVideo')} {next.title}</div>
        <div className={'mt-auto d-flex justify-content-end'}>
          <Button variant={'dark'} size={'sm'} onClick={() => setIgnore(true)} className={'mr-2'}>
          {t('labels.chiudi')}
          </Button>
          <Button
            variant={'primary'}
            size={'sm'}
            className={'next-button'}
            style={{ background: `linear-gradient(to right, #3393d0 ${backgroundWidth}%, white ${backgroundWidth}%)` }}
            onClick={onCountdownComplete}
          >
            {t('labels.riproduci')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { NextVideoComponent as NextVideo };
