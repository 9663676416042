import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Title } from '../../components/Title/Title';
import { Topbar } from '../../components/Topbar/Topbar';
import { Button, Card, Container, Modal } from 'react-bootstrap';
import { Footer } from '../../components/Footer/Footer';
import '../Acty/Acty.scss';
import { useState, useEffect } from 'react';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { intlFormat } from 'date-fns';
import { useCurrentLanguage } from '../../../hooks/useCurrentLanguage';

const ActyAppointmentListComponent = () => {
  const { currentLanguage } = useCurrentLanguage();
  const [historyLogsOpen, setHistoryLogsOpen] = useState(false);
  const { getActyAppointments } = useFirebase();
  const history = useHistory();
  const { t } = useTranslation();
  const [appointments, setAppointments] = useState([]);
  const [appointmentsEnded, setAppointmentsEnded] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentAppointment, setCurrentAppointment] = useState(null);

  useEffect(() => {
    setLoading(true);
    getActyAppointments()
      .then((data: any) => {
        setAppointments(data.appointments);
        setAppointmentsEnded(data.appointmentsEnded);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  const formatTimestamp = (timestamp) => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return intlFormat(
      new Date(timestamp._seconds * 1000),
      {
        timeZone: tz,
        hour: 'numeric',
        minute: 'numeric',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      },
      { locale: currentLanguage }
    );
  };

  const getStatusDescription = (appointment: any) => {
    const code = appointment.status.statusCode;
    switch (code) {
      case 'NOT_READY':
        return t('acty.labels.statusNotReadyDescription', { date: formatTimestamp(appointment.timestamp) });
      case 'USER_IN':
        return t('acty.labels.statusUserInDescription', { tutorName: appointment.tutor.name });
      case 'USER_OUT':
        return t('acty.labels.statusUserOutDescription', { tutorName: appointment.tutor.name });
      case 'STARTED':
        return t('acty.labels.statusStartedDescription', { tutorName: appointment.tutor.name });
      case 'ENDED':
      case 'EXPIRED':
        if (appointment.status.status) {
          return appointment.status.status.toUpperCase() == 'OK'
            ? t('acty.labels.statusEndedOkDescription', { tutorName: appointment.tutor.name })
            : t('acty.labels.statusEndedKoDescription', { tutorName: appointment.tutor.name });
        }
        return t('acty.labels.statusEndedKoDescription', { tutorName: appointment.tutor.name });
      case 'DELETED':
        return t('acty.labels.statusDeletedDescription', { tutorName: appointment.tutor.name, date: formatTimestamp(appointment.status.deletedAt) });
      default:
        return '';
    }
  };

  const getButtonComponent = (appointment: any) => {
    let color = 'primary';
    let text = t('acty.labels.statusNew');
    let onClick = (_) => {
      history.push(`/acty/appointments/${appointment.id}`);
    };
    let isDisabled = false;

    const code = appointment.status.statusCode;
    switch (code) {
      case 'NOT_READY':
        color = 'secondary';
        text = t('acty.labels.statusNew');
        onClick = null;
        isDisabled = true;
        break;
      case 'USER_IN':
        color = 'success';
        text = t('acty.labels.statusUserIn');
        break;
      case 'USER_OUT':
        color = 'warning';
        text = t('acty.labels.statusUserOut');
        break;
      case 'STARTED':
        color = 'success';
        text = t('acty.labels.statusStarted');
        break;
      default:
        break;
    }

    return (
      <Button variant={color} onClick={onClick} className={'ml-auto'} disabled={isDisabled}>
        {text}
      </Button>
    );
  };

  return (
    <>
      <Title>{t('acty.labels.titlePrenotazioni')}</Title>

      <div style={{ marginBottom: '72px' }}>
        <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
          <Topbar />
        </div>
      </div>

      <Container className={'my-5'}>
        <Container
          className={'one-to-one-box justify-content-center align-items-center pe-none'}
          style={{ backgroundImage: `url(../${process.env.PUBLIC_URL}one-to-one/categorie.jpg)`, pointerEvents: 'none' }}
          onClick={() => {
            history.push(`/acty/appointments`);
          }}
        >
          <h2 className={'mb-0 crop-text-2'}>{t('acty.labels.elencoPrenotazioni')}</h2>
          <div className={'course-image-wrapper'}></div>
        </Container>
        {loading && <FullscreenLoader fullscreen={false} />}
        {!loading && appointments.length === 0 && <div className={'text-center text-secondary'}>{t('acty.labels.noPrenotazioniMsg')}</div>}
        {!loading &&
          appointments.length > 0 &&
          appointments.map((appointment) => (
            <Card bg={'dark p-3 mb-3'}>
              <div className={'text-secondary'}>{formatTimestamp(appointment.timestamp)}</div>
              <div className={'title-docente-card p-0 bg-primary'}>{appointment.name}</div>
              <div className={'text-secondary'}>
                {t('acty.labels.docente')}: {appointment.tutor.name}
              </div>
              {/* <div className={'text-secondary'}>Branca D'interesse: {appointment.tutor.categories.map((c) => c.label).join(', ')}</div> */}
              {appointment.tutor.category && (
                <div className={'text-secondary'}>
                  {t('acty.labels.brancaInteresse')}: {appointment.tutor.category}
                </div>
              )}
              <div className={'text-secondary'}>
                <b>{getStatusDescription(appointment)}</b>
              </div>
              <div className={'d-flex'}>{getButtonComponent(appointment)}</div>
            </Card>
          ))}

        {!loading && appointmentsEnded.length > 0 && (
          <>
            <h3 className={''}>{t('acty.labels.storico')}</h3>
            {appointmentsEnded.map((appointment) => (
              <Card bg={'dark p-3 mb-3'}>
                <div className={'text-secondary'}>{formatTimestamp(appointment.timestamp)}</div>
                <div className={`title-docente-card p-0 ${appointment.status.statusCode == 'DELETED' ? 'bg-danger' : 'bg-primary'}`}>{appointment.name}</div>
                <div className={'text-secondary'}>
                  {t('acty.labels.docente')}: {appointment.tutor.name}
                </div>
                {/* <div className={'text-secondary'}>Branca D'interesse: {appointment.tutor.categories.map((c) => c.label).join(', ')}</div> */}
                {appointment.tutor.category && (
                  <div className={'text-secondary'}>
                    {t('acty.labels.brancaInteresse')}: {appointment.tutor.category}
                  </div>
                )}
                <div className={`${appointment.status.statusCode === 'DELETED' ? 'text-danger' : 'text-secondary'}`}>
                  <b>{getStatusDescription(appointment)}</b>
                </div>
                {appointment.status.statusCode === 'ENDED' && (
                  <>
                    {appointment.status.date && (
                      <div className={'text-secondary'}>
                        <b>{t('acty.labels.terminato')}:</b> {formatTimestamp(appointment.status.date)}
                      </div>
                    )}
                    {appointment.status.duration >= 0 && (
                      <div className={'text-secondary'}>
                        <b>{t('acty.labels.durata')}:</b>{' '}
                        {`${appointment.status.duration} ${appointment.status.duration == 1 ? t('acty.labels.minuto') : t('acty.labels.minuti')}`}
                      </div>
                    )}
                  </>
                )}
              </Card>
            ))}
          </>
        )}
      </Container>
      <Footer />
      <Modal centered show={historyLogsOpen} onHide={() => setHistoryLogsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('acty.labels.informazioniModalTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentAppointment && (
            <>
              <div className={'text-secondary'}>
                {t('acty.labels.terminato')}: {formatTimestamp(currentAppointment.status.date)}
              </div>
              <div className={'text-secondary'}>
                {t('acty.labels.durata')}: {currentAppointment.status.duration}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ActyAppointmentListComponent;
