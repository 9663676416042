import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faAt, faInfoCircle, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties, FormEvent, useEffect, useReducer, useRef, useState } from 'react';
import { Alert, Badge, Button, Col, Container, Form, Modal } from 'react-bootstrap';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { Redirect, useHistory } from 'react-router-dom';
import Snowfall from 'react-snowfall';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useCapacitor } from '../../../hooks/useCapacitor';
import { useCurrentLanguage } from '../../../hooks/useCurrentLanguage';
import { useMobileDetect } from '../../../hooks/useMobileDetect';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { Title } from '../../components/Title/Title';
import { Topbar } from '../../components/Topbar/Topbar';
import { clamp, deleteCookie, formReducer, innerHtml, readCookies, setCookie } from '../../utils/utils';
import './AdventCalendar.scss';
import { CalendarCell } from './CalendarCell';
import { CalendarStrip } from './CalendarStrip';
import { ReactComponent as SvgBells } from './svgs/bells.svg';
import giftAnimationData from './svgs/gift.json';
import { ReactComponent as SvgSled } from './svgs/sled.svg';

const AdventCalendarComponent = () => {
  const { t } = useTranslation();
  const { getAdventCalendar, notifyAdventData, userDataFmt } = useFirebase();
  const [cal, setCal] = useState<any>(null);
  const [hiddenStripTooltip, setHiddenStripTooltip] = useState(false);
  const [showLottieGift, setShowLottieGift] = useState(false);
  const [isAso, setIsAso] = useState(false);
  const { isMobile } = useMobileDetect();
  const { isNative } = useCapacitor();
  const history = useHistory();
  const { currentLanguage } = useCurrentLanguage();

  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const [dontShowHelpModalChecked, setDontShowHelpModalChecked] = useState(false);
  const COOKIE_NAME = 'DONT_SHOW_ADVENT_HELP';
  useEffect(() => {
    const cookies = readCookies();
    if (!cookies[COOKIE_NAME]) setHelpModalOpen(true);
    setDontShowHelpModalChecked(!!cookies[COOKIE_NAME]);
  }, []);
  useEffect(() => {
    if (dontShowHelpModalChecked) setCookie(COOKIE_NAME, 'true');
    else deleteCookie(COOKIE_NAME);
  }, [dontShowHelpModalChecked]);
  useEffect(() => {
    setHiddenStripTooltip(helpModalOpen);
  }, [helpModalOpen]);

  const todayDate = (() => {
    if (process.env.NODE_ENV != 'production') {
      // return new Date('2022-12-04'); // inizio dicembre
      // return new Date('2021-12-27'); // dicembre, dopo fine calendario
      // return new Date('2022-01-01'); // gennaio, anno nuovo
      // return new Date('2021-12-16'); // dicembre, dentro calendario
      // return new Date(2021, 11, new Date().getDate()); // dicembre, ma data di oggi
    }
    return new Date(); // oggi
  })();

  const currentMonth = todayDate.getMonth() + 1;
  const currentYear = todayDate.getFullYear();
  const today = (() => {
    if (currentYear > 2022) return 26;
    if (currentMonth < 12) {
      const lastDayDate = new Date(currentYear, currentMonth, 0);
      const daysInCurrentMonth = lastDayDate.getDate();
      return -(daysInCurrentMonth - todayDate.getDate());
    }
    return todayDate.getDate();
  })();

  const [formLoading, setFormLoading] = useState(false);
  const [formData, setFormData] = useReducer(formReducer, {
    nome: '',
    cognome: '',
    indirizzo: '',
    indirizzo2: '',
    cap: '',
    citta: '',
    provincia: '',
    email: '',
    telefono: '',
    checkProfilazione: false,
    checkComunicazioniDaTootor: false,
    checkMarketingSocietaTerze: false,
  });
  const handleChange = (event) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  };
  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);
    handleNotify(formData).catch(console.error);
  };
  const handleNotify = async (formData) => {
    setFormLoading(true);
    notifyAdventData(openedCell.id, cal.calendar.id, formData)
      .then(() => {
        openedCell.submitted = !!formData;
        openedCell.formData = formData;
      })
      .catch(console.error)
      .finally(() => {
        setFormLoading(false);
      });
  };

  useEffect(() => {
    getAdventCalendar().then(setCal);
    const gridArea = `'${'a1 '.repeat(9)}${'a2 '.repeat(6)}${'a3 '.repeat(6)}${'a4 '.repeat(6)}${'a5 '.repeat(9).trim()}'
'${'a1 '.repeat(9)}${'a2 '.repeat(6)}${'a3 '.repeat(6)}${'a4 '.repeat(6)}${'a5 '.repeat(9).trim()}'
'${'a1 '.repeat(9)}${'a2 '.repeat(6)}${'a3 '.repeat(6)}${'a4 '.repeat(6)}${'a5 '.repeat(9).trim()}'
'${'a1 '.repeat(9)}${'a2 '.repeat(6)}${'a3 '.repeat(6)}${'a4 '.repeat(6)}${'a5 '.repeat(9).trim()}'
'${'a1 '.repeat(9)}${'a2 '.repeat(6)}${'a3 '.repeat(6)}${'a4 '.repeat(6)}${'a5 '.repeat(9).trim()}'
'${'a1 '.repeat(9)}${'a2 '.repeat(6)}${'a3 '.repeat(6)}${'a4 '.repeat(6)}${'a5 '.repeat(9).trim()}'
'${'a1 '.repeat(9)}${'a8 '.repeat(6)}${'a3 '.repeat(6)}${'a4 '.repeat(6)}${'a11 '.repeat(4)}${'a12 '.repeat(5).trim()}'
'${'a1 '.repeat(9)}${'a8 '.repeat(6)}${'a9 '.repeat(6)}${'a4 '.repeat(6)}${'a11 '.repeat(4)}${'a12 '.repeat(5).trim()}'
'${'a1 '.repeat(9)}${'a8 '.repeat(6)}${'a9 '.repeat(6)}${'a4 '.repeat(6)}${'a11 '.repeat(4)}${'a12 '.repeat(5).trim()}'
'${'a6 '.repeat(4)}${'a7 '.repeat(5)}${'a8 '.repeat(6)}${'a9 '.repeat(6)}${'a4 '.repeat(6)}${'a11 '.repeat(4)}${'a12 '.repeat(5).trim()}'
'${'a6 '.repeat(4)}${'a7 '.repeat(5)}${'a8 '.repeat(6)}${'a9 '.repeat(6)}${'a10 '.repeat(7)}${'a16 '.repeat(3)}${'a12 '.repeat(5).trim()}'
'${'a6 '.repeat(4)}${'a7 '.repeat(5)}${'a8 '.repeat(6)}${'a9 '.repeat(6)}${'a10 '.repeat(7)}${'a16 '.repeat(3)}${'a12 '.repeat(5).trim()}'
'${'a6 '.repeat(4)}${'a13 '.repeat(11)}${'a9 '.repeat(6)}${'a10 '.repeat(7)}${'a16 '.repeat(3)}${'a17 '.repeat(5).trim()}'
'${'a6 '.repeat(4)}${'a13 '.repeat(11)}${'a9 '.repeat(6)}${'a10 '.repeat(7)}${'a16 '.repeat(3)}${'a17 '.repeat(5).trim()}'
'${'a6 '.repeat(4)}${'a13 '.repeat(11)}${'a9 '.repeat(6)}${'a15 '.repeat(7)}${'a16 '.repeat(3)}${'a17 '.repeat(5).trim()}'
'${'a6 '.repeat(4)}${'a13 '.repeat(11)}${'a14 '.repeat(6)}${'a15 '.repeat(7)}${'a16 '.repeat(3)}${'a17 '.repeat(5).trim()}'
'${'a6 '.repeat(4)}${'a13 '.repeat(11)}${'a14 '.repeat(6)}${'a15 '.repeat(7)}${'a16 '.repeat(3)}${'a17 '.repeat(5).trim()}'
'${'a6 '.repeat(4)}${'a13 '.repeat(11)}${'a14 '.repeat(6)}${'a15 '.repeat(7)}${'a16 '.repeat(3)}${'a25 '.repeat(5).trim()}'
'${'a18 '.repeat(4)}${'a13 '.repeat(11)}${'a14 '.repeat(6)}${'a15 '.repeat(7)}${'a16 '.repeat(3)}${'a25 '.repeat(5).trim()}'
'${'a18 '.repeat(4)}${'a19 '.repeat(12)}${'a23 '.repeat(5)}${'a15 '.repeat(7)}${'a16 '.repeat(3)}${'a25 '.repeat(5).trim()}'
'${'a18 '.repeat(4)}${'a19 '.repeat(12)}${'a23 '.repeat(5)}${'a15 '.repeat(7)}${'a16 '.repeat(3)}${'a25 '.repeat(5).trim()}'
'${'a18 '.repeat(4)}${'a19 '.repeat(12)}${'a23 '.repeat(5)}${'a24 '.repeat(10)}${'a25 '.repeat(5).trim()}'
'${'a20 '.repeat(8)}${'a21 '.repeat(4)}${'a22 '.repeat(4)}${'a23 '.repeat(5)}${'a24 '.repeat(10)}${'a25 '.repeat(5).trim()}'
'${'a20 '.repeat(8)}${'a21 '.repeat(4)}${'a22 '.repeat(4)}${'a23 '.repeat(5)}${'a24 '.repeat(10)}${'a25 '.repeat(5).trim()}'
'${'a20 '.repeat(8)}${'a21 '.repeat(4)}${'a22 '.repeat(4)}${'a23 '.repeat(5)}${'a24 '.repeat(10)}${'a25 '.repeat(5).trim()}'
'${'a20 '.repeat(8)}${'a21 '.repeat(4)}${'a22 '.repeat(4)}${'a23 '.repeat(5)}${'a24 '.repeat(10)}${'a25 '.repeat(5).trim()}'
'${'a20 '.repeat(8)}${'a21 '.repeat(4)}${'a22 '.repeat(4)}${'a23 '.repeat(5)}${'a24 '.repeat(10)}${'a25 '.repeat(5).trim()}'`;
    document.documentElement.style.setProperty('--calendar-grid-area', gridArea);
  }, []);

  useEffect(() => {
    if (!userDataFmt) return;
    const myCategory = userDataFmt.profile?.formData?.categoriaProfessionale;
    setIsAso(myCategory == 'aso');
  }, [userDataFmt]);

  useEffect(() => {
    if (isAso) history.replace('/');
  }, [isAso]);

  const [contentModalOpen, setContentModalOpen] = useState(false);
  const [openedCell, setOpenedCell] = useState<any>({});
  const openContent = (cell) => {
    setHiddenStripTooltip(true);
    setOpenedCell(cell);
    setContentModalOpen(true);
  };
  const openContentWithAnimations = (cell) => {
    // setShowLottieGift(true);
    // setTimeout(() => {
    openContent(cell);
    runFireConfetti();
    // }, 1150);
  };
  useEffect(() => {
    if (contentModalOpen) return;
    setTimeout(() => {
      // setShowLottieGift(false);
      if (openedCell.updating && !openedCell.submitted) {
        openedCell.updating = false;
        openedCell.submitted = true;
      }
      setOpenedCell({});
      resetForm();
    }, 150);
  }, [contentModalOpen]);
  const resetForm = (
    value = {
      nome: '',
      cognome: '',
      indirizzo: '',
      indirizzo2: '',
      cap: '',
      citta: '',
      provincia: '',
      email: '',
      telefono: '',
      checkProfilazione: false,
      checkComunicazioniDaTootor: false,
      checkMarketingSocietaTerze: false,
    }
  ) => {
    setFormData({ name: 'RESET', value });
    console.log(formData);
  };

  const [askSubscriptionModalOpen, setAskSubscriptionModalOpen] = useState(false);
  const [askYearlySubscriptionModalOpen, setAskYearlySubscriptionModalOpen] = useState(false);

  const confettiInstance = useRef<any>();
  const canvasStyles: CSSProperties = {
    position: 'fixed',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1600,
  };
  const fireConfetti = () => {
    const makeShot = (particleRatio, opts) => {
      if (!confettiInstance.current) return;
      confettiInstance.current({ ...opts, particleCount: Math.floor(200 * particleRatio) });
    };

    const makeLeftRight = (particleRatio, opts) => {
      const yOrigin = 0.7;
      makeShot(particleRatio, { ...opts, angle: 60, origin: { y: yOrigin, x: 0 } });
      makeShot(particleRatio, { ...opts, angle: 120, origin: { y: yOrigin, x: 1 } });
    };

    [
      [0.25, { spread: 26, startVelocity: 55 }],
      [0.2, { spread: 60 }],
      [0.35, { spread: 100, decay: 0.91, scalar: 0.8 }],
      [0.1, { spread: 120, startVelocity: 25, decay: 0.92, scalar: 1.2 }],
      [0.1, { spread: 120, startVelocity: 45 }],
    ].forEach(([particleRatio, opts]) => makeLeftRight(particleRatio, opts));
  };

  const runFireConfetti = () => setTimeout(() => fireConfetti(), 150);
  if (currentLanguage !== 'it') {
    return <Redirect to={'/'} />;
  }
  return (
    <>
      <Snowfall style={{ zIndex: 2, position: 'fixed' }} color={'ivory'} snowflakeCount={70} />
      <ReactCanvasConfetti refConfetti={(instance) => (confettiInstance.current = instance)} style={canvasStyles} />

      <Title>{t('labels.calendarioAvvento')}</Title>

      <div className={'calendar-page-wrapper'} style={{ zIndex: 3 }}>
        <Topbar />

        {showLottieGift && (
          <div className={'lottie-gift-wrapper'}>
            <Lottie
              options={{
                animationData: giftAnimationData,
                loop: false,
                autoplay: true,
              }}
              width={400}
            />
          </div>
        )}

        <Container className={'mt-4 h-100 d-flex flex-column mb-md-4'} style={{ marginBottom: '4rem' }}>
          <div className={'d-flex align-items-center justify-content-between'}>
            <div className={'display-4 mb-3'} style={{ color: '#a8bbbd', fontFamily: "'Architects Daughter', cursive" }}>
              {t('labels.calendarioAvvento')}
            </div>
            <Button variant={'warning'} className={'ml-2'} onClick={() => setHelpModalOpen(true)}>
              {!isMobile && <FontAwesomeIcon icon={faInfoCircle} className={'mr-2'} />}
              {t('labels.guida')}
            </Button>
          </div>
          {!cal && <FullscreenLoader fullscreen={false} />}
          {!!cal && (
            <>
              <CalendarStrip
                cal={cal}
                today={today}
                showTooltipOn={hiddenStripTooltip ? null : clamp(today, 1, 25)}
                onBallClick={(id) => {
                  try {
                    (document.querySelector(`[data-id="calendar-cell-${id}"]`) as HTMLDivElement).click();
                  } catch {}
                }}
              />
              <div className={'calendar-container mt-4'}>
                {cal.elements.map((cell) => (
                  <CalendarCell
                    cellData={cell}
                    key={cell.id}
                    today={today}
                    currentMonth={currentMonth}
                    calendar={cal.calendar}
                    onUnlock={(content) => {
                      cell.unlocked = true;
                      cell.content = content;
                      openContentWithAnimations(cell);
                    }}
                    onViewContent={() => {
                      // if (process.env.NODE_ENV != 'production') {
                      //   openContentWithAnimations(cell);
                      //   return;
                      // }
                      openContent(cell);
                    }}
                    onProWithoutSubscription={() => {
                      setAskSubscriptionModalOpen(true);
                    }}
                    // onProWithoutYearlySubscription={() => {
                    //   setAskYearlySubscriptionModalOpen(true);
                    // }}
                  />
                ))}
              </div>
            </>
          )}
        </Container>
      </div>

      <div className={'winter-bg-color'} />
      <SvgSled className={'sled-image'} />

      <Modal show={contentModalOpen} onHide={() => setContentModalOpen(false)} centered scrollable>
        <SvgBells className={'bells-svg'} />
        <Modal.Header closeButton>
          <Modal.Title as={'h5'} className={'ml-5'}>
            {openedCell.id} {t('labels.dicembre')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'p-0'}>
          <div {...innerHtml(openedCell.content)} className={'p-3'} />

          {!!openedCell.askData && (
            <>
              <hr className={'my-0'} />
              <div className={'p-3 ' + (openedCell.submitted ? '' : 'reward-form-container')}>
                {!openedCell.submitted && <p>{t('adventCalendar.labels.compialarePerSorpresa')}</p>}
                {!!openedCell.dataAlert && (
                  <Alert variant={'warning'}>
                    <div {...innerHtml(openedCell.dataAlert)} />
                  </Alert>
                )}
                {!openedCell.submitted && (
                  <Form onSubmit={handleFormSubmit}>
                    <Form.Row>
                      <Col>
                        <Form.Group>
                          <Form.Control
                            name={'nome'}
                            disabled={formLoading}
                            value={formData.nome}
                            onChange={handleChange}
                            placeholder={t('adventCalendar.labels.nome')}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Control
                            name={'cognome'}
                            disabled={formLoading}
                            value={formData.cognome}
                            onChange={handleChange}
                            placeholder={t('adventCalendar.labels.cognome')}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row>

                    <Form.Row>
                      <Col>
                        <Form.Group>
                          <Form.Control
                            name={'indirizzo'}
                            disabled={formLoading}
                            value={formData.indirizzo}
                            onChange={handleChange}
                            placeholder={t('adventCalendar.labels.riga1')}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row>

                    <Form.Row>
                      <Col>
                        <Form.Group>
                          <Form.Control
                            name={'indirizzo2'}
                            disabled={formLoading}
                            value={formData.indirizzo2}
                            onChange={handleChange}
                            placeholder={t('adventCalendar.labels.riga2')}
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row>

                    <Form.Row>
                      <Col xs={3}>
                        <Form.Group>
                          <Form.Control
                            name={'cap'}
                            disabled={formLoading}
                            value={formData.cap}
                            onChange={handleChange}
                            placeholder={'CAP*'}
                            type={'number'}
                            min={0}
                            max={99999}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Control
                            name={'citta'}
                            disabled={formLoading}
                            value={formData.citta}
                            onChange={handleChange}
                            placeholder={t('adventCalendar.labels.citta')}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group>
                          <Form.Control
                            name={'provincia'}
                            disabled={formLoading}
                            value={formData.provincia}
                            onChange={handleChange}
                            placeholder={t('adventCalendar.labels.provincia')}
                            minLength={2}
                            maxLength={2}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row>

                    <Form.Row>
                      <Col>
                        <Form.Group>
                          <Form.Control
                            name={'email'}
                            disabled={formLoading}
                            value={formData.email}
                            onChange={handleChange}
                            placeholder={t('adventCalendar.labels.email')}
                            type={'email'}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Control
                            name={'telefono'}
                            disabled={formLoading}
                            value={formData.telefono}
                            onChange={handleChange}
                            placeholder={t('adventCalendar.labels.telefono')}
                            type={'tel'}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row>

                    <Form.Row>
                      <Col>
                        {/* <Form.Check
                          type={'checkbox'}
                          required
                          disabled={formLoading}
                          id={'checkAccettazione'}
                          label={
                            <div className={'small text-muted'} style={{ lineHeight: '1rem' }}>
                              {t('adventCalendar.labels.accettoTermini', { title: process.env.REACT_APP_TITLE })}{' '}
                              {!!openedCell.privacy && (
                                <a href={openedCell.privacy} target={'_blank'}>
                                  (Privacy policy)
                                </a>
                              )}
                            </div>
                          }
                        /> */}
                        <Form.Check
                          type={'checkbox'}
                          disabled={formLoading}
                          name={'checkProfilazione'}
                          onChange={(e) =>
                            setFormData({
                              name: e.target.name,
                              value: e.target.checked,
                            })
                          }
                          checked={formData.checkProfilazione}
                          label={
                            <div className={'small text-muted'} style={{ lineHeight: '1rem' }}>
                              {t('adventCalendar.labels.accettoProfilazione', { title: process.env.REACT_APP_TITLE })}{' '}
                            </div>
                          }
                        />
                        <Form.Check
                          type={'checkbox'}
                          disabled={formLoading}
                          name={'checkComunicazioniDaTootor'}
                          onChange={(e) =>
                            setFormData({
                              name: e.target.name,
                              value: e.target.checked,
                            })
                          }
                          checked={formData.checkComunicazioniDaTootor}
                          label={
                            <div className={'small text-muted'} style={{ lineHeight: '1rem' }}>
                              {t('adventCalendar.labels.accettoComunicazioniDaTootor', { title: process.env.REACT_APP_TITLE })}{' '}
                            </div>
                          }
                        />
                        <Form.Check
                          type={'checkbox'}
                          disabled={formLoading}
                          name={'checkMarketingSocietaTerze'}
                          onChange={(e) =>
                            setFormData({
                              name: e.target.name,
                              value: e.target.checked,
                            })
                          }
                          checked={formData.checkMarketingSocietaTerze}
                          label={
                            <div className={'small text-muted'} style={{ lineHeight: '1rem' }}>
                              {t('adventCalendar.labels.accettoMarketingSocietaTerze', { title: process.env.REACT_APP_TITLE })}{' '}
                            </div>
                          }
                        />
                      </Col>
                    </Form.Row>

                    <div className={'mt-3 d-flex justify-content-end'}>
                      <Button variant={'info'} type={'submit'} disabled={formLoading}>
                        {formLoading ? t('labels.invioInCorso') : t('labels.invia')}
                      </Button>
                    </div>
                  </Form>
                )}

                {!!openedCell.submitted && (
                  <>
                    <Alert variant={'success'}>
                      <FontAwesomeIcon icon={faCheckCircle} className={'mr-2'} />
                      {t('labels.datiInviatiSuccesso')}!
                    </Alert>

                    <div className={'small'}>
                      <div>
                        <strong>
                          {openedCell.formData.nome} {openedCell.formData.cognome}
                        </strong>
                      </div>
                      <div>{openedCell.formData.indirizzo}</div>
                      {!!openedCell.formData.indirizzo2 && <div>{openedCell.formData.indirizzo2}</div>}
                      <div>
                        {openedCell.formData.cap} {openedCell.formData.citta} ({openedCell.formData.provincia})
                      </div>
                      <div>
                        <FontAwesomeIcon icon={faAt} fixedWidth className={'mr-1 text-muted'} size={'sm'} />
                        {openedCell.formData.email}
                      </div>
                      <div>
                        <FontAwesomeIcon icon={faPhone} fixedWidth className={'mr-1 text-muted'} size={'sm'} />
                        {openedCell.formData.telefono}
                      </div>
                    </div>

                    <div className={'d-flex mt-1'}>
                      {/*<Button
                        size={'sm'}
                        className={'mr-1'}
                        disabled={formLoading}
                        variant={'outline-info'}
                        onClick={() => {
                          handleNotify(null)
                            .then(() => resetForm())
                            .catch(console.error);
                        }}
                      >
                        Annulla inserimento
                      </Button>*/}
                      <Button
                        size={'sm'}
                        disabled={formLoading}
                        variant={'info'}
                        onClick={() => {
                          openedCell.submitted = false;
                          openedCell.updating = true;
                          resetForm(openedCell.formData);
                        }}
                      >
                        {t('adventCalendar.labels.modificaDati')}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </Modal.Body>
        {(!openedCell.askData || !!openedCell.submitted) && (
          <Modal.Footer>
            <Button variant={'primary'} onClick={() => setContentModalOpen(false)}>
              {t('labels.chiudi')}
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      <Modal show={askSubscriptionModalOpen} onHide={() => setAskSubscriptionModalOpen(false)} centered>
        <Modal.Header>
          <Modal.Title>{t('adventCalendar.labels.sorpresaPremium')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('adventCalendar.labels.necessarioAbbonamentoAnnuale')}</p>
          <p className={'mb-0'}>{t('adventCalendar.labels.necessarioAbbonamentoAnnuale2')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={'secondary'} onClick={() => setAskSubscriptionModalOpen(false)}>
            {t('labels.chiudi')}
          </Button>
          {!isNative && (
            <Button variant={'primary'} onClick={() => history.push('/plans')}>
              {t('labels.aquistaPiano')}
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={askYearlySubscriptionModalOpen} onHide={() => setAskYearlySubscriptionModalOpen(false)} centered>
        <Modal.Header>
          <Modal.Title>{t('adventCalendar.labels.sorpresaPremium')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('adventCalendar.labels.necessarioAbbonamentoAnnuale')}</p>
          <p className={'mb-0'}>{t('adventCalendar.labels.passaAbbonamentoAnnuale')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={'secondary'} onClick={() => setAskYearlySubscriptionModalOpen(false)}>
            {t('labels.chiudi')}
          </Button>
          {/*<Button variant={'primary'} onClick={() => history.push('/profile')}>
            Acquista piano
          </Button>*/}
        </Modal.Footer>
      </Modal>

      <Modal show={askYearlySubscriptionModalOpen} onHide={() => setAskYearlySubscriptionModalOpen(false)} centered>
        <Modal.Header>
          <Modal.Title>{t('adventCalendar.labels.sorpresaPremium')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('adventCalendar.labels.necessarioAbbonamentoAnnuale')}</p>
          <p className={'mb-0'}>{t('adventCalendar.labels.passaAbbonamentoAnnuale')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={'secondary'} onClick={() => setAskYearlySubscriptionModalOpen(false)}>
            {t('labels.chiudi')}
          </Button>
          {/*<Button variant={'primary'} onClick={() => history.push('/profile')}>
            Acquista piano
          </Button>*/}
        </Modal.Footer>
      </Modal>

      <Modal size={'lg'} show={helpModalOpen} onHide={() => setHelpModalOpen(false)} centered backdrop={'static'} keyboard={false} scrollable>
        <Modal.Header>
          <Modal.Title>{t('labels.calendarioAvvento')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Condizioni d'uso del Calendario dell'Avvento di {process.env.REACT_APP_TITLE} 2022</h5>
          <p>
            Il Calendario dell'Avvento di {process.env.REACT_APP_TITLE} 2022 è un calendario esclusivamente online, accessibile a tutti gli iscritti alla
            piattaforma {process.env.REACT_APP_TITLE} <a href={'https://tootor.it/advent'}>a questo link</a>.
          </p>
          <p>
            Il calendario è composto da due tipi di caselle, quelle base e quelle premium. Le caselle premium sono contrassegnate dalla scritta{' '}
            <Badge variant={'warning'}>VIP</Badge> e possono essere aperte solamente dagli abbonati a {process.env.REACT_APP_TITLE}. Le caselle base, invece,
            possono essere aperte da tutti gli iscritti.
          </p>
          <h5>Come funziona il Calendario dell'Avvento di {process.env.REACT_APP_TITLE}?</h5>
          <p>
            A partire dal 1° dicembre fino al 25 dicembre compreso 2022, alle ore 00:00 di ogni giorno si sblocca la casella con il numero corrispondente (il
            primo dicembre la n. 1, il 2 dicembre la n. 2 e così via). Una volta aperta la casella, se ne scopre il contenuto e si può scegliere se farne
            richiesta oppure no.
          </p>
          <p>Per farne richiesta bisogna compilare il form che compare dopo l’apertura della casella.</p>
          <p>
            Compilando il form per richiedere il regalo, si autorizza {process.env.REACT_APP_TITLE} a fornire i propri dati alle aziende che provvederanno
            all’invio del regalo stesso. Non sarà dunque {process.env.REACT_APP_TITLE}, ma le varie aziende a contattare gli utenti e fornire loro l’omaggio per
            conto di {process.env.REACT_APP_TITLE}. Unica eccezione, quando l’omaggio sarà un gadget di {process.env.REACT_APP_TITLE} stessa.
          </p>
          <p>Ogni utente può richiedere ogni regalo un’unica volta.</p>
          <h5>Cosa succede se sottoscrivo un abbonamento a {process.env.REACT_APP_TITLE} dopo che il Calendario dell'Avvento è già iniziato?</h5>
          <p>
            Chiunque si abboni a {process.env.REACT_APP_TITLE} dopo che il Calendario dell'Avvento è già iniziato (quindi successivamente al 1° dicembre 2022)
            potrà aprire le caselle che prima gli erano precluse in quanto non utente <Badge variant={'warning'}>VIP</Badge>.
          </p>
          <h5>Fino a quando posso riscattare le sorprese del Calendario dell'Avvento 2022?</h5>
          <p>Le caselle rimarranno a disposizione degli utenti fino alla fine delle feste, ovvero fino al 6 gennaio 2023 compreso.</p>
          <p className={'text-center font-weight-bold'}>INFORMATIVA SULLA PRIVACY AI SENSI DELL’ARTICOLO 13 DEL REGOLAMENTO (UE) 2016/679</p>{' '}
          <p>
            {' '}
            La presente Informativa sul trattamento dei dati personali (di seguito, l’ “<span className={'font-weight-bold'}>Informativa Privacy</span>”)
            disciplina il trattamento dei dati personali e delle informazioni raccolte da Tootor S.r.l., con sede legale in Milano, via Cusani 10, Partita IVA
            10378400963 (di seguito, “<span className={'font-weight-bold'}>Tootor</span>” o il “<span className={'font-weight-bold'}>Titolare</span>”), in
            qualità di titolare del trattamento, in occasione della tua partecipazione al “Calendario dell’Avvento” (l’ "
            <span className={'font-weight-bold'}>Iniziativa</span>") per le finalità e con le modalità di seguito specificate, nel rispetto di quanto stabilito
            ai sensi dell’articolo 13 del Regolamento (UE) 2016/679 del Parlamento europeo e del Consiglio del 27 aprile 2016 relativo alla protezione delle
            persone fisiche con riguardo al trattamento dei dati personali (di seguito, il “<span className={'font-weight-bold'}>Regolamento</span>” e della
            normativa di attuazione.{' '}
          </p>{' '}
          <p>La presente Informativa Privacy fornisce indicazioni anche riguardo ai cookie e al relativo utilizzo da parte di Tootor.</p>{' '}
          <p className={'font-weight-bold'}>1. Dati personali raccolti</p>{' '}
          <p>
            {' '}
            I dati personali che ti riguardano vengono da te direttamente forniti quando effettui la registrazione alla piattaforma di Tootor tramite il sito
            www.tootor.it o l’app Tootor.{' '}
          </p>{' '}
          <p>
            {' '}
            I dati raccolti e trattati da Tootor comprendono: <br /> - i dati identificativi quali nome e cognome; <br /> - l’indirizzo e-mail e l’indirizzo di
            residenza; <br /> - i dati ricevuti automaticamente quali indirizzo IP, il tipo e versione di browser, il dispositivo mobile e l’identificativo
            unico del dispositivo, le caratteristiche del software e dell’hardware, nonché le informazioni raccolte attraverso i cookie; <br /> - il numero di
            telefono.{' '}
          </p>{' '}
          <p className={'font-weight-bold'}>2. Finalità del trattamento e base giuridica</p>{' '}
          <p>
            {' '}
            I dati personali di cui al punto 1 che precede vengono trattati da Tootor per le seguenti finalità: <br /> a) adempimenti necessari alla tua
            iscrizione e partecipazione all’Iniziativa o comunque correlati alla tua iscrizione e partecipazione all’Iniziativa; <br /> b) finalità
            amministrativo-contabili inerenti all’iscrizione e partecipazione all’Iniziativa; <br /> c) finalità di profilazione; <br /> d) invio da parte di
            Tootor di aggiornamenti periodici sulle attività di quest’ultima e di comunicazioni commerciali e promozionali circa sconti, offerte, servizi o
            eventi;
            <br /> e) comunicazione dei dati a società partner di Tootor per finalità di marketing da parte di tali società terze.{' '}
          </p>{' '}
          <p>
            {' '}
            La base giuridica del trattamento dei dati personali è: <br />- l’esecuzione di un contratto per le finalità di cui alle lettere a) e b). Tale
            trattamento è quindi legittimo ai sensi dell’articolo 6.1(b) del Regolamento; e<br />- il tuo preventivo consenso per le finalità di cui alle
            lettere c), d) ed e). Potrai revocare il consenso in qualsiasi momento, ciò tuttavia non pregiudica la liceità del trattamento basata sul consenso
            da te prestato prima di tale revoca.{' '}
          </p>{' '}
          <p>
            {' '}
            Il conferimento dei dati è facoltativo. Tuttavia, l’eventuale rifiuto di fornire i dati per le finalità di cui al presente punto 2) lettere a) e b),
            comporterà l’impossibilità di iscriversi e partecipare all’Iniziativa. Anche una successiva eventuale opposizione o revoca al trattamento dei dati
            personali per le suddette finalità comporterà l’immediata sospensione della fruizione dei relativi servizi. <br /> L’eventuale rifiuto di
            acconsentire al trattamento per le ulteriori finalità di profilazione, invio di aggiornamenti periodici e invio comunicazioni commerciali e
            promozionali, o di comunicazione dei dati a società partner di Tootor per finalità commerciali di queste ultime di cui al presente punto 2), lettere
            c), d) ed e), non comporterà alcuna conseguenza, salva l’impossibilità di essere informato circa comunicazioni di marketing e promo-pubblicitarie di
            Tootor e/o dei suoi partner, nonché di ricevere comunicazioni commerciali e/o contenuti pubblicitari di tuo interesse.{' '}
          </p>{' '}
          <p className={'font-weight-bold'}>3. Modalità di trattamento dei dati personali</p>{' '}
          <p>
            {' '}
            Il trattamento dei tuoi dati per le finalità di cui al paragrafo 2 che precede, avrà luogo con modalità cartacee, automatizzate e telematiche, in
            base a criteri logici funzionali alle finalità per cui i dati stessi sono stati raccolti e, in ogni caso, nell&#39;osservanza di tutte le cautele
            necessarie a garantire la sicurezza e la riservatezza delle informazioni ai sensi del Regolamento.{' '}
          </p>{' '}
          <p className={'font-weight-bold'}>4. Comunicazione dei dati personali</p>{' '}
          <p>
            {' '}
            Nell’ambito dell’organizzazione del Titolare del trattamento, i tuoi dati potranno essere trattati dai soli dipendenti che necessitano di conoscerli
            e/o utilizzarli limitatamente allo svolgimento delle relative mansioni e ai fini della fornitura dei servizi. Tali soggetti sono nominati per
            iscritto da Tootor quali incaricati del trattamento e formati sugli obblighi previsti dal Regolamento.{' '}
          </p>{' '}
          <p>
            {' '}
            Nello svolgimento delle proprie attività Tootor comunica i dati personali che ti riguardano ai seguenti soggetti terzi, nominati responsabili del
            trattamento: partner e fornitori che erogano servizi a favore di Tootor, società di mailing e consulenti del Titolare.
            <br /> Inoltre, al fine di consegnarti il premio nel contesto dell’Iniziativa, Tootor comunicherà al partner di volta in volta applicabile e
            indicato nella specifica casella dell’Iniziativa – che agisce quale responsabile del trattamento nominato da Tootor – i tuoi dati personali
            strettamente necessari ad assicurare la consegna diretta del premio. <br /> Un elenco completo dei Responsabili del trattamento nominati da Tootor
            può essere agevolmente e gratuitamente conosciuto inviando una richiesta all’indirizzo indicato al paragrafo 8 della presente Informativa Privacy.{' '}
          </p>{' '}
          <p>
            Tootor potrebbe, infine, comunicare i tuoi dati alle autorità giudiziarie per rispondere a citazioni, adempiere agli ordini emessi da tribunali o
            altre legittime richieste provenienti da autorità competenti; per far valere o esercitare i nostri diritti o difenderci in giudizio; per fini
            investigativi, di prevenzione o contrasto alla criminalità e in ogni altro caso previsto dalla legge.
          </p>{' '}
          <p>
            Potremmo altresì comunicare i soli dati anonimi raccolti tramite i cookie ai nostri clienti, partner commerciali e società con cui intratteniamo
            rapporti di collaborazione.
          </p>{' '}
          <p>Salvo quanto precede, i tuoi dati non saranno comunicati ad altri soggetti terzi né altrimenti diffusi.</p>{' '}
          <p className={'font-weight-bold'}>5. Conservazione dei dati personali</p>{' '}
          <p>
            {' '}
            I tuoi dati personali saranno conservati nei server a disposizione di Tootor e collocati nell’Unione Europea. Il Titolare non trasferirà i tuoi dati
            personali verso paesi situati al di fuori dell’Unione Europea.{' '}
          </p>{' '}
          <p>
            {' '}
            I dati personali raccolti per le finalità di trattamento indicate al precedente punto 2) lettere a) e b) saranno conservati per tutto il tempo
            necessario alla partecipazione all’Iniziativa e, successivamente,per un periodo non eccedente il termine di prescrizione previsto dalla legge.{' '}
            <br />I dati raccolti per le finalità di trattamento indicate al precedente punto 2), lettere c), d) ed e) saranno conservati fino a che tu non
            abbia ritirato il consenso alle attività di profilazione e alla ricezione di comunicazioni commerciali e promozionali di Tootor o di suoi partner,
            oppure tu richieda la cancellazione dei dati, salvo l’eccezionale necessità di conservare i dati per difendere i diritti del Titolare in relazione a
            contestazioni in essere al momento della richiesta, o su indicazione delle autorità pubbliche.
          </p>{' '}
          <p className={'font-weight-bold'}>6. I tuoi diritti</p>{' '}
          <p>
            {' '}
            Ai sensi degli articoli 15-21 del Regolamento, ti ricordiamo infine che hai il diritto: <br /> a) di ottenere informazioni in relazione alle
            finalità per cui i tuoi dati personali sono trattati, al periodo del trattamento e ai soggetti cui i dati sono comunicati (c.d. diritto di accesso);{' '}
            <br /> b) di ottenere la rettifica o integrazione dei dati personali inesatti che ti riguardano (c.d. diritto di rettifica); <br /> c) di ottenere
            la cancellazione dei dati personali che ti riguardano nei seguenti casi: (a) i dati non siano più necessari per le finalità per le quali sono stati
            raccolti o trattati; (b) hai ritirato il tuo consenso al trattamento dei dati qualora essi siano trattati sulla base del tuo consenso; (c) ti sei
            opposto al trattamento dei dati personali che ti riguardano nel caso in cui essi siano trattati per un legittimo interesse di Tootor; o (d) il
            trattamento dei tuoi dati personali non sia conforme alla legge. Tuttavia, ti segnaliamo che la conservazione dei dati personali da parte di Tootor
            è lecita qualora sia necessaria per consentirle di adempiere un obbligo legale o per accertare, esercitare o difendere un diritto in sede
            giudiziaria (c.d. diritto di cancellazione); <br /> d) di ottenere che i dati personali che ti riguardano siano solo conservati senza che di essi
            sia fatto altro uso nei seguenti casi: (a) hai contestato l&#39;esattezza dei dati personali, per il periodo necessario a consentire a Tootor di
            verificare l&#39;esattezza di tali dati personali; (b) il trattamento sia illecito ma ti opponi comunque alla cancellazione dei dati personali da
            parte di Tootor; (c) i dati personali ti sono necessari per l&#39;accertamento, l&#39;esercizio o la difesa di un diritto in sede giudiziaria; (d)
            ti sei opposto al trattamento e si sia in attesa della verifica in merito all&#39;eventuale prevalenza dei motivi legittimi di Tootor al trattamento
            rispetto ai tuoi (c.d. diritto di limitazione); <br /> e) di ottenere la cessazione del trattamento nei casi in cui i tuoi dati personali siano
            trattati per il legittimo interesse di Tootor e contesti l’esistenza di questo interesse (c.d. diritto di opposizione); <br /> f) di ricevere in un
            formato di uso comune, leggibile da dispositivo automatico e interoperabile i dati personali che ti riguardano trattati con mezzi automatizzati, se
            essi siano trattati in forza di contratto o sulla base del tuo consenso (c.d. diritto di portabilità).{' '}
          </p>{' '}
          <p>
            {' '}
            Ti ricordiamo che hai altresì il diritto di rivolgerti al Garante per la Protezione dei Dati Personali (i cui dati di contatto sono disponibili al
            seguente link <a href="https://www.garanteprivacy.it/home/footer/contatti">https://www.garanteprivacy.it/home/footer/contatti</a>) per far valere i
            tuoi diritti in relazione al trattamento dei tuoi dati personali.{' '}
          </p>{' '}
          <p>
            {' '}
            Per i fini di cui al presente punto 6, potrai inviare una richiesta al Titolare del trattamento a mezzo di posta elettronica all&#39;indirizzo{' '}
            <a href="mailto:privacy@tootor.it">privacy@tootor.it</a> oppure tramite lettera raccomandata presso la sede legale di Tootor indicata al punto 8 che
            segue.{' '}
          </p>{' '}
          <p className={'font-weight-bold'}>7. Cookies</p>{' '}
          <p>
            {' '}
            Tootor utilizza cookie tecnici, analitici e di profilazione al fine di raccogliere e accedere alle informazioni memorizzate sul tuo device. Per
            maggiori informazioni ti invitiamo a visitare la <a href="https://tootor.it/legal/cookie">Cookie Policy</a> di Tootor.{' '}
          </p>{' '}
          <p className={'font-weight-bold'}>8. Il titolare del trattamento</p>{' '}
          <p>
            {' '}
            Il titolare del trattamento è Tootor S.r.l., con sede legale in Milano, via Cusani 10, Partita IVA 10378400963. Il Titolare del trattamento può
            essere contattato per richieste o segnalazioni al seguente indirizzo e-mail: privacy@tootor.it{' '}
          </p>
        </Modal.Body>
        <Modal.Footer>
          {/* <Form.Check
            id={'dontShowHelpModal'}
            type={'checkbox'}
            label={'Non mostrare più'}
            className={'mr-auto'}
            checked={dontShowHelpModalChecked}
            onChange={(e) => setDontShowHelpModalChecked(e.target.checked)}
          /> */}
          <Button
            variant={'secondary'}
            onClick={() => {
              setDontShowHelpModalChecked(false);
              setTimeout(() => {
                history.push(`/`);
              });
            }}
          >
            {t('labels.tornaHome')}
          </Button>
          <Button
            variant={'primary'}
            onClick={() => {
              setHelpModalOpen(false);
              setDontShowHelpModalChecked(true);
            }}
          >
            {t('adventCalendar.labels.hoCapito')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdventCalendarComponent;
