import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Title } from '../../components/Title/Title';
import { Topbar } from '../../components/Topbar/Topbar';
import { Button, Card, Container } from 'react-bootstrap';
import { Footer } from '../../components/Footer/Footer';
import './Acty.scss';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useEffect, useState } from 'react';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';

const ActyComponent = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <Title>{t('acty.labels.title')}</Title>

      <div style={{ marginBottom: '72px' }}>
        <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
          <Topbar />
        </div>
      </div>

      <Container className={'my-5'}>
        <div className={'d-flex align-items-center justify-content-center mb-4 w-100 mx-auto'}>
          <img className={'banner-acty-container mb-5'} src={process.env.PUBLIC_URL + 'one-to-one/banner.jpg'} />
        </div>
        <div>
          <Container
            className={'one-to-one-box'}
            style={{ backgroundImage: `url(${process.env.PUBLIC_URL}one-to-one/elenco-appuntamenti.jpg)` }}
            onClick={() => {
              history.push(`/acty/appointments`);
            }}
          >
            <div className={'d-flex w-100'}>
              <h2 className={'mb-0 crop-text-2'}>{t('acty.labels.elencoPrenotazioni')}</h2>
              <Button
                variant={'primary'}
                onClick={() => {
                  history.push(`/acty/appointments`);
                }}
                className={'ml-auto mt-auto'}
              >
                {t('acty.labels.entraOraBtn')}
              </Button>
            </div>
            <div className={'course-image-wrapper'}></div>
          </Container>
          <Container
            className={'one-to-one-box'}
            style={{ backgroundImage: `url(${process.env.PUBLIC_URL}one-to-one/categorie.jpg)` }}
            onClick={() => {
              history.push(`/acty/categories`);
            }}
          >
            <div className={'d-flex w-100'}>
              <h2 className={'mb-0 crop-text-2'}>{t('acty.labels.prenotaLiveMentoring')}</h2>
              <Button
                variant={'primary'}
                onClick={() => {
                  history.push(`/acty/appointments`);
                }}
                className={'ml-auto mt-auto'}
              >
                {t('acty.labels.prenotaOraBtn')}
              </Button>
            </div>
            <div className={'course-image-wrapper'}></div>
          </Container>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default ActyComponent;
