import { useEffect, useState } from 'react';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { FullscreenLoader } from '../FullscreenLoader/FullscreenLoader';
import { innerHtml } from '../../utils/utils';

const LegalDocumentComponent = ({ doc }: { doc: 'privacy' | 'cookie' | 'tos' }) => {
  const { getLegal } = useFirebase();
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState<string>(null);

  useEffect(() => {
    getLegal(doc)
      .then(({ content }) => {
        setContent(content);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  if (loading) return <FullscreenLoader fullscreen={false} />;

  return <div style={{ color: '#909eab' }} className={'mt-5'} {...innerHtml(content)} />;
};

export { LegalDocumentComponent as LegalDocument };
