import { Helmet } from 'react-helmet-async';

const TitleComponent = ({ children }) => {
  return (
    <Helmet defaultTitle={process.env.REACT_APP_TITLE} titleTemplate={'%s - ' + process.env.REACT_APP_TITLE}>
      <title>{children}</title>
    </Helmet>
  );
};

export { TitleComponent as Title };
