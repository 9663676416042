import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SortableColumnHeaderComponent({ field, title, sortField, sortDirection, onSort }) {
  return (
    <th onClick={() => onSort(field)} style={{ cursor: 'pointer' }}>
      <div className={'d-flex justify-content-between align-items-center'}>
        {title}
        {sortField === field && (sortDirection === 'asc' ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />)}
      </div>
    </th>
  );
}

export default SortableColumnHeaderComponent;
