import { Badge, Button, Container } from 'react-bootstrap';
import { Footer } from '../../components/Footer/Footer';
import { Title } from '../../components/Title/Title';
import { Topbar } from '../../components/Topbar/Topbar';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { canAccessFeature, formatSecondsTimestamp, innerHtml } from '../../utils/utils';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { Redirect, useHistory } from 'react-router-dom';
import './Blog.scss';
import { CountryFlag } from '../../components/CountryFlag/CountryFlag';
import { useCurrentLanguage } from '../../../hooks/useCurrentLanguage';

const BlogComponent = () => {
  const { queryHubspot, staticData, userDataFmt } = useFirebase();
  const { currentLanguage } = useCurrentLanguage();
  const history = useHistory();

  const [tags, setTags] = useState({});
  const [posts, setPosts] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [nextPageToken, setNextPageToken] = useState<string>(null);

  const fetchPage = useCallback(() => {
    setLoading(true);
    Promise.all([queryHubspot('BLOG_TAGS'), queryHubspot('BLOG_PAGE', { after: nextPageToken })])
      .then(([tags, response]) => {
        setTags(tags);
        setNextPageToken(response.paging?.next.after);
        setPosts((current) => [...current, ...response.results]);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [nextPageToken]);

  useEffect(() => {
    fetchPage();
  }, []);

  if (!staticData.LOADED || !userDataFmt) return <FullscreenLoader />;
  if (!canAccessFeature('BLOG', currentLanguage, staticData, userDataFmt)) {
    return <Redirect to={'/browse'} />;
  }

  return (
    <>
      <Title>Blog</Title>

      <div style={{ marginBottom: '72px' }}>
        <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
          <Topbar />
        </div>
      </div>

      <Container className={'my-5'}>
        {/*<div className={'d-flex align-items-center mb-5'}>
          <div className={'display-4'}>Blog</div>
        </div>*/}

        <div className={'display-4 mb-5'}>
          <div className={'d-flex align-items-center justify-content-center w-100 mx-auto'}>
            <img className={'w-100'} src="https://d260e0gu5nbefz.cloudfront.net/gaming/banners/banner_blog.jpg" />
          </div>
        </div>

        {posts.map((post, i, list) => (
          <Fragment key={post.id}>
            <div
              className={'d-flex blog-article ' + (i == 0 ? 'jumbo-article mb-5' : '')}
              onClick={() => (window.location.href = `/blog/${post.id}/${post.slug}`)}
            >
              <div
                className={'overflow-hidden ml-3 article-image'}
                style={{
                  backgroundImage: `url(${post.featuredImage})`,
                }}
              />
              <div className={'article-content'}>
                <div className={'mb-1'}>
                  <CountryFlag language={post.language} className={'mr-2'} withTooltip={true} />
                  {(post.tagIds || []).map((tagId) => (
                    <Badge variant={'dark'} key={tagId} className={'mr-1'}>
                      {tags[tagId]}
                    </Badge>
                  ))}
                </div>
                <h4 className={'article-title'} {...innerHtml(post.htmlTitle)} />
                <p {...innerHtml(post.postSummary)} />
                <div className={'small text-muted'}>
                  <span className={'text-light'}>{post.authorName}</span> <span className={'mx-2'}>&bull;</span>
                  <span>{post.publishDate ? formatSecondsTimestamp(post.publishDate, 'dd/MM/Y HH:mm') : ''}</span>
                </div>
              </div>
            </div>
            {i != 0 && i != list.length - 1 && <hr className={'border-secondary my-4'} />}
          </Fragment>
        ))}

        {loading && <FullscreenLoader fullscreen={false} />}

        {!!nextPageToken && !loading && <Button onClick={fetchPage}>Carica articoli precedenti&hellip;</Button>}
      </Container>

      <Footer />
    </>
  );
};

export default BlogComponent;
