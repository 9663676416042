import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useFirebase } from '../../../contexts/FirebaseContext';

const LogoComponent = ({ variant = 'color', style = {}, className = '', clickable = false }) => {
  const { isLoggedIn } = useFirebase();
  const history = useHistory();
  const [logo, setLogo] = useState<string>();

  const getLogoName = () => {
    switch (variant) {
      default:
      case 'color':
        return 'logo.png';
      case 'light':
        return 'logo-w.png';
      case 'dark':
        return 'logo-b.png';
      case 'reduced':
        return 'logo-oo.png';
    }
  };

  useEffect(() => {
    setLogo(getLogoName());
  }, [variant]);

  return (
    <img
      src={process.env.PUBLIC_URL + '/' + logo}
      style={{ cursor: clickable ? 'pointer' : null, ...style }}
      alt={process.env.REACT_APP_TITLE}
      onClick={() => clickable && history.push(isLoggedIn ? '/browse' : '/')}
      className={className}
    />
  );
};

export { LogoComponent as Logo };
