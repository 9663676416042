import { useEffect, useState } from 'react';
import initI18n from '../app/utils/i18n';

export const I18nProvider = ({ children }) => {
  const [translationsReady, setTranslationsReady] = useState(false);

  useEffect(() => {
    const baseUrl =
      window.location.origin.includes('tootor-dev') || process.env.NODE_ENV == 'development'
        ? 'europe-west3-tootor-development'
        : 'europe-west3-tootor-production';
    fetch(`https://${baseUrl}.cloudfunctions.net/http-getTranslations`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ data: {} }),
    })
      .then((res) => res.json())
      .then(({ result }) => initI18n(result))
      .then(() => {
        setTranslationsReady(true);
      });
  }, []);

  if (!translationsReady) return null;
  return children;
};
