import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useEffect } from 'react';
import { useQuery } from '../../../hooks/useQuery';
import { useHistory } from 'react-router-dom';

const WaitComponent = () => {
  const { database, currentUser } = useFirebase();
  const { sid, next, docid } = useQuery();
  const history = useHistory();

  useEffect(() => {
    if (!sid || !next || !docid) return;
    const unsub = database.doc(`userData/${currentUser.uid}/early/${docid}`).onSnapshot((snap) => {
      const data = snap.data();
      if (data.sessionId != sid) return unsub();
      if (data.status == 'complete') {
        unsub();
        history.push('/' + next);
      }
    });
  }, []);

  return <FullscreenLoader />;
};

export { WaitComponent as Wait };
