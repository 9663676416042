import { VideoCard } from '../VideoCard/VideoCard';
import './ScrollableList.scss';
import Slider from 'react-slick';
import { Breakpoints } from '../../utils/utils';
import { useMobileDetect } from '../../../hooks/useMobileDetect';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useEffect, useRef, useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useInViewport } from 'react-in-viewport';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useCapacitor } from '../../../hooks/useCapacitor';
import { FullscreenLoader } from '../FullscreenLoader/FullscreenLoader';

const ScrollableListComponent = ({
  title = null,
  description = null,
  keepWatching = false,
  showPosition = false,
  forceVertical = false,
  elements,
  onDetailsOpen = (video) => {},
  onDetailsClose = (video) => {},
  onKeepWatchingRemove = (id) => {},
  children = null,
  tagId = null,
}) => {
  const { getVideosForTag } = useFirebase();
  const { windowWidth } = useWindowSize();
  const [inputList, setInputList] = useState<any[]>(elements);
  const [listElements, setListElements] = useState([]);
  const [fetched, setFetched] = useState(!!elements.length);
  const { isMobile } = useMobileDetect();
  const { isNative } = useCapacitor();

  const slidesAmountBreakpoints = {
    [Breakpoints.xl]: isMobile ? 5 : 4,
    [Breakpoints.lg]: isMobile ? 4 : 3,
    [Breakpoints.md]: isMobile ? 4 : 3,
    [Breakpoints.sm]: isMobile ? 3 : 2,
    default: isMobile ? 6 : 5,
  };
  const [expectedAmountOfSlidesForCurrentBreakpoint, setExpectedAmountOfSlidesForCurrentBreakpoint] = useState(slidesAmountBreakpoints.default);

  useEffect(() => {
    const [, currentBreakpoint] = Object.entries(Breakpoints).find(([, breakpoint]) => breakpoint >= windowWidth) ?? [];
    const expected = slidesAmountBreakpoints[currentBreakpoint] ?? slidesAmountBreakpoints.default;
    setExpectedAmountOfSlidesForCurrentBreakpoint(expected);
  }, [windowWidth, inputList]);

  const slider = useRef();
  const wrapperRef = useRef();
  const { inViewport, enterCount } = useInViewport(wrapperRef, { rootMargin: '150px' }, { disconnectOnLeave: false }, {});

  useEffect(() => {
    if (!inViewport || fetched) return;
    getVideosForTag(tagId)
      .then(setInputList)
      .catch(console.error)
      .finally(() => setFetched(true));
  }, [inViewport, fetched]);

  useEffect(() => {
    if (!inputList.length) return;
    if (inputList.length >= expectedAmountOfSlidesForCurrentBreakpoint) {
      setListElements(inputList);
      return;
    }
    const missingElements = expectedAmountOfSlidesForCurrentBreakpoint - elements.length;
    setListElements([...inputList, ...Array(missingElements).fill(null)]);
  }, [expectedAmountOfSlidesForCurrentBreakpoint, inputList]);

  /**
   * {@link https://react-slick.neostack.com/docs/api}
   */
  const sliderSettings = {
    accessibility: false,
    adaptiveHeight: false,
    arrows: !isMobile,
    autoplay: false,
    dots: true,
    draggable: false,
    easing: 'ease-out',
    infinite: true,
    lazyLoad: 'ondemand',
    slidesToScroll: slidesAmountBreakpoints.default,
    slidesToShow: slidesAmountBreakpoints.default,
    speed: 500,
    swipe: isMobile,
    onInit: () => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      });
    },
    responsive: [Breakpoints.xl, Breakpoints.lg, Breakpoints.sm].map((breakpoint) => ({
      breakpoint,
      settings: {
        slidesToShow: slidesAmountBreakpoints[breakpoint],
        slidesToScroll: slidesAmountBreakpoints[breakpoint],
      },
    })),
  };

  const lockVerticalScrollWhenHorizontalSwiping = (direction: 'vertical' | 'right' | 'left'): void => {
    if (!isNative) return;
    const isHorizontal = direction !== 'vertical';
    if (isHorizontal) disableBodyScroll(slider.current);
  };

  const releaseBodyScroll = (): void => {
    if (!isNative) return;
    enableBodyScroll(slider.current);
  };

  const EmptyElement = ({ key }) => <div key={key}>&nbsp;</div>;

  if (fetched && !inputList.length) return <></>;

  return (
    <section className={'mt-4 mx-3 mx-sm-4 mb-4'} onTouchEnd={releaseBodyScroll} ref={wrapperRef}>
      <div className={'section-header mb-1 mb-sm-4 d-flex align-items-center'}>
        {!!children && <span className={'mr-2'}>{children}</span>}
        {!!title && <h3 className={'mb-0'}>{title}</h3>}
      </div>

      <Slider className={'pr-2'} ref={slider} swipeEvent={lockVerticalScrollWhenHorizontalSwiping} enableVerticalScroll {...sliderSettings}>
        {(!inViewport && !enterCount) || !fetched
          ? Array(expectedAmountOfSlidesForCurrentBreakpoint)
              .fill(null)
              .map((video, i) => (i < 2 ? <VideoCard empty={true} className={'m-1'} key={i.toString()} /> : <EmptyElement key={i.toString()} />))
          : listElements.map((video, i) =>
              video ? (
                <VideoCard
                  className={'m-1'}
                  key={i.toString()}
                  index={i}
                  video={video}
                  forceVertical={forceVertical}
                  showPosition={showPosition}
                  keepWatching={keepWatching}
                  onDetailsOpen={() => onDetailsOpen(video)}
                  onDetailsClose={() => onDetailsClose(video)}
                  onKeepWatchingRemove={onKeepWatchingRemove}
                />
              ) : (
                <EmptyElement key={i.toString()} />
              )
            )}
      </Slider>
    </section>
  );
};

export { ScrollableListComponent as ScrollableList };
