import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Title } from '../../components/Title/Title';
import { Topbar } from '../../components/Topbar/Topbar';
import { Button, Card, Container } from 'react-bootstrap';
import { Footer } from '../../components/Footer/Footer';
import '../Acty/Acty.scss';
import { intlFormat, addDays, addWeeks, isToday } from 'date-fns';
import { useEffect, useState } from 'react';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { faArrowLeft, faArrowRight, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCurrentLanguage } from '../../../hooks/useCurrentLanguage';

const ActySpeakersListComponent = () => {
  const { currentLanguage } = useCurrentLanguage();
  const { getActyTutors } = useFirebase();
  const history = useHistory();
  const { t } = useTranslation();
  const { actyCategoryId } = useParams<{ actyCategoryId: string }>();
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [actyTutors, setActyTutors] = useState([]);
  const [category, setCategory] = useState('');
  const [loading, setLoading] = useState(true);

  const endDate = addDays(startDate, 6);

  const humanReadableDate = (date: Date): string => {
    return intlFormat(date, { day: '2-digit', month: 'long' }, { locale: currentLanguage });
  };

  const addWeek = (): void => {
    setStartDate(addWeeks(startDate, 1));
  };

  const removeWeek = (): void => {
    if (isToday(startDate)) return;
    setStartDate(addWeeks(startDate, -1));
  };

  useEffect(() => {
    setLoading(true);
    getActyTutors(startDate, actyCategoryId)
      .then((data) => {
        const { category, parsedTutors } = data;
        setActyTutors(parsedTutors);
        setCategory(category);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [startDate]);

  return loading ? (
    <FullscreenLoader fullscreen={true} />
  ) : (
    <>
      <Title>{t('acty.labels.docentiTitle')}</Title>

      <div style={{ marginBottom: '72px' }}>
        <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
          <Topbar />
        </div>
      </div>

      <Container className={'my-5'}>
        <Container
          className={'one-to-one-box justify-content-center align-items-center pe-none'}
          style={{ backgroundImage: `url(/${process.env.PUBLIC_URL}one-to-one/categorie.jpg)`, pointerEvents: 'none' }}
          onClick={() => {
            history.push(`/acty/appointments`);
          }}
        >
          <h2 className={'mb-0 crop-text-2'}>
            {t('acty.labels.prenotaLiveMentoring')}: {category}
          </h2>
          <div className={'course-image-wrapper'}></div>
        </Container>
        <div style={{ gap: '0.25rem' }} className={'d-flex align-items-center mb-3'}>
          <Button
            className={'btn-dark mr-2'}
            onClick={() => {
              history.push('/acty/categories/');
            }}
            disabled={loading}
          >
            <FontAwesomeIcon className={'mr-2'} icon={faArrowLeft} /> {t('acty.labels.tornaCategorieBtn')}
          </Button>
          <Button className={'btn-dark'} onClick={() => removeWeek()} disabled={loading}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <div style={{ lineHeight: '38px', borderRadius: '0.25rem' }} className={'btn-dark border-round-md px-3 text-capitalize'}>
            {humanReadableDate(startDate)} - {humanReadableDate(endDate)}
          </div>
          <Button className={'btn-dark'} onClick={() => addWeek()} disabled={loading}>
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </div>
        {!actyTutors.length && <div className={'text-center'}>Nessun relatore disponibile</div>}
        {actyTutors.length > 0 && (
          <div className={'acty-cards-container speakers-list justify-content-start justify-content-sm-center'}>
            {actyTutors.map((tutor) => (
              <Card bg={'dark'} className={'acty-docente-card'} onClick={() => history.push(`/acty/categories/${actyCategoryId}/speakers/${tutor.id}`)}>
                <div className={'title-docente-card'}>{tutor.name}</div>
                {/* <div className={'fs-6 description-docente-card text-secondary'}>
                  {tutor.courses.map((course) => (
                    <div>{`- ${course.name}`}</div>
                  ))}
                </div> */}
                <div className={'body-docente-card position-relative'}>
                  <div className={'d-flex justify-content-between'}>
                    <div className={'disponibilita-card'}>
                      <div className={'chip-container'}>
                        {tutor.shortDates.map((a) => {
                          const currDate = new Date(a.shortDate);
                          const shortDateNumb = intlFormat(currDate, { day: '2-digit' }, { locale: currentLanguage });
                          const shortDate = intlFormat(currDate, { weekday: 'short' }, { locale: currentLanguage });

                          return <div className={`chip ${a.isAvailable ? 'categoria-odonto' : ''}`}>{`${shortDate} ${shortDateNumb}`}</div>;
                        })}
                      </div>
                    </div>

                    <div style={{ width: '40%', position: 'relative', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {tutor.imageUrl ? (
                        <img
                          src={tutor.imageUrl}
                          style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faUser}
                          style={{
                            position: 'absolute',
                            width: '90%',
                            height: '90%',
                            objectFit: 'cover',
                            color: 'rgb(74, 74, 74)', // Set color
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <Button className={'make-reservation-button'} variant={'primary'}>
                    {t('acty.labels.prenotaBtn')}
                  </Button>
                  {/* <div className={'w-100 p-3 chip-container'}>
                      {tutor.categories.map((category) => (
                        <div className={'chip categoria-odonto'}>{category.label}</div>
                      ))}
                    </div> */}
                </div>
              </Card>
            ))}
          </div>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default ActySpeakersListComponent;
