const AppDownloadComponent = ({ className = '' }) => {
  const playUrl = 'https://play.google.com/store/apps/details?id=com.tootor.app';
  const iosUrl = 'https://apps.apple.com/us/app/tootor/id1583954798';

  const goto = (url: string) => {
    window.location.href = url;
  };

  return (
    <div className={className}>
      <img
        alt={'Download from App Store'}
        src={process.env.PUBLIC_URL + '/badge-app-store.svg'}
        style={{ height: '28px', cursor: 'pointer' }}
        onClick={() => goto(iosUrl)}
      />
      <img
        alt={'Download from Google Play'}
        src={process.env.PUBLIC_URL + '/badge-play-store.png'}
        style={{ height: '40px', cursor: 'pointer' }}
        onClick={() => goto(playUrl)}
      />
    </div>
  );
};

export { AppDownloadComponent as AppDownload };
