import { Topbar } from '../../components/Topbar/Topbar';
import { Footer } from '../../components/Footer/Footer';
import { CourseBox } from '../../components/CourseBox/CourseBox';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { Alert, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Title } from '../../components/Title/Title';
import { useEffect } from 'react';
import './Courses.scss';
import { useParams } from 'react-router-dom';

const CoursesComponent = () => {
  const { t } = useTranslation();
  const { courses, getCourses, userCompanyData } = useFirebase();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    getCourses(id).catch(console.error);
  }, []);

  if (!courses) return <FullscreenLoader />;

  return (
    <>
      <Title>{t('labels.corsi')}</Title>

      <div style={{ marginBottom: '72px' }}>
        <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
          <Topbar />
        </div>
      </div>

      <Container className={'my-5'}>
        {!courses.length && (
          <div className={'my-5'}>
            <h1 className={'text-center display-4'}>{t('courses.labels.noCorsi')}</h1>
          </div>
        )}

        {/* CORSO ASO */}
        {!!courses.length && !id && (
          <>
            <div className={'display-4 mb-3'}>{t('courses.labels.corsiDisponibili')}</div>
            <Alert variant={'info'}>
              <p>{t('courses.labels.corsiDisponibiliDescrizione')}</p>
              <p>{t('courses.labels.corsiDisponibiliDescrizione2')}</p>
              <p className={'mb-0'}>{t('courses.labels.corsiDisponibiliDescrizione3')}</p>
            </Alert>
            {courses.map((course, i) => (
              <CourseBox key={i.toString()} course={course} />
            ))}
          </>
        )}

        {/* CORSO AZIENDALE */}
        {!!courses.length && id && (
          <>
            <div
              style={{
                height: '150px',
                border: '3px solid white',
                borderRadius: '8px',
                backgroundImage: `url(${userCompanyData.logoUrl}})`,
              }}
              className={'display-4 mb-5 text-center d-flex align-items-center justify-content-center tootor-bg'}
            >
              <div className={'title-card'}>{userCompanyData.name}</div>
            </div>
            {courses.map((course, i) => (
              <CourseBox key={i.toString()} course={course} />
            ))}
          </>
        )}
      </Container>

      <Footer />
    </>
  );
};

export default CoursesComponent;
