import * as Sentry from '@sentry/react';
import { cfaSignIn, cfaSignOut } from 'capacitor-firebase-auth/alternative';
import { addDays, startOfDay } from 'date-fns';
import firebase from 'firebase/app';
import { createContext, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FullscreenLoader } from '../app/components/FullscreenLoader/FullscreenLoader';
import * as sharded from '../app/utils/sharded-counter';
import {
  addConfidence,
  calculateDeviceIdentifier,
  clearWatchingInfo,
  CloudFunctions,
  Experiments,
  fn,
  formReducer,
  isItalian,
  manualCloudFunction,
  MINUTES,
  stableSort,
} from '../app/utils/utils';
import {
  analytics,
  appleAuthProvider,
  auth,
  database,
  emailAuthProvider,
  fieldValue,
  googleAuthProvider,
  realtimeDatabase,
  storage,
  timestamp,
  videoDataStorage,
} from '../firebase';
import { useCapacitor } from '../hooks/useCapacitor';
import { useCurrentLanguage } from '../hooks/useCurrentLanguage';
import { useSession } from '../hooks/useSession';
import { useGeolocation } from './GeolocationContext';
import { GenericObject } from './GamingContext';

const FirebaseContext = createContext<IFirebaseContext>(null);

type DocumentData = firebase.firestore.DocumentData;
type DocumentSnapshot = firebase.firestore.DocumentSnapshot<DocumentData>;
type UserCredential = firebase.auth.UserCredential;
type User = firebase.User;

interface IFirebaseContext {
  currentUser: User;
  isLoggedIn: boolean;

  getStaticData: () => Promise<DocumentData>;

  userData: DocumentSnapshot;
  userDataFmt: any;
  userCompanyData: any;
  updateUserData: (data?: DocumentData, options?: { skipListUpdate?: boolean }) => Promise<void>;
  getVideoShardedCounterIncrementor: (videoId: string) => () => Promise<void>;
  getVideoOpeningsShardedCounterIncrementor: (videoId: string) => () => Promise<void>;
  updateUserVideoAnalytics: (videoId: string, data: DocumentData) => Promise<void>;
  updateHubspotProperties: (data: { [p: string]: any }) => Promise<void>;

  plans: any[];
  promoCodes: any[];
  subscription: any;
  globalSecondsIncrementor: () => void;

  isTrial: boolean;
  trialTimeLeft: number;
  isTrialExpired: boolean;

  getDeviceIdentifier: () => Promise<string>;
  checkDeviceRegistrationAbility: (deviceId: string) => Promise<{ deviceId: string; canRegister: boolean; existing?: boolean }>;
  registerDevice: (deviceInfo: { browser: string; os: string; deviceId: string }, existing?: boolean) => Promise<string>;
  getRegisteredDevices: () => Promise<DocumentData[]>;
  removeDevice: (deviceId: string) => Promise<void>;

  addCheckoutSession: (price: string, promotion_code?: string) => Promise<{ sessionId: string; url: string }>;
  getCustomerPortalUrl: () => Promise<any>;

  staticData: DocumentData;
  signupData: DocumentData;

  notifications: { title?: string; body: string; image?: string; read: boolean; data?: any }[];
  notificationBadge: number;
  addNotifications: (items: any[]) => void;
  readNotification: (index: number) => void;
  cleanNotifications: () => void;

  getVideosForTag: (tagId: string) => Promise<DocumentData[]>;
  requestVideoData: (videoId: string, drmType: string) => Promise<DocumentData>;
  videos: DocumentData[];
  comingSoonVideos: DocumentData[];
  mostViewsVideos: DocumentData[];
  freeVideos: DocumentData[];
  keepWatching: DocumentData[];
  newVideos: DocumentData[];
  series: DocumentData[];
  seriesAuthors: { author: DocumentData; authorVideos: DocumentData[] }[];
  seriesTags: { tag: DocumentData; tagVideos: DocumentData[] }[];
  courses: DocumentData[];
  billboard: DocumentData;
  getCourse: (id: string) => Promise<DocumentData>;
  getTest: (courseId: string, id: string) => Promise<DocumentData>;
  validateTest: (data: any) => Promise<any>;
  tags: DocumentData[];
  authors: DocumentData[];
  getSeries: (seriesId: string) => DocumentData;
  isVideoLocked: (video: any) => boolean;
  recommendations: DocumentData[];
  bonusVideos: DocumentData[];
  setValidBonus: (bonusDocumentId: string) => void;

  queryVideosByString: (query: string) => Promise<{ data: DocumentData[]; time: number }>;

  tagCards: { id: string; label: string }[];
  myList: DocumentData[];
  addToMyList: (videoId: string) => Promise<void>;
  removeFromMyList: (videoId: string) => Promise<void>;
  myListLoading: boolean;

  login: (email: string, password: string) => Promise<UserCredential>;
  loginWithGoogle: () => Promise<any>;
  loginWithApple: () => Promise<any>;
  loginWithToken: (token: string) => Promise<UserCredential>;
  signup: (email: string, password: string) => Promise<UserCredential>;
  logout: () => Promise<void>;
  logoutAll: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updateEmail: (email: string) => Promise<void>;
  updatePassword: (password: string) => Promise<void>;
  checkEmail: (email: string) => Promise<string[]>;
  sendEmailVerification: (path?: string) => Promise<void>;
  recoverPassword: (email: string) => Promise<void>;
  changePassword: (password: string) => Promise<void>;
  reauthenticate: (password: string) => Promise<UserCredential>;
  reauthenticateGoogle: () => Promise<UserCredential>;
  deleteAccount: () => Promise<void>;

  mfaGetSession: () => Promise<firebase.auth.MultiFactorSession>;
  mfaEnroll: (assertion: firebase.auth.MultiFactorAssertion) => Promise<void>;
  mfaUnenroll: (factorId: string) => Promise<void>;

  getActyTutorCategories: () => Promise<DocumentData[]>;
  getActyTutors: (startDate: Date, categoryId: string) => Promise<any>;
  getActyAppointments: (
    asAdmin?: boolean,
    page?: number,
    sorting?: { direction: 'asc' | 'desc'; field: string },
    filters?: any,
    exportCsv?: boolean
  ) => Promise<any[]>;
  getActyAppointment: (appointmentId: string) => Promise<DocumentData>;
  getActyReservationUrl: (actyTutorId: string, actyCategoryId: string) => Promise<any>;

  getTvLoginCode: () => Promise<string>;
  listenForTvLoginToken: (code: string) => Promise<string>;
  consumeTvLoginCode: (code: string) => Promise<boolean>;

  getTickets: (asAdmin?: boolean) => Promise<DocumentData[]>;
  getTicket: (ticketId: string, asAdmin?: boolean) => Promise<DocumentData>;
  submitTicket: (data: any) => Promise<{ ticketId: string; messageId: string }>;
  getTicketStatuses: () => Promise<DocumentData[]>;
  rateTicket: (ticketId: string, rating?: 1 | -1) => Promise<void>;
  assignTicket: (ticketId: string, assignee?: string) => Promise<void>;

  getUserData: (userIdentifier: { uid?: string; email?: string; alias?: any }) => Promise<DocumentData>;
  getUserCourse: (uid: string, cid: string, action: string, payload?: any) => Promise<any>;
  updateUser: (uid: string, updater: { [p: string]: any }) => Promise<boolean>;
  requestImpersonationToken: (uid: string, sender: string) => Promise<string>;

  getPublishData: () => Promise<{ tags: DocumentData[]; authors: DocumentData[]; series: DocumentData[] }>;
  getContent: () => Promise<{ videos: DocumentData[]; tags: DocumentData[]; authors: DocumentData[]; faqs: DocumentData[]; courses: DocumentData[] }>;
  requestVideoContent: (id: string) => Promise<DocumentData>;
  getStats: () => Promise<{ [p: string]: any }>;
  getVideoReports: () => Promise<{ reports: DocumentData[] }>;
  getFeedbacks: () => Promise<{ feedbacks: DocumentData[] }>;
  getLogs: (offset: number, elementId?: string) => Promise<DocumentData[]>;
  upsertHubspotProduct: (id: string, objectType: 'video' | 'tag') => Promise<boolean>;
  getStripeOneOffs: () => Promise<{ products: any[]; history: DocumentData[] }>;
  getCheckoutDetails: (id: string) => Promise<DocumentData>;
  createOneOffSession: (data: any) => Promise<any>;
  updateCustomerSubscription: (priceId?: string, upgradeInterval?: boolean, finalize?: string, cancel?: string) => Promise<boolean | DocumentData>;
  getCustomerData: () => Promise<any>;
  requestCollaboration: (request: any) => Promise<boolean>;
  getAdventCalendar: () => Promise<any>;
  unlockAdventDay: (day: number, year: number) => Promise<any>;
  notifyAdventData: (day: number, year: number, data?: any) => Promise<any>;
  trackConversion: (event: string, params?: { [p: string]: any }) => Promise<void>;
  getExperiments: () => Promise<DocumentData[]>;
  getAwsGuid: (guid: string) => Promise<any>;
  getEbooks: (page: number, isMobile: boolean) => Promise<{ list: DocumentData[]; hasMore: boolean }>;
  getNextQuestion: (questionId?: string, answerId?: string) => Promise<DocumentData>;
  unlockEbook: (ebookId: string, value: { questionId: string; answerId: string }[]) => Promise<{ success: boolean }>;
  getEbookDownload: (ebookId: string) => Promise<{ success: boolean; url: string }>;
  manageCache: (op: string, params?: any) => () => Promise<any>;
  getCourses: (companyId: string) => Promise<void>;
  updateCourse: (
    name: string,
    withCertificate: boolean,
    visibleForAso: boolean,
    description: string[],
    visible: boolean,
    order: number,
    maxErrors: number,
    companyId?: string,
    id?: string
  ) => Promise<boolean>;
  requestCourseContent: (id: string) => Promise<DocumentData>;
  queryGoogleDrive: (
    op: 'LISTALL' | 'TOS3',
    options?: Partial<{
      pageToken: string;
      folder: string;
      fileId: string;
      fileName: string;
    }>
  ) => Promise<any>;
  queryHubspot: (op: 'BLOG_PAGE' | 'BLOG_ARTICLE' | 'BLOG_TAGS', options?: Partial<{ after: string; id: string }>) => Promise<any>;
  requestTicketRating: (ticketId: string, ticketTitle: string, authorId: string) => Promise<boolean>;
  getUserNotifications: (page: number) => Promise<DocumentData[]>;
  sendVideoNotifications: (notificationId: string) => Promise<void>;
  sendNotification: (uids: string[], title: string, body: string, dryRun: boolean) => Promise<any>;
  getStripeDashboard: () => Promise<any>;
  submitReservation: (event: string, body: any) => Promise<any>;
  earlyRenew: () => Promise<any>;
  buyShop: (priceId: string) => Promise<any>;
  getStreamingData: (channelId?: string) => Promise<any>;
  getCompanies: () => Promise<DocumentData[]>;
  getCompanyUsers: (companyId: string, page: number, filter?: string) => Promise<DocumentData[]>;
  updateCompany: (
    {
      enabled,
      companyData,
      users,
      userToDelete,
      deleteCompany,
    }: {
      enabled?: boolean;
      companyData?: GenericObject;
      users?: string[];
      userToDelete?: string;
      deleteCompany?: boolean;
    },
    companyId?: string
  ) => Promise<{
    duplicated: string[];
    nonExistent: string[];
    occupied: { email: string; companyName: string }[];
    created: { email: string; uid: string; password: string }[];
    deleteErrors: any[];
  }>;

  setTrack: any;
  setSocketFire: any;

  getLegal: (doc: 'privacy' | 'cookie' | 'tos' | 'bal' | 'contacts', year?: number) => Promise<{ content: string }>;
  getActyFaq: () => Promise<{ content: string }>;

  // remoteConfig: firebase.remoteConfig.RemoteConfig;
  storage: firebase.storage.Reference;
  videoDataStorage: firebase.storage.Reference;
  database: firebase.firestore.Firestore;
  realtimeDatabase: firebase.database.Database;
  analytics: firebase.analytics.Analytics;

  fv: typeof firebase.firestore.FieldValue;
  timestamp: typeof firebase.firestore.Timestamp;

  deviceFcmToken: string;
}

export const useFirebase = () => {
  return useContext<IFirebaseContext>(FirebaseContext);
};

export const FirebaseProvider = ({ children }) => {
  const { isNative } = useCapacitor();
  const { currentLanguage, supportedLanguages, updateLanguage } = useCurrentLanguage();
  const { country_code } = useGeolocation();
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  const [currentUser, setCurrentUser] = useSession<User>('user', null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [userData, setUserData] = useState<DocumentSnapshot>(null);
  const [userCompanyData, setUserCompanyData] = useState<any>(null);
  const [userDataFmt, setUserDataFmt] = useState<any>(null);

  const [plans, setPlans] = useState<any>([]);
  const [promoCodes, setPromoCodes] = useState<any>([]);
  const [subscription, setSubscription] = useState<any>(null);
  const [globalSecondsIncrementor, setGlobalSecondsIncrementor] = useState<() => void>(null);

  const [isTrial, setIsTrial] = useState(undefined);
  const [trialTimeLeft, setTrialTimeLeft] = useState<number>(null);
  const [isTrialExpired, setIsTrialExpired] = useState(false);

  const [staticData, setStaticData] = useState<DocumentData>({});
  const [signupData, setSignupData] = useState<DocumentData>({});

  const [actyTutors, setActyTutors] = useState([]);

  const [tags, setTags] = useState<DocumentData[]>([]);
  const [authors, setAuthors] = useState<DocumentData[]>([]);
  const [videos, setVideos] = useState<DocumentData[]>([]);
  const [comingSoonVideos, setComingSoonVideos] = useState<DocumentData[]>([]);
  const [mostViewsVideos, setMostViewsVideos] = useState<DocumentData[]>([]);
  const [freeVideos, setFreeVideos] = useState<DocumentData[]>([]);
  const [keepWatching, setKeepWatching] = useState<DocumentData[]>([]);
  const [newVideos, setNewVideos] = useState<DocumentData[]>([]);
  const [billboard, setBillboard] = useState<DocumentData>(null);
  const [series, setSeries] = useState<DocumentData[]>([]);
  const [seriesAuthors, setSeriesAuthors] = useState<{ author: DocumentData; authorVideos: DocumentData[] }[]>([]);
  const [seriesTags, setSeriesTags] = useState<{ tag: DocumentData; tagVideos: DocumentData[] }[]>([]);
  const [courses, setCourses] = useState<DocumentData[]>();
  const [recommendations, setRecommendations] = useState<DocumentData[]>([]);
  const [bonusVideos, setBonusVideos] = useState<DocumentData[]>([]);

  const [tagCards, setTagCards] = useState<{ id: string; label: string }[]>([]);
  const [myList, setMyList] = useState<DocumentData[]>(null);
  const [myListLoading, setMyListLoading] = useState(true);

  const [deviceFcmToken, setDeviceFcmToken] = useState<string>(null);
  const previousFcmToken = useRef<string>(null);

  const [notifications, setNotifications] = useState([]);
  const [notificationBadge, setNotificationBadge] = useState(0);

  const [deviceId, setDeviceId] = useState<string>(null);

  const [track, setTrack] = useState<any>(null);
  const [socket, setSocketFire] = useState<any>(null);
  const [formLoading, setFormLoading] = useState(false);
  const [formData, setFormData] = useReducer(formReducer, {
    nome: '',
    cognome: '',
    indirizzo: '',
    indirizzo2: '',
    cap: '',
    citta: '',
    provincia: '',
    email: '',
    telefono: '',
  });

  const refreshTokenInterval = useRef<number>(null);

  const _ = (...segments: string[]) => segments.join('/');

  const fv = fieldValue;

  const _getOrigin = () => {
    const origin = window.location.origin;
    if (origin.includes('//localhost') && process.env.NODE_ENV == 'production') return 'https://tootor.it';
    return origin;
  };

  const checkEmail = (email: string) => {
    return auth.fetchSignInMethodsForEmail(email);
  };

  const sendEmailVerification = (path = '/') => {
    return currentUser.sendEmailVerification({ url: _getOrigin() + path });
  };

  const recoverPassword = (email: string) => {
    return auth.sendPasswordResetEmail(email, { url: _getOrigin() });
  };

  const changePassword = (password: string) => {
    return currentUser.updatePassword(password);
  };

  const reauthenticate = (password: string) => {
    const credential = emailAuthProvider(currentUser.email, password);
    return currentUser.reauthenticateWithCredential(credential);
  };

  const reauthenticateGoogle = () => {
    return currentUser.reauthenticateWithPopup(googleAuthProvider);
  };

  const signup = (email: string, password: string) => {
    return auth.createUserWithEmailAndPassword(email, password);
  };

  const login = (email: string, password: string) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  const loginWithProvider = (providerId: 'apple.com' | 'google.com', authProvider: firebase.auth.AuthProvider) => {
    return (() => {
      if (isNative) return cfaSignIn(providerId).toPromise();
      return auth.signInWithPopup(authProvider);
    })();
    // .then((result) => {
    //   const credentials = isNative ? result.userCredential : result.credential;
    //   return currentUser.linkWithCredential(credentials);
    // })
    // .then((linkResult) => {
    //   return auth.signInWithCredential(linkResult.credentials);
    // });
  };

  const loginWithGoogle = () => {
    return loginWithProvider('google.com', googleAuthProvider);
  };

  const loginWithApple = () => {
    return loginWithProvider('apple.com', appleAuthProvider);
  };

  const loginWithToken = (token: string) => {
    return auth.signInWithCustomToken(token);
  };

  const logout = async () => {
    // try {
    //   await messaging?.deleteToken();
    // } catch {}
    await clearWatchingInfo(currentUser.uid, realtimeDatabase);
    localStorage.removeItem('currentLanguage');
    document.cookie = `AUTO=;expires=${new Date(0).toUTCString()}`;
    setDeviceFcmToken(null);
    return (() => {
      if (isNative) return cfaSignOut().toPromise();
      return auth.signOut();
    })();
  };

  const logoutAll = async () => {
    await realtimeDatabase.ref(`users/${currentUser.uid}/onDisconnect`).update({ value: firebase.database.ServerValue.TIMESTAMP });
    // await messaging?.deleteToken();
    setDeviceFcmToken(null);
    await fn(CloudFunctions.LogoutAll);
  };

  const deleteAccount = async () => {
    // await messaging?.deleteToken();
    setDeviceFcmToken(null);
    return currentUser.delete();
  };

  const resetPassword = (email: string) => {
    return auth.sendPasswordResetEmail(email);
  };

  const updateEmail = (email: string) => {
    return currentUser.updateEmail(email);
  };

  const updatePassword = (password: string) => {
    return currentUser.updatePassword(password);
  };

  const mfaGetSession = () => {
    return currentUser.multiFactor.getSession();
  };

  const mfaEnroll = (assertion: firebase.auth.MultiFactorAssertion) => {
    return currentUser.multiFactor.enroll(assertion);
  };

  const mfaUnenroll = (factorId: string) => {
    return currentUser.multiFactor.unenroll(factorId);
  };

  const getTvLoginCode = async () => {
    return fn(CloudFunctions.GetTvLoginCode);
  };

  const consumeTvLoginCode = async (loginCode: string) => {
    return fn(CloudFunctions.ConsumeTvLoginCode, { loginCode });
  };

  const clearTvLoginCode = async (loginCode: string) => {
    return fn(CloudFunctions.ClearTvLoginCode, { loginCode });
  };

  const listenForTvLoginToken = (code: string) => {
    return new Promise<string>((resolve) => {
      const unsubscribe = database.doc(_('login_codes', code)).onSnapshot((snap) => {
        const { loginToken } = snap.data();
        if (!loginToken) return;
        unsubscribe();
        resolve(loginToken);
        clearTvLoginCode(code).catch(console.error);
      });
    });
  };

  const getDeviceIdentifier = async () => {
    if (deviceId) return deviceId;
    const id = await calculateDeviceIdentifier();
    setDeviceId(id);
    return id;
  };

  const checkDeviceRegistrationAbility = async (_deviceId: string) => {
    if (!subscription) return { deviceId: _deviceId, canRegister: true };
    if (userDataFmt?.admin) return { deviceId: _deviceId, canRegister: true };
    const maximumNumberOfDevices = userDataFmt?.customDevices || +subscription.items[0].price?.plan?.metadata?.DEVICES || 2;
    const devicesCollection = await userData?.ref.collection('devices').get();
    if (devicesCollection.docs.map((doc) => doc.id).includes(_deviceId)) return { deviceId: _deviceId, canRegister: true, existing: true };
    const currentAmountOfDevices = devicesCollection.docs.length;
    return { deviceId: _deviceId, canRegister: currentAmountOfDevices < maximumNumberOfDevices };
  };

  const registerDevice = async ({ deviceId, ...deviceData }, existing = false) => {
    if (existing) {
      await userData?.ref.collection('devices').doc(deviceId).update({ ts: fieldValue.serverTimestamp() });
    } else {
      await userData?.ref
        .collection('devices')
        .doc(deviceId)
        .set({ deviceId, ...deviceData, ts: fieldValue.serverTimestamp(), lockedUntil: timestamp.fromDate(startOfDay(addDays(new Date(), 30))) });
    }
    return deviceId;
  };

  const removeDevice = (deviceId: string) => {
    return userData?.ref.collection('devices').doc(deviceId).delete();
  };

  const getRegisteredDevices = async () => {
    const collection = await userData?.ref.collection('devices').get();
    if (!collection) return [];
    return collection.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  };

  const addFcmToken = (token: string) => {
    return userData?.ref.collection('fcm').doc(token).set({ created: fieldValue.serverTimestamp() });
  };

  const removeFcmToken = (token: string) => {
    return userData?.ref.collection('fcm').doc(token).delete();
  };

  const addToMyList = async (id: string) => {
    setMyListLoading(true);
    await userData?.ref.update({ list: fieldValue.arrayUnion(id) });
    if (currentUser) await getUpdatedUserData();
  };

  const removeFromMyList = async (id: string) => {
    setMyListLoading(true);
    await userData?.ref.update({ list: fieldValue.arrayRemove(id) });
    if (currentUser) await getUpdatedUserData();
  };

  const updateMyList = async (inputList?: any[]) => {
    if (inputList && videos && videos.length) {
      const list = videos.filter((video) => inputList.includes(video.id));
      setMyList(list);
      setMyListLoading(false);
    } else if (!inputList && !videos && !videos.length) {
      return setMyList(null);
    }
  };

  const getSeries = (seriesId: string) => {
    return series.find((serie) => serie.id == seriesId);
  };

  const getCourse = async (courseId: string) => {
    return fn(CloudFunctions.GetCourse, { courseId });
  };

  const getTest = async (courseId: string, id: string) => {
    const course = await getCourse(courseId);
    if (!course) return null;
    return course.tests.find((test) => test.id == id);
  };

  const validateTest = async (data: any) => {
    return fn(CloudFunctions.ValidateTest, data);
  };

  const addCheckoutSession = async (price: string, promotion_code: string = null) => {
    const subscription: any = {
      price,
      locale: currentLanguage,
      success_url: _getOrigin() + '/success?sid={CHECKOUT_SESSION_ID}',
      cancel_url: _getOrigin() + (window.location.pathname.endsWith('wait') ? '/' : window.location.pathname) + window.location.search,
      allow_promotion_codes: true,
      collect_shipping_address: true,
      tax_id_collection: isItalian(country_code),
    };

    if (promotion_code) subscription.promotion_code = promotion_code;

    const ref = await database.collection(_('userData', currentUser.uid, 'checkout_sessions')).add(subscription);
    return new Promise<{ sessionId: string; url: string }>((resolve, reject) => {
      const unsubscribe = ref.onSnapshot((snap) => {
        const { error, sessionId, url } = snap.data();
        if (!error && (!sessionId || !url)) return;
        unsubscribe();
        if (error) reject(error);
        resolve({ sessionId, url });
      });
    });
  };

  const getCustomerPortalUrl = async () => {
    return fn(CloudFunctions.CreatePortalLink, { returnUrl: _getOrigin() + '/profile' });
  };

  const updateUserData = async (data?: DocumentData, options: { skipListUpdate?: boolean } = {}) => {
    try {
      if (data) {
        await userData.ref.set({ ...data, updatedAt: fieldValue.serverTimestamp() }, { merge: true });
      }
    } catch (e) {
      console.error(e);
    }
    await getUpdatedUserData(options);
  };

  const getVideoShardedCounterIncrementor = (videoId: string): (() => Promise<void>) => {
    const videoAnalyticsDoc = database.collection('userData').doc(currentUser.uid + '/videos/' + videoId);
    const videoSecondsViews = new sharded.Counter(videoAnalyticsDoc, 'views');
    return () => videoSecondsViews.incrementBy(1);
  };

  const getVideoOpeningsShardedCounterIncrementor = (videoId: string): (() => Promise<void>) => {
    const videoAnalyticsDoc = database.collection('userData').doc(currentUser.uid + '/videos/' + videoId);
    const videoOpenings = new sharded.Counter(videoAnalyticsDoc, 'openings');
    return () => videoOpenings.incrementBy(1);
  };

  const updateUserVideoAnalytics = async (videoId: string, data: DocumentData) => {
    try {
      const videoAnalyticsDoc = database.doc(_('userData', currentUser.uid, 'videos', videoId));
      await videoAnalyticsDoc.set(data, { merge: true });
    } catch {}
    // await getUpdatedUserData();
  };

  const addNotifications = (items: any[]) => {
    setNotifications((previousNotifications) => [
      ...items.filter((i) => {
        const id = i.data?.id;
        if (!id) return true;
        return !previousNotifications.find((f) => {
          const prevId = f.data?.id;
          if (!prevId) return false;
          return prevId == id;
        });
      }),
      ...previousNotifications,
    ]);
  };

  const readNotification = (id: number) => {
    setNotifications((previousNotifications) =>
      previousNotifications.map((notification) => {
        if (notification.data.id != id) return notification;
        return { ...notification, read: true };
      })
    );
  };

  const cleanNotifications = () => {
    setNotifications((previousNotifications) => previousNotifications.filter((n) => !n.read));
  };

  // const prevNotifications = new Set<string>();
  // useEffect(() => {
  //   const unsubscribe = messaging?.onMessage((event) => {
  //     const { notification, data, ...rest } = event;
  //     console.log(event);
  //     if (prevNotifications.has(data.id)) return;
  //     prevNotifications.add(data.id);
  //     addNotifications([{ ...notification, data, read: false }]);
  //     toast(
  //       <div
  //         className={'d-flex align-items-center'}
  //         onClick={() => {
  //           if (!notification.read) {
  //             const id = notification.id ?? notification.data?.id;
  //             readNotification(id);
  //           }
  //           const action = notification.data?.action;
  //           if (!action) return;
  //           if (action.startsWith('goto::')) {
  //             const [, path] = action.split('::');
  //             history.push(path);
  //           }
  //         }}
  //       >
  //         <FontAwesomeIcon icon={faBell} className={'mr-3'} />
  //         <div>
  //           <div>{notification.title}</div>
  //           <div className={'text-muted'}>{notification.body}</div>
  //         </div>
  //       </div>
  //     );
  //   });
  //   return () => unsubscribe?.();
  // }, [track, socket]);

  useEffect(() => {
    getStaticData().catch(console.error);

    database
      .collection('plans')
      .where('active', '==', true)
      .where('metadata.PLAN', '==', 'yes')
      .get()
      .then(async (querySnapshot) => {
        const plans = [];
        for (const planDoc of querySnapshot.docs) {
          const plan: { [p: string]: any } = { id: planDoc.id, ...planDoc.data(), prices: [] };
          const priceSnap = await planDoc.ref.collection('prices').get();
          for (const priceDoc of priceSnap.docs) {
            const price: { [p: string]: any } = { id: priceDoc.id, ...priceDoc.data() };
            if (price.active) plan.prices.push(price);
          }
          plan.description = plan.metadata?.[`i18n.${currentLanguage}.description`] || plan.description;
          plan.name = plan.metadata?.[`i18n.${currentLanguage}.name`] || plan.name;
          plans.push(plan);
        }
        setPlans(plans);
      })
      .catch(console.error);

    fn(CloudFunctions.GetStripeCoupons).then((data) => setPromoCodes(data));
  }, []);

  useEffect(() => {
    const unread = notifications.filter((n) => !n.read).length;
    setNotificationBadge(unread);
  }, [notifications]);

  useEffect(() => {
    if (deviceFcmToken) addFcmToken(deviceFcmToken)?.catch(console.error);
    else if (previousFcmToken.current) removeFcmToken(previousFcmToken.current)?.catch(console.error);
  }, [deviceFcmToken, userData]);

  useEffect(() => {
    previousFcmToken.current = deviceFcmToken;
  }, [deviceFcmToken, userData]);

  useEffect(() => {
    if (currentUser) getUpdatedUserData().catch(console.error);
  }, [videos]);

  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      console.info({ user: JSON.parse(JSON.stringify(user)) });
      setCurrentUser(user);
      setIsLoggedIn(!!user);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (currentUser) {
      getUserCompanyData().catch(console.error);
    }
  }, [currentUser, userDataFmt, userData]);

  const getUserCompanyData = async () => {
    if (currentUser) {
      const userData = await database.doc(_('userData', currentUser.uid)).get();
      if (userData.data().company) {
        const companyDoc = await userData.data().company.get();
        if (companyDoc.exists) {
          const companyData = companyDoc.data();
          const data = { id: companyDoc.id, ...companyData };
          if (companyData.logo) {
            const logo = videoDataStorage.child(`companies_data/${companyDoc.id}/${companyData.logo}`);
            try {
              data.logoUrl = await logo.getDownloadURL();
            } catch (_) {}
          }
          setUserCompanyData(data);
          return;
        }
      }
    }
    setUserCompanyData(null);
  };

  useEffect(() => {
    let disconnect: firebase.database.OnDisconnect;

    const onConversationsAPIReady = () => {
      console.log('Loading HubSpot Chat...');
      (window as any).HubSpotConversations.resetAndReloadWidget();
      (window as any).HubSpotConversations.widget.load();
    };

    if (!currentUser) {
      disconnect?.cancel();
      realtimeDatabase?.goOffline();

      setUserData(null);
      setUserDataFmt(null);
      setSubscription(null);
      setGlobalSecondsIncrementor(null);
      setIsTrial(null);
      setIsTrialExpired(false);
      setTrialTimeLeft(null);
      setDeviceFcmToken(null);
      setDeviceId(null);
      Sentry.configureScope((scope) => scope.setUser(null));
      (window as any).hsConversationsSettings = { loadImmediately: true };
      if ((window as any).HubSpotConversations) onConversationsAPIReady();
      else (window as any).hsConversationsOnReady = [onConversationsAPIReady];
    } else {
      if (!currentUser.toJSON) return;

      (window as any).hsConversationsSettings = { ...((window as any).hsConversationsSettings || {}), loadImmediately: false };
      fn(CloudFunctions.GetHubspotIdentificationToken).then(({ token }) => {
        if (token) {
          (window as any).hsConversationsSettings = {
            ...(window as any).hsConversationsSettings,
            identificationEmail: currentUser.email,
            identificationToken: token,
          };
        }
        if ((window as any).HubSpotConversations) onConversationsAPIReady();
        else (window as any).hsConversationsOnReady = [onConversationsAPIReady];
      });

      Sentry.setUser({ id: currentUser.uid, email: currentUser.email });
      analytics.setUserId(currentUser.uid);
      try {
        (window as any).aa('setUserToken', currentUser.uid);
      } catch (e) {
        console.error(e);
      }

      realtimeDatabase.goOnline();
      (async () => {
        const clientName = `client_${await calculateDeviceIdentifier()}`;
        const rtdbUserRecord = realtimeDatabase.ref(`users/${currentUser.uid}/${clientName}`);
        const rtdbOnDisconnectRecord = realtimeDatabase.ref(`users/${currentUser.uid}/onDisconnect`);
        const PresenceDatabase = realtimeDatabase.ref('.info/connected');
        let onDisconnectTimestampState = false;

        rtdbUserRecord.on('value', (_) => {
          // callback esistente solo per mantenere attiva la connessione al RTDB,
          // altrimenti Android creerebbe problemi
        });
        rtdbOnDisconnectRecord.on('value', async (snapshot) => {
          if (!snapshot.val() || !onDisconnectTimestampState) {
            onDisconnectTimestampState = true;
            return;
          }
          setIsLoggedIn(false);
          await logout();
          window.location.href = '/';
        });
        PresenceDatabase.on('value', async (snapshot) => {
          if (!snapshot.val()) return;
          disconnect = rtdbUserRecord.onDisconnect();
          disconnect
            .update({
              online: firebase.database.ServerValue.increment(-1),
            })
            .catch(console.error);

          rtdbUserRecord
            .update({
              online: firebase.database.ServerValue.increment(1),
            })
            .catch(console.error);
        });
      })();
      // https://stackoverflow.com/a/56933697
      document.addEventListener('visibilitychange', () => {
        const isVisible = document.visibilityState == 'visible';
        if (isVisible) {
          if (refreshTokenInterval.current) {
            clearInterval(refreshTokenInterval.current);
            refreshTokenInterval.current = null;
          }
        } else {
          currentUser.getIdToken(true).catch(console.error);
          refreshTokenInterval.current = setInterval(() => {
            currentUser.getIdToken(true).catch(console.error);
          }, MINUTES(10)) as unknown as number;
        }
      });

      // if (messaging) messaging.getToken().then(setDeviceFcmToken).catch(console.error);

      getUpdatedUserData().then((userData) => {
        getStaticData().then((staticData) => {
          const trialFullTime = userData.customTrialSeconds || staticData.DEFAULT_TRIAL_SECONDS;
          const trialExtension = userData.trialExtension || 0;

          const totalViews = new sharded.Counter(database.doc(_('userData', currentUser.uid)), 'views');
          setGlobalSecondsIncrementor(() => () => totalViews.incrementBy(1));

          if (userData.preferredLanguage && userData.preferredLanguage != currentLanguage) {
            if (supportedLanguages.includes(userData.preferredLanguage)) {
              return updateLanguage(userData.preferredLanguage);
            }
          }

          database
            .collection(_('userData', currentUser.uid, 'subscriptions'))
            .where('status', 'in', [/*'trialing',*/ 'active'])
            .get()
            .then(async (snapshot) => {
              const doc = snapshot.docs[0];
              if (doc) {
                setIsTrial(false);
                setSubscription({ id: doc.id, ...doc.data() });
              } else {
                setIsTrial(true);
                totalViews.onSnapshot((snap) => {
                  const secondsElapsed = snap.data() || 0;
                  const trialSecondsLeft = trialFullTime + trialExtension - secondsElapsed;
                  setTrialTimeLeft(trialSecondsLeft);
                  setIsTrialExpired(trialSecondsLeft < 0 && !userData.isNewTrial);
                });
              }
            });

          fn(CloudFunctions.GetPreVideoData, { language: currentLanguage }).then((data) => {
            const { tags, authors } = data;
            setTags(tags);
            setAuthors(authors);

            if (userData.experiments?.[Experiments.InfiniteScroll]) {
              Promise.all([
                fn(CloudFunctions.GetBillboard, { language: currentLanguage }).then((data) => {
                  const { billboard } = data;
                  setBillboard(billboard);
                }),
                fn(CloudFunctions.GetComingSoon).then((data) => {
                  const { comingSoon = [] } = data;
                  setComingSoonVideos(comingSoon);
                }),
                fn(CloudFunctions.GetMostViews, { language: currentLanguage }).then((data) => {
                  const { mostViews = [] } = data;
                  setMostViewsVideos(mostViews);
                }),
                fn(CloudFunctions.GetKeepWatching).then((data) => {
                  const { keepWatching = [] } = data;
                  setKeepWatching(keepWatching);
                }),
                fn(CloudFunctions.GetNewVideos).then((data) => {
                  const { newVideos = [] } = data;
                  setNewVideos(newVideos);
                }),
              ]).then(() => {
                setSeriesTags(
                  tags
                    .filter((tag) => tag.showAsSeries && (!tag.visibleFor || tag.visibleFor[userData.profile?.formData?.categoriaProfessionale]))
                    .map((tag) => ({ tag, tagVideos: [] }))
                    .sort((a, b) => b.tag.order - a.tag.order)
                );
                getUpdatedUserData().catch(console.error);
              });
            } else {
              fn(CloudFunctions.GetBillboard, { language: currentLanguage }).then((data) => {
                const { billboard } = data;
                setBillboard(billboard);
              });

              Promise.all([
                fn(CloudFunctions.GetVideosNew, { language: currentLanguage, countryProv: country_code }),
                (() => {
                  if (
                    staticData.RECOMMENDATIONS_LANGUAGES.includes(currentLanguage) &&
                    (staticData.ENABLE_RECOMMENDATIONS || userData.experiments?.[Experiments.Recommendations])
                  ) {
                    try {
                      return manualCloudFunction(CloudFunctions.Recommendations, currentUser, { id_user: currentUser.uid });
                    } catch {
                      return Promise.resolve({});
                    }
                  }
                  return Promise.resolve({});
                })(),
              ]).then(async ([data, result]) => {
                // data: getVideosNew
                const { videos, comingSoon = [], series, seriesTags, mostViews = [], freeVideos = [] } = data;
                setVideos(videos);
                setSeries(series);
                setSeriesTags(seriesTags);
                setComingSoonVideos(comingSoon);
                setMostViewsVideos(mostViews);
                setFreeVideos(freeVideos);
                setBonusVideos(videos.filter((x) => x.bonus));

                const subscriptionCollection = await database
                  .collection(_('userData', currentUser.uid, 'subscriptions'))
                  .where('status', 'in', [/*'trialing',*/ 'active'])
                  .get();
                const subscription = !!subscriptionCollection.docs[0];

                const keepWatching = videos
                  .filter((video) => video.hasPercentage && !video.hidden)
                  .filter((video) => !isVideoLocked(video, userData, subscription))
                  .slice(0, 10);

                setKeepWatching(keepWatching);
                const newVideos = videos
                  .filter((video) => video.isNew && (!video.languages || video.languages.includes(currentLanguage)))
                  .sort((a, b) => (b.created?._seconds ?? 0) - (a.created?._seconds ?? 0))
                  .slice(0, 6);
                setNewVideos(newVideos);

                getUpdatedUserData().catch(console.error);

                // result: recommendations
                if (!result) return;
                if (!result.recommendations_series || !result.recommendations_videos || !result.recommendations_confidence) return;
                if (!result.recommendations_series.length || !result.recommendations_videos.length || !result.recommendations_confidence.length) return;
                const zipped = result.recommendations_videos.map((v, i) => ({
                  videoId: v,
                  seriesId: result.recommendations_series[i],
                  confidence: result.recommendations_confidence[i],
                }));
                const recs = zipped.map(addConfidence(videos, seriesTags, mostViews, series, true)).filter(Boolean);
                setRecommendations(recs);
              });
            }
          });
        });

        database
          .doc(_('system', 'signupData'))
          .get()
          .then((doc) => {
            setSignupData({ id: doc.id, ...doc.data() });
          });
      });
    }
    return () => {
      realtimeDatabase.goOffline();
    };
  }, [currentUser]);

  const setValidBonus = (bonusDocumentId: string) => {
    setBonusVideos((prev) => {
      return prev.map((video) => {
        if (video.bonus == bonusDocumentId) {
          video.isValidBonus = true;
        }
        return video;
      });
    });
  };

  useEffect(() => {
    extractTagCards(videos);
  }, [tags]);

  const getStaticData = async () => {
    if (staticData && Object.keys(staticData).length) return staticData;
    const _staticData = await fn(CloudFunctions.GetStaticData, { language: currentLanguage });
    setStaticData(_staticData);
    return _staticData;
  };

  const queryVideosByString = async (query: string) => {
    const start = Date.now();
    const data = await fn(CloudFunctions.QueryVideos, { query, useReducedParse: true, language: currentLanguage, countryProv: country_code });
    return { data, time: Date.now() - start };
  };

  const extractTagCards = (videos: any[]) => {
    const countedTags: { [key: string]: number } = videos.reduce((counter, current) => {
      const videoTags = current.tags ?? [];
      for (const { id } of videoTags) {
        if (!counter[id]) counter[id] = 0;
        counter[id]++;
      }
      return counter;
    }, {});

    const tagCards = stableSort(Object.entries(countedTags), ([, a], [, b]) => b - a)
      .map(([tagId]) => ({ id: tagId, label: tags[tagId] }))
      .filter((tag) => tag.label)
      .slice(0, 5);

    setTagCards(tagCards);
  };

  const getUpdatedUserData = async (options: { skipListUpdate?: boolean } = {}) => {
    const doc = await database.doc(_('userData', currentUser.uid)).get();
    setUserData(doc);
    const videoDataCollection = await database.collection(_('userData', currentUser.uid, 'videos')).get();
    const videoData = videoDataCollection.docs.map((videoDoc) => ({ id: videoDoc.id, ...videoDoc.data() }));
    const fmt: any = { id: doc.id, ...doc.data(), videoData };
    setUserDataFmt(fmt);
    if (!options.skipListUpdate) await updateMyList(fmt?.list || []);
    return fmt;
  };

  const isVideoLocked = (video, userData?, sub?) => {
    return !(sub != null ? sub : subscription) && !video.free && (userData || userDataFmt).isNewTrial;
  };

  const requestImpersonationToken = async (uid: string, sender: string) => {
    return fn(CloudFunctions.RequestImpersonationToken, { uid, sender });
  };

  const getVideosForTag = async (tagId: string) => {
    return fn(CloudFunctions.GetVideosForTag, { tagId, language: currentLanguage });
  };

  const requestVideoData = async (videoId: string, drmType: string) => {
    return fn(CloudFunctions.GetVideo, { videoId, drmType, language: currentLanguage, countryProv: country_code });
  };

  const updateHubspotProperties = async (properties: { [p: string]: any }) => {
    return fn(CloudFunctions.UpdateHubspotProperties, properties);
  };

  const getTickets = async (asAdmin = false) => {
    return fn(CloudFunctions.GetTickets, { asAdmin });
  };

  const getTicket = async (ticketId, asAdmin = false) => {
    return fn(CloudFunctions.GetTicket, { ticketId, asAdmin });
  };

  const submitTicket = async (data) => {
    return fn(CloudFunctions.SubmitTicket, data);
  };

  const getTicketStatuses = async () => {
    return fn(CloudFunctions.GetTicketStatuses);
  };

  const rateTicket = async (ticketId, rating = null) => {
    return fn(CloudFunctions.RateTicket, { ticketId, rating });
  };

  const assignTicket = async (ticketId, assignee = null) => {
    return fn(CloudFunctions.AssignTicket, { ticketId, assignee });
  };

  const getUserData = async ({ uid, email, alias }: { email?: string; uid?: string; alias?: any }) => {
    return fn(CloudFunctions.GetUserData, { uid, email, alias });
  };

  const getUserCourse = async (uid: string, cid: string, action: string, payload?: any) => {
    return fn(CloudFunctions.GetUserCourse, { uid, courseId: cid, action, payload });
  };

  const getStreamingData = async (channelId: string = null) => {
    return fn(CloudFunctions.GetStreamingData, { channelId }, { useExponentialBackoff: false });
  };

  const updateUser = async (uid: string, updater: { [p: string]: any }) => {
    return fn(CloudFunctions.UpdateUser, { uid, updater });
  };

  const getPublishData = async () => {
    return fn(CloudFunctions.GetPublishData);
  };

  const getContent = async () => {
    return fn(CloudFunctions.GetContent);
  };

  const requestVideoContent = async (id: string) => {
    return fn(CloudFunctions.RequestVideoContent, { id });
  };

  const getStats = async () => {
    return fn(CloudFunctions.GetStats, { language: currentLanguage });
  };

  const getLogs = async (offset = 0, elementId?: string) => {
    return fn(CloudFunctions.GetLogs, { offset, elementId });
  };

  const upsertHubspotProduct = async (id: string, objectType: 'video' | 'tag') => {
    return fn(CloudFunctions.UpsertHubspotProduct, { id, objectType });
  };

  const getLegal = async (doc: 'privacy' | 'cookie' | 'tos' | 'bal' | 'contacts', year?: number) => {
    const legal = await fn(CloudFunctions.GetLegal, { doc, y: year, language: currentLanguage });
    return legal;
  };

  const getActyFaq = async () => {
    const faq = await fn(CloudFunctions.GetActyFaq, { language: currentLanguage });
    return faq;
  };

  const getCompanies = async () => {
    return fn(CloudFunctions.GetCompanies);
  };

  const getCompanyUsers = async (companyId: string, page: number, filter?: string) => {
    return fn(CloudFunctions.GetCompanyUsers, { companyId, page, filter });
  };

  const updateCompany = async (
    {
      enabled,
      companyData,
      users,
      userToDelete,
      deleteCompany,
    }: {
      enabled?: boolean;
      companyData?: GenericObject;
      users?: string[];
      userToDelete?: string;
      deleteCompany?: boolean;
    },
    companyId?: string
  ): Promise<{
    duplicated: string[];
    nonExistent: string[];
    occupied: { email: string; companyName: string }[];
    created: { email: string; uid: string; password: string }[];
    deleteErrors: any[];
  }> => {
    return fn(CloudFunctions.UpdateCompany, { companyId, enabled, companyData, usersToAdd: users, userToRemove: userToDelete });
  };

  const getVideoReports = async () => {
    return fn(CloudFunctions.GetVideoReports, { language: currentLanguage });
  };

  const getActyTutorCategories = async (): Promise<DocumentData[]> => {
    return fn(CloudFunctions.GetActyTutorCategories, { language: currentLanguage });
  };

  const getActyTutors = async (actyStartDate: Date, actyCategoryId: string): Promise<any[]> => {
    return fn(CloudFunctions.GetActyTutors, { actyStartDate: actyStartDate, actyCategoryId: actyCategoryId, language: currentLanguage });
  };

  const getActyAppointments = async (asAdmin = false, page = 1, sorting = { direction: 'desc', field: 'timestamp' }, filters: any, exportCsv: boolean) => {
    return fn(CloudFunctions.GetActyAppointments, { language: currentLanguage, asAdmin, page, sorting, filters, exportCsv });
  };

  const getActyAppointment = async (appointmentId: string) => {
    return fn(CloudFunctions.GetActyAppointment, { appointmentId: appointmentId, language: currentLanguage });
  };

  const getActyReservationUrl = (actyTutorId: string, actyCategoryId: string) => {
    return fn(CloudFunctions.GetActyReservationUrl, { actyTutorId, actyCategoryId });
  };

  const getFeedbacks = async () => {
    return fn(CloudFunctions.GetFeedbacks);
  };

  const getStripeOneOffs = async () => {
    return fn(CloudFunctions.GetStripeOneOffs, { countryProv: country_code });
  };

  const getCheckoutDetails = async (id: string) => {
    return fn(CloudFunctions.GetCheckoutDetails, { id });
  };

  const createOneOffSession = async (data: any) => {
    return fn(CloudFunctions.CreateOneOffSession, { data, language: currentLanguage });
  };

  const updateCustomerSubscription = async (priceId?: string, upgradeInterval?: boolean, finalize?: string, cancel?: string) => {
    return fn(CloudFunctions.UpdateCustomerSubscription, { priceId, upgradeInterval, finalize, cancel });
  };

  const getCustomerData = async () => {
    return fn(CloudFunctions.GetCustomerData);
  };

  const requestCollaboration = async (request: any) => {
    return fn(CloudFunctions.RequestCollaboration, request);
  };

  const getAdventCalendar = async () => {
    return fn(CloudFunctions.GetAdventCalendar);
  };

  const unlockAdventDay = async (day, year) => {
    return fn(CloudFunctions.UnlockAdventDay, { day, year });
  };

  const notifyAdventData = async (day, year, data?) => {
    return fn(CloudFunctions.NotifyAdventData, { day, year, data });
  };

  const trackConversion = async (event, params = {}) => {
    return fn(CloudFunctions.TrackConversion, { event, params });
  };

  const getExperiments = async () => {
    return fn(CloudFunctions.GetExperiments);
  };

  const getAwsGuid = async (guid) => {
    return fn(CloudFunctions.GetAwsGuid, { guid });
  };

  const getEbooks = async (page, isMobile) => {
    return fn(CloudFunctions.GetEbooks, { page, isMobile, language: currentLanguage });
  };

  const getNextQuestion = async (questionId?: string, answerId?: string) => {
    return fn(CloudFunctions.GetNextQuestion, { questionId, answerId });
  };

  const unlockEbook = async (ebookId, value) => {
    return fn(CloudFunctions.UnlockEbook, { ebookId, value });
  };

  const getEbookDownload = async (ebookId) => {
    return fn(CloudFunctions.GetEbookDownload, { ebookId });
  };

  const manageCache = (op: string, params?: string) => {
    return async () => {
      const start = Date.now();
      const data = await fn(CloudFunctions.ManageCache, { op, params });
      return { data, time: Date.now() - start };
    };
  };

  const getCourses = (companyId = '') => {
    return fn(CloudFunctions.GetCourses, { countryProv: country_code, onlyCompanyCourse: !!companyId, companyId: companyId }).then((data) => {
      const { courses } = data;
      setCourses(courses);
    });
  };

  const updateCourse = (
    name: string,
    withCertificate: boolean,
    visibleForAso: boolean,
    description: string[],
    visible: boolean,
    order: number,
    maxErrors: number,
    companyId?: string,
    id?: string
  ) => {
    return fn(CloudFunctions.UpdateCourse, { name, withCertificate, visibleForAso, description, visible, order, maxErrors, companyId, id });
  };

  const requestCourseContent = async (id: string) => {
    return fn(CloudFunctions.RequestCourseContent, { id });
  };

  const queryGoogleDrive = (
    op: 'LISTALL' | 'TOS3',
    options: Partial<{
      pageToken: string;
      folder: string;
      fileId: string;
      fileName: string;
    }> = {}
  ) => {
    return fn(CloudFunctions.GoogleDrive, { op, options }, { useExponentialBackoff: op != 'TOS3' });
  };

  const queryHubspot = (op: 'BLOG_PAGE' | 'BLOG_ARTICLE' | 'BLOG_TAGS', options: Partial<{ after: string; id: string }> = {}) => {
    return fn(CloudFunctions.HubspotWrapper, { op, options });
  };

  const requestTicketRating = (ticketId: string, ticketTitle: string, authorId: string) => {
    return fn(CloudFunctions.RequestTicketRating, { ticketId, ticketTitle, authorId });
  };

  const getUserNotifications = (page: number) => {
    return fn(CloudFunctions.GetUserNotifications, { page });
  };

  const sendVideoNotifications = (notificationId: string) => {
    return fn(CloudFunctions.SendVideoNotifications, { notificationId });
  };

  const sendNotification = (uids: string[], title: string, body: string, dryRun = false) => {
    return fn(CloudFunctions.SendNotification, { uids, title, body, dryRun });
  };

  const getStripeDashboard = () => {
    return fn(CloudFunctions.GetStripeDashboard);
  };

  const submitReservation = (event: string, body: any) => {
    return fn(CloudFunctions.Reservation, { event, body });
  };

  const earlyRenew = () => {
    return fn(CloudFunctions.HandleCredit, { countryProv: country_code, language: currentLanguage });
  };

  const buyShop = (priceId: string) => {
    return fn(CloudFunctions.BuyShop, { countryProv: country_code, language: currentLanguage, priceId });
  };

  const value: IFirebaseContext = {
    currentUser,
    isLoggedIn,

    getStaticData,

    userData,
    userDataFmt,
    userCompanyData,
    updateUserData,
    getVideoShardedCounterIncrementor,
    getVideoOpeningsShardedCounterIncrementor,
    updateUserVideoAnalytics,
    updateHubspotProperties,

    plans,
    promoCodes,
    subscription,
    globalSecondsIncrementor,

    isTrial,
    trialTimeLeft,
    isTrialExpired,

    getDeviceIdentifier,
    checkDeviceRegistrationAbility,
    registerDevice,
    getRegisteredDevices,
    removeDevice,

    addCheckoutSession,
    getCustomerPortalUrl,

    staticData,
    signupData,

    notifications,
    notificationBadge,
    addNotifications,
    readNotification,
    cleanNotifications,

    getVideosForTag,
    requestVideoData,
    videos,
    comingSoonVideos,
    mostViewsVideos,
    freeVideos,
    keepWatching,
    newVideos,
    series,
    seriesAuthors,
    seriesTags,
    courses,
    billboard,
    getCourse,
    getTest,
    validateTest,
    tags,
    authors,
    getSeries,
    isVideoLocked,
    recommendations,
    bonusVideos,
    setValidBonus,

    queryVideosByString,

    tagCards,
    myList,
    addToMyList,
    removeFromMyList,
    myListLoading,

    login,
    loginWithGoogle,
    loginWithApple,
    loginWithToken,
    signup,
    logout,
    logoutAll,
    resetPassword,
    updateEmail,
    updatePassword,
    checkEmail,
    sendEmailVerification,
    recoverPassword,
    changePassword,
    reauthenticate,
    reauthenticateGoogle,
    deleteAccount,

    mfaGetSession,
    mfaEnroll,
    mfaUnenroll,

    getTvLoginCode,
    listenForTvLoginToken,
    consumeTvLoginCode,

    getTickets,
    getTicket,
    submitTicket,
    getTicketStatuses,
    rateTicket,
    assignTicket,

    getUserData,
    getUserCourse,
    updateUser,
    requestImpersonationToken,

    getPublishData,
    getContent,
    requestVideoContent,
    getStats,
    getLogs,
    upsertHubspotProduct,
    getVideoReports,
    getFeedbacks,
    getCompanies,
    getCompanyUsers,
    updateCompany,
    getStripeOneOffs,
    getCheckoutDetails,
    createOneOffSession,
    updateCustomerSubscription,
    getCustomerData,
    requestCollaboration,
    getAdventCalendar,
    unlockAdventDay,
    notifyAdventData,
    trackConversion,
    getExperiments,
    getAwsGuid,
    getEbooks,
    getNextQuestion,
    unlockEbook,
    getEbookDownload,
    manageCache,
    getCourses,
    updateCourse,
    requestCourseContent,
    queryGoogleDrive,
    queryHubspot,
    requestTicketRating,
    getUserNotifications,
    sendVideoNotifications,
    sendNotification,
    getStripeDashboard,
    submitReservation,
    earlyRenew,
    buyShop,
    getStreamingData,

    getLegal,
    getActyTutorCategories,
    getActyTutors,
    getActyAppointments,
    getActyAppointment,
    getActyReservationUrl,
    getActyFaq,

    storage,
    videoDataStorage,
    database,
    realtimeDatabase,
    analytics,

    fv,
    timestamp,

    deviceFcmToken,

    setTrack,
    setSocketFire,
  };

  return <FirebaseContext.Provider value={value}>{loading ? <FullscreenLoader /> : children}</FirebaseContext.Provider>;
};
