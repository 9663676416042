import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Title } from '../../components/Title/Title';
import { Topbar } from '../../components/Topbar/Topbar';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useEffect, useRef, useState } from 'react';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { Button, Container } from 'react-bootstrap';
import { Footer } from '../../components/Footer/Footer';

const ActyCreateReservationComponent = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { getActyReservationUrl } = useFirebase();
  const { actyTutorId, actyCategoryId } = useParams<{ actyTutorId: string; actyCategoryId: string }>();
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState('');
  const [reservation, setReservation] = useState(null);
  const { database } = useFirebase();
  const [showResult, setShowResult] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    getActyReservationUrl(actyTutorId, actyCategoryId).then((data) => {
      const { reservationId, url } = data;
      setReservation(reservationId);
      setUrl(url);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!reservation) return;

    const unsubscribe = database.doc(`acty_reservations/${reservation}`).onSnapshot((snapshot) => {
      const data = snapshot.data();

      if (data.status === 'ok' || data.status === 'ko') {
        unsubscribe();
        setShowResult(true);
        setIsSuccess(data.status === 'ok');
        //history.push('/acty/appointments');
      }
    });

    return () => unsubscribe();
  }, [reservation]);

  return loading ? (
    <FullscreenLoader fullscreen={true} />
  ) : (
    <>
      <Title>{t('acty.labels.reservationTitle')}</Title>

      <div style={{ marginBottom: '72px' }}>
        <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
          <Topbar />
        </div>
      </div>

      <Container>
        <div style={{ gap: '0.25rem' }} className={'d-flex align-items-center mb-3'}>
          <Button
            className={'btn-dark mr-2'}
            onClick={() => {
              history.push(`/acty/categories/${actyCategoryId}/speakers`);
            }}
            disabled={loading}
          >
            <FontAwesomeIcon className={'mr-2'} icon={faArrowLeft} /> {t('acty.labels.tornaSpeakersBtn')}
          </Button>
        </div>

        {!showResult && <iframe src={url} width="100%" height="650"></iframe>}

        {showResult && (
          <div
            className={'d-flex flex-column align-items-center justify-content-center m-4 w-50 mx-auto'}
            style={{ backgroundColor: '#3a3a3a', borderRadius: '10px' }}
          >
            <div
              className={'d-flex flex-column align-items-center justify-content-center m-4 '}
              style={{ width: '100px', height: '100px', borderRadius: '50%', backgroundColor: isSuccess ? '#55a856' : 'red' }}
            >
              <FontAwesomeIcon icon={isSuccess ? faCheck : faExclamation} color={'white'} size={'3x'} />
            </div>
            <div className={'m-4 text-center'}>
              <h2>{isSuccess ? t('acty.labels.reservationOk') : t('acty.labels.reservationKo')}</h2>
            </div>
            <Button
              variant={'primary'}
              onClick={() => {
                history.push(`/acty/appointments`);
              }}
              className={'m-4'}
            >
              {t('acty.labels.elencoPrenotazioni')}
            </Button>
          </div>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default ActyCreateReservationComponent;
