import { Topbar } from '../../components/Topbar/Topbar';
import { Badge, Button, Col, Container, Form, InputGroup, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { Footer } from '../../components/Footer/Footer';
import { Title } from '../../components/Title/Title';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useEffect, useMemo, useState } from 'react';
import { useCurrentLanguage } from '../../../hooks/useCurrentLanguage';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { formatSecondsTimestamp } from '../../utils/utils';
import SortableColumnHeader from './SortableColumnHeader';
import './AdminActyAppointments.scss';
import Pagination from './Pagination';
import { Copyable } from '../../components/Copyable/Copyable';
import { saveAs } from 'file-saver';
import Select from 'react-select';

const AdminActyAppointmentsComponent = () => {
  const { getActyAppointments, database } = useFirebase();
  const [loading, setLoading] = useState(true);
  const [appointments, setAppointments] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [currentAppointment, setCurrentAppointment] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentAppointmentEvents, setCurrentAppointmentEvents] = useState([]);

  const { currentLocale } = useCurrentLanguage();
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [sortField, setSortField] = useState('startAt');

  const [titleFilter, setTitleFilter] = useState('');

  const today = new Date();
  const _90DaysBefore = new Date();
  _90DaysBefore.setDate(today.getDate() - 90);

  const [startDateFrom, setStartDateFrom] = useState(_90DaysBefore.toISOString().split('T')[0]);
  const [startDateTo, setStartDateTo] = useState(today.toISOString().split('T')[0]);
  const [startTimeFrom, setStartTimeFrom] = useState('');
  const [startTimeTo, setStartTimeTo] = useState('');
  const maxStartDateTo = useMemo(() => {
    if (!startDateFrom) {
      return '';
    }

    const date = new Date(startDateFrom);
    date.setDate(date.getDate() + 90);
    return date.toISOString().split('T')[0];
  }, [startDateFrom]);

  const [endDateFrom, setEndDateFrom] = useState('');
  const [endDateTo, setEndDateTo] = useState('');
  const [endTimeFrom, setEndTimeFrom] = useState('');
  const [endTimeTo, setEndTimeTo] = useState('');

  const [isDeleted, setIsDeleted] = useState(false);
  const [deletedDateFrom, setDeletedDateFrom] = useState('');
  const [deletedDateTo, setDeletedDateTo] = useState('');
  const [deletedTimeFrom, setDeletedTimeFrom] = useState('');
  const [deletedTimeTo, setDeletedTimeTo] = useState('');

  const [delayedTitleFilter, setDelayedTitleFilter] = useState(titleFilter);
  const [timeoutId, setTimeoutId] = useState(null);

  const [tutorsList, setTutorsList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [statusesList, setStatusesList] = useState([]);

  const [selectedTutors, setSelectedTutors] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);

  const [exporting, setExporting] = useState(false);

  const handleTitleFilterChange = (event) => {
    setTitleFilter(event.target.value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      setDelayedTitleFilter(event.target.value);
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  function createSelectOptions(list, valueField, labelFields) {
    return list.map((item) => ({
      value: item[valueField],
      label: labelFields.map((field) => item[field]).join(' | '),
    }));
  }

  const handleSelectChange = (setFunction) => (selectedOptions) => {
    const selectedValues = Array.isArray(selectedOptions) ? selectedOptions.map((option) => option.value) : [];
    setFunction(selectedValues);
  };

  const optionStyles = (provided, state) => ({
    ...provided,
    color: state.isFocused ? 'white' : 'black',
    backgroundColor: state.isFocused ? '#3393d0' : 'white',
  });

  const multiValueLabelStyles = (provided, { data }) => ({
    ...provided,
    color: 'white',
    backgroundColor: '#3393d0',
  });

  const multiValueRemoveStyles = (provided, { data }) => ({
    ...provided,
    color: 'white',
    backgroundColor: '#3393d0',
    ':hover': {
      backgroundColor: '#3393d0',
      color: 'white',
    },
  });

  const columns = [
    { field: 'startAt', title: 'Data Inizio' },
    { field: 'title', title: 'Titolo' },
    { field: 'tutor', title: 'Docente' },
    { field: 'user', title: 'Utente' },
    { field: 'category', title: 'Branca' },
    { field: 'status', title: 'Stato' },
    { field: 'deleted', title: 'Cancellato' },
    { field: 'deletedAt', title: 'Data Cancellazione' },
    { field: 'endedAt', title: 'Data Fine' },
    { field: 'duration', title: 'Durata' },
    { field: 'price', title: 'Prezzo' },
  ];

  useEffect(() => {
    if (!currentAppointment) setCurrentAppointmentEvents([]);
    else {
      database
        .collection('acty_webhook_logs')
        .where('payload.content.appointment.unique_id', '==', currentAppointment.id)
        .orderBy('timestamp', 'desc')
        .get()
        .then((result) => {
          setCurrentAppointmentEvents(result.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
        });
    }
  }, [currentAppointment]);

  useEffect(() => {
    const today = new Date();
    const _90DaysBefore = new Date();
    _90DaysBefore.setDate(today.getDate() - 90);

    setStartDateFrom(_90DaysBefore.toISOString().split('T')[0]);
    setStartDateTo(today.toISOString().split('T')[0]);
  }, []);

  useEffect(() => {
    let dateFrom = startDateFrom ? new Date(startDateFrom) : new Date();
    let dateTo = startDateTo ? new Date(startDateTo) : new Date();

    if (!startDateFrom) {
      setStartDateFrom(dateFrom.toISOString().split('T')[0]);
    }

    if (!startDateTo || dateFrom > dateTo || dateTo > new Date(dateFrom.getTime() + 90 * 24 * 60 * 60 * 1000)) {
      const newDateTo = new Date(dateFrom);
      newDateTo.setDate(newDateTo.getDate() + 90);
      setStartDateTo(newDateTo.toISOString().split('T')[0]);
    }
  }, [startDateFrom, startDateTo]);

  const filters = useMemo(
    () => ({
      titleFilter: delayedTitleFilter,
      startDateFrom,
      startDateTo,
      startTimeFrom,
      startTimeTo,
      endDateFrom,
      endDateTo,
      endTimeFrom,
      endTimeTo,
      isDeleted,
      deletedDateFrom,
      deletedDateTo,
      deletedTimeFrom,
      deletedTimeTo,
      selectedTutors,
      selectedUsers,
      selectedCategories,
      selectedStatuses,
    }),
    [
      delayedTitleFilter,
      startDateFrom,
      startDateTo,
      startTimeFrom,
      startTimeTo,
      endDateFrom,
      endDateTo,
      endTimeFrom,
      endTimeTo,
      isDeleted,
      deletedDateFrom,
      deletedDateTo,
      deletedTimeFrom,
      deletedTimeTo,
      selectedTutors,
      selectedUsers,
      selectedCategories,
      selectedStatuses,
    ]
  );

  useEffect(() => {
    if (startDateFrom && startDateTo) {
      setLoading(true);
      getActyAppointments(true, currentPage, { direction: sortOrder, field: sortField }, filters)
        .then((data: any) => {
          const { list, pageSize, total, tutorsList, usersList, categoriesList, statusesList } = data;
          setAppointments(list);
          setTotalPages(Math.ceil(total / pageSize));
          setTutorsList(tutorsList);
          setUsersList(usersList);
          setCategoriesList(categoriesList);
          setStatusesList(statusesList);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [currentPage, sortOrder, sortField, filters]);

  useEffect(() => {
    if (totalPages < currentPage) {
      setCurrentPage(1);
    }
  }, [totalPages, currentPage]);

  const statusBadgeVariants = {
    NOT_READY: 'secondary',
    USER_IN: 'info',
    STARTED: 'success',
    USER_OUT: 'warning',
    ENDED: 'primary',
    EXPIRED: 'danger',
    DELETED: 'danger',
  };

  const getStatusBadgeVariant = (statusCode: string) => {
    return statusBadgeVariants[statusCode] || 'primary';
  };

  const handleShowModal = (appointment) => {
    setCurrentAppointment(appointment);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setCurrentAppointment(null);
    setShowModal(false);
  };

  const handleSort = (field) => {
    setSortField(field);
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const handleExport = async () => {
    if (startDateFrom && startDateTo) {
      setExporting(true);

      getActyAppointments(true, currentPage, { direction: sortOrder, field: sortField }, filters, true)
        .then((file: any) => {
          const csv = atob(file.csv);
          saveAs(new Blob([csv], { type: 'text/csv' }), 'sessioni_one_to_one.csv');
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setExporting(false);
        });
    }
  };

  return (
    <>
      <Title>Sessioni One to One</Title>
      <div style={{ marginBottom: '72px' }}>
        <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
          <Topbar />
        </div>
      </div>
      <Container fluid className={'my-5'}>
        <div className={'display-4'}>Sessioni One to One</div>
        <Row className={'mt-4'}>
          <Col sm={10} md={5}>
            <Form.Group>
              <Form.Label>Titolo</Form.Label>
              <Form.Control type={'text'} size="sm" value={titleFilter} onChange={handleTitleFilterChange} />
            </Form.Group>
          </Col>
          <Col sm={2} md={1}>
            <Form.Group>
              <Form.Label>Cancellato</Form.Label>
              <Form.Check type={'checkbox'} onChange={(e) => setIsDeleted(e.target.checked)} />
            </Form.Group>
          </Col>
          <Col sm={6} md={3}>
            <Form.Group>
              <Form.Label className={isDeleted ? '' : 'text-secondary'}>Data Eliminazione Da</Form.Label>
              <InputGroup>
                <Form.Control
                  type={'date'}
                  size="sm"
                  value={deletedDateFrom}
                  max={deletedDateTo}
                  disabled={!isDeleted}
                  onChange={(event) => setDeletedDateFrom(event.target.value)}
                  onKeyPress={(event) => event.preventDefault()}
                />
                <Form.Control
                  type={'time'}
                  size="sm"
                  value={deletedTimeFrom}
                  disabled={!isDeleted}
                  onChange={(event) => setDeletedTimeFrom(event.target.value)}
                  onKeyPress={(event) => event.preventDefault()}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm={6} md={3}>
            <Form.Group>
              <Form.Label className={isDeleted ? '' : 'text-secondary'}>Data Eliminazione A</Form.Label>
              <InputGroup>
                <Form.Control
                  type={'date'}
                  size="sm"
                  value={deletedDateTo}
                  min={deletedDateFrom}
                  disabled={!isDeleted}
                  onChange={(event) => setDeletedDateTo(event.target.value)}
                  onKeyPress={(event) => event.preventDefault()}
                />
                <Form.Control
                  type={'time'}
                  size="sm"
                  value={deletedTimeTo}
                  disabled={!isDeleted}
                  onChange={(event) => setDeletedTimeTo(event.target.value)}
                  onKeyPress={(event) => event.preventDefault()}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={3}>
            <Form.Group>
              <Form.Label>Data Inizio Da</Form.Label>
              <InputGroup>
                <Form.Control
                  type={'date'}
                  size="sm"
                  value={startDateFrom}
                  onChange={(event) => setStartDateFrom(event.target.value)}
                  onKeyPress={(event) => event.preventDefault()}
                />
                <Form.Control
                  type={'time'}
                  size="sm"
                  value={startTimeFrom}
                  onChange={(event) => setStartTimeFrom(event.target.value)}
                  onKeyPress={(event) => event.preventDefault()}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm={6} md={3}>
            <Form.Group>
              <Form.Label>Data Inizio A</Form.Label>
              <InputGroup>
                <Form.Control
                  type={'date'}
                  size="sm"
                  value={startDateTo}
                  min={startDateFrom}
                  max={maxStartDateTo}
                  onChange={(event) => setStartDateTo(event.target.value)}
                  onKeyPress={(event) => event.preventDefault()}
                />
                <Form.Control
                  type={'time'}
                  size="sm"
                  value={startTimeTo}
                  onChange={(event) => setStartTimeTo(event.target.value)}
                  onKeyPress={(event) => event.preventDefault()}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm={6} md={3}>
            <Form.Group>
              <Form.Label>Data Fine Da</Form.Label>
              <InputGroup>
                <Form.Control
                  type={'date'}
                  size="sm"
                  value={endDateFrom}
                  max={endDateTo}
                  onChange={(event) => setEndDateFrom(event.target.value)}
                  onKeyPress={(event) => event.preventDefault()}
                />
                <Form.Control
                  type={'time'}
                  size="sm"
                  value={endTimeFrom}
                  onChange={(event) => setEndTimeFrom(event.target.value)}
                  onKeyPress={(event) => event.preventDefault()}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm={6} md={3}>
            <Form.Group>
              <Form.Label>Data Fine A</Form.Label>
              <InputGroup>
                <Form.Control
                  type={'date'}
                  size="sm"
                  value={endDateTo}
                  min={endDateFrom}
                  onChange={(event) => setEndDateTo(event.target.value)}
                  onKeyPress={(event) => event.preventDefault()}
                />
                <Form.Control
                  type={'time'}
                  size="sm"
                  value={endTimeTo}
                  onChange={(event) => setEndTimeTo(event.target.value)}
                  onKeyPress={(event) => event.preventDefault()}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={3}>
            <Form.Label>Docente</Form.Label>
            <Select
              isMulti
              name="tutors"
              options={createSelectOptions(tutorsList, 'id', ['name'])}
              onChange={handleSelectChange(setSelectedTutors)}
              styles={{
                option: optionStyles,
                multiValueLabel: multiValueLabelStyles,
                multiValueRemove: multiValueRemoveStyles,
              }}
            />
          </Col>
          <Col sm={6} md={3}>
            <Form.Label>Utente</Form.Label>
            <Select
              isMulti
              name="users"
              options={createSelectOptions(usersList, 'id', ['fullname', 'email'])}
              onChange={handleSelectChange(setSelectedUsers)}
              styles={{
                option: optionStyles,
                multiValueLabel: multiValueLabelStyles,
                multiValueRemove: multiValueRemoveStyles,
              }}
            />
            <Form.Text>La lista mostra solo gli utenti per cui esiste almeno un appuntamento, a prescindere dai filtri impostati.</Form.Text>
          </Col>
          <Col sm={6} md={3}>
            <Form.Label>Branca</Form.Label>
            <Select
              isMulti
              name="categories"
              options={createSelectOptions(categoriesList, 'id', ['label'])}
              onChange={handleSelectChange(setSelectedCategories)}
              styles={{
                option: optionStyles,
                multiValueLabel: multiValueLabelStyles,
                multiValueRemove: multiValueRemoveStyles,
              }}
            />
          </Col>
          <Col sm={6} md={3}>
            <Form.Label>Stato</Form.Label>
            <Select
              isMulti
              name="statuses"
              options={createSelectOptions(statusesList, 'id', ['label'])}
              onChange={handleSelectChange(setSelectedStatuses)}
              styles={{
                option: optionStyles,
                multiValueLabel: multiValueLabelStyles,
                multiValueRemove: multiValueRemoveStyles,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col className="text-right mt-2">
            <Button
              disabled={exporting}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleExport();
              }}
            >
              <div style={{ width: '100px' }}>{exporting ? <Spinner animation="border" size="sm" /> : 'Esporta CSV'}</div>
            </Button>
          </Col>
        </Row>

        {loading ? (
          <FullscreenLoader fullscreen={false} />
        ) : (
          <>
            <Table striped bordered hover size={'sm'} variant={'dark'} responsive style={{ width: '100%' }} className="mt-5">
              <thead>
                <tr>
                  {columns.map(({ field, title }) => (
                    <SortableColumnHeader key={field} field={field} title={title} sortField={sortField} sortDirection={sortOrder} onSort={handleSort} />
                  ))}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {appointments.map((appointment) => (
                  <tr key={appointment.id}>
                    <td className={'text-center'}>{formatSecondsTimestamp(appointment.timestamp._seconds, 'dd/MM/y HH:mm', currentLocale)}</td>
                    <td className={'text-left'}>{appointment.name}</td>
                    <td className={'text-left'}>{appointment.tutor.name}</td>
                    <td className={'text-left'}>{`${appointment.user.name} ${appointment.user.surname}`}</td>
                    <td className={'text-left'}>{appointment.tutor.category}</td>
                    <td className={'text-center'}>
                      <Badge variant={getStatusBadgeVariant(appointment.status.statusCode)}>{appointment.status.statusCode}</Badge>
                    </td>
                    <td className={'text-center'}>{appointment.status.statusCode === 'DELETED' ? 'Yes' : 'No'}</td>
                    <td className={'text-center'}>
                      {appointment.status.deletedAt ? formatSecondsTimestamp(appointment.status.deletedAt._seconds, 'dd/MM/y HH:mm', currentLocale) : ''}
                    </td>
                    <td className={'text-center'}>
                      {appointment.status.date ? formatSecondsTimestamp(appointment.status.date._seconds, 'dd/MM/y HH:mm', currentLocale) : ''}
                    </td>
                    <td className={'text-right'}>{appointment.status.duration ?? ''}</td>
                    <td className={'text-right'}>{appointment.price ?? ''}</td>
                    <td className={'text-center'}>
                      <Button onClick={() => handleShowModal(appointment)}>Info</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
          </>
        )}
      </Container>
      <Footer />

      <Modal show={showModal} size={'xl'} onHide={() => handleCloseModal()} scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Dettaglio appuntamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Cronologia eventi</h5>
          {currentAppointmentEvents.map((e) => (
            <Table striped bordered size={'sm'}>
              <tbody>
                <tr>
                  <th scope={'row'}>Timestamp</th>
                  <td>{e.payload.occurred_at}</td>
                </tr>
                <tr>
                  <th scope={'row'}>ID</th>
                  <td>
                    <Copyable text={e.id} />
                  </td>
                </tr>
                <tr>
                  <th scope={'row'}>Acty ID</th>
                  <td>
                    <Copyable text={e.payload._id} />
                  </td>
                </tr>
                <tr>
                  <th scope={'row'}>Event type</th>
                  <td>
                    <code>{e.payload.event_type}</code>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <details>
                      <summary>Raw event</summary>
                      <pre>{JSON.stringify(e, null, 2)}</pre>
                    </details>
                  </td>
                </tr>
              </tbody>
            </Table>
          ))}

          <details>
            <summary>Raw appointment</summary>
            <pre>{JSON.stringify(currentAppointment, null, 2)}</pre>
          </details>
        </Modal.Body>
        <Modal.Footer>
          <div className={'w-100 d-flex justify-content-end'}>
            <Button
              size={'sm'}
              variant="outline-secondary"
              className={'mr-2'}
              onClick={() => {
                handleCloseModal();
              }}
            >
              Chiudi
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminActyAppointmentsComponent;
