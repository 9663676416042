import { Topbar } from '../../components/Topbar/Topbar';
import { Button, Container, Form, InputGroup, Tab, Tabs } from 'react-bootstrap';
import { Footer } from '../../components/Footer/Footer';
import { FormEvent, useEffect, useState } from 'react';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { UserData } from '../../components/UserData/UserData';
import { useQuery } from '../../../hooks/useQuery';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { Copyable } from '../../components/Copyable/Copyable';
import { useHistory } from 'react-router-dom';
import { Title } from '../../components/Title/Title';
import { UserCourse } from '../../components/UserCourse/UserCourse';

const UsersComponent = ({ iframe = false, initQuery = '' }) => {
  const { uid: queryUid } = useQuery();
  const { getUserData, currentUser } = useFirebase();
  const [query, setQuery] = useState(initQuery || queryUid || '');
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const [activeTab, setActiveTab] = useState('dati');
  const [activeCourse, setActiveCourse] = useState<string>(null);
  const history = useHistory();

  useEffect(() => {
    if (!queryUid && !initQuery) return;
    submitSearch();
  }, []);

  const submitSearch = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (!query) return;
    const _userData: { uid?: string; email?: string; alias?: any } = {};
    if (query.includes('@')) _userData.email = query;
    else if (parseInt(query) == +query) _userData.alias = query;
    else _userData.uid = query;
    setActiveTab('dati');
    setUserData(null);
    setLoading(true);
    getUserData(_userData).then((res) => {
      setUserData(res);
      if (res.uid && !iframe) {
        history.replace({
          pathname: '/admin/users',
          search: `uid=${res.uid}`,
        });
      }
      setLoading(false);
    });
  };

  return (
    <>
      {!iframe && (
        <>
          <Title>Gestione utenti</Title>
          <div style={{ marginBottom: '72px' }}>
            <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
              <Topbar />
            </div>
          </div>
        </>
      )}

      <Container fluid={iframe} className={iframe ? '' : 'my-5'}>
        {!iframe && (
          <div className={'display-4 d-flex align-items-baseline'}>
            Gestione utenti
            {/*<Button variant={'info'} size={'sm'} className={'ml-auto'}>
              Visualizza amministratori
            </Button>*/}
          </div>
        )}

        <Form className={'mt-4'} onSubmit={submitSearch}>
          <Form.Group>
            {!iframe && (
              <Form.Label htmlFor={'query'}>
                {loading ? (
                  'Ricerca in corso...'
                ) : (
                  <>
                    Cerca utenti per UID, alias o email <span className={'text-muted'}>(l'email deve essere esatta)</span>
                  </>
                )}
              </Form.Label>
            )}
            <InputGroup>
              <Form.Control
                autoFocus
                style={{ textAlign: 'center' }}
                required
                size={'lg'}
                id={'query'}
                value={query}
                disabled={loading || !!iframe}
                onChange={(e) => setQuery(e.target.value)}
              />
              {!iframe && (
                <InputGroup.Append>
                  <Button variant={'primary'} style={{ minWidth: '100px' }} disabled={loading || !query} type={'submit'}>
                    Cerca
                  </Button>
                </InputGroup.Append>
              )}
            </InputGroup>
            <Form.Text className={'text-right'}>
              Stai cercando il tuo ID? <Copyable text={currentUser.uid} />
            </Form.Text>
          </Form.Group>
        </Form>
        {loading && <FullscreenLoader fullscreen={false} />}
        {!!userData && (
          <Tabs variant={'pills'} activeKey={activeTab} onSelect={setActiveTab} mountOnEnter={true} unmountOnExit={false}>
            <Tab title={'Dati utente'} eventKey={'dati'}>
              <UserData data={userData} onUserUpdate={() => submitSearch()} />
            </Tab>
            <Tab title={'Corsi ASO'} eventKey={'corso'} disabled={userData.userData?.profile?.formData?.categoriaProfessionale != 'aso'}>
              <Tabs variant={'pills'} className={'mt-3'} activeKey={activeCourse} onSelect={setActiveCourse} mountOnEnter={true} unmountOnExit={false}>
                {userData?.userData?.courses.map((c) => (
                  <Tab title={c.name} key={c.id} eventKey={c.id}>
                    <UserCourse uid={userData?.uid} cid={c.id} />
                  </Tab>
                ))}
              </Tabs>
            </Tab>
          </Tabs>
        )}
      </Container>

      {!iframe && <Footer />}
    </>
  );
};

export { UsersComponent as Users };
