import { useEffect, useRef } from 'react';
import { useGaming } from '../../../../contexts/GamingContext';
import { Multiplier } from '../../Multiplier/Multiplier';
import { Exp } from '../Exp/Exp';
import './GamingRecap.scss';

const GamingRecapComponent = ({ className = '' }) => {
  const { player, onExperienceGain, humanExperience } = useGaming();

  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!containerRef.current) return;
    onExperienceGain('show-xp-drop', (amount) => {
      const span = document.createElement('span');
      span.classList.add('exp-drop');
      span.textContent = `+ ${amount} EXP`;
      span.addEventListener('animationend', () => span.remove());
      containerRef.current?.append(span);
    });
  }, [containerRef.current]);

  if (!player) return null;

  return (
    <div style={{ width: '100px', userSelect: 'none' }} className={'d-none d-md-flex flex-column ' + className} ref={containerRef}>
      <div className={'small'}>
        Livello <strong>{player.level}</strong>
      </div>
      <div className={'d-flex align-items-center justify-content-between small'}>
        <div className={'d-flex align-items-center'}>
          <Exp className={'mr-1'} /> {humanExperience}
        </div>
        {player.multiplier > 1 && <Multiplier multiplier={player.multiplier} />}
      </div>
      <div className={'gaming-level-progress'} style={{ '--level-progress': `${player.levelProgress * 100}%` } as any} />
    </div>
  );
};

export { GamingRecapComponent as GamingRecap };
