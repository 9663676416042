import { Button, Card, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useGaming } from '../../../contexts/GamingContext';
import { FullscreenLoader } from '../FullscreenLoader/FullscreenLoader';

const NotificationsList = ({
  isDropdown,
  userNotifications,
  fetchOthers,
  loading,
  finished,
  onUpdate,
}: {
  isDropdown: boolean;
  userNotifications?: any[];
  fetchOthers?: Function;
  loading?: boolean;
  finished?: boolean;
  onUpdate?: Function;
}) => {
  const { notifications } = useFirebase();
  const { openNotification } = useGaming();
  const { t } = useTranslation();

  const notificationToVisualize = isDropdown ? notifications : userNotifications;

  return (
    <>
      {!isDropdown && loading && <FullscreenLoader fullscreen={false} />}

      {(isDropdown || !loading) && (
        <div className="d-flex flex-column">
          {!notificationToVisualize.length && (
            <Card bg={!isDropdown ? 'dark' : 'light'}>
              <Card.Body>{t('labels.noNotifiche')}</Card.Body>
            </Card>
          )}
          {!!notificationToVisualize.length &&
            !isDropdown &&
            notificationToVisualize.map((notification, i) => (
              <Card bg={'dark'} key={notification.id} className={notification.read ? 'mb-2 bg-transparent no-shadow bordered-card' : 'mb-2'}>
                <Card.Body key={i.toString()} style={{ cursor: notification.data.form ? 'default' : 'pointer' }} onClick={() => openNotification(notification)}>
                  <div className={'d-flex flex-column'}>
                    <div className={notification.read ? '' : 'font-weight-bold'}>{notification.title}</div>
                    <small>{notification.body}</small>
                  </div>
                </Card.Body>
              </Card>
            ))}

          <ListGroup variant={'flush'}>
            {!notificationToVisualize.length && <ListGroup.Item></ListGroup.Item>}
            {!!notificationToVisualize.length &&
              isDropdown &&
              notificationToVisualize.map((notification, i) => (
                <ListGroup.Item
                  key={i.toString()}
                  style={{ cursor: notification.data.form ? 'default' : 'pointer' }}
                  variant={notification.read ? null : 'info'}
                  onClick={() => openNotification(notification)}
                >
                  <div>
                    <div className={'text-primary'}>{notification.title}</div>
                    <div className={'text-muted small'}>{notification.body}</div>
                  </div>
                </ListGroup.Item>
              ))}
            {isDropdown && (
              <ListGroup.Item>
                <a href="/notifications">{t('labels.visualizzaTutteNotifiche')}</a>
              </ListGroup.Item>
            )}
          </ListGroup>
          <div className="d-flex justify-content-center">
            {!isDropdown && !finished && (
              <Button className="mt-3" onClick={() => fetchOthers()}>
                {t('labels.loadMore')}
              </Button>
            )}
            {!isDropdown && finished && <span className="mt-3">{t('labels.noMoreNotifications')}</span>}
          </div>
        </div>
      )}
    </>
  );
};

export { NotificationsList };
