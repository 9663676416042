import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useHover } from '../../../hooks/useHover';
import './IconButton.scss';

const IconButtonComponent = ({ icon, to = '#', className = '', onClick = null, badge = null, disabled = false }) => {
  const [wrapperRef, isHover] = useHover<HTMLDivElement>();

  return (
    <>
      <div
        className={'icon-button-wrapper ' + className}
        ref={wrapperRef}
        style={{ pointerEvents: disabled ? 'none' : null }}
        onClick={(e) => {
          onClick && onClick(e);
        }}
      >
        <Link to={to}>
          <div style={{ transition: 'color ease-in-out 0.2s' }} className={'text-light light-button'}>
            <FontAwesomeIcon icon={icon} size={'lg'} />
          </div>
        </Link>
      </div>
      {!!badge && (
        <div
          className={'position-absolute'}
          style={{ top: -5, right: 0, cursor: 'pointer' }}
          onClick={(e) => {
            onClick && onClick(e);
          }}
        >
          {badge}
        </div>
      )}
    </>
  );
};

export { IconButtonComponent as IconButton };
