import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMediumM } from '@fortawesome/free-brands-svg-icons';
import { Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import './ContactMaso.scss';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { useApplicationBuildType } from '../../../hooks/useApplicationBuildType';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { formatSecondsTimestamp } from '../../utils/utils';

const ContactMasoComponent = () => {
  const SLACK_CHANNEL = 'project-tootor';
  const CHANNEL_ID = 'C01U4DZKXTM';

  const slugify = () => SLACK_CHANNEL.toLowerCase().replace(/-/g, '--');

  const buttonRef = useRef<HTMLButtonElement>();
  const [text, setText] = useState('');
  const { isDevelopment } = useApplicationBuildType();
  const { userDataFmt, staticData } = useFirebase();
  const [loading, setLoading] = useState(false);
  const [esito, setEsito] = useState<boolean>(null);

  useEffect(() => {
    if (esito == null) return;
    setTimeout(() => {
      if (esito) close();
      setEsito(null);
    }, 1000);
  }, [esito]);

  const close = () => {
    setText('');
    buttonRef.current.click();
  };

  const onFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!text) return;

    setLoading(true);
    setEsito(null);
    fetch('https://hooks.slack.com/services/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', {
      method: 'post',
      body: JSON.stringify({
        text: `MEC da ${userDataFmt.profile.formData.nome} ${userDataFmt.profile.formData.cognome}`,
        blocks: [
          {
            type: 'header',
            text: {
              type: 'plain_text',
              text: `${userDataFmt.profile.formData.nome} ${userDataFmt.profile.formData.cognome} scrive:`,
              emoji: true,
            },
          },
          {
            type: 'section',
            text: {
              type: 'plain_text',
              text,
              emoji: true,
            },
          },
          {
            type: 'context',
            elements: [
              {
                type: 'plain_text',
                text: `Pagina: ${window.location.pathname}`,
                emoji: true,
              },
              {
                type: 'plain_text',
                text: `Data: ${formatSecondsTimestamp(new Date().getTime() / 1000, 'dd/MM/Y HH:mm')}`,
                emoji: true,
              },
            ],
          },
          {
            type: 'divider',
          },
        ],
        emoji: true,
        username: 'Tootor MEC',
        channel: isDevelopment ? '#app-test' : `#${SLACK_CHANNEL.toLowerCase()}`,
        icon_emoji: ':m:',
      }),
    })
      .then(() => {
        setEsito(true);
        setText('');
      })
      .catch((err) => {
        console.error(err);
        setEsito(false);
      })
      .finally(() => setLoading(false));
  };

  if (!staticData.ENABLE_MEC) return null;

  return (
    <div className={'btn-contact-maso'}>
      <OverlayTrigger
        trigger={'click'}
        placement={'top'}
        overlay={
          <Popover id={'popover-contact-maso'}>
            <Popover.Title as={'h3'}>
              <span className={'text-dark'}>Masina Emergency Contact</span>
            </Popover.Title>
            <Popover.Content>
              {loading ? (
                <div className={'text-center'}>Caricamento...</div>
              ) : (
                (() => {
                  switch (esito) {
                    case true:
                      return <div className={'text-center'}>Messaggio inviato!</div>;
                    case false:
                      return <div className={'text-center'}>Si è verificato un errore.</div>;
                    default:
                      return (
                        <>
                          <p>Utilizza questa funzionalità per contattare direttamente l'amministratore.</p>
                          <p>
                            Il messaggio verrà inviato su Slack nel canale <code>#{SLACK_CHANNEL}</code>.
                          </p>
                          <p className={'d-flex align-items-end justify-content-center'}>
                            <span className={'mr-1'}>Accedi:</span>
                            <a href={`https://exabyte-srl.slack.com/archives/${CHANNEL_ID}`}>
                              <img src={`https://img.shields.io/badge/slack-${slugify()}-blueviolet`} alt={null} />
                            </a>
                          </p>
                          <Form onSubmit={onFormSubmit}>
                            <Form.Group>
                              <Form.Control
                                as={'textarea'}
                                placeholder={'Scrivi qui il tuo messaggio...'}
                                rows={5}
                                style={{ resize: 'none' }}
                                required
                                autoFocus
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                              />
                            </Form.Group>
                            <div className={'d-flex justify-content-end'}>
                              <Button
                                variant={'secondary'}
                                className={'mr-1'}
                                size={'sm'}
                                onClick={() => {
                                  close();
                                }}
                              >
                                Annulla
                              </Button>
                              <Button type={'submit'} size={'sm'}>
                                Invia
                              </Button>
                            </div>
                          </Form>
                        </>
                      );
                  }
                })()
              )}
            </Popover.Content>
          </Popover>
        }
      >
        <Button variant={'warning'} size={'lg'} ref={buttonRef}>
          <FontAwesomeIcon icon={faMediumM} fixedWidth={true} size={'lg'} />
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export { ContactMasoComponent as ContactMaso };
