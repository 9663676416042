import { Topbar } from '../../components/Topbar/Topbar';
import { Button, Card, Container, Modal } from 'react-bootstrap';
import { Footer } from '../../components/Footer/Footer';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useEffect, useState } from 'react';
import { FullscreenLoader } from '../../components/FullscreenLoader/FullscreenLoader';
import { useHistory, useParams } from 'react-router-dom';
import { formatSecondsTimestamp } from '../../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCalendarCheck, faCoins, faHourglassHalf, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '../../../hooks/useQuery';
import { Copyable } from '../../components/Copyable/Copyable';
import { useTranslation } from 'react-i18next';
import { Title } from '../../components/Title/Title';
import { useGaming } from '../../../contexts/GamingContext';

const GiftDetailsComponent = () => {
  const { getCheckoutDetails } = useFirebase();
  const { track } = useGaming();
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const { sid } = useQuery();
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<any>(null);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!id) history.replace('/gift');
    setLoading(true);
    getCheckoutDetails(id)
      .then((res) => {
        if (!res.coupons) history.replace('/gift');
        else setDetails(res);
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (sid) {
      setSuccessModalOpen(true);
      track('GIFT_SUBSCRIPTION', { sid });
    }
  }, []);

  if (loading || !details) return <FullscreenLoader />;

  return (
    <>
      <Title>{t('giftDetails.labels.dettaglioRegalo')}</Title>

      <div style={{ marginBottom: '72px' }}>
        <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
          <Topbar />
        </div>
      </div>

      <Container className={'my-5'}>
        <div className={'d-flex align-items-center'}>
          <Button variant={'outline-light'} onClick={() => history.push('/gift')} className={'mr-2'}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <div className={'display-4'}>{t('giftDetails.labels.dettaglioRegalo')}</div>
        </div>

        <Card bg={'dark'} className={'mt-4'}>
          <Card.Body>
            <Card.Title>{t('labels.transazione')}</Card.Title>
            <div>
              <FontAwesomeIcon icon={faCoins} fixedWidth={true} className={'text-primary mr-2'} />
              {t('giftDetails.labels.idtransazione')} <Copyable text={id} />
            </div>
            <div>
              <FontAwesomeIcon icon={faCalendarCheck} fixedWidth={true} className={'text-primary mr-2'} />
              {t('giftDetails.labels.effettuataIl')} {formatSecondsTimestamp(details.ts._seconds, 'dd/MM/y HH:mm')}
            </div>
            {!!details.coupons?.[0]?.expires && (
              <div>
                <FontAwesomeIcon icon={faHourglassHalf} fixedWidth={true} className={'text-primary mr-2'} />
                {t('giftDetails.labels.scadenzaCodici')} {formatSecondsTimestamp(details.coupons?.[0]?.expires, 'dd/MM/y')}
              </div>
            )}
            <details className={'mt-2'}>
              <summary>
                <span className={'text-muted'}>{t('giftDetails.labels.dettagliTecnici')}</span>
              </summary>
              <div>{t('giftDetails.labels.inCasoContatto')}</div>
              <div>
                {t('giftDetails.labels.idsessione')} <Copyable text={details.sessionId} />
              </div>
              <div>
                {t('giftDetails.labels.idfattura')} <Copyable text={details.invoiceId} />
              </div>
              <div>
                {t('giftDetails.labels.idPagamento')} <Copyable text={details.paymentIntent} />
              </div>
            </details>
          </Card.Body>
        </Card>

        <Card bg={'dark'} className={'mt-3'}>
          <Card.Body>
            <Card.Title>{t('giftDetails.labels.couponGenerati')}</Card.Title>
            {details.coupons.map((coupon, i, arr) => (
              <div key={i.toString()} className={i == arr.length - 1 ? '' : 'mb-2'}>
                <div>
                  <strong>
                    <Copyable text={coupon.promoCode} />
                  </strong>{' '}
                  {coupon.productName}
                </div>
                <div className={'text-muted'}>
                  <FontAwesomeIcon icon={faPaperPlane} /> {coupon.name} | {coupon.email}
                </div>
              </div>
            ))}
          </Card.Body>
        </Card>
      </Container>

      {/*<pre>{JSON.stringify(details, null, 2)}</pre>*/}

      <Footer />

      <Modal centered show={successModalOpen} onHide={() => setSuccessModalOpen(false)} backdrop={'static'} keyboard={false}>
        <Modal.Body>
          <Modal.Title className={'text-success mb-2'}>{t('giftDetails.labels.acquistoEffettuato')}</Modal.Title>
          <div className={'mb-2'}>{t('giftDetails.labels.acquistoEffettuatoDescrizione')}</div>
          <div>{t('giftDetails.labels.acquistoRiepilogo')}</div>
          <div className={'d-flex justify-content-start mt-3'}>
            <Button variant={'outline-dark'} onClick={() => setSuccessModalOpen(false)}>
              {t('labels.chiudi')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GiftDetailsComponent;
