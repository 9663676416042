import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import firebase from 'firebase/app';
import { useEffect, useRef, useState } from 'react';
import { Button, Container, Form, InputGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { useQuery } from '../../../hooks/useQuery';
import { ErrorModal } from '../../components/ErrorModal/ErrorModal';
import { Footer } from '../../components/Footer/Footer';
import { Topbar } from '../../components/Topbar/Topbar';
import { createHashedQueryParams, verifyHashedQueryParams } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import { Title } from '../../components/Title/Title';
import {useCurrentLanguage} from "../../../hooks/useCurrentLanguage";

const SignupComponent = () => {
  const history = useHistory();
  const params = useQuery();
  const { signup, isLoggedIn, fv, database, analytics, currentUser } = useFirebase();
  const { currentLanguage } = useCurrentLanguage();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorCode, setErrorCode] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const recaptchaCallback = useRef<() => Promise<void>>();
  const recaptchaVerifier = useRef<firebase.auth.RecaptchaVerifier>();
  const { t } = useTranslation();

  useEffect(() => {
    if (isLoggedIn) history.replace('/');
  }, []);

  useEffect(() => {
    (async () => {
      const queryParamsCheck = await verifyHashedQueryParams(params, ['category']);
      if (!queryParamsCheck || !['odontoiatra', 'aso'].includes(params.category)) return history.replace('/');

      recaptchaVerifier.current = new firebase.auth.RecaptchaVerifier('sign-up-btn', {
        size: 'invisible',
        callback: () => {
          recaptchaCallback.current();
        },
      });
      recaptchaVerifier.current.render().catch(console.error);
    })();

    return () => recaptchaVerifier.current?.clear();
  }, []);

  useEffect(() => {
    recaptchaCallback.current = async () => {
      setErrorCode('');
      try {
        setLoading(true);
        const res = await signup(email, password);
        analytics.logEvent('sign_up', { user_id: res.user.uid });
        const userData: any = {
          preferredLanguage: currentLanguage,
          profile: { formData: { categoriaProfessionale: params.category } },
          createdAt: fv.serverTimestamp(),
        };
        if (params.category == 'aso') userData.customTrialSeconds = 1800;
        await database.doc(`userData/${res.user.uid}`).set(userData, { merge: true });
        if (params.price) {
          const newParamsObject: any = { price: params.price };
          if (params.promo) newParamsObject.promo = params.promo;
          if (params.code) newParamsObject.code = params.code;
          const newParams = await createHashedQueryParams(newParamsObject);
          history.replace({
            pathname: '/first-login',
            search: newParams,
          });
        } else history.replace(`/first-login`);
      } catch (error: any) {
        console.error(error);
        setLoading(false);
        setIsModalOpen(true);
        setErrorCode(error.code);
        (recaptchaVerifier.current as any).reset();
      }
    };
  }, [email, password]);

  return (
    <>
      <Title>Registrazione</Title>

      <div style={{ marginBottom: '72px' }}>
        <div style={{ backgroundColor: '#141414', position: 'fixed', top: '0', left: '0', right: '0', zIndex: 100 }}>
          <Topbar />
        </div>
      </div>
      <Container className={'mb-5'}>
        <h1 className={'display-4 text-center mb-5'}>{t('labels.registrazione')}</h1>

        {/* <div className={'d-flex justify-content-center'}>
          <LoginWithGoogle className={'mb-4'} />
        </div>

        <p className={'text-center'}>Oppure, scegli una password per completare la registrazione</p>*/}

        <Form>
          <Form.Group>
            <Form.Label htmlFor={'email'}>{t('labels.email')}</Form.Label>
            <Form.Control required size={'lg'} type={'email'} id={'email'} value={email} onChange={(e) => setEmail(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor={'password'}>{t('labels.password')}</Form.Label>
            <InputGroup>
              <Form.Control
                required
                size={'lg'}
                type={passwordVisible ? 'text' : 'password'}
                minLength={8}
                id={'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputGroup.Append>
                <Button variant={'secondary'} onClick={() => setPasswordVisible((previousState) => !previousState)}>
                  <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye} fixedWidth={true} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <div className={'d-flex justify-content-center mt-5'}>
            <Button size={'lg'} variant={'primary'} type={'submit'} id={'sign-up-btn'} disabled={loading}>
              {t('labels.registrati')}
            </Button>
          </div>
        </Form>
      </Container>

      <Footer />

      <ErrorModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} errorCode={errorCode} />
    </>
  );
};

export default SignupComponent;
