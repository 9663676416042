import { useCallback, useEffect, useRef } from 'react';

export const useAnimationFrame = (animation = (elapsed: number) => null, endCondition = (elapsed: number) => false, onComplete = () => {}) => {
  const animationHandler = useRef<number>(null);
  const start = useRef<number>(null);
  const previousTimestamp = useRef<number>(null);
  const requestStop = useRef(false);

  const _internal = useCallback(
    (timestamp: number) => {
      if (!start.current) start.current = timestamp;
      const elapsed = timestamp - start.current;

      if (previousTimestamp.current != timestamp) {
        animation(elapsed);
      }

      if (endCondition(elapsed)) onComplete();
      else {
        if (requestStop.current) return;
        previousTimestamp.current = timestamp;
        animationHandler.current = window.requestAnimationFrame(_internal);
      }
    },
    [animation, endCondition, onComplete, requestStop.current]
  );

  useEffect(() => {
    return () => {
      stop();
    };
  }, []);

  const stop = () => {
    window.cancelAnimationFrame(animationHandler.current);
    requestStop.current = true;
  };

  return {
    start() {
      animationHandler.current = window.requestAnimationFrame(_internal);
    },
    stop,
  };
};
