import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import { Alert, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { ErrorModal } from '../ErrorModal/ErrorModal';
import { LoginWithGoogle } from '../LoginWithGoogle/LoginWithGoogle';
import { MFACode } from '../MFACode/MFACode';
import { useCapacitor } from '../../../hooks/useCapacitor';
import { Logo } from '../Logo/Logo';
import './LoginModal.scss';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import { useMobileDetect } from '../../../hooks/useMobileDetect';
import { useTranslation } from 'react-i18next';

const LoginModalComponent = ({
  isModalOpen,
  setIsModalOpen = () => {},
  redirectTo,
  showCancel = true,
}: {
  isModalOpen: boolean;
  setIsModalOpen?: Dispatch<SetStateAction<boolean>>;
  redirectTo?: string;
  showCancel?: boolean;
}) => {
  const { analytics, currentUser } = useFirebase();
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [recoveryEmail, setRecoveryEmail] = useState('');

  const [appleLoginWarningModalOpen, setAppleLoginWarningModalOpen] = useState(false);
  const [appleLoginProceed, setAppleLoginProceed] = useState(false);
  useEffect(() => {
    if (appleLoginWarningModalOpen) setAppleLoginProceed(false);
  }, [appleLoginWarningModalOpen]);
  useEffect(() => {
    if (!appleLoginWarningModalOpen && appleLoginProceed) requestAppleLogin();
  }, [appleLoginWarningModalOpen, appleLoginProceed]);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isPasswordRecoveryModalOpen, setIsPasswordRecoveryModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [errorCode, setErrorCode] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [isMfaCodeModalOpen, setIsMfaCodeModalOpen] = useState(false);
  const [resolver, setResolver] = useState<any>(null);

  const { login, checkEmail, recoverPassword, loginWithApple, staticData } = useFirebase();
  const history = useHistory();
  const { isNative, platform } = useCapacitor();
  const { isMobile, isAndroid, isIos } = useMobileDetect();

  const onLoginSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorCode('');
    try {
      await login(email, password);
      setIsModalOpen(false);
      (window as any).dataLayer.push({ event: 'login' });
      if (redirectTo) history.replace(redirectTo);
      else history.replace('/browse');
    } catch (error: any) {
      switch (error.code) {
        case 'auth/multi-factor-auth-required':
          setResolver(error.resolver);
          setIsModalOpen(false);
          setIsMfaCodeModalOpen(true);
          break;
        case 'auth/wrong-password':
          checkEmail(email).then((res) => {
            if (res.includes('google.com') && !res.includes('password')) {
              setErrorCode('custom/google-login-needed');
            } else setErrorCode(error.code);
            setIsErrorModalOpen(true);
          });
          break;
        default:
          console.error(error);
          if (error.code) {
            setErrorCode(error.code);
            setIsErrorModalOpen(true);
          }
      }
    }
  };

  const onRecoverySubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    recoverPassword(recoveryEmail)
      .then(() => {
        setIsPasswordRecoveryModalOpen(false);
        setIsSuccessModalOpen(true);
      })
      .catch((error) => {
        setErrorCode(error.code);
        setIsErrorModalOpen(true);
      });
  };

  const requestAppleLogin = () => {
    loginWithApple()
      .then((_) => {
        setIsModalOpen(false);
        analytics.logEvent('login', { user_id: currentUser.uid });
        history.replace('/browse');
      })
      .catch((error) => {
        console.error(error);
        if (error.code) {
          if (error.code == 'auth/internal-error') {
            if(error.message.includes('Unauthorized login location')) {
              setErrorCode('custom/locationError');
            } else if(error.message.includes('HTTP Cloud Function')) {
              setErrorCode('custom/password-signup-required');
            }
          } else setErrorCode(error.code);
          setIsErrorModalOpen(true);
        }
      });
  };

  const isLoginMethodVisible = (method: 'GOOGLE' | 'APPLE'): boolean => {
    const getForPlatform = (platform: string): boolean => !!staticData?.SSO?.[method]?.[platform];
    const isVisibleForWeb = getForPlatform('web');
    const isVisibleForAndroid = getForPlatform('android');
    const isVisibleForIos = getForPlatform('ios');
    const isVisibleForAndroidApp = getForPlatform('android_app');
    const isVisibleForIosApp = getForPlatform('ios_app');
    return (
      (platform == 'web' && !isMobile && isVisibleForWeb) ||
      (platform == 'web' && isAndroid && isVisibleForAndroid) ||
      (platform == 'web' && isIos && isVisibleForIos) ||
      (platform == 'android' && isNative && isVisibleForAndroidApp) ||
      (platform == 'ios' && isNative && isVisibleForIosApp)
    );
  };

  return (
    <>
      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} centered={isNative} backdrop={isNative ? 'static' : true} keyboard={!isNative}>
        {isNative && (
          <div style={{ position: 'absolute', top: '-42px', display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Logo style={{ height: '36px' }} />
          </div>
        )}
        <Modal.Header>
          <Modal.Title>{t('loginModal.labels.effettuaAccesso')}</Modal.Title>
        </Modal.Header>
        {staticData.SHOW_SIGNUP_NEEDED_WARNING && isNative && (
          <Modal.Body className={'pb-0'}>
            <Alert variant={'info'} className={'mb-0'}>
              <span>&Egrave; {t('loginModal.labels.necessarioRegistrarsi')}</span>
            </Alert>
          </Modal.Body>
        )}
        <Modal.Body className={'pb-0 d-flex flex-column flex-sm-row align-items-center'}>
          {isLoginMethodVisible('GOOGLE') && (
            <LoginWithGoogle
              block
              onAuthorize={() => {
                setIsModalOpen(false);
                // history.replace('/browse');
              }}
            />
          )}

          {isLoginMethodVisible('APPLE') && (
            <button
              className={'apple-login ' + (isLoginMethodVisible('GOOGLE') && isLoginMethodVisible('APPLE') ? 'ml-0 ml-sm-2 mt-2 mt-sm-0' : '')}
              onClick={async () => {
                if (staticData.SHOW_APPLE_LOGIN_WARNING) {
                  setAppleLoginWarningModalOpen(true);
                } else requestAppleLogin();
              }}
            >
              <div className={'d-flex align-items-center'}>
                <FontAwesomeIcon icon={faApple} fixedWidth={true} />
                <span style={{ fontSize: '85%' }}>{t('loginModal.labels.accediApple')}</span>
              </div>
            </button>
          )}
        </Modal.Body>
        {(isLoginMethodVisible('GOOGLE') || isLoginMethodVisible('APPLE')) && (
          <div style={{ position: 'relative' }}>
            <hr className={'mb-0 mt-4'} />
            <small className={'text-muted absolute-or'}>{t('loginModal.labels.oppure')}</small>
          </div>
        )}
        <Form onSubmit={onLoginSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label htmlFor={'email'}>{t('labels.email')}</Form.Label>
              <Form.Control required size={'lg'} type={'email'} id={'email'} value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>
            <Form.Group className={'mb-0'}>
              <Form.Label htmlFor={'password'}>{t('labels.password')}</Form.Label>
              <InputGroup>
                <Form.Control
                  required
                  size={'lg'}
                  type={passwordVisible ? 'text' : 'password'}
                  id={'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputGroup.Append>
                  <Button variant={'secondary'} onClick={() => setPasswordVisible((previousState) => !previousState)}>
                    <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye} fixedWidth={true} />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
              <Form.Text className={'mt-2'}>
                <a
                  href={'#'}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsPasswordRecoveryModalOpen(true);
                  }}
                >
                  {t('loginModal.labels.dimenticatoPassword')}
                </a>
              </Form.Text>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {showCancel && (
              <Button variant={'secondary'} onClick={() => setIsModalOpen(false)}>
                {t('labels.annulla')}
              </Button>
            )}
            <Button variant={'primary'} type={'submit'}>
              {t('labels.accedi')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <ErrorModal isModalOpen={isErrorModalOpen} setIsModalOpen={setIsErrorModalOpen} errorCode={errorCode} />

      <Modal show={isPasswordRecoveryModalOpen} centered={isNative} onHide={() => setIsPasswordRecoveryModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>{t('loginModal.labels.recuperoPassword')}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={onRecoverySubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label htmlFor={'recoveryEmail'}>{t('loginModal.labels.inserisciEmail')}</Form.Label>
              <Form.Control required size={'lg'} type={'email'} id={'recoveryEmail'} value={recoveryEmail} onChange={(e) => setRecoveryEmail(e.target.value)} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant={'secondary'} onClick={() => setIsPasswordRecoveryModalOpen(false)}>
              {t('labels.annulla')}
            </Button>
            <Button variant={'primary'} type={'submit'}>
              {t('labels.conferma')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={isSuccessModalOpen} centered={isNative} onHide={() => setIsSuccessModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>{t('loginModal.labels.emailInviata')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('loginModal.labels.verificaEmail')}</Modal.Body>
        <Modal.Footer>
          <Button variant={'primary'} onClick={() => setIsSuccessModalOpen(false)}>
            {t('labels.chiudi')}
          </Button>
        </Modal.Footer>
      </Modal>

      <MFACode
        show={isMfaCodeModalOpen}
        onHide={() => setIsMfaCodeModalOpen(false)}
        resolver={resolver}
        onSuccess={() => {
          setIsMfaCodeModalOpen(false);
          setIsModalOpen(false);
          setTimeout(() => {
            history.replace('/browse');
          });
        }}
        onError={(e) => {
          setErrorCode(e.code);
          setIsErrorModalOpen(true);
        }}
      />

      <Modal show={appleLoginWarningModalOpen} centered={isNative} onHide={() => setAppleLoginWarningModalOpen(false)}>
        <Modal.Body>{staticData.APPLE_LOGIN_WARNING}</Modal.Body>
        <Modal.Footer>
          <Button variant={'secondary'} onClick={() => setAppleLoginWarningModalOpen(false)}>
            {t('labels.annulla')}
          </Button>
          <Button
            variant={'primary'}
            onClick={() => {
              setAppleLoginWarningModalOpen(false);
              setAppleLoginProceed(true);
            }}
          >
            {t('labels.prosegui')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { LoginModalComponent as LoginModal };
