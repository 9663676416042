import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirebase } from '../../../contexts/FirebaseContext';
import { ErrorModal } from '../ErrorModal/ErrorModal';
import { MFACode } from '../MFACode/MFACode';
import './LoginWithGoogle.scss';

const LoginWithGoogleComponent = ({ block = false, size = 'lg', className = '', onAuthorize = () => {}, reauth = false }) => {
  const { loginWithGoogle, reauthenticateGoogle, analytics, currentUser } = useFirebase();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorCode, setErrorCode] = useState('');
  const [isMfaCodeModalOpen, setIsMfaCodeModalOpen] = useState(false);
  const [resolver, setResolver] = useState<any>(null);
  const { t } = useTranslation();

  const callLoginWithGoogle = async () => {
    setErrorCode('');
    try {
      if (reauth) await reauthenticateGoogle();
      else {
        await loginWithGoogle();
        analytics.logEvent('login', { user_id: currentUser.uid });
      }
      onAuthorize();
    } catch (error: any) {
      if (error.code == 'auth/popup-closed-by-user') return;
      if (error.code == 'auth/cancelled-popup-request') return;

      if (error.code == 'auth/multi-factor-auth-required') {
        setResolver(error.resolver);
        setIsMfaCodeModalOpen(true);
        return;
      }

      if (error.code) {
        console.error(error);

        if (error.code == 'auth/internal-error') {
          if (error.message.includes('Unauthorized login location')) {
            setErrorCode('custom/locationError');
          } else if (error.message.includes('HTTP Cloud Function')) {
            setErrorCode('custom/password-signup-required');
          }
        } else setErrorCode(error.code);
        setIsErrorModalOpen(true);
      }
    }
  };

  return (
    <>
      <button className={'google-login ' + className} onClick={callLoginWithGoogle}>
        <div className={'d-flex align-items-center'}>
          <img
            alt={'Google'}
            style={{ backgroundColor: '#FFFFFF', padding: '8px', borderRadius: '4px' }}
            src={process.env.PUBLIC_URL + '/google-logo.png'}
            className={'mr-2'}
          />
          <span style={{ fontSize: '85%' }}>{t('loginWithGoogle.labels.accediConGoogle')}</span>
        </div>
      </button>

      <MFACode
        show={isMfaCodeModalOpen}
        onHide={() => setIsMfaCodeModalOpen(false)}
        resolver={resolver}
        onSuccess={() => {
          setIsMfaCodeModalOpen(false);
          onAuthorize();
        }}
        onError={(e) => {
          setErrorCode(e.code);
          setIsErrorModalOpen(true);
        }}
      />

      <ErrorModal isModalOpen={isErrorModalOpen} setIsModalOpen={setIsErrorModalOpen} errorCode={errorCode} />
    </>
  );
};

export { LoginWithGoogleComponent as LoginWithGoogle };
